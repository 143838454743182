import {
  isAndroid,
  isBrowser,
  isChrome,
  isEdge,
  isFirefox,
  isIOS,
  isMacOs,
  isMobile,
  isSafari,
  isTablet,
  isWindows,
  isWinPhone,
} from "react-device-detect";
import { TRACKING_EVENTS_NAME } from "../constants/trackingEvents";
import { isProd } from "../store/utils";

export const getDebugEventUserIdPrefix = isProd ? "" : "-"; // TODO: remove and use the method in src/utils/analyticsUtils instead

export const getDebugEventPrefix = isProd ? "" : "debug_";

interface DevicePlatform {
  isAndroid: boolean;
  isIOS: boolean;
  isWinPhone: boolean;
  isMacOs: boolean;
  isWindows: boolean;
}

interface DeviceBrowser {
  isSafari: boolean;
  isChrome: boolean;
  isFirefox: boolean;
  isEdge: boolean;
}

interface DeviceFormFactor {
  isMobile: boolean;
  isTablet: boolean;
  isBrowser: boolean;
}

interface DeviceInfo {
  platform: DevicePlatform;
  browser: DeviceBrowser;
  formFactor: DeviceFormFactor;
}

let cachedDeviceInfo: DeviceInfo | null = null;

const getDeviceInfo = (): DeviceInfo => {
  if (cachedDeviceInfo) {
    return cachedDeviceInfo;
  }

  cachedDeviceInfo = {
    platform: {
      isAndroid,
      isIOS,
      isWinPhone,
      isMacOs,
      isWindows,
    },
    browser: {
      isSafari,
      isChrome,
      isFirefox,
      isEdge,
    },
    formFactor: {
      isMobile,
      isTablet,
      isBrowser,
    },
  };

  return cachedDeviceInfo;
};

export interface AnalyticsEvent {
  name: string;
  data?: Record<string, unknown>;
  options?: SegmentAnalytics.SegmentOpts;
  callback?: (success: boolean) => void;
}

export const trackEvent = (eventData: AnalyticsEvent) => {
  if (!window.analytics) {
    eventData.callback?.(false);
    return;
  }

  const callback = () => {
    eventData.callback?.(true);
  };

  try {
    window.analytics.track(
      `${getDebugEventPrefix}${eventData.name}`,
      {
        ...eventData.data,
        device: getDeviceInfo(),
      },
      eventData.options,
      callback,
    );
  } catch {
    eventData.callback?.(false);
    return;
  }
};

export const emitAnalyticsTrackingEvent = (name: string, data: object) => {
  if (!window.analytics) {
    return;
  }

  try {
    window.analytics.track(`${getDebugEventPrefix}${name}`, {
      ...data,
      device: getDeviceInfo(),
    });
  } catch {
    return;
  }
};

export const identifyAuthenticatedUser = (
  userId: number,
  data: object,
  anonymousId?: string,
) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify(
    `${getDebugEventUserIdPrefix}${userId}`,
    {
      ...data,
      device: getDeviceInfo(),
    },
    {
      anonymousId,
    },
  );
  emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.AUTH_USER_IDENTIFIED, {
    user_id: `${getDebugEventUserIdPrefix}${userId}`,
  });
};

export const identifyUnauthenticatedUser = (data: object) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify(data);
};
