import { MusoCredit } from "../../muso";
import { PlaylistTrack } from "../types";
import convertMusoCreditToPlayListTrack from "./convertMusoCreditToPlayListTrack";

const convertMusoCreditsToPlaylistTrack = (
  musoCredits: MusoCredit[],
  engineerDisplayName?: string,
): PlaylistTrack[] => {
  return musoCredits
    .filter((credit) => credit.spotify_preview_url)
    .map((credit) =>
      convertMusoCreditToPlayListTrack(credit, engineerDisplayName),
    );
};

export default convertMusoCreditsToPlaylistTrack;
