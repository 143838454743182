import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  pointerWithin,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  BoxProps,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Button, ButtonVariant } from "../Button/Button";
import { Chip, ChipProps, ChipVariant } from "../Chip/Chip";
import { ChipAsButton } from "../Chip/Chip.styles";
import { ChipsListContainer } from "./ChipsList.styles";
import { SortableItem } from "./SortableItem";

const DEFAULT_SHORT_LIST_LENGTH = 8;

export interface ISingleItem {
  value: UniqueIdentifier;
  label: string;
}

export interface ChipsListProps extends BoxProps {
  dataSource: ISingleItem[]; // This needs to be a memoized value. Any state value should be fine
  editMode?: boolean;
  loading?: boolean;
  optionsToAdd?: ISingleItem[];
  shortListLength?: number;
  chipVariant?: ChipVariant;
  chipProps?: ChipProps;
  autoCompletePropsSx?: object;
  chipContainerSx?: SxProps<Theme>;
  onAddItem?: (newItem: UniqueIdentifier, label?: string) => void;
  onDeleteItem?: (item: UniqueIdentifier) => void;
  onFinishReordering?: (newState: ISingleItem[], reset: () => void) => void;
  handleElementClick?: (value: string | number) => void;
  useConfirm?: boolean;
}

export const ChipsList = (props: ChipsListProps) => {
  const {
    dataSource,
    editMode,
    loading,
    optionsToAdd,
    shortListLength = DEFAULT_SHORT_LIST_LENGTH,
    chipVariant = ChipVariant.OUTLINED,
    onAddItem,
    onDeleteItem,
    onFinishReordering,
    chipProps = {},
    handleElementClick = (value: string | number) => {},
    chipContainerSx = {},
    autoCompletePropsSx = {},
    ...containerProps
  } = props;

  const theme = useTheme();

  const [internalItems, setInternalItems] = useState(dataSource);
  const [draggingItem, setDraggingItem] = useState<ISingleItem | null>(null);
  const [itemToBeAdded, setItemToBeAdded] = useState<ISingleItem | null>(null);
  const [showFullList, setShowFullList] = useState(false);
  const [showAddOption, setShowAddOption] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Set the delay for the item to become draggable
      // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
  );

  // This effect sync the internal state with the changes in the outside world
  useEffect(() => {
    setInternalItems(dataSource);
    setItemToBeAdded(null);
  }, [dataSource]);

  const filteredOptionsToAdd = useMemo(() => {
    return (
      optionsToAdd?.filter(
        (opt) =>
          internalItems.findIndex((item) => item.value === opt.value) === -1,
      ) || []
    );
  }, [internalItems, optionsToAdd]);

  const handleDragEnd = () => {
    setDraggingItem(null);
    onFinishReordering?.(internalItems, () => setInternalItems(dataSource));
  };

  const handleDragStart = (e: DragStartEvent) => {
    setDraggingItem(
      internalItems.find((el) => el.value === e.active.id) || null,
    );
  };

  useEffect(() => {
    if (!editMode) {
      setShowAddOption(false);
    }
  }, [editMode]);

  // This updates state on every drag over. For now, this should be fine since we don't expect
  // the list to be big and drag n drop is not something that the user does every so often
  // This is the solution for now since the library doesn't provide a sorting strategy that supports both horizontal + vertical
  // Another option is writing our own sorting strategy, which could be done at a later stage if needed
  // https://github.com/clauderic/dnd-kit/issues/44#issuecomment-757312037
  const handleDragOver = (e: DragOverEvent) => {
    const { active, over } = e;

    if (over && active.id !== over.id) {
      setInternalItems((items) => {
        const oldIndex = items.findIndex((item) => item.value === active.id);
        const newIndex = items.findIndex((item) => item.value === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const renderChipItem = (item: ISingleItem) => {
    return (
      <Button
        style={{ minHeight: "unset" }}
        onClick={() => handleElementClick(item.value)}
        variant={ButtonVariant.UNSTYLED}
      >
        <Chip
          text={item.label}
          variant={chipVariant}
          onDelete={
            editMode && onDeleteItem
              ? () => onDeleteItem(item.value)
              : undefined
          }
          loading={loading}
          {...chipProps}
        />
      </Button>
    );
  };

  let listToBeDisplayed = internalItems;
  if (!showFullList && !editMode && internalItems.length > shortListLength) {
    listToBeDisplayed = internalItems.slice(0, shortListLength);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      gap={1}
      {...containerProps}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragEnd}
        onDragOver={handleDragOver}
      >
        <SortableContext
          items={internalItems.map((i) => i.value)}
          strategy={() => null}
        >
          <ChipsListContainer sx={chipContainerSx}>
            {listToBeDisplayed.map((item) => (
              <SortableItem
                key={item.value}
                id={item.value}
                disableDragging={!editMode || loading}
              >
                {renderChipItem(item)}
              </SortableItem>
            ))}
            <DragOverlay>
              {draggingItem ? renderChipItem(draggingItem) : null}
            </DragOverlay>
            {editMode && onAddItem && (
              <Button
                style={{ fontSize: "12px" }}
                variant={ButtonVariant.UNSTYLED}
                labelIcon={
                  <FontAwesomeIcon
                    icon={showAddOption ? faCircleMinus : faCirclePlus}
                  />
                }
                disabled={loading}
                onClick={() => setShowAddOption(!showAddOption)}
              >
                {showAddOption ? "Hide" : "Add"}
              </Button>
            )}
            {!editMode && internalItems.length > shortListLength && (
              <ChipAsButton
                variant={ButtonVariant.UNSTYLED}
                onClick={() => setShowFullList((e) => !e)}
                loading={loading}
                {...chipProps}
                style={{
                  border: "unset",
                }}
              >
                {showFullList ? "View less" : "View more"}
              </ChipAsButton>
            )}
          </ChipsListContainer>
        </SortableContext>
      </DndContext>
      {editMode && onAddItem && showAddOption && (
        <Autocomplete
          disabled={loading}
          {...autoCompletePropsSx}
          value={itemToBeAdded}
          onChange={(_, newVal) => {
            if (!newVal) {
              return;
            }
            onAddItem(newVal.value, newVal.label);
          }}
          renderInput={(params) => (
            <TextField
              placeholder="Type "
              {...params}
              sx={{
                "& .MuiInputBase-root": {
                  height: "44px",
                  borderRadius: "var(--border-radius-md)",
                },
                ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: theme.palette.text.primary,
                    borderWidth: "1px",
                  },
              }}
            />
          )}
          options={filteredOptionsToAdd}
          getOptionLabel={(option) => option.label}
          clearOnBlur
          slotProps={{
            paper: {
              sx: {
                border: `1px solid ${theme.palette.text.primary}`,
                boxShadow: "none",
                marginTop: "8px",
                borderRadius: theme.border.radius.md,

                "& .MuiAutocomplete-listbox": {
                  padding: "8px",
                  borderRadius: theme.border.radius.md,
                },
              },
            },
            clearIndicator: {
              sx: {
                color: theme.palette.text.primary,
              },
            },
            popupIndicator: {
              sx: {
                color: theme.palette.text.primary,
              },
            },
          }}
        />
      )}
    </Box>
  );
};
