import { useSetAtom } from "jotai/index";
import { useCallback } from "react";
import { COMMANDS, POWER_USER_CONTROLS_LABEL } from "../types";
import { usePowerUserControl } from "../usePowerUserControl";
import { powerUserControlsHelpModalAtom } from "../../../atoms/modals/powerUserControlsHelpModalAtom";

const LABEL = POWER_USER_CONTROLS_LABEL.HELP;

/**
 * Sets up the power user control that shows/hides the shortcuts modal.
 *
 * When the user presses the shortcut, the modal is toggled.
 *
 * @returns The shortcuts data, which is an object with two properties:
 * - `keys`: an array of strings representing the keys that trigger the shortcut
 * - `label`: a string that describes the shortcut
 */
const useHelpControls = () => {
  const setPowerUserControlsHelpModal = useSetAtom(
    powerUserControlsHelpModalAtom,
  );
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      setPowerUserControlsHelpModal((prev) => !prev);
    },
    [setPowerUserControlsHelpModal],
  );
  return usePowerUserControl(LABEL, COMMANDS[LABEL], handleKeyPress);
};

export default useHelpControls;
