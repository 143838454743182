import React from "react";
import { toast } from "react-toastify";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  updateUserAAndR,
  updateUserAdmin,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getImageSourceAndText,
  MajorLabelEnum,
  UMGSubLabelEnum,
  UMGSubLabelList,
} from "../../../store/models/trophy";
import { Button } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  LabelItemContainer,
  LabelItemPhoto,
  LabelSelectionFormBody,
  LabelSelectionFormContainer,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import { DISCIPLINE_TYPE, getUserDisciplines } from "../../../hooks/user";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../Navigation/BottomNav/useBottomTabBarOverlayView";
import { Box } from "@mui/material";

export const LabelSelection = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();
  const { isLoading } = useAppSelector((state) => state.accountInfo);
  const [selectedOption, setSelectedOption] =
    React.useState<UMGSubLabelEnum | null>(
      user?.aandr?.label?.[0]?.umg_sub_label ||
        user?.admin?.label?.[0]?.umg_sub_label ||
        null,
    );
  const { isDesktop } = useMediaQueryBreakpoint();
  const userDisciplines = getUserDisciplines(user);

  const handleLabelSelection = () => {
    if (selectedOption === null) return;

    emitAnalyticsTrackingEvent("auth_label_selection_next_clicked", {});

    const promises = [];

    if (userDisciplines.includes(DISCIPLINE_TYPE.A_AND_R)) {
      promises.push(
        dispatch(
          updateUserAAndR({
            is_primary_type: true,
            deleted: false,
            major_label: MajorLabelEnum.UMG,
            sub_label: [selectedOption],
          }),
        ).unwrap(),
      );
    }

    if (userDisciplines.includes(DISCIPLINE_TYPE.ADMIN)) {
      promises.push(
        dispatch(
          updateUserAdmin({
            deleted: false,
            major_label: MajorLabelEnum.UMG,
            sub_label: [selectedOption],
          }),
        ).unwrap(),
      );
    }

    Promise.all(promises)
      .then(() => {
        nextStep();
      })
      .catch(() => {
        toast.error("Error setting sub-label. Please try again.");
      });
  };

  const continueMobileStickyButton = useBottomTabBarOverlayView(
    !isDesktop,
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        flexWrap: "nowrap",
      }}
    >
      <Button
        style={{ borderRadius: "0px" }}
        onClick={() => handleLabelSelection()}
        disabled={isLoading || selectedOption === null}
        loading={isLoading}
        fullWidth
      >
        Continue
      </Button>
    </Box>,
    DEFAULT_TAB_OVERLAY_CLASS,
  );

  return (
    <LabelSelectionFormContainer>
      <AuthNavigation />
      <Text variant={TextStyleVariant.H3}>Choose your label</Text>
      <Text variant={TextStyleVariant.P1}>
        Select your primary label association
      </Text>
      <LabelSelectionFormBody>
        {UMGSubLabelList.map((option: UMGSubLabelEnum, index: number) => {
          const { source, text } = getImageSourceAndText(
            option,
            MajorLabelEnum.UMG,
          );
          return (
            <LabelItemContainer key={index}>
              <LabelItemPhoto
                key={index}
                onClick={() => {
                  if (selectedOption === option) {
                    setSelectedOption(null);
                  } else {
                    setSelectedOption(option);
                  }
                }}
                $isSelected={selectedOption === option}
              >
                {option === UMGSubLabelEnum.UMG_UNKNOWN && (
                  <Text
                    variant={TextStyleVariant.P1}
                    weight={TEXT_WEIGHT.SEMI_BOLD}
                    color={TextColor.BLACK}
                  >
                    {text ?? ""}
                  </Text>
                )}
                {option !== UMGSubLabelEnum.UMG_UNKNOWN && (
                  <img
                    src={source}
                    alt={text ?? ""}
                    width={!isDesktop ? 150 : 200}
                    height={!isDesktop ? 150 : 200}
                    style={{ borderRadius: "7px" }}
                  />
                )}
              </LabelItemPhoto>
              <Text
                variant={TextStyleVariant.P1}
                weight={TEXT_WEIGHT.SEMI_BOLD}
              >
                {text ?? ""}
              </Text>
            </LabelItemContainer>
          );
        })}
      </LabelSelectionFormBody>
      {!isDesktop ? (
        selectedOption ? (
          continueMobileStickyButton
        ) : null
      ) : (
        <Button
          onClick={() => handleLabelSelection()}
          disabled={isLoading || selectedOption === null}
          loading={isLoading}
        >
          Continue
        </Button>
      )}
    </LabelSelectionFormContainer>
  );
};
