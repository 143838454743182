import { useMutation } from "@tanstack/react-query";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../api/helpers";
import { QUERY_KEYS } from "../constants/queryKeys";
import { receiveErrors } from "../store/actions/errorStore";
import { ServiceOperations } from "../store/actions/services";
import { useAppDispatch } from "../store/hooks";
import { APIService } from "../store/models/service";
import { makeBackendPostCallWithJsonResponse } from "../store/utils/fetch";
import { ENGINEER_SERVICE_CRUD } from "../store/utils/routes";

interface useDeleteEngineerServiceProps {
  onSuccess: (data: APIService) => void;
}

interface mutationFnProps {
  id: number;
}

export const useDeleteEngineerService = ({
  onSuccess,
}: useDeleteEngineerServiceProps) => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: [QUERY_KEYS.DELETE_ENGINEER_SERVICE],
    mutationFn: async ({ id }: mutationFnProps) => {
      const response = await makeBackendPostCallWithJsonResponse<APIService>(
        ENGINEER_SERVICE_CRUD,
        { id, operation: ServiceOperations.DELETE },
      );
      if (response.success) {
        return response.resultJson;
      }

      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
    onError: (error) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
    },
    onSuccess,
  });
};
