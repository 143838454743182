import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useCanAddEngineerService } from "../../../hooks/useCanAddEngineerService";
import { useHasMasteringService } from "../../../hooks/useHasMasteringService";
import useManageEngineeringService, {
  MAXIMUM_DEPOSIT,
  MINIMUM_DEPOSIT,
} from "../../../hooks/useManageEngineeringService";
import { useMinAndMaxServiceRates } from "../../../hooks/useMinAndMaxServiceRates";
import { useMinimumDepositDescription } from "../../../hooks/useMinimumDepositDescription";
import { useAppSelector } from "../../../store/hooks";
import {
  ProjectType,
  ScheduledProjectPaywallOptions,
} from "../../../store/models/project";
import Service from "../../../store/models/service";
import { selectEngineerHasDolbyServices } from "../../../store/selectors/servicesSelectors";
import {
  getNumberAsOptionType,
  getNumberedOptions,
} from "../../../store/utils/serviceUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { DropdownSelector } from "../../elements/DropDownSelector/DropdownSelector";
import { PercentageTextfield } from "../../elements/PercentageTextField/PercentageTextfield";
import { PriceField } from "../../elements/PriceField/PriceField";
import { ToggleBundleDiscount } from "../../elements/ToggleBundledDiscount/ToggleBundledDiscount";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import EngineeringServiceDownloadPreference from "../EngineeringServiceDownloadPreference/EngineeringServiceDownloadPreference";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import EngineeringServiceBulkSongDiscountPreference from "./EngineeringServiceBulkSongDiscountPreference";
import "./ManageEngineeringService.css";
import {
  PlatinumUpgradeContainer,
  ServiceFormContainer,
  ServiceFormInputContainer,
  ServiceFormLabel,
  ServiceFormLabelContainer,
  ServiceFormLabelSubtext,
  ServiceFormRow,
  ServiceRateContainer,
  ServiceRateFeeContainer,
} from "./ManageEngineeringService.styles";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { getAddSubscriptionRoute } from "../../../store/utils/routeGetters";
import Link from "../../elements/Link/Link";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { BaseAccordion } from "../../core-ui/components/BaseAccordion/BaseAccordion";

// eslint-disable-next-line import/no-unused-modules
export interface ManageEngineeringServiceProps {
  service?: Service;
  selectedServiceType: ProjectType;
  isUpdating: boolean;
  onUpdatePrice: (newPrice: number) => void;
  pendingService: Service;
  setPendingService: Dispatch<SetStateAction<Service>>;
  handleDeleteClick: (serviceId: number, serviceType: ProjectType) => void;
}

export const ManageEngineeringService = ({
  service,
  selectedServiceType,
  isUpdating,
  onUpdatePrice,
  pendingService,
  setPendingService,
  handleDeleteClick,
}: ManageEngineeringServiceProps) => {
  const theme = useTheme();
  const [minServiceRate, maxServiceRate] = useMinAndMaxServiceRates(
    service ? service.service_type : selectedServiceType,
  );

  const {
    allowPartialPayments,
    setAllowPartialPayments,
    handleDepositPercentage,
    updateCostPerExtraAlt,
    updateExtraRevisionPrice,
    changeServiceRateForLabelPrice,
    updateNumberOfFreeRevisions,
    changeServiceRatePrice,
    updateWAVDownloadPreference,
    updateMP3DownloadPreference,
    handlePartialPaymentPaywallOption,
    addBulkDiscount,
    setAddBulkDiscount,
  } = useManageEngineeringService({
    onUpdatePrice,
    pendingService,
    setPendingService,
  });

  const minimumDepositDescription =
    useMinimumDepositDescription(pendingService);

  const services = useAppSelector((state) => state.engineerServices.services);
  const hasMaster = useHasMasteringService(services);
  const engineerHasDolbyServices = useAppSelector(
    selectEngineerHasDolbyServices,
  );
  const { canAddService, message } = useCanAddEngineerService(pendingService);

  const updateBulkSongDiscountPreference = (value: boolean) => {
    setPendingService({
      ...pendingService,
      enable_bulk_song_discounts: value,
    });
  };

  const userData = useAppSelector((state) => state.accountInfo.user);
  const { data: subscription } = useGetSubscriptionStatus({
    user: userData,
    refetchSubsStatus: true,
    force: true,
  });
  const showUpgradeButton = useMemo(() => {
    return (
      subscription?.stripe_subscription_status &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY
    );
  }, [subscription]);

  if (!canAddService && message && !isUpdating) {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  }

  const canBeBundledWithMasterService =
    pendingService.service_type === ProjectType.MIXING ||
    pendingService.service_type === ProjectType.TWO_TRACK_MIXING;
  const canBeBundledWithDolbyService =
    pendingService.service_type === ProjectType.MIXING;

  return (
    <ServiceFormContainer>
      <ServiceRateContainer>
        <ServiceFormRow>
          <ServiceFormLabelContainer>
            <ServiceFormLabel htmlFor="service-rate-input">
              Service Rate
            </ServiceFormLabel>
            <ServiceFormLabelSubtext>
              (For independent artists)
            </ServiceFormLabelSubtext>
          </ServiceFormLabelContainer>
          <ServiceFormInputContainer>
            <PriceField
              min={minServiceRate}
              max={maxServiceRate}
              value={pendingService.service_rate?.price}
              placeholder="Service Rate"
              onChange={changeServiceRatePrice}
              id="service-rate-input"
            />
          </ServiceFormInputContainer>
        </ServiceFormRow>
        {showUpgradeButton && (
          <>
            <ServiceRateFeeContainer>
              <Text variant={TextStyleVariant.P3} weight={TEXT_WEIGHT.BOLD}>
                Service fee rate (10%)
              </Text>
              <Text variant={TextStyleVariant.P3} weight={TEXT_WEIGHT.BOLD}>
                {PennyDollarFormatter().format(
                  pendingService.service_rate?.price * 0.1,
                )}
              </Text>
            </ServiceRateFeeContainer>
            <PlatinumUpgradeContainer>
              <Text
                variant={TextStyleVariant.P1}
                weight={TEXT_WEIGHT.BOLD}
                style={{ color: theme.palette.standardColor.DeepBlue[800] }}
              >
                Reduce your service fee rate to 0% by upgrading to Platinum!
              </Text>
              <Link
                to={getAddSubscriptionRoute()}
                style={{ textDecoration: "none" }}
              >
                <Text
                  variant={TextStyleVariant.P3}
                  style={{
                    display: "inline",
                    color: theme.palette.standardColor.DeepBlue[800],
                    textDecoration: "underline",
                  }}
                >
                  learn more
                </Text>
              </Link>
            </PlatinumUpgradeContainer>
          </>
        )}
      </ServiceRateContainer>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
            <ServiceFormLabel>Number of free revisions</ServiceFormLabel>
            <ToolTipTextArea
              text={"Rounds of edits allowed after the first mix"}
            />
          </Box>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <DropdownSelector
            value={getNumberAsOptionType(pendingService.free_revisions ?? 3)}
            onChange={updateNumberOfFreeRevisions}
            options={getNumberedOptions(1, 6)}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      {selectedServiceType === ProjectType.MASTERING && (
        <ServiceFormRow>
          <ServiceFormLabelContainer>
            <ServiceFormLabel htmlFor="extra-alt-cost-input">
              Cost per extra alt
            </ServiceFormLabel>
            <ServiceFormLabelSubtext>
              *Note: main and clean alts are included.
            </ServiceFormLabelSubtext>
          </ServiceFormLabelContainer>

          <ServiceFormInputContainer>
            <PriceField
              min={
                pendingService.service_rate.price
                  ? (5 * pendingService.service_rate.price) / 100
                  : 5
              }
              max={
                pendingService.service_rate.price
                  ? (50 * pendingService.service_rate.price) / 100
                  : 50
              }
              value={pendingService.extra_alt_price ?? 5}
              placeholder="Cost per extra revision"
              onChange={function (value: number): void {
                updateCostPerExtraAlt(value);
              }}
              id="extra-alt-cost-input"
            />
          </ServiceFormInputContainer>
        </ServiceFormRow>
      )}
      <BaseAccordion
        renderHeader={() => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text bold variant={TextStyleVariant.P2}>
              Advanced settings
            </Text>
          </Box>
        )}
        sx={{
          "&.MuiAccordion-root": {
            boxShadow: "none",
            borderBottom: "none",
            backgroundColor: "transparent",
            backgroundImage: "none",
          },
          "& .MuiAccordionSummary-root": {
            padding: "0px",
          },
          "&::before": {
            display: "none",
          },
        }}
      >
        <ServiceFormContainer>
          <ServiceFormRow>
            <ServiceFormLabelContainer>
              <Box
                sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}
              >
                <ServiceFormLabel htmlFor="extra-revision-cost-input">
                  Cost per extra revision
                </ServiceFormLabel>
                <ToolTipTextArea
                  text={
                    "Revision price cannot exceed 50% of service rate and must be greater than 5% of service rate"
                  }
                />
              </Box>
            </ServiceFormLabelContainer>
            <ServiceFormInputContainer>
              <PriceField
                min={
                  pendingService.service_rate.price
                    ? (5 * pendingService.service_rate.price) / 100
                    : 5
                }
                max={
                  pendingService.service_rate.price
                    ? (50 * pendingService.service_rate.price) / 100
                    : 50
                }
                value={pendingService.extra_revision_price ?? 5}
                placeholder="Revision price"
                onChange={function (value: number): void {
                  updateExtraRevisionPrice(value);
                }}
                id="extra-revision-cost-input"
              />
            </ServiceFormInputContainer>
          </ServiceFormRow>
          {selectedServiceType !== ProjectType.ATMOS_MIXING &&
            selectedServiceType !== ProjectType.MASTERING && (
              <ServiceFormRow>
                <ServiceFormLabelContainer>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "4px",
                    }}
                  >
                    <ServiceFormLabel htmlFor="extra-alt-cost-input">
                      Cost per extra alt
                    </ServiceFormLabel>
                    <ToolTipTextArea
                      text={
                        "Alt price cannot exceed 50% of service rate and must be greater than 5% of service rate"
                      }
                    />
                  </Box>
                  <ServiceFormLabelSubtext>
                    *Note: main and clean alts are included.
                  </ServiceFormLabelSubtext>
                </ServiceFormLabelContainer>

                <ServiceFormInputContainer>
                  <PriceField
                    min={
                      pendingService.service_rate.price
                        ? (5 * pendingService.service_rate.price) / 100
                        : 5
                    }
                    max={
                      pendingService.service_rate.price
                        ? (50 * pendingService.service_rate.price) / 100
                        : 50
                    }
                    value={pendingService.extra_alt_price ?? 5}
                    placeholder="Cost per extra revision"
                    onChange={function (value: number): void {
                      updateCostPerExtraAlt(value);
                    }}
                    id="extra-alt-cost-input"
                  />
                </ServiceFormInputContainer>
              </ServiceFormRow>
            )}
          <ServiceFormRow>
            <ServiceFormLabelContainer>
              <ServiceFormLabel htmlFor="label-rate-input">
                Label Rate
              </ServiceFormLabel>
              <ServiceFormLabelSubtext>
                (Rates apply once verified and visible to labels)
              </ServiceFormLabelSubtext>
            </ServiceFormLabelContainer>
            <ServiceFormInputContainer>
              <PriceField
                min={minServiceRate}
                max={maxServiceRate}
                value={
                  pendingService.service_rate?.label_price ?? minServiceRate
                }
                placeholder="Label Rate"
                onChange={changeServiceRateForLabelPrice}
                id="label-rate-input"
              />
            </ServiceFormInputContainer>
          </ServiceFormRow>
          <EngineeringServiceBulkSongDiscountPreference
            pendingService={pendingService}
            onUpdate={updateBulkSongDiscountPreference}
          />
          {selectedServiceType !== ProjectType.ATMOS_MIXING &&
            selectedServiceType !== ProjectType.MASTERING && (
              <ToggleBundleDiscount
                addService={addBulkDiscount}
                setAddService={setAddBulkDiscount}
                canBeBundledWithDolbyService={canBeBundledWithDolbyService}
                canBeBundledWithMasterService={canBeBundledWithMasterService}
                engineerHasDolbyServices={engineerHasDolbyServices}
                engineerHasMasterServices={hasMaster}
                setPendingService={setPendingService}
                pendingService={pendingService}
              />
            )}
          <ServiceFormRow>
            <ServiceFormLabelContainer>
              <Box
                sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}
              >
                <ServiceFormLabel htmlFor="partial-payments-toggle">
                  Allow partial payments&nbsp;
                </ServiceFormLabel>
                <ToolTipTextArea
                  text={
                    "Allows your clients to put down an initial deposit when booking; this percentage determines the initial deposit amount."
                  }
                />
              </Box>
            </ServiceFormLabelContainer>
            <ServiceFormInputContainer>
              <ToggleSwitch
                checked={allowPartialPayments}
                onChange={(checked: boolean) => {
                  setAllowPartialPayments(checked);
                }}
                id="partial-payments-toggle"
              />
            </ServiceFormInputContainer>
          </ServiceFormRow>
          {allowPartialPayments && (
            <>
              <ServiceFormRow>
                <DropdownSelector
                  value={ScheduledProjectPaywallOptions.find(
                    (option) =>
                      option.value ===
                      pendingService.partial_payment_paywall_option,
                  )}
                  options={ScheduledProjectPaywallOptions.slice(1)}
                  onChange={handlePartialPaymentPaywallOption}
                  placeholder="Select paywall option"
                />
              </ServiceFormRow>
              <ServiceFormRow>
                <ServiceFormLabelContainer>
                  <ServiceFormLabel htmlFor="minimum-deposit-required-input">
                    Minimum deposit required
                  </ServiceFormLabel>
                  <ServiceFormLabelSubtext>
                    (Initial deposit amount {minimumDepositDescription} per
                    song)
                  </ServiceFormLabelSubtext>
                </ServiceFormLabelContainer>
                <ServiceFormInputContainer>
                  <PercentageTextfield
                    max={MAXIMUM_DEPOSIT}
                    min={MINIMUM_DEPOSIT}
                    value={pendingService.minimum_deposit_percentage}
                    onChangePercentage={handleDepositPercentage}
                    step={5}
                    id="minimum-deposit-required-input"
                  />
                </ServiceFormInputContainer>
              </ServiceFormRow>
            </>
          )}
          <EngineeringServiceDownloadPreference
            show={false}
            pendingService={pendingService}
            onWAVUpdate={updateWAVDownloadPreference}
            onMP3Update={updateMP3DownloadPreference}
          />
        </ServiceFormContainer>
      </BaseAccordion>
      {isUpdating && (
        <ServiceFormRow>
          <PopConfirm
            title="Are you sure?"
            description="You are about to remove this service. Are you sure?"
            onConfirm={() => {
              if (!service?.id) return;
              handleDeleteClick(service.id, selectedServiceType);
            }}
          >
            <Button
              variant={ButtonVariant.DELETE}
              style={{ marginLeft: "auto", minWidth: "140px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete this service
              </Box>
            </Button>
          </PopConfirm>
        </ServiceFormRow>
      )}
    </ServiceFormContainer>
  );
};
