import { GET_ACTIVE_STUDIOS } from "../../../store/utils/routes";
import { ProfileType } from "../../../store/models/base";
import { SlimActiveStudio } from "../../../store/models/studio";
import { makeBackendGetCallWithJsonResponse } from "../../../store/utils/fetch";
import { promiseEngineEarsHandler } from "../../helpers";
import queryString from "query-string";

export interface FetchActiveStudiosParams {
  user_id?: number;
}

export const fetchActiveStudios = async (args: FetchActiveStudiosParams) => {
  return makeBackendGetCallWithJsonResponse<SlimActiveStudio[]>(
    GET_ACTIVE_STUDIOS,
    `?${queryString.stringify(args, { skipEmptyString: true, skipNull: true })}`,
  )
    .then(promiseEngineEarsHandler)
    .then((resultJson) => {
      const transformedData: SlimActiveStudio[] = resultJson.map((value) => {
        return {
          ...value,
          type: ProfileType.STUDIO,
          displayableName: value.display_name
            ? value.display_name
            : `@${value.username}`,
          hasSeparateStripeEnabled:
            Boolean(value.separate_stripe_account_opt_in) &&
            Boolean(value.stripe_account_id),
        };
      });
      return transformedData;
    });
};
