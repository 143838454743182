import { Project, ProjectById } from "../../project";
import { PlaylistTrack } from "../types";
import convertProjectByIdToPlayListTrack from "./convertProjectByIdToPlayListTrack";
import convertProjectToPlayListTrack from "./convertProjectToPlayListTrack";
import isValidProjectForPlaylist from "./isValidProjectForPlaylist";

const convertProjectsToPlayListTracks = (
  projects: Project[] | ProjectById[],
): PlaylistTrack[] => {
  if (
    Array.isArray(projects) &&
    (projects as Project[]).every((project) => "artist" in project)
  ) {
    return (projects as Project[]).reduce(
      (acc: PlaylistTrack[], project: Project) => {
        if (isValidProjectForPlaylist(project)) {
          acc.push(convertProjectToPlayListTrack(project));
        }
        return acc;
      },
      [],
    );
  } else {
    return (projects as ProjectById[]).reduce(
      (acc: PlaylistTrack[], project: ProjectById) => {
        if (isValidProjectForPlaylist(project)) {
          acc.push(convertProjectByIdToPlayListTrack(project));
        }
        return acc;
      },
      [],
    );
  }
};

export default convertProjectsToPlayListTracks;
