import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIMixingProject } from "../../models/project";
import { makeBackendPostCallWithJsonResponse } from "../../utils/fetch";
import { ARTIST_MIXING_PROJECT } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { ProjectParams } from "./types";

interface artistMixTransitionParams extends ProjectParams {
  artist_mix_notes?: string;
  artist_mix_revision_notes?: string;
  code?: string | null;
  artist_file_link?: string;
  final_asset_file_notes?: string;
  instagram_username?: string;
  social_media_sharing_approval?: boolean;
  // TODO: Remove this param once ArtistMixingProjectAPI has been updated to handle in progress projects.
  is_in_progress_project?: boolean;
}

const artistMixingTransitions = createAsyncThunk(
  ARTIST_MIXING_PROJECT,
  async (args: artistMixTransitionParams, thunkAPI) => {
    if (!args.code) {
      delete args.code;
    }
    const response =
      await makeBackendPostCallWithJsonResponse<APIMixingProject>(
        ARTIST_MIXING_PROJECT,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default artistMixingTransitions;
