/**
 * Gets the user's platform.
 *
 * This function first tries to get the user's platform from the `userAgentData`
 * API, which is supported in newer browsers. If that fails, it falls back to
 * using the `platform` property from the `navigator` object.
 *
 * @returns The user's platform
 */
export const getUserPlatform = () => {
  // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/platform
  // @ts-expect-error the userAgentData does exist in new browsers
  return window.navigator.userAgentData?.platform || window.navigator.platform;
};
