import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  displayEngineEarsError,
  promiseEngineEarsHandler,
} from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { setUser, userEngineerParams } from "../../store/actions/accountInfo";
import { EngineEarsError, ErrorsAction } from "../../store/actions/errorStore";
import { updateUsers } from "../../store/actions/users";
import { useAppDispatch } from "../../store/hooks";
import User from "../../store/models/user";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { USER_ENGINEER } from "../../store/utils/routes";

export const useUpdateUserEngineer = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_USER_ENGINEER],
    mutationFn: async (body: userEngineerParams) => {
      return makeBackendPostCallWithJsonResponse<User>(
        USER_ENGINEER,
        body,
      ).then(promiseEngineEarsHandler);
    },
    onSuccess: (updatedUser) => {
      dispatch(setUser(updatedUser));
      dispatch(updateUsers(updatedUser));
      queryClient.setQueryData(
        [QUERY_KEYS.GET_USER_PROFILE, updatedUser.username],
        updatedUser,
      );
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return { errors: error } as ErrorsAction;
    },
  });
};
