import { DialogActions, DialogActionsProps } from "@mui/material";
import classNames from "classnames";
import { TrackableClassNames } from "../../../../constants/trackableClassNames";

type ResponsiveDialogActionsProps = Partial<DialogActionsProps>;

export const ResponsiveDialogActions = (
  actionsProps: ResponsiveDialogActionsProps,
) => {
  const {
    children: actionsChildren,
    className: actionsClassName,
    ...remainingActionsProps
  } = actionsProps;
  return (
    <DialogActions
      className={classNames(
        TrackableClassNames.ENGINEEARS_DIALOG_ACTIONS,
        actionsClassName,
      )}
      {...remainingActionsProps}
    >
      {actionsChildren}
    </DialogActions>
  );
};
