import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ArtistSuggestion,
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
  LocationSuggestion,
} from "../models/autocomplete";

export enum SearchOrigin {
  UNDEFINED_SEARCH_ORIGIN,
  EXPLORE_SCREEN,
  ENGINEER_SEARCH_SCREEN,
  STUDIO_ROOM_SEARCH_SCREEN,
}

interface userSearchState {
  page: number;
  latitude: number;
  longitude: number;
  autocompleteSuggestions: AutocompleteSuggestion[];
  requestingLatLong: boolean;
}

interface SetAutoCompleteSuggestionsPayload {
  autocompleteSuggestion: AutocompleteSuggestion;
}

export const initialState: userSearchState = {
  page: 1,
  latitude: 0,
  longitude: 0,
  autocompleteSuggestions: [],
  requestingLatLong: false,
};

const userSearchSlice = createSlice({
  name: "userSearch",
  initialState,
  reducers: {
    toggleRequestingLatLong: (state) => {
      state.requestingLatLong = !state.requestingLatLong;
    },
    requestLatLong: (state) => {
      state.requestingLatLong = true;
    },
    updateUserSearchPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    removeAutoCompleteSuggestions: (
      state,
      action: PayloadAction<Partial<AutocompleteSuggestion>>,
    ) => {
      state.page = 1;
      const suggestion = action.payload;
      switch (suggestion.type) {
        case AutoCompleteTypeEnum.ARTIST_CREDIT:
          const artistIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) =>
              suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
          );
          if (artistIndex !== -1) {
            state.autocompleteSuggestions.splice(artistIndex, 1);
          }
          break;
        case AutoCompleteTypeEnum.LOCATION:
          const locationIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
          );
          if (locationIndex !== -1) {
            state.autocompleteSuggestions.splice(locationIndex, 1);
          }
          break;
        default:
          break;
      }
    },
    setAutoCompleteSuggestions: (
      state,
      action: PayloadAction<SetAutoCompleteSuggestionsPayload>,
    ) => {
      state.page = 1;
      const { autocompleteSuggestion: suggestion } = action.payload;
      switch (suggestion.type) {
        case AutoCompleteTypeEnum.ARTIST_CREDIT:
          const artistSuggestions = suggestion;
          const artistIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) =>
              suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
          );
          if (artistIndex === -1) {
            state.autocompleteSuggestions.push(artistSuggestions);
          } else {
            const currentArtist = state.autocompleteSuggestions[
              artistIndex
            ] as ArtistSuggestion;
            if (currentArtist.label === artistSuggestions.label) {
              state.autocompleteSuggestions.splice(artistIndex, 1);
            } else {
              state.autocompleteSuggestions.splice(artistIndex, 1);
              state.autocompleteSuggestions.push(artistSuggestions);
            }
          }
          break;
        case AutoCompleteTypeEnum.LOCATION:
          const locationSuggestions = suggestion;
          const locationIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
          );
          if (locationIndex === -1) {
            state.autocompleteSuggestions.push(locationSuggestions);
          } else {
            const currentLocation = state.autocompleteSuggestions[
              locationIndex
            ] as LocationSuggestion;
            if (
              currentLocation.latitude === locationSuggestions.latitude &&
              currentLocation.longitude === locationSuggestions.longitude
            ) {
              state.autocompleteSuggestions.splice(locationIndex, 1);
            } else {
              state.autocompleteSuggestions.splice(locationIndex, 1);
              state.autocompleteSuggestions.push(locationSuggestions);
            }
          }
          break;
        default:
          break;
      }
    },
    setUserLatLngForUserSearch: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>,
    ) => {
      state.page = 1;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
  },
});

export default userSearchSlice.reducer;
export const {
  setUserLatLngForUserSearch,
  setAutoCompleteSuggestions,
  removeAutoCompleteSuggestions,
  updateUserSearchPage,
  toggleRequestingLatLong,
  requestLatLong,
} = userSearchSlice.actions;
