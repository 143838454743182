import React, { useMemo } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useImageURLFromPath } from "../../../../../../hooks/useImageURLFromPath";
import { SCREENS } from "../../../../../../routes/screens";
import { Studio } from "../../../../../../store/models/studio";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../../../../store/utils/routeGetters";
import "./ProfileRow.css";
import { useAtom, useAtomValue } from "jotai";
import { activeProfileAtom } from "../../../../../../atoms/user/activeProfileAtom";
import { ProfileType } from "../../../../../../store/models/base";
import { ProfileRowComponent } from "./ProfileRowComponent";
import { activeUserAtom } from "../../../../../../atoms/user/activeUserAtom";

export interface ProfileRowProps {
  studio?: Studio;
  onClick: () => void;
}

export const ProfileRow = ({ studio, onClick }: ProfileRowProps) => {
  const activeUser = useAtomValue(activeUserAtom);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userImage = useMemo(() => {
    return activeUser?.profile_picture;
  }, [activeUser?.profile_picture]);
  const studioImage = useImageURLFromPath(true, studio?.photo?.path);
  const matchProfileScreen = matchPath(
    {
      path: SCREENS.PROFILE_SCREEN,
      caseSensitive: false,
      end: true,
    },
    pathname,
  );
  const matchStudioScreen = matchPath(
    {
      path: SCREENS.STUDIO_SCREEN,
      caseSensitive: false,
      end: true,
    },
    pathname,
  );
  const [activeProfile, setProfileId] = useAtom(activeProfileAtom);

  const onCurrentProfile =
    matchProfileScreen &&
    // @ts-ignore
    matchProfileScreen.params.username === activeUser?.username;
  const isOnProfileScreen = Boolean(onCurrentProfile || matchStudioScreen);

  const selected = useMemo(() => {
    if (studio) {
      return activeProfile?.id === studio.id;
    }
    return activeProfile?.type === ProfileType.USER;
  }, [activeProfile, studio]);

  const { username, imagePath } = useMemo(() => {
    if (studio) {
      return {
        username: studio?.username,
        imagePath: studioImage,
      };
    }
    return {
      username: activeUser?.username || "",
      imagePath: userImage,
    };
  }, [studio, activeUser, userImage, studioImage]);

  const url = useMemo(() => {
    if (studio) {
      return getStudioScreenRoute(studio.username);
    }
    return getProfileScreenRoute(activeUser?.username || "");
  }, [studio, activeUser]);

  const currentPath = window.location.pathname;

  const handleSettingSelectedProfile = () => {
    if (selected) {
      if (currentPath === url) {
        onClick();
        return;
      }
      navigate(url);
      onClick();
    }
    if (studio) {
      setProfileId(studio.id.toString());
      if (currentPath === url) {
        onClick();
        return;
      }
      if (isOnProfileScreen) navigate(getStudioScreenRoute(studio.username));
      onClick();
      return;
    }
    setProfileId("0");
    if (currentPath === url) {
      onClick();
      return;
    }
    if (isOnProfileScreen)
      navigate(getProfileScreenRoute(activeUser!.username));
    onClick();
  };

  return (
    <ProfileRowComponent
      imageSrc={imagePath}
      onClick={handleSettingSelectedProfile}
      username={username}
      selected={selected}
    />
  );
};
