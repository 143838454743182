import {
  FormEventHandler,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import classNames from "classnames";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";
import { TrackableClassNames } from "../../../constants/trackableClassNames";

export interface FormProps extends HTMLAttributes<HTMLFormElement> {
  children?: ReactNode;
}
const Form = ({
  children,
  className,
  onSubmit = () => {},
  id,
  ...props
}: FormProps) => {
  const transformedClassName = useMemo(
    () => classNames(TrackableClassNames.ENGINEEARS_FORM, className),
    [className],
  );
  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.SYSTEM_SUBMIT_FORM, {
        form_id: id,
        className,
      });
      onSubmit(e);
    },
    [onSubmit, className, id],
  );
  return (
    <form
      className={transformedClassName}
      onSubmit={handleSubmit}
      {...{ id, ...props }}
    >
      {children}
    </form>
  );
};

export default Form;
