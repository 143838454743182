import { Box } from "@mui/material";
import { useMemo } from "react";
import { useGetFileVersions } from "../../../hooks/audioPlayerHooks/useGetFileVersions";
import {
  FooterFileTrackType,
  setLocalPlayer,
} from "../../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Alt } from "../../../store/models/alts";
import {
  FILE_STATUS,
  sortAndFilterFileVersionsForLatestPlaybackOnAbPlayer,
} from "../../../store/models/fileVersion";
import { PortfolioFeatureData } from "../../../store/models/portfolio";
import {
  Project,
  projectTypeReadableFileName,
} from "../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  PlaybackControlButton,
  PlaybackControlButtonVariant,
} from "../../elements/PlaybackControlButton/PlaybackControlButton";
import { PlaybackControlButtonSize } from "../../elements/PlaybackControlButton/PlaybackControlButton.styles";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { FeaturedABTrack } from "./FeaturedABTrack";
import { FeaturedABTrackContainer } from "./FeaturedABTrack.styles";
import convertPortfolioFeatureDataToPlayListTrack from "../../../store/models/playListTrack/helpers/convertPortfolioFeatureDataToPlayListTrack";
import { useDownloadTrackFromFileVersion } from "../../../hooks/audioPlayerHooks/fetchTrack";
import { getCurrentTrackFromPlaylist } from "../../../store/selectors/abPlayerSelectors";
import { usePlayPauseOnFooter } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";

interface FeaturedABTrackProps {
  portfolioFeatureData: PortfolioFeatureData;
  project: Project;
}

export const FeaturedABTrackWrapper = ({
  portfolioFeatureData,
  project,
}: FeaturedABTrackProps) => {
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetFileVersions({
    projectId: project.id,
    status: FILE_STATUS.FILE_UPLOADED,
  });
  const { isFooterPlaying, trackedPlayerId } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const readableProjectType = projectTypeReadableFileName.get(
    project.service_type,
  );

  const currentPlaylistTrack = useAppSelector(getCurrentTrackFromPlaylist());

  const isTrackLoaded = currentPlaylistTrack?.id === portfolioFeatureData.id;

  const latestReference = useMemo(() => {
    if (!data?.length) return undefined;

    const references = data.filter((f) => f.reference);
    const sortedAndFilteredAudioFiles =
      sortAndFilterFileVersionsForLatestPlaybackOnAbPlayer(references);

    return sortedAndFilteredAudioFiles[0];
  }, [data]);

  const latestMain = useMemo(() => {
    if (!data?.length) return undefined;

    const mains = data.filter((f) => f.alt === Alt.MAIN && !f.reference);
    const sortedAndFilteredAudioFiles =
      sortAndFilterFileVersionsForLatestPlaybackOnAbPlayer(mains);

    return sortedAndFilteredAudioFiles[0];
  }, [data]);

  const { handleClick } = usePlayPauseOnFooter();

  const { url: refUrl } = useDownloadTrackFromFileVersion(latestReference);
  const { url: mainUrl } = useDownloadTrackFromFileVersion(latestMain);

  const onClick = (loadedUrl: string, playerId: number) => {
    emitAnalyticsTrackingEvent("portfolio_featured_ab_track", {
      fileVersionId: playerId,
      portfolioFeatureDataId: portfolioFeatureData.id,
    });

    if (trackedPlayerId !== playerId) {
      dispatch(
        setLocalPlayer({
          url: loadedUrl,
          trackedPlayerId: playerId,
          footerFileTrackType: FooterFileTrackType.AB_SNIPPET,
          loadedTrack:
            convertPortfolioFeatureDataToPlayListTrack(portfolioFeatureData),
          playOnLoad: true,
          keepPosition: Boolean(
            currentPlaylistTrack?.id === portfolioFeatureData.id,
          ),
        }),
      );
    }
  };

  const playbackButtonOnClick = async () => {
    if (currentPlaylistTrack?.id === portfolioFeatureData.id) {
      handleClick();
    } else if (mainUrl) {
      onClick(mainUrl, latestMain?.id || -1);
    } else if (refUrl) {
      onClick(refUrl, latestReference?.id || -1);
    }
  };

  if (isLoading) {
    return <SoundWaveLoader width={100} height={100} />;
  }

  return (
    <FeaturedABTrackContainer>
      {latestReference && (
        <FeaturedABTrack
          url={refUrl}
          readableProjectType={readableProjectType ?? ""}
          fileVersion={latestReference}
          isRef={true}
          isFeaturedTrackPlaying={isTrackLoaded}
          onClick={onClick}
        />
      )}
      {latestMain && (
        <FeaturedABTrack
          url={mainUrl}
          readableProjectType={readableProjectType ?? ""}
          isRef={false}
          fileVersion={latestMain}
          isFeaturedTrackPlaying={isTrackLoaded}
          onClick={onClick}
        />
      )}
      <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
        <PlaybackControlButton
          disabled={Boolean(!mainUrl && !refUrl)}
          isPrimary
          onClick={playbackButtonOnClick}
          size={PlaybackControlButtonSize.LARGE}
          variant={
            isFooterPlaying && isTrackLoaded
              ? PlaybackControlButtonVariant.PAUSE
              : PlaybackControlButtonVariant.PLAY_CIRCLE
          }
        />
      </Box>
    </FeaturedABTrackContainer>
  );
};
