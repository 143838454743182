// getter/setter for the search term
import { atom } from "jotai";
import { searchParamsAtom } from "./locationAtom";
import { AuthSteps } from "../../constants/authSteps";

// Read the current step from the URL
export const readCurrentStepFromUrlAtom = atom((get) => {
  const stepValue = get(searchParamsAtom).step;
  if (!stepValue) {
    return AuthSteps.NONE;
  }

  const parsedStepValue = parseInt(stepValue);

  return parsedStepValue as AuthSteps;
});

readCurrentStepFromUrlAtom.debugLabel = "Current Step From URL";
