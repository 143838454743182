import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtomValue } from "jotai";
import { CSSProperties, ReactNode } from "react";
import Modal from "react-modal";
import { darkModeAtom } from "../../../../atoms/user/darkModeAtom";
import {
  MODAL_STICKY_FOOTER_ID,
  SERVICE_FORM_MODAL_FOOTER_ID,
} from "../../../components/Navigation/BottomNav/useBottomTabBarOverlayView";
import { Button, ButtonVariant } from "../Button/Button";
import "./BaseModal.css";
import {
  closeIconStyle,
  ModalBody,
  ModalCard,
  ModalFooter,
  ModalHeader,
  ModalHeaderContainer,
  modalStyle,
} from "./BaseModal.styles";
import { CSSInterpolation, useTheme } from "@mui/material";

export interface BaseModalProps {
  children: ReactNode;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  header: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonDisabled?: boolean;
  cancelText?: string;
  confirmText?: string;
  showModalFooter?: boolean;
  loading?: boolean;
  centerModalHeader?: boolean;
  style?: CSSProperties;
  modalBodyStyle?: CSSProperties;
  showCloseButton?: boolean;
  customModalStyle?: CSSProperties;
  customBodyStyle?: CSSInterpolation;
  showModalHeader?: boolean;
  fullScreenOnMobile?: boolean;
  hasStickyFooter?: boolean;
  modalFooterStyle?: CSSProperties;
}

Modal.setAppElement(document.getElementById("root") ?? "root");

export const BaseModal = ({
  children,
  open,
  header,
  setOpen,
  onConfirm,
  onCancel,
  cancelText = "Cancel",
  confirmButtonDisabled = false,
  confirmText = "Confirm",
  showModalFooter = false,
  loading = false,
  centerModalHeader = false,
  style,
  modalBodyStyle,
  showCloseButton = true,
  customModalStyle = {},
  customBodyStyle,
  showModalHeader = true,
  fullScreenOnMobile = false,
  hasStickyFooter = true,
  modalFooterStyle,
}: BaseModalProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  const theme = useTheme();

  return (
    <Modal
      style={{
        ...modalStyle,
        content: {
          ...modalStyle.content,
          ...customModalStyle,
        },
      }}
      className={fullScreenOnMobile ? "modal--full-width-mobile " : undefined}
      isOpen={open}
      contentLabel={header}
    >
      <ModalCard style={style} data-theme={darkMode ? "dark" : ""}>
        {showModalHeader && (
          <ModalHeaderContainer>
            {centerModalHeader && (
              <div style={{ visibility: "hidden", pointerEvents: "none" }} />
            )}
            <ModalHeader>{header}</ModalHeader>
            {showCloseButton && (
              <FontAwesomeIcon
                size={"xl"}
                icon={faClose}
                color={theme.palette.text.primary}
                style={closeIconStyle}
                onClick={() => setOpen(false)}
              />
            )}
          </ModalHeaderContainer>
        )}
        <ModalBody customStyle={customBodyStyle} style={modalBodyStyle}>
          {children}
        </ModalBody>
        {showModalFooter && (
          <ModalFooter style={modalFooterStyle}>
            {onCancel && (
              <Button
                style={{
                  minWidth: 108,
                  padding: "12px 16px",
                }}
                loading={loading}
                variant={ButtonVariant.OUTLINED}
                onClick={onCancel}
              >
                {cancelText}
              </Button>
            )}
            {onConfirm && (
              <Button
                style={{
                  minWidth: 108,
                  padding: "12px 16px",
                }}
                disabled={confirmButtonDisabled}
                loading={loading}
                variant={ButtonVariant.PRIMARY}
                onClick={onConfirm}
              >
                {confirmText}
              </Button>
            )}
          </ModalFooter>
        )}
        {hasStickyFooter && <div id={SERVICE_FORM_MODAL_FOOTER_ID}></div>}
        {open && hasStickyFooter && <div id={MODAL_STICKY_FOOTER_ID}></div>}
      </ModalCard>
    </Modal>
  );
};
