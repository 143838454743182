import { AccountDetailsFieldContainer } from "../AccountSettingsModal.styles";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { PhoneInput } from "../../../core-ui/components/PhoneNumber/PhoneInput";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useUpdateUserProfile } from "../../../../hooks/userHooks/useUpdateUserProfile";
import { useGeolocationGoogleAppEngine } from "../../../../hooks/useGeolocationCountryCode";
import { Box } from "@mui/material";

interface updateProfileErrorProps {
  code: number;
  firstName: string;
  lastName: string;
  displayName: string;
  username: string;
  email: string;
  phoneNumber: string;
}

interface AccountSettingsPhoneNumberInputFieldProps {
  originalNumber?: string;
  onSave?: (phoneNumber: string) => void;
  onCancel?: () => void;
}

export const AccountSettingsPhoneNumberInputField = ({
  onCancel = () => {},
  onSave = () => {},
  originalNumber,
}: AccountSettingsPhoneNumberInputFieldProps) => {
  const { isPending: isUpdateProfileLoading, mutateAsync: updateUserProfile } =
    useUpdateUserProfile();
  const [phoneNumber, setPhoneNumber] = useState(originalNumber || "");
  const [updateProfileError, setUpdateProfileError] =
    useState<updateProfileErrorProps>();
  const { countryCode } = useGeolocationGoogleAppEngine();
  const isValidPhoneNumber = useMemo(() => {
    return matchIsValidTel(phoneNumber);
  }, [phoneNumber]);

  const handleSavePhoneNumber = useCallback(() => {
    void updateUserProfile({
      phone_number: phoneNumber,
    })
      .then(() => {
        toast.success("Verification code sent");
        // Delay to prevent showing old data
        setTimeout(() => {
          onSave(phoneNumber);
        }, 250);
      })
      .catch((err) => {
        setUpdateProfileError(err.errors);
      });
  }, [updateUserProfile, phoneNumber, setUpdateProfileError, onSave]);

  return (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Phone number
      </Text>

      <PhoneInput
        disabled={isUpdateProfileLoading}
        isValidPhoneNumber={isValidPhoneNumber}
        defaultCountry={(countryCode as MuiTelInputCountry) || "US"}
        value={phoneNumber}
        onChange={(value: string) => {
          setPhoneNumber(value);
        }}
        size={"small"}
        style={{ width: "100%" }}
        error={Boolean(updateProfileError?.phoneNumber)}
        helperText={updateProfileError?.phoneNumber}
      />
      <Box display={"flex"} alignItems={"center"} gap={2}>
        <Button
          variant={ButtonVariant.GHOST}
          onClick={handleSavePhoneNumber}
          disabled={!phoneNumber}
        >
          Verify phone
        </Button>
        {originalNumber && (
          <Button variant={ButtonVariant.GHOST} onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Box>
    </AccountDetailsFieldContainer>
  );
};
