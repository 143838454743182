import { atom } from "jotai";
import { atomWithReset, RESET } from "jotai/utils";

// phone number for phone login
export const phoneNumberAtom = atomWithReset("");
// phone number signup
export const phoneNumberRegisterAtom = atomWithReset(false);
// phone number signin
export const phoneNumberLoginAtom = atomWithReset(false);

// reset for phone number atoms
export const resetPhoneNumberAuthAtom = atom(null, (_, set) => {
  set(phoneNumberAtom, RESET);
  set(phoneNumberRegisterAtom, RESET);
  set(phoneNumberLoginAtom, RESET);
});
