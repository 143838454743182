import React, { useState } from "react";
import { usePasswordCheck } from "../../../hooks/userPasswordCheck";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { TextColor } from "../../core-ui/components/Text/TextUtils";
import { Box, useTheme } from "@mui/material";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import {
  PasswordChecksContainer,
  PasswordFieldContainer,
  PasswordFieldInput,
  PasswordRequirement,
  ShowHideIcon,
} from "./PasswordField.styles";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { ForgotPassword } from "../ForgotPassword/ForgotPassword";

export interface PasswordFieldProps {
  newPassword?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showWarning?: boolean;
  error?: boolean;
  helperText?: string;
  registering: boolean;
  loading: boolean;
  isSubmitDisabled?: boolean;
  handleSubmit: ((e: React.FormEvent) => Promise<void>) | (() => void);
  showSignUp?: boolean;
  selectState?: () => void;
}

export const PasswordField = ({
  onChange,
  newPassword,
  showWarning = true,
  error = false,
  helperText,
  registering,
  loading,
  isSubmitDisabled = false,
  handleSubmit,
  showSignUp,
  selectState,
}: PasswordFieldProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    usePasswordCheck(password, 8);
  const isValidPassword =
    validLength && hasNumber && upperCase && lowerCase && specialChar;

  const passwordRequirement = (label: string, isPassing: boolean) => (
    <PasswordRequirement>
      <FontAwesomeIcon
        icon={isPassing ? faCheck : faClose}
        color={
          isPassing
            ? theme.palette.standardColor.Green[600]
            : theme.palette.standardColor.Red[600]
        }
      />
      <Text
        size={TEXT_SIZE.XS}
        color={isPassing ? TextColor.SUCCESS : TextColor.FAILED}
      >
        {label}
      </Text>
    </PasswordRequirement>
  );

  const passwordChecks = (
    <PasswordChecksContainer>
      {passwordRequirement("Must be 8 characters or more.", validLength)}
      {passwordRequirement(
        "Must include at least one number (0-9).",
        hasNumber,
      )}
      {passwordRequirement(
        "Must include at least one uppercase character (A-Z).",
        upperCase,
      )}
      {passwordRequirement(
        "Must include at least one lowercase character (a-z)",
        lowerCase,
      )}
      {passwordRequirement(
        "Must include at least one special character (!@#$%^&*)",
        specialChar,
      )}
    </PasswordChecksContainer>
  );

  return (
    <>
      <PasswordFieldContainer>
        <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Password</Text>
        <PasswordFieldInput
          fullWidth={true}
          autoComplete="password"
          size={"small"}
          type={showPassword ? "text" : "password"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e);
            setPassword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isSubmitDisabled && isValidPassword) {
              void handleSubmit(e);
            }
          }}
          InputProps={{
            endAdornment: (
              <ShowHideIcon>
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  color={theme.palette.text.secondary}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </ShowHideIcon>
            ),
          }}
          error={error}
          helperText={helperText}
        />
        {!registering && <ForgotPassword />}
        {selectState && (
          <Box sx={{ cursor: "pointer" }} onClick={selectState}>
            <Text bold>{showSignUp ? "Sign In" : "Sign Up"}</Text>
          </Box>
        )}
      </PasswordFieldContainer>
      <Button
        className={"mt-3"}
        fullWidth={true}
        loading={loading}
        variant={ButtonVariant.PRIMARY}
        disabled={
          registering
            ? isSubmitDisabled || loading || !password || !isValidPassword
            : isSubmitDisabled || loading || !password
        }
        onClick={handleSubmit}
      >
        {registering && !newPassword ? "Sign Up" : "Submit"}
      </Button>
      {newPassword &&
        showWarning &&
        registering &&
        password.length > 0 &&
        passwordChecks}
    </>
  );
};
