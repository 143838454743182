import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import { SCREENS } from "../../../../../../routes/screens";
import {
  getUnreadNotificationsCount,
  loadNotifications,
} from "../../../../../../store/actions/notifications";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import User from "../../../../../../store/models/user";
import { selectNotificationData } from "../../../../../../store/selectors/notificationSelectors";
import { NotificationsList } from "../../../../NotificationsList/NotificationsList";
import { NAV_BUTTON_VARIANT, NavButton } from "../NavButton/NavButton";
import "./NavNotificationButton.css";

export enum NAV_NOTIFICATION_VARIANT {
  LINK = "link",
  MODAL = "modal",
}

export interface NavNotificationButtonProps {
  iconColor?: string;
  onClick?: () => void;
  showIconOnly?: boolean;
  user: User | undefined;
  variant?: NAV_NOTIFICATION_VARIANT;
}

export const NavNotificationButton: React.FC<NavNotificationButtonProps> = ({
  iconColor = "var(--text-primary-color)",
  onClick,
  showIconOnly = false,
  user,
  variant = NAV_NOTIFICATION_VARIANT.MODAL,
}: NavNotificationButtonProps) => {
  const { unreadCount } = useAppSelector(selectNotificationData());
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [loadedCount, setLoadedCount] = useState<boolean>(false);
  const [clickedNotifications, setClickedNotifications] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const onClickOutside = () => {
    if (showDropDown) {
      setShowDropDown(false);
    }
  };

  useClickOutside(containerRef, onClickOutside);

  useEffect(() => {
    if (!user) return;
    if (loadedCount) return;
    setLoadedCount(true);
    dispatch(getUnreadNotificationsCount())
      .unwrap()
      .catch(() => {});
  }, [unreadCount, dispatch, loadedCount]);

  useEffect(() => {
    if (!user) return;
    if (!showDropDown) return;
    dispatch(
      loadNotifications({
        page: 1,
      }),
    ).catch(() => toast.error("Failed to load notifications"));
  }, [showDropDown, dispatch]);

  const isLink = variant === NAV_NOTIFICATION_VARIANT.LINK;

  const handleClick = () => {
    setClickedNotifications(true);
    if (onClick) {
      onClick();
    }

    if (isLink) {
      navigate(SCREENS.NOTIFICATIONS);
    } else {
      setShowDropDown((prev) => !prev);
    }
  };

  return (
    <>
      <NavButton
        variant={NAV_BUTTON_VARIANT.BUTTON}
        frontIcon={
          <FontAwesomeIcon color={iconColor} icon={faBell} size="xl" />
        }
        color={iconColor}
        message="Notifications"
        onClick={handleClick}
        showIconOnly={showIconOnly}
        showNotification={!clickedNotifications && unreadCount > 0}
      />
      {!isLink && (
        <div
          style={{
            borderRadius: "1px",
            display: "inline-block",
            height: "inherit",
            position: "relative",
          }}
        >
          <div
            ref={containerRef}
            className={`notification-content ${showDropDown ? "show" : "hide"}`}
          >
            <NotificationsList isDisplayed={showDropDown} />
          </div>
        </div>
      )}
    </>
  );
};
