import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { APIMasteringProject, APIMixingProject } from "../../models/project";
import { makeBackendGetCallWithJsonResponse } from "../../utils/fetch";
import { LOAD_PROJECTS } from "../../utils/routes";
import { receiveErrors } from "../errorStore";

export interface loadProjectsParams {
  mastering_page: number;
  mixing_page: number;
}

interface LoadProjectsResponse {
  mixing_projects: APIMixingProject[];
  mixing_pages: number;
  mastering_projects: APIMasteringProject[];
  mastering_pages: number;
}

const loadProjects = createAsyncThunk(
  LOAD_PROJECTS,
  async (args: loadProjectsParams, thunkAPI) => {
    let { mastering_page, mixing_page } = args;
    const {
      projectsStore: { total_mastering, total_mixing },
    } = thunkAPI.getState() as RootState;

    if (total_mastering !== undefined && mastering_page > total_mastering) {
      mastering_page = total_mastering;
    }
    if (total_mixing !== undefined && mixing_page > total_mixing) {
      mixing_page = total_mixing;
    }
    const params = `?mixing_page=${mixing_page}&mastering_page=${mastering_page}`;
    const response =
      await makeBackendGetCallWithJsonResponse<LoadProjectsResponse>(
        LOAD_PROJECTS,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);
export default loadProjects;
