import {
  GenerateBookingCardContainer,
  GenerateBookingHeaderContainer,
  GenerateBookingHeaderTextContainer,
  GenerateBookingCheckboxContainer,
} from "./GenerateBookingCard.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { BookingLabel } from "./BookingLabel";
import { generateBookingTitleMap } from "../../../api/productTransactions/models";
import { PaywallPermissions } from "./PaywallPermissions";
import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { ReactNode } from "react";
import { ScheduledProjectPaywallTypes } from "../../../store/models/project";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export interface GenerateBookingCardProps {
  selected: boolean;
  onClick: () => void;
  type: ScheduledProjectPaywallTypes;
}

export const GenerateBookingCard = ({
  selected,
  onClick,
  type,
}: GenerateBookingCardProps) => {
  const theme = useTheme();
  const title = generateBookingTitleMap[type];
  const { isDesktop } = useMediaQueryBreakpoint();

  const BookingLinkFragmentMap: {
    [key in ScheduledProjectPaywallTypes]: ReactNode;
  } = {
    [ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED]: (
      <>
        Your client must submit payment before you <b>start</b> mixing or
        mastering the project.
      </>
    ),
    [ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED]: (
      <>
        Your client must submit payment before they can <b>listen</b> to the mix
        or master that you upload.
      </>
    ),
    [ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED]: (
      <>
        Your client must submit payment before they can{" "}
        <b>request any new revisions</b> to the mix or master you upload.
      </>
    ),

    [ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED]: (
      <>
        Your client must submit payment before they can{" "}
        <b style={{ color: theme.palette.text.primary }}>
          download the final asset files
        </b>{" "}
        for the approved mix or master.
      </>
    ),
  };

  return (
    <Button
      variant={ButtonVariant.UNSTYLED}
      onClick={() => {
        onClick();
      }}
      fullWidth={!isDesktop}
    >
      <GenerateBookingCardContainer $isDesktop={isDesktop} $selected={selected}>
        <GenerateBookingHeaderContainer>
          <GenerateBookingHeaderTextContainer>
            <Text
              style={{
                fontSize: "1.375rem",
              }}
              variant={TextStyleVariant.S3}
            >
              {title}
            </Text>
          </GenerateBookingHeaderTextContainer>
          <GenerateBookingCheckboxContainer $selected={selected}>
            <FontAwesomeIcon icon={faCheck} />
          </GenerateBookingCheckboxContainer>
        </GenerateBookingHeaderContainer>
        <Text
          style={{
            color: theme.palette.text.secondary,
            lineHeight: ".875rem",
            textAlign: "left",
          }}
          variant={TextStyleVariant.P2}
        >
          {BookingLinkFragmentMap[type]}
        </Text>
        <BookingLabel type={type} />
        <PaywallPermissions type={type} selected={selected} />
      </GenerateBookingCardContainer>
    </Button>
  );
};
