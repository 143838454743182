import { useState } from "react";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import {
  NAV_LIST_ITEM_VARIANT,
  NavDropDownListItem,
} from "../NavDropDownListItem/NavDropDownListItem";
import { useLocation } from "react-router-dom";
import { HUBSPOT_SUPPORT_URL } from "../../../../../../constants/hubspot";

interface NavDropDownHelpButtonProps {
  onClose: () => void;
}

export const NavDropDownHelpButton: React.FC<NavDropDownHelpButtonProps> = ({
  onClose,
}: NavDropDownHelpButtonProps) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { pathname } = useLocation();
  return (
    <>
      <NavDropDownListItem
        text="Help"
        variant={NAV_LIST_ITEM_VARIANT.LINK}
        path={HUBSPOT_SUPPORT_URL}
        currentPath={pathname}
        onClick={() => {
          emitAnalyticsTrackingEvent("logged_in_open_feedback", {});
          setShowFeedbackModal(!showFeedbackModal);
          onClose();
        }}
      />
    </>
  );
};
