import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import {
  createArtistSurveyData,
  CreateArtistSurveyDataArgs,
} from "../../api/marketing/createArtistSurveyData";

const useCreateArtistSurveyDataMutation = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.CREATE_ARTIST_SURVEY],
    mutationFn: async (params: CreateArtistSurveyDataArgs) => {
      return createArtistSurveyData(params);
    },
  });
};

export default useCreateArtistSurveyDataMutation;
