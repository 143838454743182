import { Box } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import ViewSlider, { ViewProps } from "react-view-slider";
import { useFetchProductsByReleaseIdQuery } from "../../../api/products/hooks/usefetchProductsByReleaseIdQuery";
import { useFetchFeaturedArtistReleaseQuery } from "../../../api/releases/hooks/useFetchFeaturedReleaseQuery";
import {
  artistReleaseTabViewAtom,
  ReleasesTabView,
  selectedReleaseIdAtom,
} from "../../../atoms/profileScreen/artistReleaseTabView";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { FeaturedArtistRelease } from "../FeaturedArtistRelease/FeaturedArtistRelease";
import { FeaturedReleaseProducts } from "../FeaturedReleaseProducts/FeaturedReleaseProducts";
import { ProductCheckoutFooter } from "../ProductCheckoutFooter/ProductCheckoutFooter";
import { AddReleaseView } from "./AddReleaseView";
import { ReleaseDetailView } from "./ReleaseDetailView";
import { ReleaseOverview } from "./ReleaseOverview";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";
import { editMode } from "../../../atoms/profileScreenEdit";
import { useAppSelector } from "../../../store/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { FooterFileTrackType } from "../../../store/actions/abPlayerStore";
import convertArtistReleaseToPlaylistTrack from "../../../store/models/playListTrack/helpers/convertArtistReleaseToPlaylistTrack";
import { useSetPlayList } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { ReleaseTrack } from "../../../store/models/release";

export const ArtistReleaseTabView = () => {
  const [currentView, setView] = useAtom(artistReleaseTabViewAtom);
  const [selectedReleaseId, setSelectedReleaseId] = useAtom(
    selectedReleaseIdAtom,
  );
  const queryClient = useQueryClient();
  const { isFooterPlaying } = useAppSelector((state) => state.abPlayerStore);
  const { username } = useParams<{ username: string }>();
  const activeUser = useAtomValue(activeUserAtom);
  const { data: userData } = useGetUserProfile(username);
  const { data: release, isPending: isReleaseLoading } =
    useFetchFeaturedArtistReleaseQuery(userData?.id);
  const currentUserIsArtistOnRelease =
    release?.artist_user_id === activeUser?.id;
  const {
    data: featuredProducts,
    isPending: isFeaturedProductsFetching,
    isSuccess: isFeaturedProductsFetchingSuccess,
  } = useFetchProductsByReleaseIdQuery(release?.id);

  useEffect(() => {
    return () => {
      setSelectedReleaseId(null);
      setView(ReleasesTabView.overview);
    };
  }, [setSelectedReleaseId, setView]);

  const setPlayList = useSetPlayList();

  const handleSettingUpPlaylist = useCallback(
    (releaseId: number) => {
      if (isFooterPlaying) return;
      const tracks =
        (queryClient.getQueryData([
          QUERY_KEYS.FETCH_ARTIST_RELEASE_TRACKS,
          releaseId,
          activeUser?.id,
        ]) as ReleaseTrack[] | undefined) ?? [];

      if (!tracks.length) return;
      setPlayList({
        tracks: convertArtistReleaseToPlaylistTrack(tracks).filter(
          (releaseTrack) => !releaseTrack.paymentRequiredForPlayback,
        ),
        index: 0,
        playOnLoad: true,
        playlistId: releaseId,
        footerFileTrackType: FooterFileTrackType.RELEASE,
      });
    },
    [isFooterPlaying, queryClient, activeUser?.id, setPlayList],
  );

  const onReleaseClick = useCallback(
    (releaseId?: number) => {
      if (!releaseId) return;
      handleSettingUpPlaylist(releaseId);
      setSelectedReleaseId(releaseId);
      setView(ReleasesTabView.detail);
    },
    [handleSettingUpPlaylist, setSelectedReleaseId, setView],
  );

  const ctaText = useMemo(() => {
    const products = featuredProducts || [];
    if (currentUserIsArtistOnRelease && !editMode) {
      return "Play Project";
    }
    if (isFeaturedProductsFetching) {
      return "...";
    }
    const userBoughtSomeProductOnRelease = products.some(
      (product) => product.product_purchased_by_current_user,
    );
    if (
      isFeaturedProductsFetchingSuccess &&
      products.length > 0 &&
      !userBoughtSomeProductOnRelease
    ) {
      return "Buy Project";
    }
    return "Play Project";
  }, [
    featuredProducts,
    currentUserIsArtistOnRelease,
    isFeaturedProductsFetching,
    isFeaturedProductsFetchingSuccess,
  ]);

  // TODO: Clean this up after the smino livestream.
  const userIsNotSmino = userData?.username !== "smino";

  const renderView = ({ index }: ViewProps) => {
    switch (index) {
      case ReleasesTabView.overview:
        return (
          <ReleaseOverview onReleaseClick={onReleaseClick}>
            {userIsNotSmino && (
              <Box
                sx={(theme) => ({
                  // This accounts for the box shadow of the feature image
                  [theme.breakpoints.up("md")]: {
                    padding: "16px 0 0 16px",
                  },

                  [theme.breakpoints.down("sm")]: {
                    padding: "16px 0 0 0",
                  },

                  [theme.breakpoints.up("sm")]: {
                    padding: "16px 16px 0 16px",
                  },
                })}
              >
                <FeaturedArtistRelease
                  release={release}
                  onClick={() => {
                    onReleaseClick(release?.id);
                  }}
                  ctaText={ctaText}
                  isReleaseLoading={isReleaseLoading}
                />
              </Box>
            )}
            <FeaturedReleaseProducts featuredReleaseId={release?.id} />
          </ReleaseOverview>
        );

      case ReleasesTabView.detail:
        if (!selectedReleaseId) {
          return <AddReleaseView />;
        }
        return (
          <ReleaseDetailView
            hidePurchaseButton={false}
            navigateBack={() => {
              setView(ReleasesTabView.overview);
            }}
            releaseId={selectedReleaseId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box minHeight={1050}>
      <ViewSlider
        onSlideTransitionEnd={() => {
          if (currentView === ReleasesTabView.overview) {
            setSelectedReleaseId(null);
          }
        }}
        numViews={2}
        keepViewsMounted
        renderView={renderView}
        animateHeight
        activeView={currentView}
      />
      {isFeaturedProductsFetchingSuccess &&
        featuredProducts &&
        featuredProducts.length > 0 && <ProductCheckoutFooter />}
    </Box>
  );
};
