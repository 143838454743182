import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { ResponsiveDialogContent } from "../../core-ui/components/ResponsiveDialog/ResponsiveDialogContent";
interface YouTubeVideoModalProps {
  open: boolean;
  onClose: () => void;
  videoUrl: string;
}

export const YouTubeVideoModal = ({
  open,
  onClose,
  videoUrl,
}: YouTubeVideoModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const videoId = videoUrl.split("v=")[1];

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "1280px",
          margin: "24px",
          backgroundColor: theme.palette.customColor.backgroundColor,
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 1,
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "inherit",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: theme.palette.text.primary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FontAwesomeIcon icon={faXmark} size="lg" />
        </IconButton>
      </DialogTitle>
      <ResponsiveDialogContent
        sx={{
          padding: "0 0 56.25% 0",
          border: "none",
          position: "relative",
          "& iframe": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
          },
        }}
      >
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="YouTube Video"
          allow="accelerometer; autoplay"
          allowFullScreen
        />
      </ResponsiveDialogContent>
    </Dialog>
  );
};
