import { Box, Container, css, styled, Theme } from "@mui/material";
import { CSSProperties } from "react";
import { MUI_CONTAINER_NO_PADDING_CUT_OFF } from "../../../../utils/breakpoints";
import { Text } from "../../../core-ui/components/Text/Text";

export const StyledImage = styled("img")({
  objectFit: "cover",
  display: "block",
});

export const UserInfoContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== "$stickyCoverPhotoHeight",
})<{
  $stickyCoverPhotoHeight?: number;
}>(({ theme, $stickyCoverPhotoHeight }) => ({
  height: $stickyCoverPhotoHeight ? `${$stickyCoverPhotoHeight}px` : "unset",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.common.white,
  columnGap: "16px",
  [theme.breakpoints.up("lg")]: {
    // To match exactly what the design looks like
    maxWidth: "var(--max-screen-width)",
  },

  [theme.breakpoints.up(MUI_CONTAINER_NO_PADDING_CUT_OFF)]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: $stickyCoverPhotoHeight ? "center" : "flex-end",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: $stickyCoverPhotoHeight ? "center" : "flex-end",
    rowGap: "8px",
  },
  "& .button-default:not(.button-ghost)": {
    boxShadow: theme.palette.customColor.buttonBoxShadow,
  },

  "& .cover-photo__action-button": {
    minWidth: "140px",
  },

  ".button-ghost": {
    color: theme.palette.common.white,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  "& .social-media-element-button": {
    backgroundColor: `${theme.palette.standardColor.Gray[600]} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));

export const HeroTextContainer = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
});

export const CoverPhotoOverlay = styled(Box)({
  background: `linear-gradient(
  hsla(0, 0%, 0%, 0) 0%,
  hsla(0, 0%, 0%, 0.002) 1.8%,
  hsla(0, 0%, 0%, 0.008) 4.8%,
  hsla(0, 0%, 0%, 0.021) 9%,
  hsla(0, 0%, 0%, 0.042) 13.9%,
  hsla(0, 0%, 0%, 0.075) 19.8%,
  hsla(0, 0%, 0%, 0.126) 27%,
  hsla(0, 0%, 0%, 0.194) 35%,
  hsla(0, 0%, 0%, 0.278) 43.5%,
  hsla(0, 0%, 0%, 0.382) 53%,
  hsla(0, 0%, 0%, 0.541) 66%,
  hsla(0, 0%, 0%, 0.738) 81%,
  hsl(0, 0%, 0%) 100%
)`,
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
});

export const ActionsDropdownMenuStyle = (theme: Theme) => css`
  padding: 8px;
  border-radius: ${theme.border.radius.md};
  border: 1px solid ${theme.palette.text.primary};
`;

export const ReleaseTrackOptionsMenuStyle = (theme: Theme) => css`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: ${theme.border.radius.md};
  border: 1px solid ${theme.palette.standardColor.Gray[200]};
  cursor: pointer;
`;

export const UsernameText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "$isInStickyMode",
})<{
  $isInStickyMode: boolean;
}>(({ theme, $isInStickyMode }) => ({
  maxWidth: "calc(100% - 16px)",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0",
  },
}));

export const CoverPhotoEditLayerOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0,0,0,0.6)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  visibility: "hidden",

  [theme.breakpoints.down("md")]: {
    opacity: 1,
    visibility: "visible",
    background: "none",
    height: "50%",
  },
}));

export const StyledLabel = styled("label")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  columnGap: "8px",
  border: `1px solid ${theme.palette.text.primary}`,
  padding: "12px 10px",
  borderRadius: theme.border.radius.md,
  fontWeight: "bold",
  cursor: "pointer",
  marginTop: "16px",
  color: theme.palette.text.primary,

  "&:hover": {
    backgroundColor: theme.palette.customColor.buttonOutlinedColorHover,
  },
}));

export const ColumnContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  rowGap: "24px",
});

export const modalBodyStyle = css`
  justify-content: flex-start;
  padding: 32px;
  overflow-y: auto;
  max-height: 60vh;
  min-height: 350px;

  * {
    box-sizing: border-box;
  }
`;

export const CoverPhotoButtonsContainer = styled(Box, {
  shouldForwardProp: (props) => props !== "$isInStickyMode",
})<{ $isInStickyMode: boolean }>(({ theme, $isInStickyMode }) => ({
  display: "flex",
  flexDirection: "column",
  gap: $isInStickyMode ? "16px" : "32px",

  [theme.breakpoints.down("md")]: {
    gap: "16px",
  },
}));

export const CoverPhotoEditContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  justifyContent: "flex-end",
  alignSelf: "center",
  textWrap: "nowrap",

  [theme.breakpoints.down("md")]: {
    justifyContent: "flex-start",
  },
}));

export const CoverPhotoUploadModalBodyCSSProperties: CSSProperties = {
  padding: 0,
  width: "unset",
  overflowY: "hidden",
  boxSizing: "border-box",
  maxHeight: "60vh",
};

export const CoverPhotoUploadModalBodyContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isCroppingMode",
})<{ $isCroppingMode: boolean }>(({ theme, $isCroppingMode }) => ({
  height: "100%",
  overflowY: "auto",
  padding: $isCroppingMode ? "40px 64px 20px 64px" : "32px",

  [theme.breakpoints.down("md")]: {
    padding: "32px",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "16px",
  },
}));

export const CoverPhotoCroppingContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  boxSizing: "border-box",

  [theme.breakpoints.down("md")]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const CoverPhotoUploadInstruction = styled(Text)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxWidth: "80%",
    margin: "0 auto",
    textAlign: "center",
  },
}));
