import {
  GET_STRIPE_CHECKOUT_SESSION,
  GET_STRIPE_PORTAL_SESSION,
  GET_SUBSCRIPTION_STATUS,
  STRIPE_SUBSCRIPTION_SUCCESS,
  STRIPE_SUBSCRIPTION_CUSTOMER,
  ADD_ESSENTIALS_SUBSCRIPTION_PLAN,
} from "../utils/routes";
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  makeBackendPostCallWithJsonResponse,
  makeBackendGetCallWithJsonResponse,
} from "../utils/fetch";
import {
  Subscription,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../models/subscription";
import Engineer from "../models/engineer";
import { receiveErrors } from "./errorStore";

export interface createStripeCustomerProps {
  billing_email: string;
  name?: string;
}

export const createStripeCustomer = createAsyncThunk(
  STRIPE_SUBSCRIPTION_CUSTOMER,
  async (args: createStripeCustomerProps, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Engineer>(
      STRIPE_SUBSCRIPTION_CUSTOMER,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface getSubscriptionStatusParams {
  refetch_subs_status: boolean;
}

export const getSubscriptionStatus = createAsyncThunk(
  GET_SUBSCRIPTION_STATUS,
  async (args: getSubscriptionStatusParams, thunkAPI) => {
    const refetch_subs_status = args.refetch_subs_status ? true : false;
    const params = `?refetch_subs_status=${refetch_subs_status}`;
    const result = await makeBackendGetCallWithJsonResponse<Subscription>(
      GET_SUBSCRIPTION_STATUS,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface StripeCheckoutSessionResponse {
  checkout_url: string;
}

export const getStripeCheckoutSession = createAsyncThunk(
  GET_STRIPE_CHECKOUT_SESSION,
  async (
    args: {
      plan: SUBSCRIPTION_PLAN;
      refund_fees: boolean;
      force_no_trial?: boolean;
    },
    thunkAPI,
  ) => {
    const result =
      await makeBackendPostCallWithJsonResponse<StripeCheckoutSessionResponse>(
        GET_STRIPE_CHECKOUT_SESSION,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const handleStripeCheckoutSuccess = createAsyncThunk(
  STRIPE_SUBSCRIPTION_SUCCESS,
  async (args: { session_id: string }, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Subscription>(
      STRIPE_SUBSCRIPTION_SUCCESS,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface GetStripePortalSessionResponse {
  portal_url: string;
}

export const getStripePortalSession = createAsyncThunk(
  GET_STRIPE_PORTAL_SESSION,
  async (_, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<GetStripePortalSessionResponse>(
        GET_STRIPE_PORTAL_SESSION,
        {},
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const addBasicSubscriptionPlan = createAsyncThunk(
  ADD_ESSENTIALS_SUBSCRIPTION_PLAN,
  async (_, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Subscription>(
      ADD_ESSENTIALS_SUBSCRIPTION_PLAN,
      {},
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface SubscriptionState extends Subscription {
  loading: boolean;
}

const initialState: SubscriptionState = {
  subscription_plan_choice: SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN,
  stripe_subscription_status: SUBSCRIPTION_STATUS.NOT_DEFINED,
  loading: false,
};

export const subscriptionSlice = createSlice({
  name: "subscription store",
  initialState,
  reducers: {
    // For mocking in storybook.
    addTestPlan: (state, action: PayloadAction<Subscription>) => {
      state.subscription_plan_choice = action.payload.subscription_plan_choice;
      state.stripe_subscription_status =
        action.payload.stripe_subscription_status;
    },
    clearSubscription: (state) => {
      state.stripe_subscription_status =
        initialState.stripe_subscription_status;
      state.subscription_plan_choice = initialState.subscription_plan_choice;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addBasicSubscriptionPlan.pending,
        handleStripeCheckoutSuccess.pending,
        getSubscriptionStatus.pending,
      ),
      (state) => {
        state.loading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        addBasicSubscriptionPlan.rejected,
        handleStripeCheckoutSuccess.rejected,
        getSubscriptionStatus.rejected,
      ),
      (state) => {
        state.loading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        addBasicSubscriptionPlan.fulfilled,
        handleStripeCheckoutSuccess.fulfilled,
        getSubscriptionStatus.fulfilled,
      ),
      (state, action) => {
        state.loading = false;
        const subscription = action.payload;
        if (
          state.stripe_subscription_status ===
            subscription.stripe_subscription_status &&
          state.subscription_plan_choice ===
            subscription.subscription_plan_choice
        ) {
          return state;
        }
        state.stripe_subscription_status =
          subscription.stripe_subscription_status;
        state.subscription_plan_choice = subscription.subscription_plan_choice;
      },
    );
  },
});

export const { addTestPlan, clearSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
