import { useMutation } from "@tanstack/react-query";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
  promiseEngineEarsHandler,
} from "../api/helpers";
import { QUERY_KEYS } from "../constants/queryKeys";
import { EngineEarsError } from "../store/actions/errorStore";
import { RecordingService } from "../store/models/recording";
import { makeBackendPostCallWithJsonResponse } from "../store/utils/fetch";
import { DELETE_RECORDING_SERVICE } from "../store/utils/routes";

interface useDeleteEngineerServiceProps {
  onSuccess: (data: RecordingService) => void;
}

interface mutationFnProps {
  recording_service_id: number;
}

export const useDeleteRecordingService = ({
  onSuccess,
}: useDeleteEngineerServiceProps) => {
  return useMutation({
    mutationKey: [QUERY_KEYS.DELETE_RECORDING_SERVICE],
    mutationFn: async (args: mutationFnProps) => {
      const response =
        await makeBackendPostCallWithJsonResponse<RecordingService>(
          DELETE_RECORDING_SERVICE,
          {
            recording_service_id: args.recording_service_id,
          },
        );
      return promiseEngineEarsHandler(response);
    },
    onError: (error: EngineEarsError) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
    },
    onSuccess,
  });
};
