import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectById } from "../../models/project";
import { makeBackendPostCallWithJsonResponse } from "../../utils/fetch";
import { MARK_FINAL_ASSETS_APPROVED } from "../../utils/routes";
import { receiveErrors } from "../errorStore";

export interface markFinalAssetsApprovedParams {
  project_id?: number;
  code?: string;
  qc_approver_email?: string;
}

const markFinalAssetsApproved = createAsyncThunk(
  MARK_FINAL_ASSETS_APPROVED,
  async (args: markFinalAssetsApprovedParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<ProjectById>(
      MARK_FINAL_ASSETS_APPROVED,
      args,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default markFinalAssetsApproved;
