import { Box, styled } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";

export const StyledTextArea = styled("textarea")(({ theme }) => {
  return {
    border: "none",
    overflow: "auto",
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    resize: "none",
    fontFamily: "Roobert, serif",
    transition: "all linear 500ms",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    color: theme.palette.text.primary,
    display: "flex",
    width: "100%",
    height: "100%",
    '&:enabled:not([type="submit"])': {
      border: "solid thin var(--light-grey)",
      borderRadius: "10px",
      padding: "12px",
      lineHeight: "17px",
      boxSizing: "border-box",
    },
  };
});

export const EditableTextAreaContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
}));

export const CharacterCount = styled(Text)(({ theme }) => [
  {
    position: "absolute",
    right: "10px",
    bottom: "10px",
    color: theme.palette.grey[600],
    animation: "fadeIn 500ms ease-in-out",
  },
  theme.applyStyles("dark", {
    color: theme.palette.grey[100],
  }),
]);
