import { useEffect } from "react";
import useActiveUserQuery from "../../hooks/userHooks/useActiveUserQuery";
import { AppScreenContent } from "../../stories/components/AppScreenContent/AppScreenContent";
import { Outlet, useNavigate } from "react-router-dom";
import { SCREENS } from "../screens";
import { SignInModal } from "../../stories/components/SignInModal/SignInModal";

interface AuthenticatedRouteProps {
  // If the route should show a sign in modal, or redirect to the home screen
  hidden?: boolean;
  signInLabel?: string;
}

export const AuthenticatedRoute = ({
  signInLabel = "Sign In To Continue",
  hidden,
}: AuthenticatedRouteProps) => {
  const { isLoading, data: activeUser } = useActiveUserQuery();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && !activeUser && hidden) {
      navigate(SCREENS.SIGNED_OUT_HOME_SCREEN, { replace: true });
    }
  }, [activeUser, navigate, isLoading, hidden]);

  if (isLoading) {
    return <AppScreenContent isLoading={true} />;
  }
  if (!activeUser && !hidden) {
    return <SignInModal label={signInLabel} />;
  }
  return <Outlet />;
};
