import { makeBackendGetCallWithJsonResponse } from "../../../store/utils/fetch";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import { GET_SCHEDULED_PROJECT } from "../../../store/utils/routes";
import { promiseEngineEarsHandler } from "../../helpers";

interface getScheduledProjectParams {
  scheduledProjectId: number;
}

export const fetchScheduledProject = async (
  args: getScheduledProjectParams,
) => {
  const { scheduledProjectId } = args;
  const params = `?scheduled_project_id=${scheduledProjectId}`;
  return makeBackendGetCallWithJsonResponse<ScheduledProject>(
    GET_SCHEDULED_PROJECT,
    params,
  ).then(promiseEngineEarsHandler);
};
