import { Box, Chip, MenuItem, TextField, TextFieldProps } from "@mui/material";
import classNames from "classnames";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ReactNode } from "react";
import { TrackableClassNames } from "../../../constants/trackableClassNames";

interface FormSelectProps
  extends Omit<TextFieldProps<"outlined">, "variant" | "select"> {
  items?: Record<string, ReactNode> | string[];
  multiple?: boolean;
}

const FormSelect = ({
  className,
  id,
  items = {},
  SelectProps = {},
  multiple,
  type = "text",
  children,
  ...props
}: FormSelectProps) => {
  return (
    <TextField
      className={classNames(TrackableClassNames.ENGINEEARS_SELECT, className)}
      id={id}
      type={type}
      variant={"outlined"}
      select
      SelectProps={{
        ...(multiple ? { multiple, defaultValue: [] } : {}),
        renderValue: (selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {typeof selected === "string"
              ? selected
              : (selected as string[]).map((value) => (
                  <Chip key={value} color={"primary"} label={value} />
                ))}
          </Box>
        ),
        ...SelectProps,
      }}
      {...props}
    >
      {items
        ? (Array.isArray(items) ? items : Object.entries(items)).map((item) => {
            const name = typeof item === "string" ? item : item[0];
            const value = typeof item === "string" ? name : item[1];
            return (
              <MenuItem key={name} value={name}>
                {typeof value === "string" ? (
                  <Text variant={TextStyleVariant.P2}>{value}</Text>
                ) : (
                  value
                )}
              </MenuItem>
            );
          })
        : children}
    </TextField>
  );
};

export default FormSelect;
