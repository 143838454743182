export enum LOCAL_STORAGE_KEYS {
  TOKEN = "token",
  ACTIVE_STUDIO_PROFILE_ID = "activeStudioProfileId",
  STREAM_TOKEN = "chatStreamToken",
  PALETTE_BY_USER = "paletteByUser",
  PRODUCT_SHOPPING_CART = "productShoppingCart",
  PRODUCT_PRICE_DETAILS = "productPriceDetails",
  SUPPLY_CHAIN_TOKEN = "scToken",
  CHAT_MESSAGES = "chatMessages",
  SUPPLY_CHAIN_EXPIRATION = "scExpiration",
  LOCATION_FILTER_HISTORY = "locationFilterHistory",
  REAL_TIME_INTERACTION_USER_IDS = "realTimeInteractionUserIds",
}
