import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PortfolioFeatureData,
  PortfolioFeatureRequestData,
  transformRawPortfolioData,
  FeaturedTrackArtwork,
} from "../models/portfolio";
import { Project } from "../models/project";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  FEATURED_TRACK_REQUEST,
  GET_BEAT_MATRIX_SUBMISSIONS,
  LOAD_FEATURED_TRACKS,
  PAGINATED_COMPLETED_PROJECTS,
  SUBMIT_BEAT_MATRIX_SELECTIONS,
  UPDATE_FEATURED_TRACK,
  UPDATE_FEATURE_COVER_ART,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";
import { StringLiteral } from "typescript";

interface CompletedProjects {
  [page: number]: Project[] | undefined;
}

interface FeaturedTrackState {
  featuredTracks: PortfolioFeatureData[];
  loadingFeaturedTrackRequest: boolean;
  featuredTrackRequest?: PortfolioFeatureRequestData;
  fetchingFeaturedTracks: boolean;
  completedProjects: CompletedProjects;
  loadingCompletedProjects: boolean;
  currentPageCompletedProjects: number;
  totalPagesCompletedProjects: number;
  countCompletedProjects: number;
  updatingFeaturedTrack: boolean;
  respondingToFeaturedTrackRequest: boolean;
}

const initialState: FeaturedTrackState = {
  featuredTracks: [],
  loadingFeaturedTrackRequest: false,
  featuredTrackRequest: undefined,
  fetchingFeaturedTracks: false,
  completedProjects: {},
  loadingCompletedProjects: false,
  currentPageCompletedProjects: 1,
  totalPagesCompletedProjects: 0,
  countCompletedProjects: 0,
  updatingFeaturedTrack: false,
  respondingToFeaturedTrackRequest: false,
};

export interface fetchAllFeaturedTracksByUserIdParams {
  user_id: number;
}

export const fetchAllFeaturedTracksById = createAsyncThunk(
  LOAD_FEATURED_TRACKS,
  async (args: fetchAllFeaturedTracksByUserIdParams, thunkAPI) => {
    const user_id = args.user_id;
    const params = `?user_id=${user_id}`;
    const result = await makeBackendGetCallWithJsonResponse<
      PortfolioFeatureData[]
    >(LOAD_FEATURED_TRACKS, params);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface getFeaturedTrackRequestParams {
  featuredTrackId: number;
}

export const getFeaturedTrackRequest = createAsyncThunk(
  FEATURED_TRACK_REQUEST,
  async (args: getFeaturedTrackRequestParams, thunkAPI) => {
    const params = `?featured_track_id=${args.featuredTrackId}`;
    const result =
      await makeBackendGetCallWithJsonResponse<PortfolioFeatureRequestData>(
        FEATURED_TRACK_REQUEST,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface respondToFeaturedTrackRequestParams {
  featured_track_request_id: number;
  action: string;
}

export const respondToFeaturedTrackRequest = createAsyncThunk(
  FEATURED_TRACK_REQUEST + "/post",
  async (args: respondToFeaturedTrackRequestParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<PortfolioFeatureRequestData>(
        FEATURED_TRACK_REQUEST,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateFeaturedTrackDetailsParams {
  id?: number;
  album_name?: string;
  artist_name?: string;
  song_name?: string;
  description?: string;
  project_id?: number;
}

export interface updateFeaturedTrackParams {
  featured_track_id?: number;
  featured_track_details?: updateFeaturedTrackDetailsParams;
  muso_credit_id?: number;
  sort_index?: number;
  deleted?: boolean;
}

export const updateFeaturedTrack = createAsyncThunk(
  UPDATE_FEATURED_TRACK + "/post",
  async (args: updateFeaturedTrackParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<PortfolioFeatureData>(
        UPDATE_FEATURED_TRACK,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface fetchCompletedProjectsForPortfolioParams {
  page: number;
}

interface CompletedProjectsResponse {
  data: Project[];
  num_pages: number;
  page: number;
  count: number;
}

export const fetchCompletedProjectsForPortfolio = createAsyncThunk(
  PAGINATED_COMPLETED_PROJECTS,
  async (args: fetchCompletedProjectsForPortfolioParams, thunkAPI) => {
    const params = `?page=${args.page}`;
    const result =
      await makeBackendGetCallWithJsonResponse<CompletedProjectsResponse>(
        PAGINATED_COMPLETED_PROJECTS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface uploadCoverArtParams {
  data: string;
  featured_track_id?: number;
}

export const uploadCoverArt = createAsyncThunk(
  UPDATE_FEATURE_COVER_ART,
  async (args: uploadCoverArtParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<FeaturedTrackArtwork>(
        UPDATE_FEATURE_COVER_ART,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface BeatMatrixSelection {
  path: string;
  volume: number;
}

export interface submitBeatMatrixBeatParams {
  beat_name: string;
  selections: BeatMatrixSelection[];
}

export interface SubmitBeatMatrixResponse {
  download_url: string;
}

export const submitBeatMatrixBeat = createAsyncThunk(
  SUBMIT_BEAT_MATRIX_SELECTIONS,
  async (args: submitBeatMatrixBeatParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<SubmitBeatMatrixResponse>(
        SUBMIT_BEAT_MATRIX_SELECTIONS,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface BeatMatrixSubmission {
  url: string;
  timestamp: string;
}

export interface BeatMatrixSubmissionsResponse {
  submissions: BeatMatrixSubmission[];
}

export const fetchBeatMatrixSubmissions = createAsyncThunk(
  GET_BEAT_MATRIX_SUBMISSIONS,
  async (_, thunkAPI) => {
    const result =
      await makeBackendGetCallWithJsonResponse<BeatMatrixSubmissionsResponse>(
        GET_BEAT_MATRIX_SUBMISSIONS,
        "",
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const PortfolioServiceSlice = createSlice({
  name: "PortfolioServiceSlice",
  initialState,
  reducers: {
    clearPortfolioFeatureData: () => {
      return initialState;
    },
    setCompletedProjectsPage: (state, action: PayloadAction<number>) => {
      state.currentPageCompletedProjects = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllFeaturedTracksById.pending, (state) => {
      state.fetchingFeaturedTracks = true;
    });
    builder.addCase(fetchAllFeaturedTracksById.rejected, () => {
      return initialState;
    });
    builder.addCase(fetchAllFeaturedTracksById.fulfilled, (state, action) => {
      state.featuredTracks = action.payload.map(transformRawPortfolioData);
      state.fetchingFeaturedTracks = false;
    });
    builder.addCase(getFeaturedTrackRequest.pending, (state) => {
      state.loadingFeaturedTrackRequest = true;
    });
    builder.addCase(getFeaturedTrackRequest.rejected, (state) => {
      state.loadingFeaturedTrackRequest = false;
      state.featuredTrackRequest = undefined;
    });
    builder.addCase(getFeaturedTrackRequest.fulfilled, (state, action) => {
      state.featuredTrackRequest = {
        id: action.payload.id,
        approver_user_id: action.payload.approver_user_id,
        featured_track: transformRawPortfolioData(
          action.payload.featured_track,
        ),
        status: action.payload.status,
      };
      state.loadingFeaturedTrackRequest = false;
    });
    builder.addCase(fetchCompletedProjectsForPortfolio.pending, (state) => {
      state.loadingCompletedProjects = true;
    });
    builder.addCase(fetchCompletedProjectsForPortfolio.rejected, (state) => {
      state.loadingCompletedProjects = false;
    });
    builder.addCase(
      fetchCompletedProjectsForPortfolio.fulfilled,
      (state, action) => {
        const page = action.meta.arg.page;
        state.countCompletedProjects = action.payload.count;
        state.currentPageCompletedProjects = action.payload.page;
        state.totalPagesCompletedProjects = action.payload.num_pages;
        state.completedProjects[page] = action.payload.data;
        state.loadingCompletedProjects = false;
      },
    );
    builder.addCase(updateFeaturedTrack.rejected, (state) => {
      state.updatingFeaturedTrack = false;
    });
    builder.addCase(updateFeaturedTrack.pending, (state) => {
      state.updatingFeaturedTrack = true;
    });
    builder.addCase(updateFeaturedTrack.fulfilled, (state, action) => {
      const tracks = state.featuredTracks;
      const updated = tracks.map((track) => {
        if (track.id === action.payload.id) {
          return transformRawPortfolioData(action.payload);
        }
        return track;
      });
      state.featuredTracks = updated;
      state.updatingFeaturedTrack = false;
    });
    builder.addCase(respondToFeaturedTrackRequest.pending, (state) => {
      state.respondingToFeaturedTrackRequest = true;
    });
    builder.addCase(respondToFeaturedTrackRequest.rejected, (state) => {
      state.respondingToFeaturedTrackRequest = false;
    });
    builder.addCase(
      respondToFeaturedTrackRequest.fulfilled,
      (state, action) => {
        state.respondingToFeaturedTrackRequest = false;
        state.featuredTrackRequest = {
          id: action.payload.id,
          approver_user_id: action.payload.approver_user_id,
          featured_track: transformRawPortfolioData(
            action.payload.featured_track,
          ),
          status: action.payload.status,
        };
      },
    );
  },
});

export const { clearPortfolioFeatureData, setCompletedProjectsPage } =
  PortfolioServiceSlice.actions;
export default PortfolioServiceSlice.reducer;
