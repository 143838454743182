import { styled } from "@mui/material";
import { Button } from "../../../core-ui/components/Button/Button";

export const UploadedFileListContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 575px;
  margin-bottom: 1rem;
`;

export const UploadedFileListItemContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const FileStatus = styled("div", {
  shouldForwardProp: (prop) => prop !== "$uploaded",
})<{
  $uploaded?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  min-width: 110px;
  gap: 6px;
  font-weight: 700;
  color: ${({ theme, $uploaded }) =>
    $uploaded
      ? theme.palette.standardColor.Green[600]
      : theme.palette.standardColor.BoxyYellow[500]};
}`;

export const PopOverIconButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "disabled",
})`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.text.disabled : theme.palette.text.primary};
  min-height: unset;
  padding: 6px;
`;
