import * as Checkbox from "@radix-ui/react-checkbox";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { CSSInterpolation, styled } from "@mui/material";

export const DropdownMenuContent = styled(DropdownMenu.Content, {
  shouldForwardProp: (prop) => prop !== "overrideStyle" && prop !== "$zIndex",
})<{
  $zIndex?: number;
  overrideStyle?: CSSInterpolation;
}>`
  border-radius: ${({ theme }) => theme.border.radius.xs};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) =>
    theme.palette.customColor.dropdownBackgroundColor};
  z-index: ${({ $zIndex, theme }) =>
    $zIndex ?? theme.zIndex.customZIndex.selectDropdownMenuContent};
  ${(props) => props.overrideStyle};
`;

export const DropdownMenuLabel = styled(DropdownMenu.Label)`
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const Separator = styled(DropdownMenu.Separator)`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.divider};
`;

export const CheckboxLabel = styled("label")`
  padding: 10px 16px 10px 36px;
  position: relative;
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.primary};
  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.customColor.midgroundColor};
  }
`;

export const CheckboxRoot = styled(Checkbox.Root)`
  width: 12px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const CheckBoxGroupTitle = styled("p")`
  padding: 10px 16px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
