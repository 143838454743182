import { useMemo } from "react";
import { DEFAULT_SERVICE_COVER_IMAGE_SRC } from "../constants/googleStorage";
import {
  entityPhotoPrefix,
  getResizeURL,
  imagePathPrefix,
} from "../store/utils";
import defaultUser from "../stories/assets/defaultuser.jpg";
import noImage from "../stories/assets/noimage.jpg";

export const useImageURLFromPath = (
  isEntityProfilePath: boolean,
  path?: string,
): string | undefined => {
  return useMemo(() => {
    if (!path) return isEntityProfilePath ? noImage : defaultUser;
    if (isEntityProfilePath) {
      return entityPhotoPrefix + path;
    }
    return imagePathPrefix + path;
  }, [isEntityProfilePath, path]);
};

export const getImageURLFromPath = (
  isEntityProfilePath: boolean,
  path?: string,
) => {
  if (!path) return defaultUser;
  if (isEntityProfilePath) {
    return entityPhotoPrefix + path;
  }
  return imagePathPrefix + path;
};

export const getEntityPhotoProps = ({
  path,
  isEntityProfilePath,
  fallback = DEFAULT_SERVICE_COVER_IMAGE_SRC,
}: {
  path?: string;
  isEntityProfilePath: boolean;
  fallback?: string;
}) => {
  const originalPath = getImageURLFromPath(isEntityProfilePath, path);
  const resizedPath = getResizeURL(isEntityProfilePath, path);

  let retriesCount = 2;

  return {
    src: resizedPath || originalPath,
    onError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (retriesCount === 0) return;
      e.currentTarget.onerror = null;

      if (retriesCount === 2) {
        e.currentTarget.src = originalPath || fallback;
      } else if (retriesCount === 1) {
        e.currentTarget.src = fallback;
      }
      retriesCount--;
    },
  };
};
