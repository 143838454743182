import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import createTeamInvite, {
  CreateInviteParams,
} from "../../api/recording/studio/createTeamInvite";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { updateTeam } from "../../store/actions/team";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

const useCreateInviteMutation = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: [QUERY_KEYS.CREATE_TEAM_INVITE],
    mutationFn: async (params: CreateInviteParams) => {
      const teamId = params.team_id;
      emitAnalyticsTrackingEvent("inviting_user_to_team", { teamId });
      return createTeamInvite(params)
        .then(async (r) => {
          dispatch(updateTeam(r));
          return r;
        })
        .catch(() => {
          toast("Something went wrong. Please try again.");
        });
    },
    retry: 0,
  });
};

export default useCreateInviteMutation;
