import { atomWithStorage } from "jotai/utils";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";
import { atom } from "jotai";

const _supplyChainExpirationAtom = atomWithStorage(
  LOCAL_STORAGE_KEYS.SUPPLY_CHAIN_EXPIRATION,
  "",
);

export const supplyChainExpirationAtom = atom(
  (get) => {
    return get(_supplyChainExpirationAtom);
  },
  (_, set, exp: string | ((exp: string) => string)) => {
    let result = "";
    // This is a helper function to capture the result from the set state action
    set(_supplyChainExpirationAtom, (oldState) => {
      if (typeof exp === "function") {
        result = exp(oldState);
      } else {
        result = exp;
      }
      return result;
    });
  },
);

supplyChainExpirationAtom.debugLabel = "SupplyChain Auth Expiration";
