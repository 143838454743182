import { ComponentType, FC } from "react";
import { SCREENS } from "./screens";

export interface RouteConfig {
  path: string;
  component: ComponentType | FC;
  exact?: boolean;
}

export enum EXTERNAL_LINKS {
  BLOG = "https://blog.engineears.com/",
  MUSO = "https://muso.ai/",
  SUPPORT = "https://support.engineears.com/",
  ARTIST_CALENDLY = "https://calendly.com/engineears-team/1-1-w-artist-relations",
}

// Be careful not to let these get out of sync with the routes below.
// Having an enum in an enum creates a compilation error
export enum ROUTE_PREFIXES {
  CUSTOMER_PORTAL = "/customer-portal",
  BOOKING = "/booking",
  BOOKINGS = "/bookings",
  FEATURED_TRACK = "/featured-track",
  LISTEN = "/listen",
  MUSO = "/muso",
  PROJECT = "/project",
  PROJECTS = "/projects",
  PROJECT_OVERVIEW = "/project-overview",
  UPLOAD = "/upload",
  SESSION = "/session",
  STUDIO = "/studio",
}

// This is used in the nav bars to highlight the icon
export const PROJECT_SCREEN_PATHS = [
  SCREENS.PROJECTS_NO_TAB,
  ROUTE_PREFIXES.BOOKINGS,
];

export const SOUND_OF_TOMORROW_LANDING =
  "https://community.engineears.com/soundoftomorrow";
export const DREAMVILLE_LANDING = "https://community.engineears.com/dreamville";
export const NAMM_LANDING =
  "https://engineears.com/community?path=community-events/post/engineears-namm-2025-6GPrwytJ0vv2Ke7";
export const MIXHER_LANDING = "https://community.engineears.com/mixher";
export const DOLBY_ATMOS_LANDING =
  "https://community.engineears.com/dolby-atmos";

const routes: RouteConfig[] = [];

export const isAuthenticatedRoute = (pathname: string) => {
  const path = pathname.split("/").filter(Boolean)[0];
  return [
    SCREENS.BOOKINGS,
    SCREENS.ADMIN_DASHBOARD,
    SCREENS.DASHBOARD,
    SCREENS.MESSAGES,
    SCREENS.DASHBOARD_FAVORITES,
    SCREENS.SELECT_FEATURED_TRACK,
    SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN,
    SCREENS.MY_LIBRARY,
  ].some((authenticatedRoute) => authenticatedRoute.includes(path));
};

export default routes;
