import { ReactNode } from "react";
import { getImageUrl } from "../../../constants/googleStorage";
import { isValidRecordingServiceStudio } from "../../../hooks/useValidRecordingServices";
import { LoadEngineeringServicesResponse } from "../../../store/actions/services";
import EntityPhoto from "../../../store/models/entityPhoto";
import { RecordingService } from "../../../store/models/recording";
import Service, { transformRawData } from "../../../store/models/service";
import User from "../../../store/models/user";
import {
  BucketName,
  getImageProps,
  getOriginalImageProps,
} from "../../../store/utils";
import { ArtistReleaseTabView } from "../../components/ArtistReleaseTabView/ArtistReleaseTabView";
import { LivestreamTab } from "../../components/LivestreamTab/LivestreamTab";
import { Overview } from "../../components/OverviewTab/Overview";
import { PortfolioTab } from "../../components/PortfolioTab/PortfolioTab";
import { ReviewsListV2Wrapper } from "../../components/ReviewsList/ReviewsListV2/ReviewsListWrapperV2";
import { SocialMediaListEnum, SocialMediaMap } from "../../types/types";
import { ServicesTab } from "./components/ServicesTab";

/**
 * Query parameter keys for the Profile Screen
 * @example ?selectedTab=services
 */
export enum ProfileScreenParamKeys {
  SelectedTab = "selectedTab",
}

// These values must be kept in sync with the BE
// engineears/accounts/models.py
export enum ProfileScreenTabs {
  Releases,
  Services,
  Reviews,
  Portfolio,
  Overview,
  Livestream,
}

export const profileTabsMap: Record<
  number,
  {
    value: ProfileScreenTabs;
    label: string;
    content: ReactNode | null;
    queryParamsValue: string;
    locked?: boolean | null;
    lockedReason?: string | null;
  }
> = {
  [ProfileScreenTabs.Releases]: {
    value: ProfileScreenTabs.Releases,
    label: "Releases",
    content: <ArtistReleaseTabView />,
    queryParamsValue: "releases",
  },
  [ProfileScreenTabs.Services]: {
    value: ProfileScreenTabs.Services,
    label: "Services",
    content: <ServicesTab />,

    queryParamsValue: "services",
  },
  [ProfileScreenTabs.Reviews]: {
    value: ProfileScreenTabs.Reviews,
    label: "Reviews",
    content: <ReviewsListV2Wrapper />,

    queryParamsValue: "reviews",
  },
  [ProfileScreenTabs.Portfolio]: {
    value: ProfileScreenTabs.Portfolio,
    label: "Portfolio",
    content: <PortfolioTab />,

    queryParamsValue: "portfolio",
  },
  [ProfileScreenTabs.Overview]: {
    value: ProfileScreenTabs.Overview,
    label: "Overview",
    content: <Overview />,
    queryParamsValue: "overview",
  },
  [ProfileScreenTabs.Livestream]: {
    value: ProfileScreenTabs.Livestream,
    label: "Livestream",
    content: <LivestreamTab />,
    queryParamsValue: "livestream",
  },
};

const validDomains = [
  "soundcloud.com",
  "instagram.com",
  "twitter.com",
  "x.com",
  "facebook.com",
  "twitch.tv",
  "twitch.com",
  "tiktok.com",
  "youtube.com",
  "spotify.com",
  "tidal.com",
  "apple.com",
  "amazon.com",
  "yt.be",
];

export const a11yProps = (key: number | string) => {
  return {
    id: `tab-${key}`,
    "aria-controls": `tabpanel-${key}`,
  };
};

export const combineServicesFromResponse = (
  engineerId: number,
  services: LoadEngineeringServicesResponse,
  loggedInUser?: User,
) => {
  if (!services) {
    return [];
  }

  const result = [];
  const {
    recording_services: recordingServices,
    services: engineeringServices,
  } = services;

  if (recordingServices) {
    const recordingService = recordingServices.find(
      (service: RecordingService) => {
        return service?.engineer && service.engineer.id === engineerId;
      },
    );

    const studioRecordingServices = recordingServices.filter(
      (service: RecordingService) =>
        Boolean(service.studio_room) &&
        isValidRecordingServiceStudio(
          service.studio_room?.studio,
          loggedInUser,
          false,
          service.studio_room ? [service.studio_room] : [],
        ),
    );

    if (
      recordingService &&
      (Boolean(
        recordingService.will_come_to_you || studioRecordingServices.length,
      ) ||
        engineerId === loggedInUser?.engineer?.id)
    ) {
      result.push(recordingService);
    }
  }

  if (engineeringServices) {
    result.push(
      ...engineeringServices
        .map(transformRawData)
        .filter((service: Service) => service.deleted === null),
    );
  }

  return result;
};

export const getProfileCoverPhotoImageProps = (
  isMobile: boolean,
  userData: User,
) => {
  return isMobile
    ? getImageProps(
        BucketName.COVER_PHOTOS_MOBILE,
        userData.cover_photo_mobile?.path || userData.cover_photo_desktop?.path,
        getImageUrl("profileCoverPhotos/mobile-boomy-orange-banner.svg"),
      )
    : getImageProps(
        BucketName.COVER_PHOTOS,
        userData.cover_photo_desktop?.path,
        getImageUrl("profileCoverPhotos/boomy-orange-banner.svg"),
      );
};

export const getEntityPhotoImageProps = (
  entityPhoto: EntityPhoto | null,
  original = false,
) => {
  if (original) {
    return getOriginalImageProps(BucketName.ENTITY_PHOTO, entityPhoto?.path);
  }
  return getImageProps(
    BucketName.ENTITY_PHOTO,
    entityPhoto?.path,
    getImageUrl("noimage.jpg"),
  );
};

export const getUserProfileImageProps = (photoPath?: string) => {
  return getImageProps(
    BucketName.USER_PHOTOS,
    photoPath,
    getImageUrl("userProfile/defaultuser.jpg"),
    "",
  );
};

const isValidUrl = (value: string): boolean => {
  const normalizedValue =
    value.startsWith("http://") || value.startsWith("https://")
      ? value
      : `https://${value}`;
  try {
    const parsedUrl = new URL(normalizedValue);
    return validDomains.some((domain) => parsedUrl.hostname.endsWith(domain));
  } catch (_error) {
    return false;
  }
};

const hasHttpProtocol = (value: string): boolean => {
  return value.startsWith("http://") || value.startsWith("https://");
};

export const validateAndPrepareUrl = (
  value: string,
  socialMediaEnum: SocialMediaListEnum,
): string => {
  if (!value) return "";
  value = value.replace(/\s/g, "");
  if (isValidUrl(value)) {
    return ensureHttpsProtocol(value);
  }
  // remove @ symbol if not valid url
  // This case is specific to youtube because we prefix the @ symbol on the url
  if (
    value.startsWith("@") &&
    socialMediaEnum === SocialMediaListEnum.youtube
  ) {
    value = value.slice(1);
  }
  return constructSocialMediaUrl(value, socialMediaEnum);
};

const ensureHttpsProtocol = (url: string): string => {
  return hasHttpProtocol(url) ? url : `https://${url}`;
};

const constructSocialMediaUrl = (
  value: string,
  socialMediaEnum: SocialMediaListEnum,
): string => {
  const baseUrl = SocialMediaMap.get(socialMediaEnum)?.baseLink;
  if (!baseUrl) return "";
  return `${baseUrl}${value}`;
};
