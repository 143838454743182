import { getImageUrl } from "../../constants/googleStorage";
import { getImageURLFromPath } from "../../hooks/useImageURLFromPath";
import noImage from "../../stories/assets/noimage.jpg";

// default resize values must stay consistent with DEFAULT_RESIZE_DIMENSIONS in settings.py
const defaultResizeWidth = 1920;
const defaultResizeHeight = 1080;

const productionHostnames = new Set([
  "engineears.com",
  "engineears.org",
  "www.engineears.org",
  "www.engineears.com",
  "engineears-215922.appspot.com",
  "prodstaging-dot-engineears-215922.appspot.com",
  "prodstaging.engineears.org",
]);

export const isProd =
  typeof window === "undefined"
    ? false
    : productionHostnames.has(window.location.hostname);

export const streamApiKey = isProd ? "98gw7rkwsyqx" : "drjq8j43rez5";

export const PRODUCTION_STRIPE =
  "pk_live_51HtM5xDDQAW5CNoRQ6HiVZUiI3lbCuMlYBcsJPCgVnD3eXlfn5HxOSZlQyGwsffZp0xTqYRjrBS73hNiG2zjwbnf00J3HVsULR";
export const DEVELOPMENT_STRIPE =
  "pk_test_51HtM5xDDQAW5CNoRo596n0AbY1wB1LIqsRfQoowSms0ZWrzwd3HeK1Sn3dBxoChxKZENFZ9Q6QJxuXymxo1k9yg7002DMAIQdS";

const DEVELOPMENT_PAYPAL =
  "Aa8SDqTeDNSPFjM_cZTlce93Vc38yFLHw06f4Oc2jFLoSPeqJMHgCJB4hrgu7wDAeG1AAQA-rJjVHJ_C";
const PRODUCTION_PAYPAL =
  "AdYbEfvTry9Ut7g0yC06jX9bXBQwXIITzg4VejP7ONYVV24bbKYtEGGC2e0stFbsc_YFDR9VPtdrIBjC";

export const getPaypalClientId = isProd
  ? PRODUCTION_PAYPAL
  : DEVELOPMENT_PAYPAL;

export enum BucketName {
  USER_PHOTOS,
  COVER_PHOTOS,
  COVER_PHOTOS_MOBILE,
  PORTFOLIO_COVER_ART,
  ENTITY_PHOTO,
  SERVICE_COVER_PHOTOS,
}

export const hardcodedDomain = isProd
  ? "https://engineears.com"
  : "https://staging.engineears.org";

export const imagePathPrefix = isProd
  ? "https://storage.googleapis.com/user_photos_prod/"
  : "https://storage.googleapis.com/user_photos_dev/";

const coverPhotoPathPrefix = isProd
  ? "https://storage.googleapis.com/cover_photos_prod/"
  : "https://storage.googleapis.com/cover_photos_dev/";

const coverPhotoMobilePathPrefix = isProd
  ? "https://storage.googleapis.com/cover_photos_mobile_prod/"
  : "https://storage.googleapis.com/cover_photos_mobile_dev/";

export const porfolioCoverArtPathPrefix = isProd
  ? "https://storage.googleapis.com/track_artwork_prod/"
  : "https://storage.googleapis.com/track_artwork_dev/";

export const entityPhotoPrefix = isProd
  ? "https://storage.googleapis.com/entity_photos_prod/"
  : "https://storage.googleapis.com/entity_photos_dev/";

export const serviceCoverPhotoPathPrefix = isProd
  ? "https://storage.googleapis.com/service_cover_photo_prod/"
  : "https://storage.googleapis.com/service_cover_photo_dev/";

const imagePathsPrefix = {
  [BucketName.USER_PHOTOS]: imagePathPrefix,
  [BucketName.COVER_PHOTOS]: coverPhotoPathPrefix,
  [BucketName.COVER_PHOTOS_MOBILE]: coverPhotoMobilePathPrefix,
  [BucketName.PORTFOLIO_COVER_ART]: porfolioCoverArtPathPrefix,
  [BucketName.ENTITY_PHOTO]: entityPhotoPrefix,
  [BucketName.SERVICE_COVER_PHOTOS]: serviceCoverPhotoPathPrefix,
};

export const MUSO_CLIENT_ID = isProd
  ? "5tjbHGl4NT1LrCc8fO5zPePCp9rWVK8O"
  : "C6Gdxn9enOpUpNifDhkDMdlvJUKI2KZY";

export const GOOGLE_OAUTH_CLIENT_ID =
  "260334920008-k5eqnj53n7bet9f32meg90c5kbo05m5e.apps.googleusercontent.com";
export const GOOGLE_API = "AIzaSyC-aUQ1stJKlzvOBQPggfN17g_nLpsdbMo";

export const getResizeURL = (isEntityPhoto: boolean, entityPath?: string) => {
  if (!entityPath) {
    return noImage;
  }
  if (!isEntityPhoto) {
    return getImageURLFromPath(false, entityPath);
  }
  const src = getImageURLFromPath(true, entityPath);
  return src?.replace(
    ".jpg",
    `_${defaultResizeWidth}x${defaultResizeHeight}.jpg`,
  );
};

// This generates `src` and `onError` property to be passed in <img />
// If the resized photo cannot be displayed, it will fall back to the original photo
// If both fails, it will fall back to the optional fallback image
export const getImageProps = (
  bucketName: BucketName,
  path?: string | null,
  fallback = "",
  resizedSuffix = "resized",
) => {
  const originalPath = path
    ? `${imagePathsPrefix[bucketName]}${path}`
    : fallback;
  const resizedPath = path
    ? `${imagePathsPrefix[bucketName]}${path}_${resizedSuffix}.jpg`
    : fallback;

  let retriesCount = 2;

  const imagePath = resizedSuffix ? resizedPath : originalPath;

  return {
    src: imagePath,
    onError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (retriesCount === 0) return;
      e.currentTarget.onerror = null;

      if (retriesCount === 2) {
        e.currentTarget.src = originalPath;
      } else if (retriesCount === 1) {
        e.currentTarget.src = fallback;
      }
      retriesCount--;
    },
    usedFallback: Boolean(imagePath === ""),
  };
};

export const getOriginalImageProps = (
  bucketName: BucketName,
  path?: string | null,
  fallback = getImageUrl("noimage.jpg"),
) => {
  const originalPath = path
    ? `${imagePathsPrefix[bucketName]}${path}`
    : fallback;
  return {
    src: originalPath,
  };
};
