export enum Genre {
  NO_GENRE,
  HIP_HOP,
  RNB,
  ROCK,
  ALTERNATIVE,
  JAZZ,
  FOLK,
  COUNTRY,
  ELECTRONIC,
  POP,
  REGGAE,
  LATIN,
  AFROBEAT,
  TECHNO,
  GOSPEL,
  HOUSE,
  AMAPIANO,
  REGGAETON,
  PUNK,
  ORCHESTRAL,
  METAL,
}

export const genreToStringMap = new Map<Genre, string>([
  [Genre.NO_GENRE, "no genre"],
  [Genre.HIP_HOP, "Hip Hop"],
  [Genre.RNB, "RnB"],
  [Genre.ROCK, "Rock"],
  [Genre.ALTERNATIVE, "Alternative"],
  [Genre.JAZZ, "Jazz"],
  [Genre.FOLK, "Folk"],
  [Genre.COUNTRY, "Country"],
  [Genre.ELECTRONIC, "Electronic"],
  [Genre.POP, "Pop"],
  [Genre.REGGAE, "Reggae"],
  [Genre.LATIN, "Latin"],
  [Genre.AFROBEAT, "Afrobeat"],
  [Genre.TECHNO, "Techno"],
  [Genre.GOSPEL, "Gospel"],
  [Genre.HOUSE, "House"],
  [Genre.AMAPIANO, "Amapiano"],
  [Genre.REGGAETON, "Reggaeton"],
  [Genre.PUNK, "Punk"],
  [Genre.ORCHESTRAL, "Orchestral"],
  [Genre.METAL, "Metal"],
]);

const valueToGenreRecord: Record<string, Genre> = {};
genreToStringMap.forEach((value, key) => {
  valueToGenreRecord[value] = key;
});
export { valueToGenreRecord };

export type GenreArray = Genre[];
