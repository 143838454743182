import { useMemo } from "react";
import { GenreAndServiceTypeSuggestion } from "../../store/models/autocomplete";
import { Genre } from "../../store/models/genres";
import {
  parseQueryStringAsNumberArray,
  parseUrlQueryParams,
} from "../../utils/searchFiltersUtils";
import {
  updateSearchFiltersProps,
  useUpdateSearchFilters,
} from "./useUpdateSearchFilters";
import { SearchScreenParamKeys } from "./utils";

export const useGenresQuery = () => {
  const parsedQueryParams = parseUrlQueryParams();
  const updateSearchFilters = useUpdateSearchFilters();
  const genresValue = parsedQueryParams[SearchScreenParamKeys.Genres];

  const genres = useMemo(
    () =>
      parseQueryStringAsNumberArray(genresValue).filter((el) =>
        Object.values(Genre).includes(el),
      ) as Genre[],
    [genresValue],
  );

  const setGenres = (newGenres: Genre[]) => {
    updateSearchFilters({
      updatedSearchQueryObject: {
        [SearchScreenParamKeys.Genres]: newGenres.join(","),
      },
    });
  };

  const updateGenresFilterOnSuggestion = (
    suggestion: GenreAndServiceTypeSuggestion,
    newPathname: string,
    updateSearchFilters: (_: updateSearchFiltersProps) => void,
  ) => {
    const genreSuggestions = suggestion;
    const genreIndex = genres.findIndex(
      (genre) => genre === genreSuggestions.value,
    );
    const allGenresIndex = genres.findIndex(
      (genre) => genre === Genre.NO_GENRE,
    );
    const genresCopy = [...genres];
    updateGenres(
      genresCopy,
      genreIndex,
      allGenresIndex,
      genreSuggestions.value,
    );

    updateSearchFilters({
      updatedSearchQueryObject: {
        [SearchScreenParamKeys.Genres]: genresCopy.join(","),
      },
      newPathname,
      resetPagination: true,
    });
  };

  return { genres, setGenres, updateGenresFilterOnSuggestion };
};

const updateGenres = (
  genres: Genre[],
  index: number,
  allGenresIndex: number,
  genre: Genre,
) => {
  if (index === -1) {
    // If "All" is selected as a genre filter, remove it
    if (allGenresIndex >= 0) {
      genres.splice(allGenresIndex, 1);
    }
    genres.push(genre);
  } else {
    genres.splice(index, 1);
    if (genres.length === 0) {
      // Add "All" as a selected genre filter if no other filters active
      genres.push(Genre.NO_GENRE);
    }
  }
};
