import { css, CSSInterpolation, styled } from "@mui/material";
import { CSSProperties } from "react";
import ReactModal from "react-modal";

// styles that center the modal content
export const modalContentStyle: CSSProperties = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  backgroundColor: "transparent",
  overflow: "unset !important",
  position: "absolute",
};

export const modalStyle: ReactModal.Styles = {
  content: modalContentStyle,
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "var(--modal-index)",
    backgroundColor: "var(--modal-backdrop-color)",
  },
};

export const closeIconStyle: CSSProperties = {
  cursor: "pointer",
};

export const ModalCard = styled("div")`
  display: flex;
  flex-direction: column;
  min-height: 214px;
  max-width: 720px;
  width: 95vw;
  background: ${({ theme }) => theme.palette.customColor.foregroundColor};
  border-radius: var(--border-radius-md);
  overflow: hidden;
`;

export const ModalHeaderContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  padding: 0 24px;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.customColor.backgroundColor};
  z-index: 1004;
`;

export const ModalHeader = styled("h6")`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ModalBody = styled("div", {
  shouldForwardProp: (prop) => prop !== "customStyle",
})<{ customStyle?: CSSInterpolation }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 24px;
  width: calc(100% - 48px);
  gap: 12px;

  ${(props) => props.customStyle};
`;

export const ModalFooter = styled("div", {
  shouldForwardProp: (prop) => prop !== "$centerFooterButton",
})<{ $centerFooterButton?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ $centerFooterButton }) =>
    $centerFooterButton ? "center" : "flex-end"};
  height: 76px;
  padding: 0 24px;
  gap: 8px;
`;

export const OverflowedModalBody = ({
  $isMobile = false,
}: {
  $isMobile?: boolean;
}) => css`
  box-sizing: border-box;
  justify-content: flex-start;
  overflow-y: auto;
  width: 100%;
  max-height: ${$isMobile ? "unset" : "75vh"};
  min-height: 350px;
  gap: 0;
`;
