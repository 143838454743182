import { faCirclePause, faCirclePlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  PlayAudioButtonContainer,
  PlayAudioButtonView,
} from "./PlayAudioButton.styles";

interface PlayAudioButtonProps {
  // the progress should be a number between 0 and 100
  progress: number;
  size: number;
  padding?: number;
  isPlaying: boolean;
  handleClick?: () => void;
  invertColor?: boolean;
  isPending?: boolean;
  paymentRequiredForPlayback?: boolean;
}

export const PlayAudioButton = ({
  progress,
  size,
  isPlaying,
  padding = 4,
  handleClick,
  invertColor = false,
  isPending = false,
  paymentRequiredForPlayback = false,
}: PlayAudioButtonProps) => {
  const theme = useTheme();
  return (
    <PlayAudioButtonContainer
      style={{
        width: size,
        height: size,
      }}
    >
      <CircularProgress
        thickness={padding}
        size={size}
        variant="determinate"
        value={progress}
        sx={{
          color: invertColor
            ? theme.palette.common.black
            : theme.palette.common.white,
        }}
      />
      <PlayAudioButtonView
        disabled={isPending}
        style={{
          borderRadius: "50%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        title={
          paymentRequiredForPlayback ? undefined : isPlaying ? "Pause" : "Play"
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!handleClick || paymentRequiredForPlayback) {
            return;
          }
          handleClick();
        }}
      >
        {isPending && !paymentRequiredForPlayback ? (
          <Box
            sx={{
              borderRadius: "50%",
              height: `${size - padding}`,
              width: `${size - padding}`,
              background: invertColor
                ? theme.palette.common.black
                : theme.palette.common.white,
            }}
          >
            <SoundWaveLoader height={size - padding} width={size - padding} />
          </Box>
        ) : (
          <FontAwesomeIcon
            style={{
              width: size - padding,
              height: size - padding,
            }}
            color={
              invertColor
                ? theme.palette.common.black
                : theme.palette.common.white
            }
            icon={isPlaying ? faCirclePause : faCirclePlay}
          />
        )}
      </PlayAudioButtonView>
    </PlayAudioButtonContainer>
  );
};
