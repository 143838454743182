import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, List, ListItem } from "@mui/material";
import { useAtomValue } from "jotai";
import { AuthSteps } from "../../../constants/authSteps";
import AandRAddPhone from "../../assets/authBanner/aandr_add_phone.png";
import AandRBuildTeam from "../../assets/authBanner/aandr_build_team.png";
import AandRUsername from "../../assets/authBanner/aandr_username.png";
import AandRVerifyPhone from "../../assets/authBanner/aandr_verify_phone.png";
import AddPhone from "../../assets/authBanner/add_phone.png";
import Start from "../../assets/authBanner/start.png";
import StudioContact from "../../assets/authBanner/studio_contact.png";
import StudioLocation from "../../assets/authBanner/studio_location.png";
import Username from "../../assets/authBanner/username.png";
import VerifyEmail from "../../assets/authBanner/verify_email.png";
import VerifyPhone from "../../assets/authBanner/verify_phone.png";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import {
  AuthBanner,
  AuthBannerInfoContainer,
  AuthBannerTextWrapper,
} from "./AuthModal.styles";
import { useAppSelector } from "../../../store/hooks";
import { useIsUMGUser } from "../../../hooks/useIsAandR";
import { currentSignUpStepAtom } from "../../../atoms/location/currentSignUpStepAtom";

export interface AuthBannerTextProps {
  title: string;
  bulletPoints: string[];
  bottomText: string;
}

export const stepToImage = (isUMGUser: boolean): Record<number, string> => ({
  [AuthSteps.LOGIN]: Start,
  [AuthSteps.START_LOGIN]: Start,
  [AuthSteps.START_SIGNUP]: Start,
  [AuthSteps.EMAIL_SIGNUP]: Start,
  [AuthSteps.USERNAME]: Username,
  [AuthSteps.VERIFY_EMAIL]: VerifyEmail,
  [AuthSteps.ADD_PHONE]: AddPhone,
  [AuthSteps.VERIFY_PHONE]: VerifyPhone,
  [AuthSteps.AANDR_USERNAME]: isUMGUser ? AandRUsername : Username,
  [AuthSteps.AANDR_ADD_PHONE]: isUMGUser ? AandRAddPhone : AddPhone,
  [AuthSteps.AANDR_VERIFY_PHONE]: isUMGUser ? AandRVerifyPhone : VerifyPhone,
  [AuthSteps.AANDR_BUILD_TEAM]: AandRBuildTeam,
  [AuthSteps.ADD_STUDIO_CONTACT]: StudioContact,
  [AuthSteps.ADD_STUDIO_LOCATION]: StudioLocation,
  [AuthSteps.ADD_PASSWORD]: VerifyEmail,
});

const takeControlText = {
  title: "Take control of your music career.",
  bulletPoints: [
    "Use flexible payment solutions",
    "Collaborate with trust and transparency",
    "Access project management tools",
    "State-of-the-art file encryption",
  ],
  bottomText: "#HearTheDifference",
};

const neverMissProjectDeadlinesText = {
  title: "Never miss project deadlines again.",
  bulletPoints: [
    "Securely share and receive project files",
    "Instant studio and audio engineer bookings",
    "Seamless budget approvals",
    "UMG direct billing payment processes",
    "Create teams by inviting admins and A&Rs",
    "Centralized project chat & workflows",
  ],
  bottomText: "#HearTheDifference",
};

const manageStudioWithEase = {
  title: "Manage your studio with ease.",
  bulletPoints: [
    "Create a profile and highlight your studio",
    "Real-time session management",
    "Seamless budget approvals",
    "Instantly receive payments from clients",
    "Studio staff management tools",
    "Create custom discounts & promotions",
  ],
  bottomText: "#HearTheDifference",
};

const stepToBannerText = (
  isUMGUser: boolean,
): Record<number, AuthBannerTextProps> => ({
  [AuthSteps.LOGIN]: takeControlText,
  [AuthSteps.START_LOGIN]: takeControlText,
  [AuthSteps.START_SIGNUP]: takeControlText,
  [AuthSteps.EMAIL_SIGNUP]: takeControlText,
  [AuthSteps.USERNAME]: takeControlText,
  [AuthSteps.VERIFY_EMAIL]: takeControlText,
  [AuthSteps.ADD_PHONE]: takeControlText,
  [AuthSteps.VERIFY_PHONE]: takeControlText,
  [AuthSteps.AANDR_USERNAME]: isUMGUser
    ? neverMissProjectDeadlinesText
    : takeControlText,
  [AuthSteps.AANDR_ADD_PHONE]: isUMGUser
    ? neverMissProjectDeadlinesText
    : takeControlText,
  [AuthSteps.AANDR_VERIFY_PHONE]: isUMGUser
    ? neverMissProjectDeadlinesText
    : takeControlText,
  [AuthSteps.AANDR_BUILD_TEAM]: neverMissProjectDeadlinesText,
  [AuthSteps.ADD_STUDIO_CONTACT]: manageStudioWithEase,
  [AuthSteps.ADD_STUDIO_LOCATION]: manageStudioWithEase,
  [AuthSteps.ADD_PASSWORD]: takeControlText,
});

const AuthBannerText = ({
  title,
  bulletPoints,
  bottomText,
}: AuthBannerTextProps) => {
  return (
    <AuthBannerTextWrapper>
      <Text
        color={TEXT_COLOR.LIGHT_GRAY}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        size={TEXT_SIZE.LARGE}
      >
        {title}
      </Text>
      <List>
        {bulletPoints.map((point, index) => (
          <ListItem
            style={{ color: TEXT_COLOR.TERTIARY, gap: "10px" }}
            key={index}
          >
            <FontAwesomeIcon icon={faCheck} />
            {point}
          </ListItem>
        ))}
      </List>
      <Text color={TEXT_COLOR.LIGHT_GRAY} weight={TEXT_WEIGHT.SEMI_BOLD}>
        {bottomText}
      </Text>
    </AuthBannerTextWrapper>
  );
};

const pagesWithoutBanner: AuthSteps[] = [
  AuthSteps.PROFILE_TYPE,
  AuthSteps.AANDR_LABEL_SELECTION,
  AuthSteps.COMPLETE,
];
const AuthBannerInfoWrapper = () => {
  const { user } = useAppSelector((state) => state.accountInfo);
  const isUMGUser = useIsUMGUser(user);
  const step = useAtomValue(currentSignUpStepAtom);
  if (pagesWithoutBanner.includes(step)) {
    return null;
  }
  return (
    <AuthBannerInfoContainer>
      <AuthBanner $step={step} $isUMGUser={isUMGUser} />
      <Box style={{ flex: 1, background: "black" }}>
        <AuthBannerText {...stepToBannerText(isUMGUser)[step]} />
      </Box>
    </AuthBannerInfoContainer>
  );
};

export default AuthBannerInfoWrapper;
