import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { EngineEarsError } from "../../../store/actions/errorStore";
import { fetchPortfolioTracksByUserId } from "../portfolioAPI";
import { convertPortfolioProjectToPlayListTrack } from "../../../store/models/playListTrack/helpers/convertPortfolioProjectToPlaylistTrack";
import { PortfolioProjectTrackResponse } from "../../../store/models/portfolio";

export const usePortfolioProjectTracksByUserIdQuery = (userId = 0) => {
  return useQuery({
    initialData: [],
    enabled: Boolean(userId),
    queryKey: [
      QUERY_KEYS.FETCH_PORTFOLIO_PROJECT_TRACKS_BY_USER_ID,
      {
        userId,
      },
    ],
    queryFn: async () => {
      const response = await fetchPortfolioTracksByUserId(userId);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return convertPortfolioProjectToPlayListTrack(
        response.resultJson as PortfolioProjectTrackResponse[],
      );
    },
  });
};
