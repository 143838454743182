import { useQuery } from "@tanstack/react-query";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { Equipment } from "../../store/models/equipment";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { USER_EQUIPMENT } from "../../store/utils/routes";
import queryString from "query-string";
import { AssociationType } from "./useMutateEquipment";

interface GetUserInterfaceResponse {
  [key: number]: Equipment[];
}

export const useGetUserEquipment = (
  // this id can either be studio, studioRoom or user
  associatedEntityId?: number,
  associationType?: AssociationType,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_USER_EQUIPMENT, associatedEntityId],
    queryFn: async () => {
      if (!associatedEntityId) return new Map();
      const queryParams = queryString.stringify({
        association_type: associationType,
      });
      const result =
        await makeBackendGetCallWithJsonResponse<GetUserInterfaceResponse>(
          USER_EQUIPMENT,
          `${associatedEntityId}?${queryParams}`,
        );
      if (assertEngineEarsErrorType(result)) {
        return Promise.reject(result);
      }

      if (result.success) {
        const userEquipmentMap = new Map<number, Equipment[]>(
          Object.entries(result.resultJson).map(([key, value]) => [
            parseInt(key),
            value,
          ]),
        );

        return userEquipmentMap;
      }
      displayEngineEarsError(result.resultJson);
      return Promise.reject(result.resultJson);
    },
    enabled: Boolean(associatedEntityId) && Boolean(associationType),
  });
};
