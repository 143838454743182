import {
  DialogTitle,
  DialogTitleProps,
  Grid2,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { MouseEvent } from "react";
import { TrackableClassNames } from "../../../../constants/trackableClassNames";

interface ResponsiveDialogTitleProps
  extends Partial<Omit<DialogTitleProps, "component" | "container" | "ref">> {
  onClose?: (e: MouseEvent<HTMLButtonElement>) => void;
  hideCloseButton?: boolean;
  disabled?: boolean;
}

export const ResponsiveDialogTitle = (
  titleProps: ResponsiveDialogTitleProps,
) => {
  const {
    children: titleChildren,
    className: titleClassName,
    disabled,
    hideCloseButton,
    onClose,
    ...remainingTitleProps
  } = titleProps;
  return (
    <DialogTitle
      {...remainingTitleProps}
      component={Grid2}
      container
      className={classNames(
        TrackableClassNames.ENGINEEARS_DIALOG_TITLE,
        titleClassName,
      )}
    >
      <Grid2 size="grow" display={"flex"} alignItems={"center"}>
        {titleChildren}
      </Grid2>
      {!hideCloseButton && (
        <Grid2 display={"flex"} size={1}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            disabled={disabled}
            // This is how it is preferred at EngineEars.
            disableTouchRipple={true}
            disableFocusRipple={true}
            disableRipple={true}
            sx={{
              height: "fit-content",
              marginLeft: "auto",
              marginRight: "0",
            }}
            className={TrackableClassNames.ENGINEEARS_DIALOG_CLOSE_BUTTON}
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Grid2>
      )}
    </DialogTitle>
  );
};
