import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * A hook to get all query parameters as an array of key-value pairs.
 * Since query parameters can be comma-separated lists, this hook manually parses them
 * to determine which values are lists and which are single values.
 * Additionally, it decodes %2C back to commas for titles and names which are not meant
 * to be parsed as multiple values.
 * @returns An array of key-value pairs.
 */
export const useQueryParamEntries = () => {
  const location = useLocation();

  return useMemo(() => {
    const searchParamsArray = location.search
      ? location.search.replace("?", "").split("&")
      : [];
    const searchParamsEntries: [string, string][] = [];

    // iterate over each key-value pair in the search params
    searchParamsArray.forEach((searchParam) => {
      const [key, value] = searchParam.split("=");
      // if value is a comma-separated list, split it into an array
      const valueArray = decodeURIComponent(value).split(",");
      valueArray.forEach((val) => {
        // use decodeURIComponent to convert %2C back to commas
        searchParamsEntries.push([key, decodeURIComponent(val)]);
      });
    });

    return searchParamsEntries;
  }, [location]);
};
