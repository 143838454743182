import { EngineEarsError } from "../store/actions/errorStore";
import {
  exceptionCodeErrorStringMap,
  isSilentError,
  TRANSITION_NOT_ALLOWED,
  UNKNOWN_ERROR,
} from "../store/models/exceptions";
import { toast } from "react-toastify";
import {
  BackendCallWithJsonErrorResponse,
  BackendCallWithJsonSuccessResponse,
} from "../store/utils/fetch";

export const assertEngineEarsErrorType = (
  obj: unknown,
): obj is EngineEarsError => {
  return (
    typeof obj === "object" &&
    typeof (obj as any).code !== "undefined" &&
    (typeof (obj as any).non_field_errors !== "undefined" ||
      typeof (obj as any).detail !== "undefined" ||
      Boolean(exceptionCodeErrorStringMap.get((obj as any).code)))
  );
};

export const displayEngineEarsError = (
  error: EngineEarsError | undefined,
  displayValidationErrors = false,
) => {
  if (error) {
    if ("code" in error && error.code === UNKNOWN_ERROR) {
      if (error.non_field_errors && error.non_field_errors.length > 0) {
        toast.error(error.non_field_errors[0]);
      }
      if (displayValidationErrors) {
        Object.entries(error).forEach(([_, value]) => {
          if (
            Array.isArray(value) &&
            value.every((item) => typeof item === "string")
          ) {
            const message = value.join(", ").replaceAll("_", " ");
            toast.error(message);
          }
        });
      }
    } else if (
      "code" in error &&
      error.code === TRANSITION_NOT_ALLOWED &&
      "detail" in error &&
      error.detail &&
      error.detail.length > 0
    ) {
      toast.error(error.detail);
    } else {
      if (exceptionCodeErrorStringMap.get(error.code)?.length !== 0) {
        // Some errors we want to react to, but not display to the user
        if (isSilentError(error.code)) {
          return;
        }
        toast.error(exceptionCodeErrorStringMap.get(error.code));
      }
    }
  }
};

export const replaceRoutePlaceholders = (
  route: string,
  placeholders: { [key: string]: number | string },
) => {
  let result = route;
  for (const key in placeholders) {
    result = result.replace(`{${key}}`, placeholders[key].toString());
  }
  return result;
};

export const promiseEngineEarsHandler = <T>(
  res: BackendCallWithJsonErrorResponse | BackendCallWithJsonSuccessResponse<T>,
) => {
  if (res.success) {
    return res.resultJson;
  }
  throw res.resultJson;
};

export interface ValidationError {
  [key: string]: string[] | number;

  code: number;
}
