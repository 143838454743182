import { getEntityPhotoImageProps } from "../../../../stories/screens/ProfileScreen/utils";
import { getDisplayableNameForUser } from "../../../utils/entityUtils";
import { ReleaseTrack } from "../../release";
import { PlaylistTrack, PlaylistTrackType } from "../types";

const convertReleaseTrackToPlaylistTrack = (
  releaseTrack: ReleaseTrack,
  currentUserId?: number,
) => {
  const artist = releaseTrack.release.artist_user;
  const artistDisplayName = getDisplayableNameForUser(artist);
  const artistUsername = artist?.username || "";
  const paymentRequiredForPlayback =
    releaseTrack.release.payment_required_for_playback &&
    !releaseTrack.release_track_purchased_by_current_user;
  const currentUserIsArtist = artist?.id === currentUserId;
  return {
    id: releaseTrack.id,
    artworkSource: getEntityPhotoImageProps(
      releaseTrack.release.release_cover,
      true,
    ).src,
    type: PlaylistTrackType.ArtistRelease,
    title: releaseTrack.title,
    artistDisplayName,
    artistUsername,
    engineerUsername: "",
    engineerDisplayName: "",
    fileVersionId: releaseTrack.file_version_id ?? undefined,
    paymentRequiredForPlayback:
      paymentRequiredForPlayback && !currentUserIsArtist,
  } as PlaylistTrack;
};
export default convertReleaseTrackToPlaylistTrack;
