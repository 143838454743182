import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { fetchActiveUser } from "../../api/auth/fetchActiveUser";
import { activeUserAtom } from "../../atoms/user/activeUserAtom";
import { useAtomValue, useSetAtom } from "jotai";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import { EngineEarsError } from "../../store/actions/errorStore";
import { displayEngineEarsError } from "../../api/helpers";
import useActiveStudiosQuery from "./useActiveStudiosQuery";
import { activeProfileAtom } from "../../atoms/user/activeProfileAtom";
import { UNKNOWN_ERROR } from "../../store/models/exceptions";

const useActiveUserQuery = () => {
  const setActiveUser = useSetAtom(activeUserAtom);
  const setProfileId = useSetAtom(activeProfileAtom);
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  useActiveStudiosQuery({});
  return useQuery({
    queryKey: [QUERY_KEYS.LOAD_USER, token],
    queryFn: async () => {
      return fetchActiveUser()
        .then(async (activeUser) => {
          setActiveUser(activeUser || undefined);
          if (activeUser?.username)
            // Invalidate the cached profile we have for the logged in user if something changes
            void queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_USER_PROFILE, activeUser.username],
            });
          return activeUser;
        })
        .catch((e: EngineEarsError) => {
          displayEngineEarsError(e);

          if (e.code === UNKNOWN_ERROR) {
            setProfileId(undefined);
            setActiveUser(undefined);
          }
        });
    },
    retry: false,
    enabled: Boolean(token),
  });
};

export default useActiveUserQuery;
