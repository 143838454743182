import { useMutation } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import {
  GuestLoginParams,
  postGuestLogin,
} from "../../api/auth/postGuestLogin";
import { displayEngineEarsError } from "../../api/helpers";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { TRACKING_EVENTS_NAME } from "../../constants/trackingEvents";
import { setUser } from "../../store/actions/accountInfo";
import { EngineEarsError } from "../../store/actions/errorStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

const useGuestLoginMutation = (logoutOnError: boolean = true) => {
  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );
  const setToken = useSetAtom(tokenAtom);
  const dispatch = useAppDispatch();
  const clearUserData = () => {
    setToken("");
    dispatch(setUser());
  };
  return useMutation({
    mutationKey: [QUERY_KEYS.LOGIN_GUEST],
    mutationFn: async (params: GuestLoginParams) => {
      emitAnalyticsTrackingEvent("guest_login_attempt", {
        email: params?.email?.length ? params.email : undefined,
        phoneNumber: params?.phone_number?.length
          ? params.phone_number
          : undefined,
      });
      return postGuestLogin(params);
    },
    // If we handle the error as part of the mutation function, it can show the toast numerous times.
    // Our custom errors are technically valid responses
    onSuccess: (result) => {
      setToken(result.token);
      dispatch(setUser(result.user));
      emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.REGISTER_VIA_OTP, {
        email: result.user.email,
        phoneNumber: result.user.phone_number?.phone_number,
        userId: result.user.id,
        ...localUTMParams,
      });
      return result;
    },
    onError: (error: EngineEarsError) => {
      if (logoutOnError) {
        clearUserData();
      }
      displayEngineEarsError(error);
      return error;
    },
  });
};

export default useGuestLoginMutation;
