import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { isActiveUserStudioManagerAtom } from "../../../atoms/user/activeUserAtom";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes/screens";
import { getMyStudios } from "../../../store/actions/studio";
import { useAppDispatch } from "../../../store/hooks";
import logo from "../../assets/header-logo.svg";
import whiteLogo from "../../assets/header-white.svg";
import Link from "../../elements/Link/Link";
import { LoggedInHeaderContent } from "../LoggedInHeaderContent/LoggedInHeaderContent";
import { NavProfileSidePanel } from "../Navigation/NavProfileSidePanel/NavProfileSidePanel";
import "./AppBarHeader.css";
import { AppBarHeaderContainer } from "./AppBarHeaderContainer";
import { AppBarHeaderContentContainer } from "./AppBarHeaderContentContainer";

export interface AppBarHeaderProps {
  isEmptyHeader?: boolean;
}

export const AppBarHeader = ({ isEmptyHeader }: AppBarHeaderProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isMobile } = useMediaQueryBreakpoint();
  const darkMode = useAtomValue(darkModeAtom);

  const isStudioEnabled = useAtomValue(isActiveUserStudioManagerAtom);
  useEffect(() => {
    if (!isStudioEnabled || isEmptyHeader) {
      return;
    }
    dispatch(getMyStudios()).catch(() => toast.error("Error loading studios"));
  }, [dispatch, isStudioEnabled, isEmptyHeader]);

  return (
    <AppBarHeaderContainer>
      <>
        {/* <AnnouncementHeader /> */}
        <AppBarHeaderContentContainer>
          <Box className="app-bar-main-content">
            {isMobile && <NavProfileSidePanel darkMode={darkMode} />}
            <Link
              className="engineears-logo"
              to={SCREENS.SIGNED_OUT_HOME_SCREEN}
            >
              <img alt="engineears" src={darkMode ? whiteLogo : logo} />
            </Link>
            {!isMobile && !isEmptyHeader && (
              <AppBarContent pathname={location.pathname} />
            )}
          </Box>
        </AppBarHeaderContentContainer>
      </>
    </AppBarHeaderContainer>
  );
};

interface AppBarContentProps {
  pathname: string;
}

const AppBarContent = ({ pathname }: AppBarContentProps) => {
  return useMemo(() => {
    switch (pathname) {
      default:
        return <LoggedInHeaderContent />;
    }
  }, [pathname]);
};
