import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { bottomNavContainerHeightAtom } from "../../../atoms/navAtoms";
import { isActiveUserEngineerAtom } from "../../../atoms/user/activeUserAtom";
import useGetOnboardingProgressQuery from "../../../hooks/onboardingHooks/useGetOnboardingProgressQuery";
import { DISCIPLINE_TYPE, getPrimaryDiscipline } from "../../../hooks/user";
import { useAppSelector } from "../../../store/hooks";
import StickyOnboardingChecklist from "./StickyOnboardingChecklist";
import {
  ChecklistPhase,
  checklistPhaseAtom,
  showOnboardingChecklistAtom,
} from "./atoms";
import { PAGE_HASH } from "../../../atoms/location/locationAtom";
import useHashBoolean from "../../../hooks/reactRouterHooks/useHashBoolean";

const ChecklistWrapper = () => {
  const theme = useTheme();
  const currentUser = useAppSelector((state) => state.accountInfo?.user);
  const isEngineer = useAtomValue(isActiveUserEngineerAtom);
  const primaryDiscipline = getPrimaryDiscipline(currentUser);
  const supportedPrimaryDisciplines = [
    DISCIPLINE_TYPE.ARTIST,
    DISCIPLINE_TYPE.ENGINEER,
    DISCIPLINE_TYPE.STUDIO_MANAGER,
  ];
  const { isOpen: campaignSidePanelisOpen } = useHashBoolean({
    value: PAGE_HASH.CAMPAIGN,
  });
  const { isOpen: createCampaignSidePanelisOpen } = useHashBoolean({
    value: PAGE_HASH.CREATE_CAMPAIGN,
  });
  const showChecklist =
    supportedPrimaryDisciplines.includes(primaryDiscipline) || isEngineer;
  const { isLoading } = useGetOnboardingProgressQuery();
  const bottomNavContainerHeight = useAtomValue(bottomNavContainerHeightAtom);
  const [showOnboardingChecklist, setShowOnboardingChecklist] = useAtom(
    showOnboardingChecklistAtom,
  );
  const checklistPhase = useAtomValue(checklistPhaseAtom);
  const location = useLocation();
  const isTabletOrBelow = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (checklistPhase === ChecklistPhase.COMPLETE) {
      setShowOnboardingChecklist(false);
      return;
    }
    const isProjectWorkflowPanelOpen =
      location.pathname.includes("project-overview") &&
      location.search.includes("project=");

    const isBookingRequestPanelOpen =
      location.pathname.includes("projects") &&
      location.search.includes("scheduled_project_id=");

    const isMessagesPage = location.pathname.includes("messages");
    const bookingRegex =
      /booking\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
    const isBookingPath = bookingRegex.test(location.pathname);
    const checkoutRegex =
      /checkout\/releases\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
    const isCheckoutPath = checkoutRegex.test(location.pathname);
    const isAuthPath = location.pathname.includes("auth");
    const isSubscriptionRoute =
      location.pathname.includes("add-subscription") ||
      location.pathname.includes("subscription-success");
    const shouldHide =
      ((!isEngineer || isTabletOrBelow) && (isBookingPath || isCheckoutPath)) ||
      isAuthPath ||
      isSubscriptionRoute ||
      (isTabletOrBelow &&
        (isProjectWorkflowPanelOpen || isBookingRequestPanelOpen)) ||
      isMessagesPage;
    setShowOnboardingChecklist(!shouldHide);
  }, [
    location.search,
    location.pathname,
    showOnboardingChecklist,
    setShowOnboardingChecklist,
    isTabletOrBelow,
    checklistPhase,
    isEngineer,
  ]);

  if (
    !showOnboardingChecklist ||
    !showChecklist ||
    campaignSidePanelisOpen ||
    createCampaignSidePanelisOpen
  ) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        position: "fixed",
        bottom: `${bottomNavContainerHeight}px`,
        zIndex: "var(--bottom-nav-z-index) !important",
        [theme.breakpoints.up("sm")]: {
          zIndex: "var(--side-panel-z-index) !important",
        },
        [theme.breakpoints.down("md")]: {
          position: "relative",
          bottom: "unset",
          width: "100%",
        },
      })}
    >
      {isLoading ? <></> : <StickyOnboardingChecklist />}
    </Box>
  );
};

export default ChecklistWrapper;
