import * as Popover from "@radix-ui/react-popover";
import {
  ComponentProps,
  Dispatch,
  forwardRef,
  Ref,
  SetStateAction,
} from "react";
import { callAllEventHandlers } from "../../../../utils/utils";
import SidePanelCloseButton from "../../../components/ProjectFilters/SidePanelFilters/SidePanelCloseButton";
import { Button, ButtonVariant } from "../Button/Button";
import { PopConfirmProps } from "../PopConfirm/PopConfirm";
import {
  PopoverContent,
  PopoverContentBody,
  PopoverFooter,
  PopoverHeader,
} from "../PopConfirm/PopConfirm.styles";
import { Text, TEXT_WEIGHT } from "../Text/Text";

interface BasePopoverCommonProps extends PopConfirmProps {
  isOpen: boolean;
  setIsPopoverOpen: Dispatch<SetStateAction<boolean>>;
  onPointerDownOutside?: ComponentProps<
    typeof PopoverContent
  >["onPointerDownOutside"];
  showXButton?: boolean;
}

interface BasePopoverNoFooterProps
  extends Omit<
    BasePopoverCommonProps,
    | "okText"
    | "okButtonProps"
    | "onConfirm"
    | "cancelText"
    | "cancelButtonProps"
    | "onCancel"
    | "side"
  > {
  hideFooter: true;
}

export interface BasePopoverWithFooterProps extends BasePopoverCommonProps {
  hideFooter?: false;
  closePopover: () => void;
  disableAutoClose?: boolean;
}

type BasePopoverProps = BasePopoverNoFooterProps | BasePopoverWithFooterProps;

const ForwardBasePopover = (
  props: BasePopoverProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    isOpen,
    setIsPopoverOpen,
    children,
    titleIcon,
    title,
    description,
    additionalContent,
    wrapperElement,
    onPointerDownOutside,
    showXButton = false,
  } = props;

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsPopoverOpen}>
      {children}
      {/* Mount component inside the dialog if one exists, otherwise on the body */}
      <Popover.Portal
        container={document.querySelector<HTMLElement>(".engineears-dialog")}
      >
        <PopoverContent
          ref={ref} // Forward the ref to the PopoverContent
          // Prevents the popover from closing if located inside a Select DropdownMenuItem
          onFocusOutside={(e: Event) => {
            e.preventDefault();
          }}
          onPointerDownOutside={(e) => {
            if (onPointerDownOutside) onPointerDownOutside(e);
          }}
          sideOffset={8}
          collisionPadding={8}
          collisionBoundary={wrapperElement}
        >
          {showXButton && (
            <SidePanelCloseButton onClick={() => setIsPopoverOpen(false)} />
          )}
          {title && (
            <PopoverHeader>
              {titleIcon}
              <Text weight={TEXT_WEIGHT.SEMI_BOLD}>{title}</Text>
            </PopoverHeader>
          )}
          <PopoverContentBody>
            <div>{description}</div>
            {additionalContent && <div>{additionalContent}</div>}
          </PopoverContentBody>
          {!props.hideFooter && (
            <PopoverFooter>
              <Button
                variant={ButtonVariant.OUTLINED}
                onClick={callAllEventHandlers(
                  props.onCancel,
                  props.closePopover,
                )}
                {...props.cancelButtonProps}
              >
                {props.cancelText || "Cancel"}
              </Button>
              <Button
                onClick={
                  props.disableAutoClose
                    ? props.onConfirm
                    : callAllEventHandlers(props.onConfirm, props.closePopover)
                }
                {...props.okButtonProps}
              >
                {props.okText || "Confirm"}
              </Button>
            </PopoverFooter>
          )}
        </PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

export const BasePopover = forwardRef(ForwardBasePopover);
