import { AccountDetailsFieldContainer } from "../AccountSettingsModal.styles";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Box } from "@mui/material";
import VerificationCodeInput from "../../Auth/VerificationCodeInput";
import { useCallback, useState } from "react";
import useVerifyPhoneMutation from "../../../../hooks/userHooks/useVerifyPhoneMutation";
import { toast } from "react-toastify";
import { useUpdateUserProfile } from "../../../../hooks/userHooks/useUpdateUserProfile";

interface AccountSettingsPhoneNumberVerificationFieldProps {
  phoneNumber: string;
  onVerified?: () => void;
  onCancel?: () => void;
  onResend?: () => void;
}

export const AccountSettingsPhoneNumberVerificationField = ({
  onCancel = () => {},
  onResend = () => {},
  onVerified = () => {},
  phoneNumber,
}: AccountSettingsPhoneNumberVerificationFieldProps) => {
  const [hasResentVerification, setHasResentVerification] = useState(false);
  const {
    mutateAsync: verifyPhone,
    isPending: isVerifyingPhone,
    isSuccess: didVerifyPhone,
  } = useVerifyPhoneMutation();
  const { isPending: isUpdateProfileLoading, mutateAsync: updateUserProfile } =
    useUpdateUserProfile();
  const handleComplete = useCallback(
    async (verification: string) => {
      return verifyPhone(verification).then(() => {
        toast.success("Verified phone number");
        onVerified();
      });
    },
    [verifyPhone, onVerified],
  );

  const handleResendCode = useCallback(async () => {
    return updateUserProfile({
      phone_number: phoneNumber,
    })
      .then(() => {
        toast.success("Verification code resent");
        onResend();
      })
      .finally(() => {
        setTimeout(() => {
          setHasResentVerification(false);
        }, 5000);
      });
  }, [updateUserProfile, phoneNumber, setHasResentVerification]);

  return (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Enter verification code
      </Text>

      <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
        <VerificationCodeInput
          disabled={isVerifyingPhone || didVerifyPhone}
          codeLength={6}
          onComplete={handleComplete}
        />
        <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Button
            variant={ButtonVariant.GHOST}
            onClick={handleResendCode}
            loading={isUpdateProfileLoading}
            disabled={isUpdateProfileLoading || hasResentVerification}
            style={{ padding: "0px" }}
          >
            Resend code
          </Button>
          <Button
            variant={ButtonVariant.GHOST}
            onClick={() => {
              onCancel();
            }}
            style={{ padding: "0px" }}
          >
            Update phone
          </Button>
        </Box>
      </Box>
    </AccountDetailsFieldContainer>
  );
};
