import { styled } from "@mui/material";

export const AddToCartButton = styled("button")<{
  $isTrackInCart: boolean;
  $releaseTrackBought?: boolean;
}>(({ theme, $isTrackInCart, $releaseTrackBought = false }) => ({
  opacity: $releaseTrackBought ? 0.9 : 1,
  borderRadius: "2px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "4px",
  padding: "8px",
  width: $isTrackInCart || $releaseTrackBought ? "84px" : "56px",
  marginHorizontal: "auto",
  backgroundColor: $releaseTrackBought
    ? theme.palette.standardColor.Green[100]
    : $isTrackInCart
      ? theme.palette.customColor.monochromeBackgroundColor
      : theme.palette.customColor.foregroundColor,
  border:
    $isTrackInCart || $releaseTrackBought
      ? "1px solid transparent"
      : `1px solid ${theme.palette.text.primary}`,
  transition: "background-color 0.2s, border-color 0.2s, width 0.1s linear",
}));
