import { LOGIN_GOOGLE } from "../../store/utils/routes";
import User from "../../store/models/user";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { promiseEngineEarsHandler } from "../helpers";

export interface LoginGoogleParams {
  authorization_code: string;
}

interface GoogleAuthResponse {
  user: User;
  token: string;
  created: boolean;
}

export const postLoginGoogle = async (params: LoginGoogleParams) => {
  return makeBackendPostCallWithJsonResponse<GoogleAuthResponse>(
    LOGIN_GOOGLE,
    params,
  ).then(promiseEngineEarsHandler);
};
