import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Text } from "../../../../core-ui/components/Text/Text";
import {
  Button,
  ButtonProps,
} from "../../../../core-ui/components/Button/Button";
import { OptionType } from "../../../../elements/DropDownSelector/DropdownSelector";
import { useMediaQueryBreakpoint } from "../../../../../hooks/useMediaQuery";

export enum GenerateBookingServiceGroups {
  NONE,
  RECORDING,
  MIX_OR_MASTER,
}

const serviceGeneratingOptions: OptionType[] = [
  { value: GenerateBookingServiceGroups.RECORDING, label: "Session booking" },
  {
    value: GenerateBookingServiceGroups.MIX_OR_MASTER,
    label: "Project booking",
  },
];

interface ServiceTypePopConfirmProps extends Omit<ButtonProps, "onClick"> {
  onClick: (serviceGenerated?: GenerateBookingServiceGroups) => void;
}

export const ServiceTypePopConfirm = ({
  variant,
  onClick,
  loading,
  ...restProps
}: ServiceTypePopConfirmProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobile } = useMediaQueryBreakpoint();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Button
        style={{ minWidth: "140px" }}
        variant={variant}
        loading={loading}
        {...restProps}
        onClick={handleClick}
        labelIcon={<FontAwesomeIcon icon={faCaretDown} />}
      >
        Generate Booking
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: isMobile ? "top" : "bottom",
          horizontal: isMobile ? "center" : "left",
        }}
        transformOrigin={
          isMobile
            ? {
                vertical: "bottom",
                horizontal: "center",
              }
            : undefined
        }
      >
        {serviceGeneratingOptions.map((option) => (
          <MenuItem
            sx={{ minWidth: "140px" }}
            key={option.value}
            onClick={() => {
              onClick(option.value);
            }}
          >
            <Text>{option.label}</Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
