// List of keys https://github.com/alibaba/hooks/blob/master/packages/hooks/src/useKeyPress/index.ts#L21
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import {
  faCommand,
  faD,
  faDiamond,
  faSlashForward,
} from "@fortawesome/pro-solid-svg-icons";
import { PLATFORM, POWER_USER_CONTROLS } from "./types";

// Link a control key to the font awesome icon that should be used in display around the site
export const PlatformOrKeyToIcon: Record<string, IconDefinition> = {
  [PLATFORM.WINDOWS]: faWindows,
  [PLATFORM.MAC]: faCommand,
  [PLATFORM.IOS]: faCommand,
  [POWER_USER_CONTROLS.META]: faDiamond,
  [POWER_USER_CONTROLS.DARK_MODE]: faD,
  [POWER_USER_CONTROLS.HELP]: faSlashForward,
};

export const PlatformToKey: Record<string, string> = {
  [PLATFORM.WINDOWS]: "Win",
  [PLATFORM.MAC]: "Cmd",
  [PLATFORM.IOS]: "Cmd",
};
