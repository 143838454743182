// getter/setter for the search term
import { atom } from "jotai";
import { ExpectedSearchParams, setSearchParamsHelper } from "./locationAtom";
import { AuthSteps } from "../../constants/authSteps";

// Write the current step to the URL
export const currentSignUpStepAtom = atom(
  AuthSteps.START_SIGNUP,
  (get, set, value: AuthSteps) => {
    const key: keyof ExpectedSearchParams = "step";
    setSearchParamsHelper(get, set, {
      key,
      value: String(value),
      clear: false,
    });
    set(currentSignUpStepAtom, value);
  },
);

currentSignUpStepAtom.debugPrivate = true;
