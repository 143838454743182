import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { createRecordingServiceParams } from "../../store/actions/recording";
import {
  generatingWorkingHours,
  workingHoursParam,
} from "../../store/actions/workingHours";
import {
  SelectAMPMOptions,
  SelectHourInDayOptions,
} from "../../store/models/alts";
import { RecordingService } from "../../store/models/recording";
import User from "../../store/models/user";
import { WEEKDAYS } from "../../store/models/workingHours";
import {
  convertDiscountRatesToDisplayableRates,
  convertMinutesToHourOptionType,
} from "../../store/utils/serviceUtils";
import { DiscountRateService } from "../../stories/components/AddRecordingService/AddRecordingService";
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { useCreateRecordingService } from "./useCreateRecordingService";
import { useCreateWorkingHours } from "./useCreateWorkingHours";

interface useRecordingServiceFormProps {
  existingService: RecordingService | undefined;
  pendingFormData: createRecordingServiceParams | undefined;
  isCreatingNewService: boolean;
  userData: User;
  weekdayTimes: Record<
    number,
    {
      start: OptionType<number>;
      end: OptionType<number>;
      startAMPM: OptionType<number>;
      endAMPM: OptionType<number>;
    }
  >;
}

export const useRecordingServiceForm = ({
  existingService,
  pendingFormData,
  isCreatingNewService,
  userData,
  weekdayTimes,
}: useRecordingServiceFormProps) => {
  const queryClient = useQueryClient();
  const [weeklyWorkDates, setWeeklyWorkDates] = useState<WEEKDAYS[]>([
    0, 1, 2, 3, 4, 5, 6,
  ]);
  const [startTime, setStartTime] = useState<OptionType>(
    SelectHourInDayOptions[0],
  );
  const [endTime, setEndTime] = useState<OptionType>(SelectHourInDayOptions[0]);
  const [startAMPM, setStartAMPM] = useState<OptionType>(SelectAMPMOptions[0]);
  const [endAMPM, setEndAMPM] = useState<OptionType>(SelectAMPMOptions[0]);
  const [recordingLocation, setRecordingLocation] = useState<
    undefined | google.maps.places.PlaceResult
  >(undefined);
  const [discountRates, setDiscountRate] = useState<DiscountRateService[]>(
    existingService
      ? convertDiscountRatesToDisplayableRates(existingService)
      : [],
  );

  const {
    isPending: isCreatingRecordingService,
    mutateAsync: createRecordingService,
  } = useCreateRecordingService();
  const { isPending: isCreatingWorkingHours, mutateAsync: createWorkingHours } =
    useCreateWorkingHours();
  const hasWorkingHoursBeenFetched =
    queryClient.getQueryState([QUERY_KEYS.GET_WORKING_HOURS_USER, userData.id])
      ?.status === "success";

  const handleGenerateWorkingHours = () => {
    return generatingWorkingHours(weekdayTimes, weeklyWorkDates);
  };

  const handleCreateWorkingHours = async (workingHours: workingHoursParam) => {
    if (!workingHours) return;

    try {
      await createWorkingHours({ working_hours: workingHours });
    } catch (error) {
      // The error has already been handled
    }
  };

  const handleCreateRecordingService = async () => {
    if (!pendingFormData) return;

    const pendingDiscountRates = discountRates.map(
      (discountRate) => discountRate.discount,
    );

    emitAnalyticsTrackingEvent(
      !isCreatingNewService
        ? "recording_service_updated"
        : "recording_service_created",
      {
        serviceType: "Recording",
        recordingLocation: recordingLocation?.formatted_address,
        minimumSessionTime: convertMinutesToHourOptionType(
          pendingFormData.minimum_session_time_minutes,
        ).value,
        maximumSessionTime: convertMinutesToHourOptionType(
          pendingFormData.maximum_session_time_minutes,
        ).value,
        minimumGapBetweenSessions:
          pendingFormData.minimum_gap_between_sessions_in_minutes,
        numberHoursNotice: pendingFormData.number_of_hours_notice,
        discountRates: pendingDiscountRates,
      },
    );

    const serviceFormData = {
      ...pendingFormData,
      recording_location: recordingLocation,
      discount_rates: pendingDiscountRates,
    };

    try {
      const service = await createRecordingService(serviceFormData);

      return service;
    } catch (error) {
      // The error has already been handled
    }
  };

  const isSubmitting = isCreatingRecordingService || isCreatingWorkingHours;

  return {
    weeklyWorkDates,
    setWeeklyWorkDates,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    startAMPM,
    setStartAMPM,
    endAMPM,
    setEndAMPM,
    recordingLocation,
    setRecordingLocation,
    discountRates,
    setDiscountRate,
    hasWorkingHoursBeenFetched,
    handleCreateWorkingHours,
    handleCreateRecordingService,
    handleGenerateWorkingHours,
    isSubmitting,
  };
};
