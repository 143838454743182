import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { fetchProfile } from "../../store/actions/users";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/selectors/userInfoSelectors";
import useInvalidateOnboardingProgress from "../onboardingHooks/useInvalidateOnboardingProgress";
import { getStudioScreenRoute } from "../../store/utils/routeGetters";

export const useGetUserProfile = (username?: string, enabled?: boolean) => {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();
  const navigate = useNavigate();

  const redirectToStudioScreen = () => {
    if (username) {
      navigate(getStudioScreenRoute(username));
    }
  };

  return useQuery({
    staleTime: user && user.username === username ? Infinity : undefined,
    queryKey: [QUERY_KEYS.GET_USER_PROFILE, username],
    queryFn: async () => {
      const result = await dispatch(
        fetchProfile({ username, redirectToStudioScreen }),
      ).unwrap();
      await invalidateOnboardingProgress();
      return result;
    },
    retry: 0,
    enabled: enabled,
  });
};
