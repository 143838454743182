import { useUsernameExistsQuery } from "../../../hooks/authHooks/useUsernameExistsQuery";
import { useDebounce } from "ahooks";
import { useMemo } from "react";
import { PASSWORDLESS_ACCOUNT_FOUND } from "../../../store/models/exceptions";
import { assertEngineEarsErrorType } from "../../../api/helpers";

export const useDebouncedUsernameCheck = (username: string) => {
  const debouncedUsername = useDebounce(username, { wait: 500 });
  const {
    data: usernameExists,
    isLoading: usernameExistsLoading,
    error,
  } = useUsernameExistsQuery(debouncedUsername);

  const usernameBelongsToPasswordlessAccount = useMemo(() => {
    if (!error) {
      return false;
    }
    if (assertEngineEarsErrorType(error)) {
      return error.code === PASSWORDLESS_ACCOUNT_FOUND;
    }
    return false;
  }, [error]);

  return {
    usernameExists,
    usernameExistsLoading,
    usernameBelongsToPasswordlessAccount,
  };
};
