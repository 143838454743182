import { useEffect } from "react";
import { PasswordFieldView } from "./types";

export const useResetSignInFormPasswordFieldView = (
  setCurrentView: (view: PasswordFieldView) => void,
  emailIsValid: boolean,
  accountPasswordless?: boolean,
  usernameExists?: boolean,
  usernameBelongsToPasswordlessAccount?: boolean,
) => {
  useEffect(() => {
    if (usernameExists && !emailIsValid) {
      setCurrentView(PasswordFieldView.passwordInputField);
      return;
    }
    if (usernameBelongsToPasswordlessAccount && !emailIsValid) {
      setCurrentView(PasswordFieldView.codeInputField);
      return;
    }
    if (!emailIsValid) {
      setCurrentView(PasswordFieldView.default);
      return;
    }
    if (accountPasswordless === undefined) return;
    if (accountPasswordless) {
      setCurrentView(PasswordFieldView.codeInputField);
    } else {
      setCurrentView(PasswordFieldView.passwordInputField);
    }
  }, [
    accountPasswordless,
    emailIsValid,
    setCurrentView,
    usernameBelongsToPasswordlessAccount,
    usernameExists,
  ]);
};
