import { ProjectType } from "../store/models/project";

export const STORAGE_URL = "https://storage.googleapis.com/engineears-static/";
export const VIDEO_STORAGE_URL = `${STORAGE_URL}videos/`;
export const DOCUMENT_STORAGE_URL = `${STORAGE_URL}docs/`;
export const IMAGES_STORAGE_URL = `${STORAGE_URL}images/`;
export const DEFAULT_USER_IMAGE_SRC = `${IMAGES_STORAGE_URL}defaultuser.jpg`;
export const DEFAULT_SERVICE_COVER_IMAGE_SRC = `${IMAGES_STORAGE_URL}default-service-cover.jpg`;
export const PROMO_ASSETS_STORAGE_URL = `${IMAGES_STORAGE_URL}promo-asset-samples/`;
const DEFAULT_RECORDING_ICON = `${IMAGES_STORAGE_URL}defaultServiceIcons/default-icon-recording.svg`;
const DEFAULT_MIXING_ICON = `${IMAGES_STORAGE_URL}defaultServiceIcons/default-icon-mixing.svg`;
const DEFAULT_MASTERING_ICON = `${IMAGES_STORAGE_URL}defaultServiceIcons/default-icon-mastering.svg`;
const DEFAULT_DOLBY_ICON = `${IMAGES_STORAGE_URL}defaultServiceIcons/default-icon-dolby.svg`;

export const getVideoUrl = (filename: string) => {
  return `${VIDEO_STORAGE_URL}${filename}`;
};

export const getDocUrl = (filename: string) => {
  return `${DOCUMENT_STORAGE_URL}${filename}`;
};

export const getImageUrl = (filename: string) => {
  return `${IMAGES_STORAGE_URL}${filename}`;
};

export const defaultIconServiceMapping = {
  [ProjectType.NO_TYPE]: "",
  [ProjectType.ATMOS_MIXING]: DEFAULT_DOLBY_ICON,
  [ProjectType.MASTERING]: DEFAULT_MASTERING_ICON,
  [ProjectType.MIXING]: DEFAULT_MIXING_ICON,
  [ProjectType.TWO_TRACK_MIXING]: DEFAULT_MIXING_ICON,
  [ProjectType.RECORDING]: DEFAULT_RECORDING_ICON,
};
