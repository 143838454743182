import { AmenityType } from "../../store/models/amenity";
import { Genre } from "../../store/models/genres";
import { ProjectType } from "../../store/models/project";
import { PromoCode } from "../../store/models/promoCode";
import { UNVERIFIED_ENG_PROMO_CODE } from "../../stories/components/EngineerPromoListModal/EngineerPromoListModal";
import { PrimaryFilterInputName } from "../../stories/components/SearchFilters/PrimaryFilterCard/PrimaryFilterCard";

export enum SearchView {
  ListView = "list-view",
  MapView = "map-view",
}

/**
 * Query parameter keys for the search filters
 * @example ?service_types=1,2&search_view=list-view
 */
export enum SearchScreenParamKeys {
  ServiceTypes = "service_types",
  SearchView = "search_view",
  SimpleBudgetSelected = "simple_budget_selected",
  MaxDistance = "max_distance",
  Duration = "duration",
  DaysAvailable = "days_available",
  Genres = "genres",
  Amenities = "amenities",
  AllowNoEngineer = "allow_no_engineer",
  UpAndComingEngineer = "up_and_coming_eng",
  MinRate = "min_rate",
  MaxRate = "max_rate",
  PromoCode = "promocode",
  PaymentDeposits = "payment_deposits",
  LatLng = "lat_lng",
  EquipmentId = "equipment_id",
  RecommendedEngineers = "recommended_engineers",
}

export const ParamKeysAffectedByRecommendedEngineers = [
  SearchScreenParamKeys.ServiceTypes,
  SearchScreenParamKeys.MinRate,
  SearchScreenParamKeys.MaxDistance,
  SearchScreenParamKeys.SimpleBudgetSelected,
  SearchScreenParamKeys.LatLng,
  SearchScreenParamKeys.Genres,
  SearchScreenParamKeys.UpAndComingEngineer,
  "term",
];

export interface SetSearchFiltersProps {
  newSimpleBudgetsSelected: string[];
  newMaxDistance: number;
  newDuration: number;
  newDaysAvailable: number[];
  newGenres: Genre[];
  newAmenities: AmenityType[];
  newAllowNoEngineer: boolean;
  newMinRate: number;
  newMaxRate: number;
  newLatLng?: {
    lat: number;
    lng: number;
  };
  newUpAndComingEngineer: boolean;
  newRecommendedEngineers?: boolean;
  newSelectedPrimaryFilters: PrimaryFilterInputName[];
}

export type SearchFiltersQueryParams = Partial<
  Record<SearchScreenParamKeys, string | number | boolean | undefined>
>;

export const applyBudgetfilter = (
  searchQueryObject: Partial<SearchFiltersQueryParams>,
  {
    newMaxRate,
    newMinRate,
    newSimpleBudgetsSelected,
  }: Pick<
    SetSearchFiltersProps,
    "newMaxRate" | "newMinRate" | "newSimpleBudgetsSelected"
  >,
) => {
  if ((newMaxRate || newMinRate) && newSimpleBudgetsSelected.length === 0) {
    searchQueryObject[SearchScreenParamKeys.MinRate] = newMinRate || undefined;
    searchQueryObject[SearchScreenParamKeys.MaxRate] = newMaxRate || undefined;
    searchQueryObject[SearchScreenParamKeys.SimpleBudgetSelected] = undefined;
  } else {
    searchQueryObject[SearchScreenParamKeys.SimpleBudgetSelected] =
      newSimpleBudgetsSelected.join(",");
    searchQueryObject[SearchScreenParamKeys.MinRate] = undefined;
    searchQueryObject[SearchScreenParamKeys.MaxRate] = undefined;
  }

  return searchQueryObject;
};

export const applyLocationFilter = (
  searchQueryObject: Partial<SearchFiltersQueryParams>,
  {
    newMaxDistance,
    newLatLng,
  }: Pick<SetSearchFiltersProps, "newMaxDistance" | "newLatLng">,
) => {
  if (newMaxDistance && newLatLng) {
    searchQueryObject[SearchScreenParamKeys.MaxDistance] = newMaxDistance;
    searchQueryObject[SearchScreenParamKeys.LatLng] =
      `${newLatLng.lat},${newLatLng.lng}`;
  } else {
    searchQueryObject[SearchScreenParamKeys.MaxDistance] = undefined;
    searchQueryObject[SearchScreenParamKeys.LatLng] = undefined;
  }

  return searchQueryObject;
};

export const shouldShowUpAndComingEngineersFilter = (
  studioSearchExclusive: boolean,
  selectedPrimaryFilters: PrimaryFilterInputName[],
  appliedPromoCode: PromoCode | undefined,
) => {
  return (
    !studioSearchExclusive &&
    !selectedPrimaryFilters.includes(PrimaryFilterInputName.RECORDING) &&
    appliedPromoCode?.code === UNVERIFIED_ENG_PROMO_CODE
  );
};

export const isLookingForRecordingEngineerOnly = (
  selectedServices: ProjectType[],
) => {
  return (
    selectedServices.length === 1 &&
    selectedServices[0] === ProjectType.RECORDING
  );
};
