import User from "../../../store/models/user";
import { VERIFY_PHONE } from "../../../store/utils/routes";
import { makeBackendPostCallWithJsonResponse } from "../../../store/utils/fetch";
import { promiseEngineEarsHandler } from "../../helpers";

export interface VerifyPhoneParams {
  verification: string;
}

export const postVerifyPhone = async (params: VerifyPhoneParams) => {
  return makeBackendPostCallWithJsonResponse<User>(VERIFY_PHONE, params).then(
    promiseEngineEarsHandler,
  );
};
