import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  FooterFileTrackType,
  setIndex,
  setSeekTo,
} from "../../store/actions/abPlayerStore";
import { useAppSelector } from "../../store/hooks";
import { PlaylistTrack } from "../../store/models/playListTrack/types";
import { usePlayPauseOnFooter, useSetPlayList } from "./useGetFooterPlayerRef";
import { getCurrentTrackFromPlaylist } from "../../store/selectors/abPlayerSelectors";

export const useReleaseTrackPlayButtonPressed = (
  releaseId: number,
  isPending: boolean,
  tracks: PlaylistTrack[],
  trackId: number,
) => {
  const { handleClick } = usePlayPauseOnFooter();
  const dispatch = useDispatch();
  const setReleasePlaylist = useSetPlayList();
  const { playlistId, playlist } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const currentTrack = useAppSelector(getCurrentTrackFromPlaylist());
  tracks = tracks.filter((track) => !track.paymentRequiredForPlayback);
  return useCallback(() => {
    const trackIndex = tracks.findIndex((track) => track.id === trackId);
    if (isPending) return;
    if (
      releaseId !== playlistId ||
      (releaseId === playlistId && tracks.length !== playlist.length)
    ) {
      setReleasePlaylist({
        tracks: tracks,
        index: trackIndex < 0 ? 0 : trackIndex,
        playOnLoad: true,
        playlistId: releaseId,
        footerFileTrackType: FooterFileTrackType.RELEASE,
      });
      return;
    }
    if (currentTrack?.id !== trackId) {
      dispatch(setSeekTo(0));
      dispatch(setIndex(trackIndex));
    }
    void handleClick();
  }, [
    tracks,
    isPending,
    releaseId,
    playlistId,
    currentTrack?.id,
    trackId,
    handleClick,
    setReleasePlaylist,
    dispatch,
    playlist,
  ]);
};
