import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PayPalScriptOptions } from "@paypal/paypal-js/types/script-options";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "stream-chat-react/dist/css/index.css";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import "./App.css";
import { consentAtom } from "./atoms/consentAtom";
import isGoogleMapsLoadedAtom from "./atoms/maps/isGoogleMapsLoadedAtom";
import { darkModeAtom } from "./atoms/user/darkModeAtom";

import {
  getPaypalClientId,
  GOOGLE_API,
  GOOGLE_OAUTH_CLIENT_ID,
} from "./store/utils";
import AppState from "./stories/components/AppState/AppState";
import { PopoverContainerContextProvider } from "./stories/core-ui/components/BasePopover/PopoverContainerContext";
import { ThemeDiv } from "./stories/screens/ThemeDiv/ThemeDiv";
import { darkTheme, GlobalStyles, lightTheme } from "./stories/theme";
import { MuiThemeProvider } from "./styles/muiTheme/MuiTheme";
import "./styles/stream-chat-react.css";

const paypalScriptOptions: PayPalScriptOptions = {
  "client-id": getPaypalClientId,
};

const queryClient = new QueryClient();

const LIBRARIES: Libraries = ["places"];

interface APILoader {
  googleMapsApiKey: string;
  preventGoogleFontsLoading: boolean;
  libraries: Libraries;
}

const apiLoader: APILoader = {
  googleMapsApiKey: GOOGLE_API,
  preventGoogleFontsLoading: false,
  libraries: LIBRARIES,
};

const App = () => {
  const darkMode = useAtomValue(darkModeAtom);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useAtom(
    isGoogleMapsLoadedAtom,
  );

  const { isLoaded, loadError } = useJsApiLoader(apiLoader);
  const hasConsent = useAtomValue(consentAtom);

  useEffect(() => {
    if (isLoaded && !isGoogleMapsLoaded) {
      setIsGoogleMapsLoaded(true);
    }
  }, [isLoaded, isGoogleMapsLoaded, setIsGoogleMapsLoaded]);

  useEffect(() => {
    if (loadError) {
      datadogLogs.logger.error("Error loading Google Maps API", loadError);
    }
  }, [loadError]);

  useEffect(() => {
    if (hasConsent) {
      datadogLogs.setTrackingConsent("granted");
      datadogRum.setTrackingConsent("granted");
      window.grantFBTracking();
      window.grantGTagTrackingA();
      window.grantGTagTrackingB();
    } else {
      datadogLogs.setTrackingConsent("not-granted");
      datadogRum.setTrackingConsent("not-granted");
      window.revokeFBTracking();
      window.revokeGTagTrackingA();
      window.revokeGTagTrackingB();
    }
  }, [hasConsent]);

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider darkMode={Boolean(darkMode)}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledComponentThemeProvider
            theme={darkMode ? darkTheme : lightTheme}
          >
            <GlobalStyles theme={darkMode ? darkTheme : lightTheme} />
            <ThemeDiv darkMode={darkMode ? "dark" : ""}>
              <PopoverContainerContextProvider>
                <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                  <PayPalScriptProvider options={paypalScriptOptions}>
                    <ToastContainer
                      position="top-center"
                      autoClose={5000}
                      closeOnClick
                      hideProgressBar={false}
                      pauseOnHover
                    />
                    <AppState />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </PayPalScriptProvider>
                </GoogleOAuthProvider>
              </PopoverContainerContextProvider>
            </ThemeDiv>
          </StyledComponentThemeProvider>
        </LocalizationProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
