import { useSetAtom } from "jotai/index";
import { toggleDarkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useCallback } from "react";
import { COMMANDS, POWER_USER_CONTROLS_LABEL } from "../types";
import { usePowerUserControl } from "../usePowerUserControl";

const LABEL = POWER_USER_CONTROLS_LABEL.DARK_MODE;

/**
 * Sets up the power user control that toggles dark mode.
 *
 * When the user presses the shortcut, the dark mode is toggled.
 *
 * @returns The shortcuts data, which is an object with two properties:
 * - `keys`: an array of strings representing the keys that trigger the shortcut
 * - `label`: a string that describes the shortcut
 */
const useDarkModeControls = () => {
  const toggleDarkMode = useSetAtom(toggleDarkModeAtom);
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      toggleDarkMode();
    },
    [toggleDarkMode],
  );
  return usePowerUserControl(LABEL, COMMANDS[LABEL], handleKeyPress);
};

export default useDarkModeControls;
