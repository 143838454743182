import { ListSubheader, MenuItem, Stack, useTheme } from "@mui/material";
import { faVideo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";
import { hardcodedDomain } from "../../../store/utils";
import { Text } from "../../core-ui/components/Text/Text";
import Link from "../../elements/Link/Link";
import { DropdownSectionInterface } from "./utils";
import { YouTubeVideoModal } from "./YouTubeVideoModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

interface DropdownNavSectionProps extends DropdownSectionInterface {
  onClick?: () => void;
}

export const DropdownNavSection = ({
  links,
  label,
  icon,
  video,
  onClick,
}: DropdownNavSectionProps) => {
  const theme = useTheme();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  return (
    <Stack flex={1} justifyContent="space-between" p={1}>
      <Stack direction="column" gap={1}>
        <ListSubheader
          component={Link}
          sx={{
            backgroundColor: "inherit",
            textDecoration: "underline",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            color: "inherit",
            ...(video?.pageLink.disabled && {
              opacity: 0.5,
              cursor: "not-allowed",
              color: theme.palette.text.disabled,
            }),
          }}
          analytics={{
            label: TRACKING_EVENTS_NAME.USER_CLICK_NAV_MENU_ITEM,
            data: {
              label: video?.pageLink.label,
              href: video?.pageLink.href,
              component: "section_header_link",
            },
          }}
          to={hardcodedDomain + video?.pageLink.href}
        >
          {icon && <img src={icon} alt={label} />}
          <Text bold>{label}</Text>
        </ListSubheader>
        {links.map((link) => (
          <MenuItem
            key={link.label}
            sx={{
              height: "40px",
              ...(link.disabled && {
                opacity: 0.5,
                cursor: "not-allowed",
                color: theme.palette.text.disabled,
              }),
              textDecoration: "none",
            }}
            onClick={onClick}
            component={Link}
            disabled={link.disabled}
            analytics={{
              label: TRACKING_EVENTS_NAME.USER_CLICK_NAV_MENU_ITEM,
              data: {
                label: link.label,
                href: link.href,
                component: "section_link",
              },
            }}
            to={hardcodedDomain + link.href}
          >
            <Text>{link.label}</Text>
          </MenuItem>
        ))}
      </Stack>
      {video && (
        <Stack
          justifyContent="space-between"
          p={1}
          borderTop="1px solid #E0E0E0"
        >
          <Stack direction="column" gap={1}>
            <>
              <Link
                analytics={{
                  label: TRACKING_EVENTS_NAME.USER_CLICK_NAV_MENU_ITEM,
                  data: {
                    label: video.pageLink.label,
                    href: video.pageLink.href,
                    component: "video_section_link",
                  },
                }}
                sx={{
                  textDecoration: "underline",
                  color: "inherit",
                  ...(video.pageLink.disabled && {
                    opacity: 0.5,
                    cursor: "not-allowed",
                    color: theme.palette.text.disabled,
                  }),
                }}
                to={hardcodedDomain + video.pageLink.href}
              >
                <Text bold>{video.pageLink.label}</Text>
              </Link>
              <Text>{video.description}</Text>
            </>
            <Button
              disabled={video.link.disabled}
              variant={ButtonVariant.UNSTYLED}
              style={{
                cursor: video.link.disabled ? "not-allowed" : "pointer",
                color: video.link.disabled
                  ? theme.palette.text.disabled
                  : theme.palette.customColor.homepageBrownishOrange,
                marginRight: "auto",
              }}
              endIcon={<FontAwesomeIcon icon={faVideo} />}
              onClick={(e) => {
                e.preventDefault();
                emitAnalyticsTrackingEvent(
                  TRACKING_EVENTS_NAME.USER_CLICK_NAV_MENU_ITEM_VIDEO_LINK,
                  {
                    label: video.link.label,
                    href: video.link.href,
                    component: "video_link",
                  },
                );
                setIsVideoModalOpen(true);
              }}
            >
              {video.link.label}
            </Button>
          </Stack>
        </Stack>
      )}
      {video && !video.link.disabled && (
        <YouTubeVideoModal
          open={isVideoModalOpen}
          onClose={() => setIsVideoModalOpen(false)}
          videoUrl={video.link.href}
        />
      )}
    </Stack>
  );
};
