import { CHECK_IF_USERNAME_EXISTS } from "../../store/utils/routes";

import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import queryString from "query-string";

interface UsernameExistsResponse {
  username_exists: boolean;
}

export const fetchUsernameExists = async (username: string) => {
  const params = queryString.stringify(
    { username: username.toLowerCase().trim() },
    {
      skipEmptyString: true,
      skipNull: true,
    },
  );
  return makeBackendGetCallWithJsonResponse<UsernameExistsResponse>(
    CHECK_IF_USERNAME_EXISTS,
    `?${params}`,
  );
};
