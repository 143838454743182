import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { uploadFilePromise } from "../../store/utils/fetch";
import { IDLE_PROGRESS } from "../../stories/screens/ProfileScreen/constants";
import { useGetServiceFeaturedVideoSignedURL } from "./useGetServiceFeaturedVideoSignedURL";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";

// Max video file size allowed for upload, 100MB.
export const MAX_VIDEO_UPLOAD_SIZE = 100 * 1024 * 1024;

export const useOnUploadServiceVideo = () => {
  const [uploadingVideo, setUploadingVideo] = useState<File>();
  const [progress, setProgress] = useState(IDLE_PROGRESS);
  const { mutateAsync: getServiceFeaturedVideoSignedURL } =
    useGetServiceFeaturedVideoSignedURL();
  const xhr = useMemo(() => {
    return new XMLHttpRequest();
  }, []);

  const uploadServiceVideo = async (acceptedFile: File) => {
    try {
      setProgress(0);

      const { signed_url, entity_video } =
        await getServiceFeaturedVideoSignedURL({
          file_name: acceptedFile.name,
          content_type: acceptedFile.type,
          file_size: acceptedFile.size,
        });

      await uploadFilePromise(
        acceptedFile,
        signed_url,
        (progress) => setProgress(progress),
        xhr,
      );

      setProgress(IDLE_PROGRESS);

      return entity_video.id;
    } catch (e) {
      setProgress(IDLE_PROGRESS);
      if (assertEngineEarsErrorType(e)) {
        displayEngineEarsError(e);
        return;
      }
      toast.error(
        "Something went wrong with your upload. Reach out for support.",
      );
    }
  };

  const cancelRequest = () => {
    xhr.abort();
  };

  return {
    uploadServiceVideo,
    progress,
    uploadingVideo,
    setUploadingVideo,
    cancelRequest,
  };
};
