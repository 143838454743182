import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { activeUserAtom } from "../../atoms/user/activeUserAtom";
import { useAtomValue, useSetAtom } from "jotai";
import { fetchActiveStudios } from "../../api/recording/studio/fetchActiveStudios";
import { activeUserStudiosAtom } from "../../atoms/user/activeUserStudiosAtom";

export interface UseActiveStudiosQueryParams {
  userId?: number;
}

const useActiveStudiosQuery = ({ userId }: UseActiveStudiosQueryParams) => {
  const activeUser = useAtomValue(activeUserAtom);
  const setActiveUserStudios = useSetAtom(activeUserStudiosAtom);
  const userIdToFetch = userId || activeUser?.id;
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [QUERY_KEYS.LOAD_STUDIOS, userIdToFetch],
    queryFn: async () => {
      return fetchActiveStudios({ user_id: userId })
        .then((activeStudios) => {
          activeStudios.forEach((studio) => {
            // Invalidate the cached profile we have for the studio if something changes
            void queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_STUDIO, studio.username],
            });
          });
          if (!userId) {
            setActiveUserStudios(activeStudios);
          }
          return activeStudios;
        })
        .catch(() => {
          if (!userId) {
            setActiveUserStudios([]);
          }
        });
    },
    retry: false,
    enabled: Boolean(userId) || Boolean(activeUser?.is_studio_manager),
  });
};

export default useActiveStudiosQuery;
