import { ReleaseTrack } from "../../release";
import { PlaylistTrack } from "../types";
import convertReleaseTrackToPlaylistTrack from "./convertReleaseTrackToPlaylistTrack";

const convertArtistReleaseToPlaylistTrack = (
  releaseTracks: ReleaseTrack[],
  currentUserId?: number,
): PlaylistTrack[] => {
  return releaseTracks.map((releaseTrack) =>
    convertReleaseTrackToPlaylistTrack(releaseTrack, currentUserId),
  );
};

export default convertArtistReleaseToPlaylistTrack;
