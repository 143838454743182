import { ProjectType } from "../project";

export enum PlaylistTrackType {
  Project,
  ProjectById,
  PortfolioFeatureData,
  ArtistRelease,
  Muso,
  PortfolioProjectTrackResponse,
}

export interface PlaylistTrack {
  id: number;
  fileVersionId?: number;
  type: PlaylistTrackType;
  title: string;
  artistDisplayName: string;
  artistUsername: string;
  engineerUsername: string;
  artworkSource?: string;
  serviceType?: ProjectType;
  engineerDisplayName?: string;
  spotify_preview_url?: string;
  paymentRequiredForPlayback?: boolean;
}
