import { atom, useSetAtom } from "jotai";
import { useUpdateSearchFilters } from "./useUpdateSearchFilters";
import { SearchFiltersQueryParams, SearchScreenParamKeys } from "./utils";

export const equipmentLabelFilterAtom = atom("");

export const useEquipmentFilterQuery = () => {
  const updateSearchFilters = useUpdateSearchFilters();

  const setEquipmentFilter = (equipmentId?: number, newPathname?: string) => {
    const updatedSearchQueryObject = {} as SearchFiltersQueryParams;

    updatedSearchQueryObject[SearchScreenParamKeys.EquipmentId] = equipmentId;

    updateSearchFilters({
      updatedSearchQueryObject,
      newPathname,
      resetPagination: true,
    });
  };

  return { setEquipmentFilter };
};
