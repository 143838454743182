import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import * as Popover from "@radix-ui/react-popover";
import { MouseEventHandler, ReactNode } from "react";
import useModal from "../../../../hooks/useModal";
import { BasePopover } from "../BasePopover/BasePopover";
import { ButtonProps } from "../Button/Button";
import { StyledTitleIcon } from "./PopConfirm.styles";

type ButtonEventHandler = MouseEventHandler<HTMLButtonElement>;

export interface PopConfirmProps {
  children: ReactNode;
  titleIcon?: ReactNode;
  title?: string;
  description?: ReactNode;
  okText?: string;
  okButtonProps?: ButtonProps;
  onConfirm: ButtonEventHandler;
  cancelText?: string;
  cancelButtonProps?: ButtonProps;
  onCancel?: ButtonEventHandler;
  wrapperElement?: HTMLElement | null;
  additionalContent?: ReactNode;
}

export const PopConfirm = ({
  children,
  titleIcon = <StyledTitleIcon icon={faCircleExclamation} />,
  title,
  description,
  okText = "Confirm",
  okButtonProps,
  onConfirm,
  cancelText = "Cancel",
  cancelButtonProps,
  onCancel,
  wrapperElement,
  additionalContent,
}: PopConfirmProps) => {
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();

  return (
    <BasePopover
      additionalContent={additionalContent}
      isOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      closePopover={closePopover}
      title={title}
      description={description}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText={okText}
      okButtonProps={okButtonProps}
      cancelText={cancelText}
      cancelButtonProps={cancelButtonProps}
      titleIcon={titleIcon}
      wrapperElement={wrapperElement}
    >
      <Popover.Trigger asChild>{children}</Popover.Trigger>
    </BasePopover>
  );
};
