import { UniqueIdentifier } from "@dnd-kit/core";
import { Box, Theme } from "@mui/material";
import { useAtomValue } from "jotai";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { editMode } from "../../../../../atoms/profileScreenEdit";
import { useGetAutoCompleteEquipment } from "../../../../../hooks/profileScreenHooks/useGetAutoCompleteEquipment";
import { useGetUserEquipment } from "../../../../../hooks/profileScreenHooks/useGetEquipment";
import { useGetUserProfile } from "../../../../../hooks/profileScreenHooks/useGetUserProfile";
import {
  AssociationType,
  useDeleteEquipment,
  usePostEquipment,
} from "../../../../../hooks/profileScreenHooks/useMutateEquipment";
import {
  EquipmentTypeEnum,
  EquipmentTypeReadableNameMap,
  Equipment,
} from "../../../../../store/models/equipment";
import {
  ISingleItem,
  ChipsList,
} from "../../../../core-ui/components/ChipsList/ChipsList";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import { useDebounce } from "ahooks";
import { TabsV2 } from "../../../../core-ui/components/TabsV2/TabsV2";

interface EquipmentTypeListProps {
  equipmentType: EquipmentTypeEnum;
  equipment: Equipment[];
  isEditMode: boolean;
  isLoadingUserEquipment: boolean;
  studioOrStudioRoomId?: number;
  associationType: AssociationType;
}

const EquipmentTypeList = ({
  equipmentType,
  equipment,
  isEditMode,
  isLoadingUserEquipment,
  studioOrStudioRoomId,
  associationType,
}: EquipmentTypeListProps) => {
  const equipmentTypeName = EquipmentTypeReadableNameMap[equipmentType];
  const { mutateAsync: postEquipment, isPending: isPosting } =
    usePostEquipment();
  const { mutateAsync: deleteEquipment, isPending: isDeleting } =
    useDeleteEquipment();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, { wait: 500 });
  const { data: queryResults } = useGetAutoCompleteEquipment({
    equipmentType: equipmentType,
    query: debouncedQuery,
  });
  const queryResultsItems = useMemo(() => {
    if (!queryResults) {
      return [];
    }
    return queryResults.map((eq) => ({
      value: eq.id,
      label: eq.label,
    }));
  }, [queryResults]);

  const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      await postEquipment({
        label: query,
        equipment_type: equipmentType,
        association_type: associationType,
        studio_room_id: studioOrStudioRoomId,
      });
    }
  };

  const selectedEquipment: ISingleItem[] = useMemo(() => {
    if (!equipment) {
      return [];
    }
    return equipment.map((eq) => ({
      value: eq.id,
      label: eq.label,
    }));
  }, [equipment]);

  if (!isEditMode && !selectedEquipment.length) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {associationType == AssociationType.USER && (
        <Text bold variant={TextStyleVariant.P2}>
          {equipmentTypeName}
        </Text>
      )}
      <ChipsList
        dataSource={selectedEquipment}
        chipContainerSx={(theme: Theme) => ({
          [theme.breakpoints.up("lg")]: {
            gap: "16px",
            [theme.breakpoints.down("md")]: {
              gap: "8px",
            },
          },
        })}
        editMode={isEditMode}
        optionsToAdd={queryResultsItems}
        sx={{ gap: "16px" }}
        onAddItem={async (_value: UniqueIdentifier, label) => {
          if (!label) {
            return;
          }
          await postEquipment({
            label: label,
            equipment_type: equipmentType,
            association_type: associationType,
            studio_room_id: studioOrStudioRoomId,
          });
        }}
        onDeleteItem={async (item: UniqueIdentifier) =>
          await deleteEquipment({
            equipment_id: Number(item),
            deleted: true,
            association_type: associationType,
            studio_room_id: studioOrStudioRoomId,
          })
        }
        loading={isPosting || isDeleting || isLoadingUserEquipment}
        autoCompletePropsSx={{
          sx: {
            maxWidth: "450px",
          },
          onInputChange: (
            _: React.SyntheticEvent<Element, Event>,
            value: string,
          ) => {
            setQuery(value);
          },
          onKeyDown,
        }}
      />
    </Box>
  );
};

export const EquipmentSection = () => {
  const isEditMode = useAtomValue(editMode);
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const { data: userEquipment, isPending: isLoadingUserEquipment } =
    useGetUserEquipment(userData?.id, AssociationType.USER);

  if (!userEquipment) {
    return null;
  }

  return (
    <Box
      id="Equipment container"
      sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
    >
      <Text variant={TextStyleVariant.S2}> Equipment list</Text>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        {Array.from(userEquipment.entries()).map(
          ([equipmentType, equipment]) => (
            <EquipmentTypeList
              key={equipmentType}
              equipmentType={equipmentType}
              equipment={equipment}
              isEditMode={isEditMode}
              isLoadingUserEquipment={isLoadingUserEquipment}
              associationType={AssociationType.USER}
            />
          ),
        )}
      </Box>
    </Box>
  );
};

export const TabbedEquipmentSection = ({
  studioOrStudioRoomId,
  canEditStudio,
  associationType,
}: {
  studioOrStudioRoomId?: number;
  canEditStudio?: boolean;
  associationType: AssociationType;
}) => {
  const isEditModeAtomValue = useAtomValue(editMode);
  const isEditMode =
    associationType === AssociationType.USER
      ? isEditModeAtomValue
      : Boolean(canEditStudio);
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(
    username,
    associationType === AssociationType.USER,
  );
  const { data: userEquipment, isPending: isLoadingUserEquipment } =
    useGetUserEquipment(
      associationType === AssociationType.USER
        ? userData?.id
        : studioOrStudioRoomId,
      associationType,
    );
  const [selectedTab, setSelectedTab] = useState(0);

  if (!userEquipment) return null;

  const tabbedEquipment = Array.from(userEquipment.entries()).reduce(
    (
      tabObj: { [key: number]: React.ReactNode },
      [equipmentType, equipment],
    ) => {
      tabObj[equipmentType] = (
        <EquipmentTypeList
          key={equipmentType}
          equipmentType={equipmentType}
          equipment={equipment}
          isEditMode={isEditMode}
          isLoadingUserEquipment={isLoadingUserEquipment}
          studioOrStudioRoomId={studioOrStudioRoomId}
          associationType={associationType}
        />
      );
      return tabObj;
    },
    {},
  );

  const { [EquipmentTypeEnum.NONE]: _, ...equipmentTypeMapWithoutNone } =
    EquipmentTypeReadableNameMap;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Text variant={TextStyleVariant.S2}> Equipment list</Text>
      <TabsV2
        tabs={equipmentTypeMapWithoutNone}
        onChange={(_, value) => {
          setSelectedTab(value);
        }}
        selectedTab={selectedTab}
      />
      <Box>{tabbedEquipment[selectedTab + 1]}</Box>
    </Box>
  );
};
