import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TRACKING_EVENTS_NAME } from "../constants/trackingEvents";
import { SetUpCartPayload } from "../store/actions/shoppingCart";
import { createTransaction } from "../store/actions/transactions";
import { useAppDispatch } from "../store/hooks";
import Engineer from "../store/models/engineer";
import { ProjectType } from "../store/models/project";
import User, { LocalUTMParams } from "../store/models/user";
import { getTransactionBookingScreenRoute } from "../store/utils/routeGetters";
import { BookingParameters } from "../store/utils/transactions";
import { emitAnalyticsTrackingEvent } from "../utils/analyticsUtils";

export const useHandleCreateTransaction = (
  localUTMParams: LocalUTMParams,
  userData?: User,
  engineer?: Engineer,
) => {
  const [isCreatingTransaction, setIsCreatingTransaction] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const createTransactionAndNavigate = useCallback(
    async (selectedRecording?: boolean) => {
      try {
        setIsCreatingTransaction(true);
        const transaction = await dispatch(createTransaction()).unwrap();
        const bookingParameters: BookingParameters = {
          engineerId: engineer?.id,
          engineerUserId: userData?.id,
          transactionId: +transaction.id, // transaction.id is a number, not sure why it's typed as string
          activeServiceTypeProjectIds: [],
        };

        if (selectedRecording) {
          bookingParameters.activeServiceType = ProjectType.RECORDING;
        }

        setIsCreatingTransaction(false);
        emitAnalyticsTrackingEvent(
          TRACKING_EVENTS_NAME.USER_GENERATING_TRANSACTION,
          {
            ...localUTMParams,
            transaction_id: bookingParameters.transactionId,
            engineer_user_id: bookingParameters.engineerUserId,
            service_type: bookingParameters.activeServiceType,
          },
        );

        navigate(
          getTransactionBookingScreenRoute(
            transaction.code,
            dispatch,
            bookingParameters,
            selectedRecording
              ? ({
                  pendingSessionData: [],
                } as SetUpCartPayload)
              : undefined,
          ),
        );
      } catch (error) {
        setIsCreatingTransaction(false);
      }
    },
    [dispatch, engineer, userData, localUTMParams, navigate],
  );

  return {
    isCreatingTransaction,
    createTransactionAndNavigate,
  };
};
