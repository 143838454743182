// getter/setter for the search term
import { atom } from "jotai";
import { searchParamsAtom } from "./locationAtom";
import { AuthFlows } from "../../constants/authSteps";

// Read current flow from the URL
export const readCurrentFlowFromUrl = atom((get) => {
  const flowValue = get(searchParamsAtom).flow;
  return flowValue ? (parseInt(flowValue) as AuthFlows) : AuthFlows.NONE;
});

readCurrentFlowFromUrl.debugLabel = "Current Flow From URL";
