import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, SxProps, useTheme } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { Link } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes/screens";
import { hardcodedDomain } from "../../../store/utils";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { Text } from "../../core-ui/components/Text/Text";

export interface MenuItemAnalytics {
  label: string;
  data?: Record<string, unknown>;
}

export interface MenuItemLink {
  href: string;
  sx?: SxProps;
}

export interface MenuItem {
  label: string;
  disabled?: boolean;
  analytics?: MenuItemAnalytics;
  link?: MenuItemLink;
  onClick?: () => void;
}

export interface LearnDropdownProps {
  buttonText: string;
  menuItems: MenuItem[];
  buttonVariant?: ButtonVariant;
}

export default function Dropdown({
  buttonText,
  menuItems,
  buttonVariant = ButtonVariant.UNSTYLED,
}: LearnDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isMobile } = useMediaQueryBreakpoint();
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item: MenuItem) => {
    if (item.onClick) {
      item.onClick();
    }
    handleClose();
  };

  return (
    <div>
      <Button variant={buttonVariant} disableElevation onClick={handleClick}>
        <Stack direction="row" alignItems="center">
          <Text bold>{buttonText}</Text>
          <FontAwesomeIcon
            size="2xs"
            icon={open ? faChevronUp : faChevronDown}
          />
        </Stack>
      </Button>
      <Menu
        id="learn-dropdown-menu"
        MenuListProps={{
          "aria-labelledby": "learn-dropdown-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={`${item.label}}`}
            onClick={() => handleItemClick(item)}
            disableRipple
            sx={
              isMobile
                ? {
                    paddingLeft: theme.spacing(4),
                  }
                : undefined
            }
          >
            {item.link ? (
              <Link
                to={
                  item.link.href === SCREENS.DOLBY_ATMOS ||
                  item.link.href === SCREENS.PRO_TOOLS
                    ? `${hardcodedDomain}${item.link.href}`
                    : item.link.href
                }
                style={{ textDecoration: "none", color: "inherit" }}
                {...(item.analytics && {
                  analytics: {
                    label: item.analytics.label,
                    data: item.analytics.data,
                  },
                })}
                {...(item.link.sx && { sx: item.link.sx })}
              >
                {item.label}
              </Link>
            ) : (
              item.label
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
