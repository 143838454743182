import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { useFetchReleaseTracksQuery } from "../../../api/releases/hooks/useFetchReleaseTracksQuery";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useDownloadTrackFromFileVersionId } from "../../../hooks/audioPlayerHooks/fetchTrack";
import {
  useCurrentProgressForPlaylistId,
  usePlayPauseOnFooter,
  useSetPlayList,
} from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import convertArtistReleaseToPlaylistTrack from "../../../store/models/playListTrack/helpers/convertArtistReleaseToPlaylistTrack";
import { FeaturedTrackPlayer } from "../FeaturedTrackPlayer/FeaturedTrackPlayer";
import { PlayAudioButton } from "../PlayAudioButton/PlayAudioButton";

import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReleaseTrackPlayButtonPressed } from "../../../hooks/audioPlayerHooks/useReleaseTrackPlayPressed";
import {
  FooterFileTrackType,
  updatePlayListTrackFileVersion,
} from "../../../store/actions/abPlayerStore";
import { PlayButton } from "../ProjectOverviewTable/NewProjectsTable.styles";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";
import { getCurrentTrackFromPlaylist } from "../../../store/selectors/abPlayerSelectors";

interface ReleasePlayPauseButtonProps {
  releaseId: number;
  size?: number;
}

export const getPaddingForPlayPauseButton = (size = 0) => {
  // Magic but gets consistently good progress padding.
  return size > 6 ? size / 6 : 1;
};

export const ReleasePlayPauseButton = ({
  releaseId,
  size = 24,
}: ReleasePlayPauseButtonProps) => {
  const { data, isPending } = useFetchReleaseTracksQuery(releaseId);
  const { isFooterPlaying, playlistId } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const activeUser = useAtomValue(activeUserAtom);
  const playableTracks = convertArtistReleaseToPlaylistTrack(
    data,
    activeUser?.id,
  ).filter((track) => !track.paymentRequiredForPlayback);
  const progress = useCurrentProgressForPlaylistId(releaseId);
  const { handleClick } = usePlayPauseOnFooter();
  const padding = getPaddingForPlayPauseButton(size);
  const setReleasePlaylist = useSetPlayList();

  return (
    <PlayAudioButton
      paymentRequiredForPlayback={playableTracks.length === 0}
      progress={progress}
      isPlaying={isFooterPlaying && playlistId === releaseId}
      handleClick={() => {
        if (isPending) {
          return;
        }
        if (releaseId !== playlistId) {
          setReleasePlaylist({
            tracks: playableTracks,
            index: 0,
            playOnLoad: true,
            playlistId: releaseId,
            footerFileTrackType: FooterFileTrackType.RELEASE,
          });
        } else {
          handleClick();
        }
      }}
      size={size}
      padding={padding}
    />
  );
};

interface ReleaseTrackPlayPauseButtonProps {
  releaseId: number;
  trackId: number;
  size?: number;
  invertColor?: boolean;
}

export const ReleaseTrackPlayPauseButton = ({
  releaseId,
  trackId,
  size = 24,
}: ReleaseTrackPlayPauseButtonProps) => {
  const darkMode = useAtomValue(darkModeAtom);
  const { isFooterPlaying, playlistId } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const { data: releaseTracks, isPending } =
    useFetchReleaseTracksQuery(releaseId);
  const releaseTrackIndex = releaseTracks?.findIndex(
    (releaseTrack) => releaseTrack.id === trackId,
  );

  const currentTrack = useAppSelector(getCurrentTrackFromPlaylist());
  const isPlaying =
    isFooterPlaying && trackId === currentTrack?.id && releaseId == playlistId;

  const activeUser = useAtomValue(activeUserAtom);
  const tracks = convertArtistReleaseToPlaylistTrack(
    releaseTracks,
    activeUser?.id,
  );
  const track = tracks[releaseTrackIndex];
  const dispatch = useAppDispatch();
  const fileVersionId = useMemo(() => {
    return track?.fileVersionId;
  }, [track]);

  useEffect(() => {
    dispatch(updatePlayListTrackFileVersion({ trackId, fileVersionId }));
  }, [trackId, fileVersionId, dispatch]);

  const { data: url, isFetchedAfterMount } =
    useDownloadTrackFromFileVersionId(fileVersionId);

  const { isMobile } = useMediaQueryBreakpoint();
  const handleButtonClick = useReleaseTrackPlayButtonPressed(
    releaseId,
    isPending,
    tracks,
    trackId,
  );
  if (isMobile && !fileVersionId) {
    return null;
  }
  const PlayAudioButtonSection = track?.paymentRequiredForPlayback ? (
    <ToolTipTextArea text="Purchase to start listening, or sign in to an account that previously purchased this track">
      <PlayButton disabled>
        <FontAwesomeIcon icon={faPlay} />
      </PlayButton>
    </ToolTipTextArea>
  ) : (
    <PlayAudioButton
      invertColor={!darkMode}
      progress={0}
      paymentRequiredForPlayback={track?.paymentRequiredForPlayback}
      isPlaying={isPlaying}
      handleClick={handleButtonClick}
      size={size}
      padding={0}
      isPending={!isFetchedAfterMount}
    />
  );

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={isMobile ? "center" : "flex-start"}
      minHeight={50}
      gap={2}
    >
      {PlayAudioButtonSection}
      {url && isFetchedAfterMount && !isMobile && (
        <FeaturedTrackPlayer
          isSameProject={currentTrack?.id === trackId}
          id={trackId}
          source={url}
          isPlayingOnFooter={isPlaying}
          showTime={false}
        />
      )}
    </Box>
  );
};
