import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { fetchUsernameExists } from "../../api/auth/usernameExistsFetch";
import { PASSWORDLESS_ACCOUNT_FOUND } from "../../store/models/exceptions";

export const useUsernameExistsQuery = (username: string) => {
  return useQuery({
    staleTime: Infinity,
    retry: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    retryOnMount: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    queryKey: [QUERY_KEYS.CHECK_IF_USERNAME_EXISTS, username],
    queryFn: async () => {
      const response = await fetchUsernameExists(username);
      if (response.success) {
        return response.resultJson.username_exists;
      }
      if (response.resultJson.code === PASSWORDLESS_ACCOUNT_FOUND) {
        throw response.resultJson;
      }
      return false;
    },
  });
};
