import {
  StyledSwitchButtonAB,
  StyledSwitchButtonABP,
} from "./SwitchButtonAB.styles";
import classNames from "classnames";

export interface SwitchButtonProps {
  isSelected: boolean;
  label: string;
  loading?: boolean;
  onClick: () => void;
}

export const SwitchButtonAB = ({
  isSelected,
  label,
  loading = false,
  onClick,
}: SwitchButtonProps) => {
  return (
    <StyledSwitchButtonAB
      disabled={loading}
      className={classNames({
        active: isSelected,
        "project-view": label.length === 1,
      })}
      onClick={onClick}
    >
      <StyledSwitchButtonABP className={classNames({ active: isSelected })}>
        {label}
      </StyledSwitchButtonABP>
    </StyledSwitchButtonAB>
  );
};
