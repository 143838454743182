import { createDraftSafeSelector, createSelector } from "@reduxjs/toolkit";

import { RootState } from "../index";
import { Admin } from "../models/admins";
import Artist from "../models/artist";
import Engineer from "../models/engineer";
import StudioManager from "../models/studiomanager";

export const selectUserAdminField = (state: RootState): undefined | Admin =>
  state.accountInfo.user?.admin;

export const selectUserEngineerField = (
  state: RootState,
): undefined | Engineer => state.accountInfo.user?.engineer;

export const selectUserStudioManagerField = (
  state: RootState,
): undefined | StudioManager => state.accountInfo.user?.studio_manager;

export const selectUserArtistField = (state: RootState): undefined | Artist =>
  state.accountInfo.user?.artist;

export const selectIsCurrentUserEngineerSelector = createDraftSafeSelector(
  selectUserEngineerField,
  (engineer: Engineer | undefined) => {
    if (!engineer) {
      return false;
    }
    return !engineer.deleted;
  },
);

export const isUserVerifiedAdminSelector = createSelector(
  selectUserAdminField,
  (admin: Admin | undefined) => {
    if (!admin) {
      return false;
    }
    return Boolean(!admin.deleted && admin.admin_verified);
  },
);

export const selectUserCanEnableStudioServices = createSelector(
  selectUserStudioManagerField,
  (studioManager: StudioManager | undefined): boolean => {
    if (!studioManager) {
      return false;
    }
    return Boolean(studioManager.can_manage_studios) && !studioManager.deleted;
  },
);

export const isCurrentUserArtistSelector = createSelector(
  selectUserArtistField,
  (artist: Artist | undefined) => {
    if (!artist) {
      return false;
    }
    return !artist.deleted;
  },
);

export const selectIsArtistPrimaryAccountType = createSelector(
  selectUserArtistField,
  isCurrentUserArtistSelector,
  (artist: Artist | undefined, isArtist: boolean) => {
    return isArtist && artist?.is_primary_type !== null;
  },
);

export const selectCurrentUser = (state: RootState) => state.accountInfo.user;

export const isEngineerVerifiedSelector = createSelector(
  selectUserEngineerField,
  selectIsCurrentUserEngineerSelector,
  (engineer: Engineer | undefined, isEngineer: boolean): boolean => {
    return isEngineer && engineer?.verified !== null;
  },
);

export const isEngineerOnboardedSelector = createSelector(
  selectUserEngineerField,
  selectIsCurrentUserEngineerSelector,
  (engineer: Engineer | undefined, isEngineer: boolean): boolean => {
    return (
      isEngineer &&
      (engineer?.started_onboarding !== null || engineer?.verified !== null)
    );
  },
);

export const engineerOnboardedDateSelector = createSelector(
  selectUserEngineerField,
  selectIsCurrentUserEngineerSelector,
  (engineer: Engineer | undefined, isEngineer: boolean): string | null => {
    return isEngineer ? engineer?.started_onboarding ?? null : null;
  },
);

export const isOnboardedEngineerOrStudioManagerSelector = createSelector(
  isEngineerOnboardedSelector,
  selectUserCanEnableStudioServices,
  (isEngineerOnboarded: boolean, isUserStudioEnabled: boolean) => {
    return isEngineerOnboarded || isUserStudioEnabled;
  },
);

export const selectIsUserDolbyEnabled = createSelector(
  selectUserEngineerField,
  selectIsCurrentUserEngineerSelector,
  (engineer: Engineer | undefined, isEngineer: boolean): boolean => {
    if (!engineer) return false;
    const dolbyEnabled =
      Boolean(engineer?.is_dolby_certified) ||
      Boolean(engineer?.can_host_dolby_services);
    return isEngineer && dolbyEnabled;
  },
);

export const selectIsUserRecordingEnabled = createSelector(
  selectUserEngineerField,
  selectIsCurrentUserEngineerSelector,
  (engineer: Engineer | undefined, isEngineer: boolean): boolean => {
    if (!engineer) return false;
    const recordingEnabled = Boolean(engineer?.started_onboarding);
    return isEngineer && recordingEnabled;
  },
);

export const selectEmailVerified = createSelector(selectCurrentUser, (user) => {
  return Boolean(user?.email_verified);
});

export const selectPhoneVerified = createSelector(selectCurrentUser, (user) => {
  return Boolean(user?.phone_number?.verified);
});

export const selectPhoneAdded = createSelector(selectCurrentUser, (user) => {
  return Boolean(user?.phone_number?.phone_number);
});

export const selectMissingPhoneNumberSelector = createSelector(
  selectCurrentUser,
  (user) => {
    return (
      Boolean(user?.phone_number) === false ||
      Boolean(user?.phone_number?.phone_number.length === 0)
    );
  },
);

export const selectMissingAccountInfo = createSelector(
  selectCurrentUser,
  (user) => {
    if (!user) {
      return false;
    }
    return (
      !user.first_name ||
      !user.last_name ||
      !user.phone_number ||
      user.phone_number.phone_number.length === 0
    );
  },
);
