import { compile } from "path-to-regexp";
import { PORTFOLIO_PROJECT_TRACK_BY_USER_ID } from "../../store/utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { EngineEarsError } from "../../store/actions/errorStore";
import { PortfolioProjectTrackResponse } from "../../store/models/portfolio";

export const fetchPortfolioTracksByUserId = async (userId: number) => {
  const route = compile(PORTFOLIO_PROJECT_TRACK_BY_USER_ID)({
    user_id: userId,
  });
  return await makeBackendGetCallWithJsonResponse<
    PortfolioProjectTrackResponse[] | EngineEarsError
  >(route, "");
};
