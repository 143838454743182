import { Box, Checkbox, FormControlLabel, styled } from "@mui/material";

export const PrimaryFilterCardContainer = styled(FormControlLabel, {
  shouldForwardProp: (prop: string) =>
    ![
      "$borderColor",
      "$backgroundColor",
      "$inactive",
      "$activeStateBorderColor",
    ].includes(prop),
})<{
  $borderColor: string;
  $backgroundColor: string;
  $inactive: boolean;
  $activeStateBorderColor: string;
}>(
  ({
    theme,
    $borderColor,
    $backgroundColor,
    $inactive,
    $activeStateBorderColor,
  }) => ({
    position: "relative",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    cursor: "pointer",
    marginRight: 0,
    marginLeft: 0,
    padding: "16px",
    borderRadius: theme.border.radius.lg,
    border: `1px solid ${$borderColor}`,
    boxShadow: theme.palette.customColor.primaryFilterCardBoxShadow,
    transition: "all 0.2s ease-in-out",
    backgroundColor: $backgroundColor,
    ".MuiTypography-root": {
      color: $inactive
        ? theme.palette.text.disabled
        : theme.palette.text.primary,
      transition: "all 0.2s ease-in-out",
    },

    "&:hover": {
      borderColor: $activeStateBorderColor,
      ".MuiTypography-root": {
        color: theme.palette.text.primary,
      },
    },

    svg: {
      width: 0,
      height: 0,
    },
  }),
);

export const StyledCheckbox = styled(Checkbox)(() => ({
  position: "absolute",
  opacity: 0,
  cursor: "pointer",
  height: 0,
  width: 0,

  "&.MuiCheckbox-root": {
    padding: 0,
  },
}));

export const CardContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  alignItems: "flex-start",
  justifyContent: "center",
});
