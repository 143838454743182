import { TextField, TextFieldProps } from "@mui/material";
import classNames from "classnames";
import { TrackableClassNames } from "../../../constants/trackableClassNames";

export type FormOutlinedTextFieldProps = Omit<
  TextFieldProps<"outlined">,
  "variant"
>;

const FormOutlinedTextField = ({
  className,
  id,
  type = "text",
  ...props
}: FormOutlinedTextFieldProps) => {
  return (
    <TextField
      className={classNames(
        TrackableClassNames.ENGINEEARS_TEXTFIELD,
        className,
      )}
      id={id}
      type={type}
      variant={"outlined"}
      {...props}
    />
  );
};

export default FormOutlinedTextField;
