export enum MaxDistanceOptionValue {
  TEN = 10,
  TWENTY_FIVE = 25,
  FIFTY = 50,
}

export const availableMaxDistanceOptionValue = [
  MaxDistanceOptionValue.TEN,
  MaxDistanceOptionValue.TWENTY_FIVE,
  MaxDistanceOptionValue.FIFTY,
];

export const DEFAULT_MAX_DISTANCE = MaxDistanceOptionValue.FIFTY;

export const maxDistanceOptions = [
  { label: "Within 10 miles", value: MaxDistanceOptionValue.TEN },
  { label: "Within 25 miles", value: MaxDistanceOptionValue.TWENTY_FIVE },
  { label: "Within 50 miles", value: MaxDistanceOptionValue.FIFTY },
];

export const MAX_LOCATION_FILTER_HISTORY_COUNT = 4;

export interface AnchorPointProps {
  lat: number;
  lng: number;
  formatted_address?: string;
  is_current_location?: boolean;
}

export interface UpdatedLocationFilterProps {
  updatedAnchorPoint: AnchorPointProps;
  updatedMaxDistance: MaxDistanceOptionValue;
}
