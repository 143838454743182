import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { MockMixingProject } from "../../models/mockDataForMixMaster/mockDataForMixMaster";
import {
  contextualTransitionMessageMastering,
  contextualTransitionMessageMixing,
  Project,
  ProjectType,
  transformRawData,
} from "../../models/project";
import downloadAllFilesForScheduledProject from "./downloadAllFilesForScheduledProject";
import loadProject from "./loadProject";
import loadProjects from "./loadProjects";
import loadInProgressProject from "./loadInProgressProject";
import artistMixingTransitions from "./artistMixingTransitions";
import artistMasteringTransitions from "./artistMasteringTransitions";
import engMixTransition from "./engMixTransition";
import engMasteringTransition from "./engMasteringTransition";
import { ProjectsState } from "./types";

const initialState: ProjectsState = {
  isLoading: false,
  singleProjectIsUpdating: false,
  contextualPendingMessage: "",
  mixingProjects: [],
  masteringProjects: [],
  mastering_pages: 0,
  mixing_pages: 0,
  downloadingAllTracks: false,
};

const currentProjectMatches =
  (project_id: number) =>
  (mixOrMaster: Project): boolean => {
    return mixOrMaster.id === project_id;
  };

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    updateProjectCounts: (state, action: PayloadAction<Project>) => {
      if (
        state.mixingProjects.some((project) => project.id === action.payload.id)
      ) {
        state.mixingProjects = state.mixingProjects.map((project) => {
          if (project.id === action.payload.id) {
            return {
              ...project,
              revisions_available: action.payload.revisions_available,
              revision_counter: action.payload.revision_counter,
            };
          }
          return project;
        });
      } else if (
        state.masteringProjects.some(
          (project) => project.id === action.payload.id,
        )
      ) {
        state.masteringProjects = state.masteringProjects.map((project) => {
          if (project.id === action.payload.id) {
            return {
              ...project,
              revisions_available: action.payload.revisions_available,
              revision_counter: action.payload.revision_counter,
            };
          }
          return project;
        });
      }
    },
    addMockProject: (state) => {
      state.mixingProjects = [MockMixingProject];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      downloadAllFilesForScheduledProject.pending,
      (state: ProjectsState) => {
        state.downloadingAllTracks = true;
      },
    );
    builder.addCase(
      downloadAllFilesForScheduledProject.fulfilled,
      (state: ProjectsState) => {
        state.downloadingAllTracks = false;
      },
    );
    builder.addCase(downloadAllFilesForScheduledProject.rejected, (state) => {
      state.downloadingAllTracks = false;
    });
    builder.addCase(loadProjects.rejected, (state: ProjectsState) => {
      state.isLoading = false;
    });
    builder.addCase(loadProjects.pending, (state: ProjectsState) => {
      state.isLoading = true;
    });
    builder.addCase(loadProjects.fulfilled, (state: ProjectsState, action) => {
      const { payload } = action;
      state.mixing_pages = payload.mixing_pages;
      state.mastering_pages = payload.mastering_pages;
      state.mixingProjects = payload.mixing_projects.map(transformRawData);
      state.masteringProjects =
        payload.mastering_projects.map(transformRawData);
      state.isLoading = false;
    });
    builder.addMatcher(
      isAnyOf(loadProject.pending, loadInProgressProject.pending),
      (state: ProjectsState) => {
        state.singleProjectIsUpdating = true;
        state.contextualPendingMessage = "fetching project details";
      },
    );
    builder.addMatcher(
      isAnyOf(
        engMasteringTransition.pending,
        artistMasteringTransitions.pending,
      ),
      (state: ProjectsState, action) => {
        const message = contextualTransitionMessageMastering.get(
          action.meta.arg.transition,
        );
        if (!message) {
          state.contextualPendingMessage = "transitioning project to next step";
        } else {
          state.contextualPendingMessage = message;
        }
        if (!action.meta.arg?.skipLoadingIndicator) {
          state.singleProjectIsUpdating = true;
        }
      },
    );
    builder.addMatcher(
      isAnyOf(artistMixingTransitions.pending, engMixTransition.pending),
      (state: ProjectsState, action) => {
        const message = contextualTransitionMessageMixing.get(
          action.meta.arg.transition,
        );
        if (!message) {
          state.contextualPendingMessage = "transitioning project to next step";
        } else {
          state.contextualPendingMessage = message;
        }
        if (!action.meta.arg?.skipLoadingIndicator)
          state.singleProjectIsUpdating = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        engMasteringTransition.rejected,
        artistMixingTransitions.rejected,
        loadProject.rejected,
        engMixTransition.rejected,
        artistMasteringTransitions.rejected,
        loadInProgressProject.rejected,
      ),
      (state) => {
        state.singleProjectIsUpdating = false;
        state.contextualPendingMessage = "";
      },
    );
    builder.addMatcher(
      isAnyOf(
        engMasteringTransition.fulfilled,
        loadProject.fulfilled,
        artistMixingTransitions.fulfilled,
        engMixTransition.fulfilled,
        artistMasteringTransitions.fulfilled,
        loadInProgressProject.fulfilled,
      ),
      (state: ProjectsState, action) => {
        const { payload } = action;
        if (payload.project?.service_type === ProjectType.MASTERING) {
          const index = state.masteringProjects.findIndex(
            currentProjectMatches(payload.project.id),
          );
          const updatedProject = transformRawData(payload);

          if (index < 0) {
            state.masteringProjects.push(updatedProject);
          } else {
            state.masteringProjects[index] = updatedProject;
          }
        }
        if (
          payload.project?.service_type === ProjectType.MIXING ||
          payload.project?.service_type === ProjectType.TWO_TRACK_MIXING ||
          payload.project?.service_type === ProjectType.ATMOS_MIXING
        ) {
          const index = state.mixingProjects.findIndex(
            currentProjectMatches(payload.project.id),
          );
          const updatedProject = transformRawData(payload);

          if (index < 0) {
            state.mixingProjects.push(updatedProject);
          } else {
            state.mixingProjects[index] = updatedProject;
          }
        }
        state.contextualPendingMessage = "";
        state.singleProjectIsUpdating = false;
      },
    );
  },
});

export const { addMockProject, updateProjectCounts } = projectSlice.actions;

export default projectSlice.reducer;
