import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useQueryParamEntries } from "../../../hooks/useQueryParamEntries";
import {
  setCollaboratorFilter,
  setStudioFilter,
  setStudioRoomFilter,
} from "../../../store/actions/bookingsSearch";
import { setSessionStages } from "../../../store/actions/paginatedRecordingSessions";
import {
  setSelectedServices,
  setStages,
  setTrackStages,
} from "../../../store/actions/paginatedScheduledProjects";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType, TrackStage } from "../../../store/models/project";
import {
  RecordingSessionStages,
  SESSION_STAGE_OPTIONS,
  SESSION_STAGE_REVERSE_OPTIONS,
} from "../../../store/models/recordingSession";
import { ScheduledProjectStage } from "../../../store/models/scheduledproject";
import {
  BookingRouteTabs,
  getMyBookingsRoute,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { ApplyFiltersParams } from "../../components/ProjectFilters/SidePanelFilters/ProjectSidePanelFilters";
import { useAtomValue } from "jotai";
import { isStudioProfileAtom } from "../../../atoms/user/activeProfileAtom";
import {
  isActiveUserEngineerAtom,
  isActiveUserEngineerOrStudioManagerAtom,
} from "../../../atoms/user/activeUserAtom";

export enum BookingTabs {
  NotSet = -1,
  Projects = "projects",
  Sessions = "sessions",
  Links = "links",
}

export enum SessionTabs {
  ListView,
  CalendarView,
}

/**
 * Query parameter keys for the booking screen.
 * @example ?services=1,2&stages=active,pending
 */
export enum BookingQueryParamKeys {
  Services = "services",
  Stages = "stages",
  TrackStages = "track_stages",
  User = "User",
  ScheduledProjectId = "scheduled_project_id",
  Studio = "Studio",
  StudioRoom = "StudioRoom",
  SessionStages = "session_stages",
}

/**
 * Human readable values for various query parameters.
 */
const SERVICES: Record<ProjectType, string> = {
  [ProjectType.NO_TYPE]: "No Type",
  [ProjectType.RECORDING]: "Recording",
  [ProjectType.MIXING]: "Mixing",
  [ProjectType.MASTERING]: "Mastering",
  [ProjectType.TWO_TRACK_MIXING]: "Mixing (2-track)",
  [ProjectType.ATMOS_MIXING]: "Dolby Atmos",
};

const STAGES: Record<ScheduledProjectStage, string> = {
  [ScheduledProjectStage.ALL]: "All",
  [ScheduledProjectStage.ACTIVE]: "Active",
  [ScheduledProjectStage.COMPLETED]: "Completed",
  [ScheduledProjectStage.PENDING]: "Pending",
  [ScheduledProjectStage.REFUNDED]: "Refunded",
};

const TRACK_STAGES: Record<TrackStage, string> = {
  [TrackStage.ALL]: "All",
  [TrackStage.FILE_TRANSFER]: "File Transfer",
  [TrackStage.IN_REVIEW]: "In Review",
  [TrackStage.IN_PROGRESS]: "In-progress",
  [TrackStage.REVISION_IN_PROGRESS]: "Revision in-progress",
  [TrackStage.FINAL_FILE_TRANSFER]: "Final file transfer",
  [TrackStage.REFUNDED]: "Refunded",
  [TrackStage.COMPLETED]: "Completed",
};

interface BookingTabsValue {
  value: BookingTabs;
  label: string;
}

const BookingTabsMapping: Record<string, BookingTabsValue> = {
  [BookingTabs.Projects]: {
    value: BookingTabs.Projects,
    label: "Projects",
  },
  [BookingTabs.Sessions]: {
    value: BookingTabs.Sessions,
    label: "Sessions",
  },
  [BookingTabs.Links]: {
    value: BookingTabs.Links,
    label: "Links",
  },
};

const bookingTabsList = Object.values(BookingTabsMapping);

export interface TabViewProps {
  selectedTab: BookingTabs;
  sessionTab?: SessionTabs;
  setSessionTab?: (tab: SessionTabs) => void;
}

export const useBookingScreenTabs = () => {
  const navigate = useNavigate();
  const { search, hash } = useLocation();
  const isServiceProvider = useAtomValue(
    isActiveUserEngineerOrStudioManagerAtom,
  );
  const { recordingService } = useAppSelector(
    (state) => state.engineerServices,
  );
  const isStudioProfileActive = useAtomValue(isStudioProfileAtom);
  const isEngineer = useAtomValue(isActiveUserEngineerAtom);
  const { tab: currentTab } = useParams<{ tab: string | undefined }>();

  const { pendingScheduledProjectCount, pendingRecordingSessionBookingCount } =
    useAppSelector((state) => state.dashboard.pendingProjects);

  const tabsList = useMemo(() => {
    let resTabsList = bookingTabsList;
    if (isStudioProfileActive) {
      resTabsList = bookingTabsList.filter(
        (tab) => tab.value !== BookingTabs.Projects,
      );
    }

    if (!isEngineer && !isStudioProfileActive) {
      resTabsList = resTabsList.filter(
        (tab) => tab.value !== BookingTabs.Links,
      );
    }

    return resTabsList.map((tab) => {
      if (tab.value === BookingTabs.Projects && pendingScheduledProjectCount) {
        return {
          ...tab,
          label: `${tab.label} (${pendingScheduledProjectCount})`,
        };
      }

      if (
        tab.value === BookingTabs.Sessions &&
        pendingRecordingSessionBookingCount
      ) {
        return {
          ...tab,
          label: `${tab.label} (${pendingRecordingSessionBookingCount})`,
        };
      }
      if (tab.value === BookingTabs.Links) {
        return {
          ...tab,
          label: `${tab.label}`,
        };
      }
      return tab;
    });
  }, [
    isEngineer,
    pendingRecordingSessionBookingCount,
    pendingScheduledProjectCount,
    isStudioProfileActive,
  ]);

  const selectedTabIndex = useMemo(() => {
    if (tabsList.length === 0) return undefined;

    if (currentTab != undefined) {
      const foundIdx = tabsList.findIndex((tab) => tab.value === currentTab);

      // We re-direct the user to the first available tab if the parsed selected tab is not found in available tabs
      if (foundIdx === -1) {
        navigate({
          pathname: getMyBookingsRoute(tabsList[0].value as BookingRouteTabs),
          search,
          hash,
        });
        return 0;
      }

      return foundIdx;
    }

    // If no tab was defined, the first tab is assumed to be the selected tab
    return 0;
  }, [currentTab, navigate, tabsList, search, hash]);

  const [searchParams] = useSearchParams();
  const sessionStages = useMemo(
    () => searchParams.get(BookingQueryParamKeys.SessionStages),
    [searchParams],
  );
  const [selectedSessionTab, setSelectedSessionTab] = useState<SessionTabs>(
    sessionStages == null && isServiceProvider && recordingService
      ? SessionTabs.CalendarView
      : SessionTabs.ListView,
  );
  const onTabClicked = useCallback(
    (tabIndex: number) => {
      if (tabIndex === selectedTabIndex || tabsList.length === 0) {
        return;
      }

      emitAnalyticsTrackingEvent("my_bookings_tab_clicked", {
        tab: tabsList[tabIndex].label,
      });

      navigate({
        pathname: getMyBookingsRoute(
          tabsList[tabIndex].value as BookingRouteTabs,
        ),
        search,
        hash,
      });
    },

    [navigate, selectedTabIndex, tabsList, search, hash],
  );

  return {
    onTabClicked,
    selectedTabIndex,
    tabsList,
    selectedSessionTab,
    setSelectedSessionTab,
    selectedTab: currentTab as BookingTabs,
  };
};

const useProjectsSelectedServices = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const { tab: currentTab } = useParams<{ tab: string | undefined }>();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const servicesQuery = useMemo(
    () => searchParams.get(BookingQueryParamKeys.Services),
    [searchParams],
  );
  const setServices = useCallback(
    (value?: string | (string | number | boolean)[] | undefined) => {
      setSearchParams((prev) => {
        if (typeof value === "undefined") {
          prev.delete(BookingQueryParamKeys.Services);
          return prev;
        }
        const valueArray = Array.isArray(value) ? value : [value];
        // turn commas and other special characters into encoded values to prevent being mistaken as a comma-separated list
        const encodedValues = valueArray.map((v) => encodeURIComponent(v));
        const encodedValuesString = encodedValues.join(",");
        return {
          ...prev,
          [BookingQueryParamKeys.Services]: encodedValuesString,
        };
      });
    },
    [setSearchParams],
  );
  const { selectedServices } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );

  const handleSelectServices = useCallback(
    (services: ProjectType[]) => {
      if (!user) return;
      emitAnalyticsTrackingEvent("selected_services", {
        services: services.join(","),
      });
      if (services.length === 2 && services[0] === ProjectType.NO_TYPE) {
        const filteredServices = services.filter(
          (service) => service !== ProjectType.NO_TYPE,
        );
        setServices(filteredServices);
      } else if (
        services.length > 1 &&
        services[services.length - 1] === ProjectType.NO_TYPE
      ) {
        setServices();
      } else if (services.length > 0) {
        setServices(services);
      } else {
        setServices();
      }
    },
    [user, setServices],
  );

  useEffect(() => {
    if (currentTab !== "projects") return;
    if (!servicesQuery) {
      dispatch(setSelectedServices([ProjectType.NO_TYPE]));
    } else {
      if (servicesQuery.includes(",")) {
        const split = servicesQuery.split(",");
        const numbers = split.map((s) => parseInt(s, 10));
        const services = numbers.map((n) => +n as ProjectType);
        dispatch(setSelectedServices(services));
      } else {
        dispatch(
          setSelectedServices([+servicesQuery as unknown as ProjectType]),
        );
      }
    }
  }, [servicesQuery, dispatch, currentTab]);

  return { selectedServices, handleSelectServices };
};

export const useSessionStages = () => {
  const { tab: currentTab } = useParams<{ tab: string | undefined }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const stagesQueryParam = useMemo(
    () => searchParams.get(BookingQueryParamKeys.SessionStages),
    [searchParams],
  );
  const setQuery = useCallback(
    (value?: string | (string | number | boolean)[] | undefined) => {
      setSearchParams((prev) => {
        if (typeof value === "undefined") {
          prev.delete(BookingQueryParamKeys.SessionStages);
          return prev;
        }
        const valueArray = Array.isArray(value) ? value : [value];
        // turn commas and other special characters into encoded values to prevent being mistaken as a comma-separated list
        const encodedValues = valueArray.map((v) => encodeURIComponent(v));
        const encodedValuesString = encodedValues.join(",");
        return {
          ...prev,
          [BookingQueryParamKeys.SessionStages]: encodedValuesString,
        };
      });
    },
    [setSearchParams],
  );
  const dispatch = useAppDispatch();
  const sessionStages = useAppSelector(
    (state) => state.paginatedRecordingSessions.stages,
  );
  const handleSelectSessionStages = useCallback(
    (updatedStages: RecordingSessionStages[]) => {
      if (
        updatedStages.length === 2 &&
        updatedStages[0] === RecordingSessionStages.ALL
      ) {
        const filteredStages = updatedStages.filter(
          (stage) => stage !== RecordingSessionStages.ALL,
        );
        const recordingSessionStageStringValue = filteredStages.map(
          (sessionStage) => SESSION_STAGE_REVERSE_OPTIONS[sessionStage],
        );
        setQuery(recordingSessionStageStringValue);
      } else if (
        updatedStages.length > 1 &&
        updatedStages[updatedStages.length - 1] === RecordingSessionStages.ALL
      ) {
        setQuery();
      } else if (updatedStages.length > 0) {
        const recordingSessionStageStringValue = updatedStages.map(
          (sessionStage) => SESSION_STAGE_REVERSE_OPTIONS[sessionStage],
        );
        setQuery(recordingSessionStageStringValue);
      } else {
        setQuery();
      }
    },
    [setQuery],
  );

  useEffect(() => {
    if (!stagesQueryParam) {
      dispatch(setSessionStages([RecordingSessionStages.ALL]));
      return;
    }
    const stagesFromQueryParam = stagesQueryParam.split(",");
    const convertStringValueToEnum = (stage: string) => {
      return SESSION_STAGE_OPTIONS.find((option) => option.label === stage)
        ?.value;
    };
    const stages = stagesFromQueryParam
      .map(convertStringValueToEnum)
      .filter((stage) => stage !== undefined) as RecordingSessionStages[];
    dispatch(setSessionStages(stages));
  }, [currentTab, dispatch, stagesQueryParam]);

  return { handleSelectSessionStages, sessionStages };
};

const useScheduledProjectStages = () => {
  const { tab: currentTab } = useParams<{ tab: string | undefined }>();
  const dispatch = useAppDispatch();
  const { stages } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const stagesQueryParam = useMemo(
    () => searchParams.get(BookingQueryParamKeys.Stages),
    [searchParams],
  );
  const setQuery = useCallback(
    (value?: string | (string | number | boolean)[] | undefined) => {
      setSearchParams((prev) => {
        if (typeof value === "undefined") {
          prev.delete(BookingQueryParamKeys.Stages);
          return prev;
        }
        const valueArray = Array.isArray(value) ? value : [value];
        // turn commas and other special characters into encoded values to prevent being mistaken as a comma-separated list
        const encodedValues = valueArray.map((v) => encodeURIComponent(v));
        const encodedValuesString = encodedValues.join(",");
        return {
          ...prev,
          [BookingQueryParamKeys.Stages]: encodedValuesString,
        };
      });
    },
    [setSearchParams],
  );

  const handleSelectStages = useCallback(
    (updatedStages: ScheduledProjectStage[]) => {
      if (
        updatedStages.length === 2 &&
        updatedStages[0] === ScheduledProjectStage.ALL
      ) {
        const filteredStages = updatedStages.filter(
          (stage) => stage !== ScheduledProjectStage.ALL,
        );
        setQuery(filteredStages);
      } else if (
        updatedStages.length > 1 &&
        updatedStages[updatedStages.length - 1] === ScheduledProjectStage.ALL
      ) {
        setQuery();
      } else if (updatedStages.length > 0) {
        setQuery(updatedStages);
      } else {
        setQuery();
      }
    },
    [setQuery],
  );

  useEffect(() => {
    if (!stagesQueryParam) {
      dispatch(setStages([ScheduledProjectStage.ALL]));
      return;
    }
    const stagesFromQueryParam = stagesQueryParam
      .split(",")
      .filter((stage) =>
        Object.values(ScheduledProjectStage).includes(
          stage as ScheduledProjectStage,
        ),
      );
    dispatch(setStages(stagesFromQueryParam as ScheduledProjectStage[]));
  }, [currentTab, dispatch, stagesQueryParam]);

  return { stages, handleSelectStages };
};

const useTrackStages = () => {
  const { tab: currentTab } = useParams<{ tab: string | undefined }>();
  const dispatch = useAppDispatch();
  const { trackStages } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const trackStageQueryParam = useMemo(
    () => searchParams.get(BookingQueryParamKeys.TrackStages),
    [searchParams],
  );
  const setQuery = useCallback(
    (value?: string | (string | number | boolean)[] | undefined) => {
      setSearchParams((prev) => {
        if (typeof value === "undefined") {
          prev.delete(BookingQueryParamKeys.TrackStages);
          return prev;
        }
        const valueArray = Array.isArray(value) ? value : [value];
        // turn commas and other special characters into encoded values to prevent being mistaken as a comma-separated list
        const encodedValues = valueArray.map((v) => encodeURIComponent(v));
        const encodedValuesString = encodedValues.join(",");
        return {
          ...prev,
          [BookingQueryParamKeys.TrackStages]: encodedValuesString,
        };
      });
    },
    [setSearchParams],
  );

  const handleSelectTrackStages = useCallback(
    (updatedStages: TrackStage[]) => {
      if (updatedStages.length === 2 && updatedStages[0] === TrackStage.ALL) {
        const filteredStages = updatedStages.filter(
          (stage) => stage !== TrackStage.ALL,
        );
        setQuery(filteredStages);
      } else if (
        updatedStages.length > 1 &&
        updatedStages[updatedStages.length - 1] === TrackStage.ALL
      ) {
        setQuery();
      } else if (updatedStages.length > 0) {
        setQuery(updatedStages);
      } else {
        setQuery();
      }
    },
    [setQuery],
  );

  useEffect(() => {
    if (currentTab !== "projects") return;
    if (!trackStageQueryParam) {
      dispatch(setTrackStages([TrackStage.ALL]));
      return;
    }
    const trackStagesFromQueryParam = trackStageQueryParam
      .split(",")
      .filter((stage) =>
        Object.values(TrackStage).includes(stage as TrackStage),
      );
    dispatch(setTrackStages(trackStagesFromQueryParam as TrackStage[]));
  }, [dispatch, currentTab, trackStageQueryParam]);

  return { trackStages, handleSelectTrackStages };
};

const useFilterEffect = (
  filterParam: string | null,
  dispatchFilterAction: ActionCreatorWithPayload<string[]>,
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const filterParamValue = filterParam;
    if (!filterParamValue) {
      dispatch(dispatchFilterAction([]));
      return;
    }
    const filterValues = filterParamValue.includes(",")
      ? filterParamValue.split(",")
      : [filterParamValue];
    dispatch(dispatchFilterAction(filterValues));
  }, [filterParam, dispatch, dispatchFilterAction]);
};

export const useSearchFilters = () => {
  const [searchParams] = useSearchParams();
  const collaboratorFilterParam = useMemo(
    () => searchParams.get(BookingQueryParamKeys.User),
    [searchParams],
  );
  const studioFilterParam = useMemo(
    () => searchParams.get(BookingQueryParamKeys.Studio),
    [searchParams],
  );
  const studioRoomFilterParam = useMemo(
    () => searchParams.get(BookingQueryParamKeys.StudioRoom),
    [searchParams],
  );
  useFilterEffect(studioRoomFilterParam, setStudioRoomFilter);
  useFilterEffect(studioFilterParam, setStudioFilter);
  useFilterEffect(collaboratorFilterParam, setCollaboratorFilter);
};

export const useScheduledProjectFilters = () => {
  const { selectedServices, handleSelectServices } =
    useProjectsSelectedServices();
  const { stages, handleSelectStages } = useScheduledProjectStages();
  const { trackStages, handleSelectTrackStages } = useTrackStages();
  const [, setSearchParams] = useSearchParams();

  const setQuery = useCallback(
    (
      key: string,
      value?: string | (string | number | boolean)[] | undefined,
    ) => {
      setSearchParams((prev) => {
        if (typeof value === "undefined") {
          prev.delete(key);
          return prev;
        }
        const valueArray = Array.isArray(value) ? value : [value];
        // turn commas and other special characters into encoded values to prevent being mistaken as a comma-separated list
        const encodedValues = valueArray.map((v) => encodeURIComponent(v));
        const encodedValuesString = encodedValues.join(",");
        return {
          ...prev,
          [key]: encodedValuesString,
        };
      });
    },
    [setSearchParams],
  );
  const onApplyFilters = useCallback(
    (appliedFilters: ApplyFiltersParams) => {
      // Service Filters
      if (appliedFilters.services.length > 0) {
        setQuery(BookingQueryParamKeys.Services, appliedFilters.services);
      } else {
        setQuery(BookingQueryParamKeys.Services);
      }

      // Project Status Filters
      if (appliedFilters.projectStages.length > 0) {
        setQuery(BookingQueryParamKeys.Stages, appliedFilters.projectStages);
      } else {
        setQuery(BookingQueryParamKeys.Stages);
      }

      // Track Status Filters
      if (appliedFilters.trackStages.length > 0) {
        setQuery(BookingQueryParamKeys.TrackStages, appliedFilters.trackStages);
      } else {
        setQuery(BookingQueryParamKeys.TrackStages);
      }
    },
    [setQuery],
  );

  return {
    selectedServices,
    handleSelectServices,
    stages,
    handleSelectStages,
    trackStages,
    handleSelectTrackStages,
    onApplyFilters,
  };
};

/**
 * Converts the query parameters to human-readable values for the booking screen.
 * @returns An array of key-value pairs with human-readable values.
 */
export const useBookingScreenURLParamsParser = () => {
  const queryParamEntries = useQueryParamEntries();

  // returns updated entries
  return useMemo(
    () =>
      queryParamEntries.map(([key, value]) => {
        switch (key) {
          case BookingQueryParamKeys.Services:
            return [key, SERVICES[parseInt(value) as ProjectType]];
          case BookingQueryParamKeys.Stages:
            return [key, STAGES[value as ScheduledProjectStage]];
          case BookingQueryParamKeys.TrackStages:
            return [key, TRACK_STAGES[value as TrackStage]];
          default:
            return [key, value];
        }
      }),
    [queryParamEntries],
  );
};

/**
 * Converts human-readable filter values into the format used for query parameters for the booking screen.
 */
export const useBookingScreenFiltersParser = () => {
  const { service, stages, trackStages } = useMemo(() => {
    const servicesParams: Record<string, string> = {};
    const stagesParams: Record<string, ScheduledProjectStage> = {};
    const trackStagesParams: Record<string, TrackStage> = {};
    Object.entries(SERVICES).map(
      ([key, value]) => (servicesParams[value] = key),
    );
    Object.entries(STAGES).map(
      ([key, value]) => (stagesParams[value] = key as ScheduledProjectStage),
    );
    Object.entries(TRACK_STAGES).map(
      ([key, value]) => (trackStagesParams[value] = key as TrackStage),
    );

    return {
      service: servicesParams,
      stages: stagesParams,
      trackStages: trackStagesParams,
    };
  }, []);

  return useCallback(
    (key: string, value: string) => {
      switch (key) {
        case BookingQueryParamKeys.Services:
          return service[value];
        case BookingQueryParamKeys.Stages:
          return stages[value];
        case BookingQueryParamKeys.TrackStages:
          return trackStages[value];
        default:
          return value;
      }
    },
    [service, stages, trackStages],
  );
};
