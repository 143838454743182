// @ts-ignore
import orangeTrendingIcon from "../../assets/icons/orangeTrendingIcon.svg";
// @ts-ignore
import blueTrendingIcon from "../../assets/icons/blueTrendingIcon.svg";
// @ts-ignore
import greenTrendingIcon from "../../assets/icons/greenTrendingIcon.svg";
// @ts-ignore
import { SCREENS } from "../../../routes/screens";
import purpleTrendingIcon from "../../assets/icons/purpleTrendingIcon.svg";
export interface DropDownLink {
  label: string;
  href: string;
  disabled?: boolean;
}
export const links: Record<string, DropDownLink[]> = {
  Artists: [
    {
      label: "Build your profile",
      href: SCREENS.CUSTOMIZE_PROFILE_FOR_ARTISTS,
      disabled: false,
    },
    {
      label: "Book Engineering Services",
      href: SCREENS.BOOKING_ENGINEERING_SERVICES_FOR_ARTISTS,
      disabled: false,
    },
    {
      label: "Book Recording Studios",
      href: SCREENS.BOOKING_ENGINEERING_SERVICES_FOR_ARTISTS,
      disabled: false,
    },
    {
      label: "Project Management Tools",
      href: SCREENS.PROJECT_MANAGEMENT_TOOLS_FOR_ARTISTS,
      disabled: false,
    },
    {
      label: "Reporting & Analytics",
      href: SCREENS.REPORTING_ANALYTICS_FOR_ARTISTS,
      disabled: false,
    },
    // { label: "Create Custom Ad Campaigns", href: SCREENS.SYMPHONY, disabled: false },
  ],
  AudioEngineers: [
    {
      label: "Build your profile",
      href: SCREENS.BUILD_PROFILE_FOR_ENGINEERS,
      disabled: false,
    },
    {
      label: "Project Management Tools",
      href: SCREENS.PROJECT_MANAGEMENT_TOOLS_FOR_ENGINEERS,
      disabled: false,
    },
    {
      label: "Reporting & Analytics",
      href: SCREENS.REPORTING_ANALYTICS_FOR_ENGINEERS,
      disabled: false,
    },
    {
      label: "Create Ad Campaigns",
      href: SCREENS.SYMPHONY,
      disabled: false,
    },
    {
      label: "Dolby Atmos Course",
      href: SCREENS.DOLBY_ATMOS,
      disabled: false,
    },
    {
      label: "Pro Tools Course",
      href: SCREENS.PRO_TOOLS,
      disabled: false,
    },
  ],
  Studios: [
    {
      label: "Build your profile",
      href: SCREENS.BUILD_PROFILE_FOR_STUDIOS,
      disabled: false,
    },
    // { label: "Project Management Tools", href: "/", disabled: true },
    {
      label: "Studio Management Tools",
      href: SCREENS.SESSION_MANAGEMENT_TOOLS,
      disabled: false,
    },
    {
      label: "Studio Team and Resources",
      href: SCREENS.STUDIO_TEAMS_RESOURCES,
      disabled: false,
    },
    // { label: "Reporting & Analytics", href: "/", disabled: true },
    {
      label: "Create Ad Campaigns",
      href: SCREENS.SYMPHONY,
      disabled: false,
    },
  ],
  RecordLabels: [
    {
      label: "Getting Started",
      href: SCREENS.GETTING_STARTED_FOR_LABELS,
      disabled: false,
    },
    // { label: "Building Lable Teams", href: "/", disabled: true },
    {
      label: "Booking Studios",
      href: SCREENS.BOOKING_STUDIO_SESSIONS_FOR_LABELS,
      disabled: false,
    },
    {
      label: "Book Engineering Services",
      href: SCREENS.BOOK_AUDIO_ENGINEERING_SERVICES_FOR_LABELS,
      disabled: false,
    },
    {
      label: "Project Management Tools",
      href: SCREENS.PROJECT_MANAGEMENT_TOOLS_FOR_LABELS,
      disabled: false,
    },
    // { label: "Admin Dashboard", href: "/", disabled: true },
  ],
  LearnMore: [
    {
      label: "Help Center",
      href: "https://support.engineears.com/en/knowledge-base",
    },
    { label: "Community", href: SCREENS.COMMUNITY },
    { label: "Blog", href: SCREENS.BLOG_SCREEN },
  ],
};

export interface DropdownSectionVideoLink {
  description: string;
  link: DropDownLink;
  disabled?: boolean;
  pageLink: DropDownLink;
}

export interface DropdownSectionInterface {
  label: string;
  icon?: string;
  links: DropDownLink[];
  video?: DropdownSectionVideoLink;
}

export const dropdownMenuData: Record<string, DropdownSectionInterface> = {
  Artists: {
    label: "For Artists",
    icon: blueTrendingIcon,
    links: links.Artists,
    video: {
      pageLink: {
        label: "For Artists",
        href: SCREENS.FOR_ARTISTS,
      },
      description: "",
      link: {
        label: "Platform Video",
        href: "https://www.youtube.com/watch?v=in9BrcweC3Q",
        disabled: false,
      },
    },
  },
  AudioEngineers: {
    label: "For Audio Engineers",
    icon: orangeTrendingIcon,
    links: links.AudioEngineers,
    video: {
      pageLink: {
        label: "For Audio Engineers",
        href: SCREENS.FOR_ENGINEERS,
      },
      description: "",
      link: {
        label: "Platform Video",
        href: "https://www.youtube.com/watch?v=GM-eD72LjrE",
        disabled: false,
      },
    },
  },
  Studios: {
    label: "For Recording Studios",
    icon: greenTrendingIcon,
    links: links.Studios,
    video: {
      pageLink: {
        label: "For Recording Studios",
        href: SCREENS.FOR_STUDIOS,
      },
      description: "",
      link: {
        label: "Platform Video",
        href: "https://www.youtube.com/watch?v=sQemgd-q0bM",
        disabled: false,
      },
    },
  },
  RecordLabels: {
    label: "For Record Labels",
    icon: purpleTrendingIcon,
    links: links.RecordLabels,
    video: {
      pageLink: {
        label: "For Record Labels",
        href: SCREENS.FOR_LABELS,
      },
      description: "",
      link: {
        label: "Video (coming soon)",
        href: "/",
        disabled: true,
      },
    },
  },
};
