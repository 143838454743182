import { useState } from "react";
import { provideUserFeedback } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import { Textfield } from "../../elements/Textfield1/Textfield1";
import "./FeedbackModal.css";
// @ts-ignore
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useIsUMGAandR } from "../../../hooks/useIsAandR";
import { emailRegex } from "../../../store/utils/signUpUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button } from "../../core-ui/components/Button/Button";
import { CheckBox } from "../../core-ui/components/CheckBox/CheckBox";
import { VideoList } from "../VideoList/VideoList";
import { getVideoUrl } from "../../../constants/googleStorage";
import { matchIsValidTel } from "mui-tel-input";
import { PhoneInput } from "../../core-ui/components/PhoneNumber/PhoneInput";

enum FeedbackType {
  ISSUE,
  QUESTION,
  FEEDBACK,
  OVERDUE_PROJECT,
  CANCEL_PROJECT,
}

const FeedbackCategoryOptions: OptionType[] = [
  {
    value: FeedbackType.ISSUE,
    label: "Issue",
  },
  {
    value: FeedbackType.QUESTION,
    label: "Question",
  },
  {
    value: FeedbackType.FEEDBACK,
    label: "Feedback",
  },
  {
    value: FeedbackType.OVERDUE_PROJECT,
    label: "Overdue Project",
  },
  { value: FeedbackType.CANCEL_PROJECT, label: "Cancel Project" },
];

const faqLink = "https://support.engineears.com/";

export interface FeedbackModalProps {
  onClose: () => void;
  cancelProject?: boolean;
}

export const FeedbackModal = ({
  onClose,
  cancelProject = false,
}: FeedbackModalProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const isUMGAandR = useIsUMGAandR(user);

  return (
    <BaseModal
      open={true}
      setOpen={onClose}
      header={!cancelProject ? "Want to provide feedback?" : "Cancel project?"}
    >
      {/* {isUMGAandR ? (
        <UMGFeedbackForm onClose={onClose} />
      ) : ( */}
      <div className={"non-umg-form-container"}>
        <FeedbackForm onClose={onClose} cancelProject={cancelProject} />
      </div>
      {/* )} */}
    </BaseModal>
  );
};

export const UMGFeedbackForm = ({ ...props }: FeedbackModalProps) => {
  return (
    <div className="umg-feed-back-form">
      <Tabs>
        <TabList>
          <Tab>
            <strong>FEEDBACK</strong>
          </Tab>
          <Tab>
            <strong>TUTORIALS</strong>
          </Tab>
        </TabList>
        <TabPanel>
          <FeedbackForm onClose={props.onClose} />
        </TabPanel>
        <TabPanel>
          <VideoList
            videos={[
              {
                title: "Sign up for EngineEars",
                webMFile: getVideoUrl("1_SignUp_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("1_SignUp_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Setting up your profile",
                webMFile: getVideoUrl("2_ProfileSetup_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("2_ProfileSetup_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Searching for an engineer",
                webMFile: getVideoUrl("3_Search_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("3_Search_ThumbnailAdded_1.mp4"),
              },
              {
                title: "DM before a project",
                webMFile: getVideoUrl("4_DMRequest_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("4_DMRequest_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Booking new projects",
                webMFile: getVideoUrl(
                  "5_BookingNewProjects_ThumbnailAdded_1.webm",
                ),
                mp4File: getVideoUrl(
                  "5_BookingNewProjects_ThumbnailAdded_1.mp4",
                ),
              },
              {
                title: "Submitting CC, WBS & GL",
                webMFile: getVideoUrl("8_WBSCCGL_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("8_WBSCCGL_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Adding alternate versions",
                webMFile: getVideoUrl("9_Alts_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("9_Alts_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Booking Dolby Atmos",
                webMFile: getVideoUrl("10_DolbyAtmos_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("10_DolbyAtmos_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Pre-arranged booking links",
                webMFile: getVideoUrl(
                  "11_PreArrangedBooking_ThumbnailAdded_1.webm",
                ),
                mp4File: getVideoUrl(
                  "11_PreArrangedBooking_ThumbnailAdded_1.mp4",
                ),
              },
              {
                title: "Uploading (Engineer has files)",
                webMFile: getVideoUrl(
                  "12_EngineerHasFiles_ThumbnailAdded_1.webm",
                ),
                mp4File: getVideoUrl(
                  "12_EngineerHasFiles_ThumbnailAdded_1.mp4",
                ),
              },
              {
                title: "Uploading Project Files",
                webMFile: getVideoUrl(
                  "13_UploadingFiles_ThumbnailAdded_1.webm",
                ),
                mp4File: getVideoUrl("13_UploadingFiles_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Project Overview and sharing",
                webMFile: getVideoUrl("14_Overview_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("14_Overview_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Project review and sharing",
                webMFile: getVideoUrl("15_Review_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("15_Review_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Downloading files for quality control",
                webMFile: getVideoUrl("16_QC_ThumbnailAdded_1.webm"),
                mp4File: getVideoUrl("16_QC_ThumbnailAdded_1.mp4"),
              },
              {
                title: "Completing a project/releasing payments",
                webMFile: getVideoUrl("17_CompleteProject_ThumbnailAdded.webm"),
                mp4File: getVideoUrl("17_CompleteProject_ThumbnailAdded.mp4"),
              },
            ]}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export const FeedbackForm = ({
  onClose,
  cancelProject = false,
}: FeedbackModalProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const dispatch = useAppDispatch();
  const isProvidingFeedback = useAppSelector(
    (state) => state.accountInfo.isProvidingFeedback,
  );
  const [feedback, setFeedback] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [feedbackCategory, setFeedbackCategory] = useState(
    !cancelProject
      ? FeedbackCategoryOptions[0]
      : FeedbackCategoryOptions[FeedbackCategoryOptions.length - 1],
  );
  const userPhoneNumber = useAppSelector(
    (state) => state.accountInfo?.user?.phone_number?.phone_number,
  );
  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber ?? "");
  const [requestCallBack, setRequestCallback] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);

  const handleSubmit = () => {
    const feedbackType = FeedbackType[feedbackCategory.value].replace("_", " ");
    if (user) {
      window.analytics.track(getDebugEventPrefix + "user_feedback", {
        user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
        ...localUTMParams,
      });
    } else {
      window.analytics.track(getDebugEventPrefix + "signed_out_user_feedback", {
        email_id: `${getDebugEventUserIdPrefix}${email}`,
        ...localUTMParams,
      });
    }
    void dispatch(
      provideUserFeedback({
        text: feedback,
        category: "V1 " + feedbackType,
        callback_number: phoneNumber,
        submitter_email: email,
      }),
    )
      .unwrap()
      .then(onClose);
  };

  return (
    <form className="feedback-form">
      {!cancelProject && (
        <>
          <a href={faqLink} className="faq-link">
            See our Help Center
          </a>
          <p className={"b1 my-2"}>
            If something is not working as expected for you, please describe
            your issue here, or please feel free to give us any and all feedback
            on how we can improve the site.
            <br></br>
            <br></br>
            We will be in touch in response to your message.
          </p>
        </>
      )}
      {cancelProject && (
        <>
          <p className={"b1 my-2"}>
            Please outline your reason for cancelling this project and the song
            name(s) for cancellation.
          </p>
        </>
      )}
      {!user && (
        <Textfield
          label="Enter your Email Here..."
          value={email}
          required={true}
          onChange={(e) => setEmail(e.target.value)}
        />
      )}
      {!cancelProject && (
        <DropdownSelector
          value={feedbackCategory}
          options={
            !cancelProject
              ? FeedbackCategoryOptions.slice(0, -1)
              : FeedbackCategoryOptions
          }
          placeholder={"Select service type"}
          onChange={setFeedbackCategory}
          isDisabled={cancelProject}
        />
      )}
      <EditableTextArea
        height={75}
        editMode={true}
        initialValue={feedback}
        placeholder={
          !cancelProject
            ? "Enter feedback here..."
            : "Enter your reason for cancellation..."
        }
        onChange={setFeedback}
        characterCount={500}
      />
      <CheckBox
        checked={requestCallBack}
        onClick={() => setRequestCallback((prev) => !prev)}
        label="Request a callback"
        style={{ margin: "14px 0" }}
      />
      {requestCallBack && (
        <PhoneInput
          style={{
            marginBottom: "10px",
          }}
          isValidPhoneNumber={isValidPhoneNumber}
          defaultCountry={"US"}
          value={phoneNumber}
          onChange={(value: string) => {
            setPhoneNumber(value);
            setIsValidPhoneNumber(matchIsValidTel(value));
          }}
        />
      )}
      {cancelProject && (
        <>
          <p className={"b1 my-2"}>Our team will be in contact soon.</p>
        </>
      )}
      <Button
        disabled={
          isProvidingFeedback ||
          ((email === "" || !emailRegex.test(email)) && !user)
        }
        loading={isProvidingFeedback}
        onClick={handleSubmit}
        style={{ minWidth: "155px" }}
      >
        Submit
      </Button>
    </form>
  );
};
