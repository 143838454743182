// getter/setter for the search term
import { atom } from "jotai";
import { searchParamsAtom, setSearchParamsHelper } from "./locationAtom";
import {
  CHANNEL_ID_PREFIX,
  SESSION_MESSAGE_PREFIXES,
} from "../../constants/chat";
import { statusAtom } from "./statusAtom";
import { channelAtom } from "./channelAtom";

export enum PAGE_VALUES {
  PROJECT_MESSAGES = 0,
  SESSION_MESSAGES = 1,
  STATUS = 2,
}

const QUERY_VALUES_TO_PAGE_VALUES = {
  "project-message": PAGE_VALUES.PROJECT_MESSAGES,
  "session-message": PAGE_VALUES.SESSION_MESSAGES,
  status: PAGE_VALUES.STATUS,
};

export const PAGE_VALUES_TO_QUERY_VALUES: Record<number, string> = {};
Object.keys(QUERY_VALUES_TO_PAGE_VALUES).forEach((k) => {
  PAGE_VALUES_TO_QUERY_VALUES[
    QUERY_VALUES_TO_PAGE_VALUES[k as keyof typeof QUERY_VALUES_TO_PAGE_VALUES]
  ] = k;
});

export const pageAtom = atom(
  async (get) => {
    if (get(statusAtom) != 0) {
      return PAGE_VALUES.STATUS;
    }
    const channelParam = get(channelAtom);
    if (channelParam.channelId) {
      const prefix = channelParam.channelId.split("-")[0];
      if (
        !prefix ||
        !SESSION_MESSAGE_PREFIXES.includes(prefix as CHANNEL_ID_PREFIX)
      ) {
        return PAGE_VALUES.PROJECT_MESSAGES;
      }
      return PAGE_VALUES.SESSION_MESSAGES;
    }
    return (
      QUERY_VALUES_TO_PAGE_VALUES[
        get(searchParamsAtom).page as keyof typeof QUERY_VALUES_TO_PAGE_VALUES
      ] || undefined
    );
  },
  (get, set, value: number) => {
    setSearchParamsHelper(get, set, [
      { key: "status", value: "", clear: true },
      { key: "channel", value: "", clear: true },
      {
        key: "page",
        value:
          PAGE_VALUES_TO_QUERY_VALUES[value] ||
          String(PAGE_VALUES.PROJECT_MESSAGES),
        clear: true,
      },
    ]);
  },
);

pageAtom.debugLabel = "Page Atom";
