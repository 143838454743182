// getter/setter for the search term
import { atom } from "jotai";
import { setSearchParamsHelper } from "./locationAtom";
import { showBottomAndTopNavAtom } from "../navAtoms";

// Controls modal
export const authModalOpenAtom = atom(false, (get, set, value: boolean) => {
  if (!value) {
    setSearchParamsHelper(get, set, [
      { key: "flow", value: "", clear: true },
      { key: "step", value: "", clear: true },
    ]);
    // Set showBottomAndTopNavAtom to true when authModalOpenAtom is set to false
    set(showBottomAndTopNavAtom, true);
  }
  set(authModalOpenAtom, value);
});
