import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import React, { ChangeEvent, useEffect, useState } from "react";
import ViewSlider, { ViewProps } from "react-view-slider";
import useAuthHandler from "../../../hooks/authHooks/useAuthHandler";
import { useCheckIfPasswordlessAccountQuery } from "../../../hooks/authHooks/useCheckIfPasswordlessAccountQuery";
import { useCheckUserLoginFlow } from "../../../hooks/authHooks/useCheckUserLoginFlow";
import useGuestLoginMutation from "../../../hooks/authHooks/useGuestLoginMutation";
import useSendEmailVerificationCodeMutation from "../../../hooks/authHooks/useSendEmailVerificationCodeMutation";
import { Button } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import VerificationCodeInput from "../Auth/VerificationCodeInput";
import { PasswordField } from "../PasswordField/PasswordField";
import { FieldContainer } from "./SignInForm.styles";
import { PasswordFieldView } from "./types";
import { useResetSignInFormPasswordFieldView } from "./useResetSignInFormPasswordFieldView";

interface SignInFormPasswordFieldProps {
  email: string;
  emailIsValid: boolean;
  registering: boolean;
  showWarning: boolean;
  newPassword: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  helperText: string;
  loading: boolean;
  handleSubmit: ((e: React.FormEvent) => Promise<void>) | (() => void);
  isSubmitDisabled: boolean;
  showSignUp: boolean;
  includeSignInOrRegisterSelect?: boolean;
  setShowSignUp: (value: boolean) => void;
  usernameExists: boolean;
  usernameBelongsToPasswordlessAccount: boolean;
}

export const SignInFormPasswordField = ({
  registering,
  showWarning,
  newPassword,
  onChange,
  error,
  helperText,
  loading,
  handleSubmit,
  isSubmitDisabled,
  showSignUp,
  includeSignInOrRegisterSelect,
  setShowSignUp,
  email,
  emailIsValid,
  usernameExists,
  usernameBelongsToPasswordlessAccount,
}: SignInFormPasswordFieldProps) => {
  const { checkUserAndSetFlow } = useCheckUserLoginFlow();
  const [currentView, setCurrentView] = useState<PasswordFieldView>(
    PasswordFieldView.default,
  );
  const [verificationError, setVerificationError] = useState(false);
  const { handleRedirect } = useAuthHandler();

  const { data: accountPasswordless } = useCheckIfPasswordlessAccountQuery(
    email,
    emailIsValid,
  );

  useEffect(() => {
    if (registering) {
      setCurrentView(PasswordFieldView.passwordInputField);
    }
  }, [registering]);

  const { mutateAsync: guestLoginAsync, isPending: checkingCode } =
    useGuestLoginMutation();

  const { mutate: getVerificationCode, isPending: isGettingVerificationCode } =
    useSendEmailVerificationCodeMutation();

  useResetSignInFormPasswordFieldView(
    setCurrentView,
    emailIsValid,
    accountPasswordless,
    usernameExists,
    usernameBelongsToPasswordlessAccount,
  );

  const disableCodeInputField =
    checkingCode || !emailIsValid || isGettingVerificationCode;

  const renderView = ({ index }: ViewProps) => {
    switch (index) {
      case PasswordFieldView.passwordInputField:
        return (
          <PasswordField
            registering={registering}
            showWarning={showWarning}
            newPassword={newPassword}
            onChange={onChange}
            error={error}
            helperText={helperText}
            loading={loading}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
            showSignUp={showSignUp}
            selectState={
              includeSignInOrRegisterSelect
                ? () => setShowSignUp(!showSignUp)
                : undefined
            }
          />
        );
      case PasswordFieldView.codeInputField:
        return (
          <FieldContainer>
            <Text weight={TEXT_WEIGHT.SEMI_BOLD}>
              Enter the verification code we sent you
            </Text>
            <VerificationCodeInput
              codeLength={6}
              onComplete={async (code) => {
                void guestLoginAsync({
                  email,
                  verification_code: code,
                })
                  .then((response) => {
                    if (response.user) {
                      checkUserAndSetFlow(response.user);
                      handleRedirect();
                    }
                  })
                  .catch(() => {
                    setVerificationError(true);
                  });
              }}
            />
            {verificationError && (
              <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
                <FontAwesomeIcon
                  style={{ marginRight: "4px" }}
                  icon={faXmark}
                />
                Invalid verification code
              </Text>
            )}
            <Button
              style={{ marginTop: 8 }}
              fullWidth
              onClick={() => {
                getVerificationCode({
                  email_or_username: email,
                  send_verification_code: true,
                });
              }}
              disabled={disableCodeInputField && !accountPasswordless}
              loading={checkingCode || loading || isGettingVerificationCode}
            >
              Resend code
            </Button>
          </FieldContainer>
        );
      default:
        return null;
    }
  };

  return (
    <ViewSlider
      numViews={3}
      renderView={renderView}
      animateHeight
      activeView={currentView}
    />
  );
};
