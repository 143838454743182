import { Stack, useMediaQuery } from "@mui/material";
import { SCREENS } from "../../../../routes/screens";
import { Text } from "../../../core-ui/components/Text/Text";
import Link from "../../../elements/Link/Link";
import { LearnDropdown } from "../../Dropdown";
import { DropdownNavMenu } from "../../DrowdownNavMenu/DropdownNavMenu";

export const DesktopNavItems = () => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  if (!isDesktop) {
    return null;
  }
  return (
    <Stack direction="row" gap={4} marginRight={4}>
      <DropdownNavMenu />
      {/* <Link
        style={{
          textDecoration: "none",
          color: "inherit",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          whiteSpace: "nowrap",
          backgroundColor: "inherit",
        }}
        to={SCREENS.SIGNED_OUT_HOME_SCREEN}
      >
        <Text bold>About us</Text>
      </Link> */}
      <LearnDropdown />
      <Link
        style={{
          textDecoration: "none",
          color: "inherit",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          whiteSpace: "nowrap",
          backgroundColor: "inherit",
        }}
        to={SCREENS.ADD_SUBSCRIPTION}
      >
        <Text bold>Pricing</Text>
      </Link>
    </Stack>
  );
};
