import { GUEST_LOGIN_VERIFY } from "../../store/utils/routes";
import { AuthResponse } from "../../store/actions/accountInfo";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { promiseEngineEarsHandler } from "../helpers";

export interface GuestLoginParams {
  email?: string;
  phone_number?: string;
  verification_code: string;
  transaction_code?: string;
}

export const postGuestLogin = (params: GuestLoginParams) => {
  return makeBackendPostCallWithJsonResponse<AuthResponse>(
    GUEST_LOGIN_VERIFY,
    params,
  ).then(promiseEngineEarsHandler);
};
