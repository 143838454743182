import {
  getArtistFromProject,
  getEngineerFromProject,
} from "../../../../utils/projectUtils";
import { getDisplayableNameForUser } from "../../../utils/entityUtils";
import { ProjectById } from "../../project";
import { PlaylistTrack, PlaylistTrackType } from "../types";

const convertProjectByIdToPlayListTrack = (
  project: ProjectById,
): PlaylistTrack => {
  const artistUser = getArtistFromProject(project);
  const engineerUser = getEngineerFromProject(project);
  return {
    id: project.id,
    type: PlaylistTrackType.ProjectById,
    title: project.title,
    artistDisplayName: getDisplayableNameForUser(artistUser),
    artistUsername: artistUser?.username || "",
    engineerUsername: engineerUser?.username || "",
    engineerDisplayName: getDisplayableNameForUser(engineerUser),
  };
};

export default convertProjectByIdToPlayListTrack;
