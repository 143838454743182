import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { displayEngineEarsError } from "../../api/helpers";
import { EngineEarsError } from "../../store/actions/errorStore";
import { useAppDispatch } from "../../store/hooks";
import { postVerifyPhone } from "../../api/accounts/user/postVerifyPhone";
import { setUser } from "../../store/actions/accountInfo";
import { useAtomValue } from "jotai";
import { tokenAtom } from "../../atoms/auth/tokenAtom";

const useVerifyPhoneMutation = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const token = useAtomValue(tokenAtom);
  return useMutation({
    mutationKey: [QUERY_KEYS.VERIFY_PHONE],
    mutationFn: async (verification: string) => {
      return postVerifyPhone({ verification });
    },
    onSuccess: async (updatedUser) => {
      dispatch(setUser(updatedUser));
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.LOAD_USER, token],
      });
      return updatedUser;
    },
    onError: (e: EngineEarsError) => {
      displayEngineEarsError(e);
    },
  });
};

export default useVerifyPhoneMutation;
