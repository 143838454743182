// getter/setter for the search term
import { atom } from "jotai";
import { ExpectedSearchParams, setSearchParamsHelper } from "./locationAtom";
import { AuthFlows, FLOW_STEPS } from "../../constants/authSteps";
import { currentSignUpStepAtom } from "./currentSignUpStepAtom";

// Write the current flow to the URL. Used when changing flows
export const currentFlowAtom = atom(
  AuthFlows.LOGIN,
  (get, set, value: AuthFlows, preventStepReset?: boolean) => {
    if (value === AuthFlows.NONE) {
      return;
    }

    const key: keyof ExpectedSearchParams = "flow";
    setSearchParamsHelper(get, set, {
      key,
      value: String(value),
      clear: false,
    });

    set(currentFlowAtom, value);
    if (!preventStepReset) {
      const flowSteps = FLOW_STEPS[value]?.steps.flat() || [];
      set(currentSignUpStepAtom, flowSteps[0]);
    }
  },
);
