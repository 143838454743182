import { useAtomValue, useSetAtom } from "jotai";
import { Fragment, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  activeProfileAtom,
  activeStudioProfileAtom,
  displayPersonalProfileAtom,
} from "../../../../atoms/user/activeProfileAtom";
import { isActiveUserEngineerOrStudioManagerAtom } from "../../../../atoms/user/activeUserAtom";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { isAuthenticatedRoute } from "../../../../routes";
import { SCREENS } from "../../../../routes/screens";
import { useAppSelector, useLogout } from "../../../../store/hooks";
import User from "../../../../store/models/user";
import { isUserVerifiedAdminSelector } from "../../../../store/selectors/userInfoSelectors";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { accountSettingsModalOpenAtom } from "../../AccountSettingsModal/atoms";
import { DropdownNavMenu } from "../../DrowdownNavMenu/DropdownNavMenu";
import { NavAddStudioProfileButton } from "./components/NavAddStudioProfileButton/NavAddStudioProfileButton";
import { NavDropDownHelpButton } from "./components/NavDropDownHelpButton/NavDropDownHelpButton";
import {
  NAV_LIST_ITEM_VARIANT,
  NavDropDownListItem,
} from "./components/NavDropDownListItem/NavDropDownListItem";
import { NavEditServicesButton } from "./components/NavEditServicesButton/NavEditServicesButton";
import { ProfileRow } from "./components/ProfileRow/ProfileRow";
import "./NavProfileContent.css";

enum NavProfileContentItem {
  ADMIN_DASHBOARD,
  DASHBOARD,
  BOOKINGS,
  LIBRARY,
  PROFILE,
  SETTINGS,
  SEARCH,
  COMMUNITY,
  FAVORITES,
  ABOUT_US,
  PRICING,
}

export interface NavProfileContentProps {
  user: User | undefined;
  onClose: () => void;
}

const loggedInItems = [
  NavProfileContentItem.BOOKINGS,
  NavProfileContentItem.LIBRARY,
  NavProfileContentItem.PROFILE,
  NavProfileContentItem.SETTINGS,
  NavProfileContentItem.FAVORITES,
];

const engineerAndStudioManagerItems = [
  NavProfileContentItem.DASHBOARD,
  ...loggedInItems,
];

const adminItems = [NavProfileContentItem.ADMIN_DASHBOARD, ...loggedInItems];

export const NavProfileContent = ({
  user,
  onClose,
}: NavProfileContentProps) => {
  const { isMobile } = useMediaQueryBreakpoint();
  const setIsAccountSettingsModalOpen = useSetAtom(
    accountSettingsModalOpenAtom,
  );
  const { userStudios } = useAppSelector((state) => state.accountInfo);
  const isEngineerOrStudioManager = useAtomValue(
    isActiveUserEngineerOrStudioManagerAtom,
  );
  const isUserAdmin = useAppSelector(isUserVerifiedAdminSelector);
  const { pathname } = useLocation();
  const isOnAuthenticatedScreen = isAuthenticatedRoute(pathname);
  const redirectPath = isOnAuthenticatedScreen
    ? SCREENS.SIGNED_OUT_HOME_SCREEN
    : undefined;
  const logout = useLogout(redirectPath);
  const activeProfile = useAtomValue(activeProfileAtom);
  const activeStudioProfile = useAtomValue(activeStudioProfileAtom);

  const username = activeProfile?.username;
  const profilePath = activeStudioProfile
    ? getStudioScreenRoute(username ?? "")
    : getProfileScreenRoute(username ?? "");

  const displayPersonalProfile = useAtomValue(displayPersonalProfileAtom);

  const navProfileContentOptions = useMemo(
    () => ({
      [NavProfileContentItem.ADMIN_DASHBOARD]: {
        title: "Dashboard",
        path: SCREENS.ADMIN_DASHBOARD,
        trackingEvent: "clicked_admin_dashboard",
      },
      [NavProfileContentItem.DASHBOARD]: {
        title: "Dashboard",
        path: SCREENS.DASHBOARD,
        trackingEvent: "clicked_dashboard",
      },
      [NavProfileContentItem.BOOKINGS]: {
        title: "Bookings",
        path: SCREENS.PROJECTS_NO_TAB,
        trackingEvent: "clicked_bookings",
      },
      [NavProfileContentItem.LIBRARY]: {
        title: "My Library",
        path: SCREENS.MY_LIBRARY,
        trackingEvent: "clicked_library",
      },
      [NavProfileContentItem.PROFILE]: {
        title: "My Profile",
        path: profilePath,
        trackingEvent: "clicked_profile",
      },
      [NavProfileContentItem.SETTINGS]: {
        title: "Settings",
        path: SCREENS.SETTINGS_SCREEN,
        trackingEvent: "clicked_settings",
      },
      [NavProfileContentItem.SEARCH]: {
        title: "Explore Profiles",
        path: SCREENS.SEARCH,
        trackingEvent: "clicked_search",
      },
      [NavProfileContentItem.PRICING]: {
        title: "Pricing",
        path: SCREENS.ADD_SUBSCRIPTION,
        trackingEvent: "clicked_pricing",
      },
      [NavProfileContentItem.COMMUNITY]: {
        title: "Community",
        path: SCREENS.COMMUNITY,
        trackingEvent: "clicked_community",
      },
      [NavProfileContentItem.FAVORITES]: {
        title: "Favorites",
        path: SCREENS.DASHBOARD_FAVORITES,
        trackingEvent: "clicked_favorites",
      },
    }),
    [profilePath],
  );

  const defaultItems = [
    NavProfileContentItem.SEARCH,
    NavProfileContentItem.COMMUNITY,
    NavProfileContentItem.PRICING,
  ];

  const profileListItems = useMemo(() => {
    if (isUserAdmin) {
      return adminItems;
    }

    if (isEngineerOrStudioManager) {
      return engineerAndStudioManagerItems;
    }

    return loggedInItems;
  }, [isUserAdmin, isEngineerOrStudioManager]);

  return (
    <ul className="nav-profile-content">
      {user && (
        <>
          {displayPersonalProfile && (
            <li className="profile-row-li">
              <ProfileRow onClick={onClose} />
            </li>
          )}
          {Array.isArray(userStudios) &&
            userStudios.map((studio) => {
              return (
                <Fragment key={`studio-${studio.id}`}>
                  <li className="profile-row-li" key={`studio-${studio.id}`}>
                    <ProfileRow studio={studio} onClick={onClose} />
                  </li>
                  <hr />
                </Fragment>
              );
            })}
          {profileListItems.map((listItem) => {
            const { title, path, trackingEvent } =
              navProfileContentOptions[listItem];
            return listItem === NavProfileContentItem.SETTINGS ? (
              <NavDropDownListItem
                text={title}
                key={`profile-list-item-${title}`}
                variant={NAV_LIST_ITEM_VARIANT.BUTTON}
                onClick={() => {
                  emitAnalyticsTrackingEvent(trackingEvent, {});
                  onClose();
                  setIsAccountSettingsModalOpen(true);
                }}
              />
            ) : (
              <NavDropDownListItem
                key={`profile-list-item-${title}`}
                text={title}
                variant={NAV_LIST_ITEM_VARIANT.LINK}
                path={path}
                currentPath={pathname}
                onClick={() => {
                  emitAnalyticsTrackingEvent(trackingEvent, {});
                  onClose();
                }}
              />
            );
          })}
          <hr />
        </>
      )}
      {user && (
        <>
          {user.engineer && !user.engineer.deleted && (
            <NavEditServicesButton engineer={user.engineer} user={user} />
          )}
          <NavAddStudioProfileButton onClose={onClose} />
          <hr />
        </>
      )}
      {isMobile && !user && <DropdownNavMenu isMobile />}
      {defaultItems.map((listItem) => {
        const { title, path, trackingEvent } =
          navProfileContentOptions[listItem];
        return (
          <NavDropDownListItem
            key={`default-list-item-${listItem}`}
            text={title}
            variant={NAV_LIST_ITEM_VARIANT.LINK}
            path={path}
            currentPath={pathname}
            onClick={() => {
              emitAnalyticsTrackingEvent(trackingEvent, {});
              onClose();
            }}
          />
        );
      })}
      <NavDropDownHelpButton onClose={onClose} />
      {user && (
        <>
          <hr />
          <NavDropDownListItem
            text="Log Out"
            variant={NAV_LIST_ITEM_VARIANT.BUTTON}
            onClick={logout}
          />
        </>
      )}
    </ul>
  );
};
