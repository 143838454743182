import { Box } from "@mui/material";
import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { localServiceCoverPhotoAtom } from "../../../../atoms/profileScreenEdit";
import { useEngineerCanHostServices } from "../../../../hooks/useEngineerCanHostServices";
import { SetUpCartPayload } from "../../../../store/actions/shoppingCart";
import { createTransaction } from "../../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import Engineer from "../../../../store/models/engineer";
import {
  ProjectType,
  ProjectTypeOptions,
} from "../../../../store/models/project";
import { RecordingService } from "../../../../store/models/recording";
import Service from "../../../../store/models/service";
import User from "../../../../store/models/user";
import { getTransactionBookingScreenRoute } from "../../../../store/utils/routeGetters";
import { BookingParameters } from "../../../../store/utils/transactions";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { EngineerServicesWrapper } from "../../../components/EngineerServicesWrapper/EngineerServicesWrapper";
import { ServiceCardV2Wrapper } from "../../../components/ServiceCard/ServiceCardV2/ServiceCardV2Wrapper";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { FormType } from "../constants";
import { AddServiceCard } from "./AddServiceCard";
import { ServicesFormModal } from "./ServicesFormModal";
import { ServicesTabContainer } from "./ServicesTab.styles";

interface EngineerServiceCardsProps {
  combinedServices: (Service | RecordingService)[];
  userData: User;
  editMode: boolean;
  engineer: Engineer;
  userOnOwnProfile: boolean;
  editServiceType?: ProjectType;
  setEditServiceType: (serviceType?: ProjectType) => void;
  addServiceType?: ProjectType;
  setAddServiceType: (serviceType?: ProjectType) => void;
}

export const EngineerServiceCards = ({
  combinedServices,
  userData,
  editMode,
  engineer,
  userOnOwnProfile,
  editServiceType,
  setEditServiceType,
  addServiceType,
  setAddServiceType,
}: EngineerServiceCardsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { localUTMParams } = useAppSelector((state) => state.accountInfo);
  const canHostServices = useEngineerCanHostServices(engineer);
  const promoCode = useAppSelector(
    (state) => state.marketingDataStore.appliedPromoCode,
  );
  const { isLoading: isGeneratingTransaction } = useAppSelector(
    (state) => state.transactionStore,
  );
  const setLocalServiceCoverPhoto = useSetAtom(localServiceCoverPhotoAtom);
  const handleGenerateTransactionAndNavigate = async (
    serviceType: ProjectType,
  ) => {
    const transaction = await dispatch(createTransaction()).unwrap();
    const bookingParameters: BookingParameters = {
      engineerId: engineer.id,
      engineerUserId: userData.id,
      activeServiceType: serviceType,
      transactionId: +transaction.id, // transaction.id is a number, not sure why it's typed as string
      activeServiceTypeProjectIds: [],
    };

    emitAnalyticsTrackingEvent("clicked_book_now", {
      ...localUTMParams,
      transaction_id: bookingParameters.transactionId,
      engineer_user_id: bookingParameters.engineerUserId,
      service_type: bookingParameters.activeServiceType,
    });

    navigate(
      getTransactionBookingScreenRoute(
        transaction.code,
        dispatch,
        bookingParameters,
        serviceType === ProjectType.RECORDING
          ? ({
              pendingSessionData: [],
            } as SetUpCartPayload)
          : undefined,
      ),
    );
  };

  const handleCTAClick = ({
    service_type: serviceType,
  }: {
    service_type: ProjectType;
  }) => {
    if (editMode) {
      setEditServiceType(serviceType);
      return;
    }
    void handleGenerateTransactionAndNavigate(serviceType);
  };

  const remainingServiceOptions = getRemainingServiceOptions(combinedServices);

  const renderServicesCard = () => {
    if (combinedServices.length === 0) {
      return (
        <>
          <AddServiceCard
            onAddService={() => {
              setAddServiceType(remainingServiceOptions[0].value);
            }}
            aspectRatio={EMPTY_CARD_ASPECT_RATIO}
          />
          {Array.from({ length: 2 }).map((_, idx) => (
            <Box
              key={idx}
              sx={(theme) => ({
                border: `1px dashed ${theme.palette.customColor.disabledTextPrimaryColor}`,
                aspectRatio: EMPTY_CARD_ASPECT_RATIO,
                borderRadius: theme.border.radius.md,
              })}
            ></Box>
          ))}
        </>
      );
    }

    return (
      <>
        {combinedServices.map((service) => {
          return (
            <ServiceCardV2Wrapper
              key={`${service.service_type}-${service.id}`}
              service={service}
              userOnOwnProfile={userOnOwnProfile}
              engineerUsername={userData.username}
              handleClick={handleCTAClick}
              editMode={editMode}
              promoCode={promoCode}
              disabled={isGeneratingTransaction}
              isLoading={isGeneratingTransaction}
            />
          );
        })}
        {editMode && remainingServiceOptions.length > 0 && canHostServices && (
          <AddServiceCard
            onAddService={() => {
              setAddServiceType(remainingServiceOptions[0].value);
            }}
          />
        )}
      </>
    );
  };

  return (
    <EngineerServicesWrapper
      combinedServices={combinedServices}
      userData={userData}
      engineer={engineer}
      userOnOwnProfile={userOnOwnProfile}
      editServiceType={editServiceType}
      addServiceType={addServiceType}
      setAddServiceType={setAddServiceType}
      remainingServiceOptions={remainingServiceOptions}
    >
      <ServicesTabContainer>{renderServicesCard()}</ServicesTabContainer>

      {editServiceType && (
        <ServicesFormModal
          isOpen={true}
          setOpen={() => {
            setEditServiceType(undefined);
          }}
          userData={userData}
          engineer={engineer}
          selectedServiceType={editServiceType}
          existingServices={combinedServices}
          formType={FormType.EDIT}
          onChangeService={(selectedProjectType: OptionType) => {
            setEditServiceType(selectedProjectType.value);
          }}
        />
      )}
      {addServiceType && (
        <ServicesFormModal
          isOpen={true}
          setOpen={() => {
            setAddServiceType(undefined);
            setLocalServiceCoverPhoto(undefined);
          }}
          userData={userData}
          engineer={engineer}
          selectedServiceType={addServiceType}
          existingServices={combinedServices}
          formType={FormType.CREATE}
          onChangeService={(selectedProjectType: OptionType) => {
            setAddServiceType(selectedProjectType.value);
          }}
        />
      )}
    </EngineerServicesWrapper>
  );
};

const NOT_FOUND_INDEX = -1;
const EMPTY_CARD_ASPECT_RATIO = "1.03";

const getRemainingServiceOptions = (
  existingServices: (Service | RecordingService)[],
) => {
  return ProjectTypeOptions.filter(
    (option) =>
      existingServices.findIndex(
        (service) => service.service_type === option.value,
      ) === NOT_FOUND_INDEX,
  );
};
