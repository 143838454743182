import { MusoAuthButtons } from "./MusoAuthButtons";
import "./MusoCard.css";

export const MusoCard = () => {
  return (
    <div className="muso-card-container">
      <MusoAuthButtons />
    </div>
  );
};
