export enum AutoCompleteTypeEnum {
  USER = "User",
  PROJECT = "Project",
  SCHEDULED_PROJECT = "ScheduledProject",
  STUDIO_ROOM = "StudioRoom",
  STUDIO = "Studio",
  LOCATION = "location",
  ARTIST_CREDIT = "artist_credit",
  GENRE = "genre",
  SERVICE_TYPE = "service_type",
  EQUIPMENT = "equipment",
}

export interface AutocompleteBase {
  type: AutoCompleteTypeEnum;
}

export interface ProfileSuggestion extends AutocompleteBase {
  type: AutoCompleteTypeEnum.USER | AutoCompleteTypeEnum.STUDIO;
  username: string;
  display_name: string;
  path: null | string;
}

export interface LocationSuggestion extends AutocompleteBase {
  type: AutoCompleteTypeEnum.LOCATION;
  label: string;
  place_id: string;
  latitude: string;
  longitude: string;
  location_string: string;
}

export interface ArtistSuggestion extends AutocompleteBase {
  type: AutoCompleteTypeEnum.ARTIST_CREDIT;
  label: string;
}

export interface GenreAndServiceTypeSuggestion extends AutocompleteBase {
  type: AutoCompleteTypeEnum.GENRE | AutoCompleteTypeEnum.SERVICE_TYPE;
  label: string;
  value: number;
}

export interface BookingSuggestion extends AutocompleteBase {
  type:
    | AutoCompleteTypeEnum.USER
    | AutoCompleteTypeEnum.STUDIO
    | AutoCompleteTypeEnum.STUDIO_ROOM;
  username: string;
  display_name: string;
  path: null | string;
}

export interface ProjectSuggestion extends AutocompleteBase {
  type: AutoCompleteTypeEnum.PROJECT | AutoCompleteTypeEnum.SCHEDULED_PROJECT;
  label: string;
  value: number;
  service_type: number;
  primary_key: number;
  scheduled_project_id: number | null;
}

export interface EquipmentSuggestion extends AutocompleteBase {
  type: AutoCompleteTypeEnum.EQUIPMENT;
  id: number;
  label: string;
  equipment_type: number;
}

export type AutocompleteSuggestion =
  | ProfileSuggestion
  | LocationSuggestion
  | ArtistSuggestion
  | EquipmentSuggestion
  | GenreAndServiceTypeSuggestion;
