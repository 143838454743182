// All basic typography styles based on the Figma Designs
import { TypographyOwnProps } from "@mui/material";

export enum TextStyleVariant {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
  S1 = "s1",
  S2 = "s2",
  S2_REGULAR = "s2_regular",
  S3 = "s3",
  S4 = "s4",
  P1 = "p1",
  P2 = "p2",
  P3 = "p3",
  P4 = "p4",
  CAPTION = "caption",
}

// All possible HTML elements
export enum TextComponent {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
  P = "p",
}

// Mapping of Style Variants to HTML elements
export const TEXT_COMPONENT = {
  [TextStyleVariant.H1]: TextComponent.H1,
  [TextStyleVariant.H2]: TextComponent.H2,
  [TextStyleVariant.H3]: TextComponent.H3,
  [TextStyleVariant.H4]: TextComponent.H4,
  [TextStyleVariant.H5]: TextComponent.H5,
  [TextStyleVariant.H6]: TextComponent.H6,
  [TextStyleVariant.S1]: TextComponent.P,
  [TextStyleVariant.S2]: TextComponent.P,
  [TextStyleVariant.S2_REGULAR]: TextComponent.P,
  [TextStyleVariant.S3]: TextComponent.P,
  [TextStyleVariant.S4]: TextComponent.P,
  [TextStyleVariant.P1]: TextComponent.P,
  [TextStyleVariant.P2]: TextComponent.P,
  [TextStyleVariant.P3]: TextComponent.P,
  [TextStyleVariant.P4]: TextComponent.P,
};

// Map TextVariants to MUI Variants
export const MUI_TEXT_VARIANTS: Record<
  TextStyleVariant,
  TypographyOwnProps["variant"]
> = {
  [TextStyleVariant.H1]: "h1",
  [TextStyleVariant.H2]: "h2",
  [TextStyleVariant.H3]: "h3",
  [TextStyleVariant.H4]: "h4",
  [TextStyleVariant.H5]: "h5",
  [TextStyleVariant.H6]: "h6",
  [TextStyleVariant.S1]: "subtitle1",
  [TextStyleVariant.S2]: "subtitle2",
  [TextStyleVariant.S2_REGULAR]: "subtitle2Regular",
  [TextStyleVariant.S3]: "subtitle3",
  [TextStyleVariant.S4]: "subtitle4",
  [TextStyleVariant.P1]: "body1",
  [TextStyleVariant.P2]: "body2",
  [TextStyleVariant.P3]: "body3",
  [TextStyleVariant.P4]: "body4",
  [TextStyleVariant.CAPTION]: "caption",
};

// Defining the color enum here allows for it to be used in multiple places without circular dependencies
export enum TextColor {
  PRIMARY = "var(--text-primary-color)",
  SECONDARY = "var(--text-secondary-color)",
  TERTIARY = "var(--text-tertiary-color)",
  INVERT = "var(--text-invert-color)",
  WHITE = "var(--white-0)",
  BLACK = "var(--black-0)",
  LIGHT_GRAY = "var(--gray-200)",
  ERROR = "var(--text-error-color)",
  PRIMARY_NO_DARK_THEME = "var(--gray-950)",
  SUCCESS = "var(--success-color)",
  FAILED = "var(--failed-color)",
}
