export const ProjectTypes = {
  "Single Song/Track": "Single Song/Track",
  "Album (EP)": "Album (EP)",
  "Album (LP)": "Album (LP)",
};

export const TargetProjectEndDates = {
  "Next 7 Days": "Next 7 Days",
  "Next 30 Days": "Next 30 Days",
  "Next 30-90 Days": "Next 30-90 Days",
  "Next 90+ Days": "Next 90+ Days",
  "Not Sure": "Not Sure",
};
