import { Box } from "@mui/material";
import {
  DefaultServicePhotoContainer,
  ServiceCardImg,
} from "./ServiceCardV2.styles";
import { PlayButtonOverlay } from "./PlayButtonOverlay";
import { defaultIconServiceMapping } from "../../../../constants/googleStorage";
import { ImgHTMLAttributes } from "react";
import EntityVideo from "../../../../store/models/entityVideo";
import { ProjectType } from "../../../../store/models/project";

interface ServiceCardImageProps {
  featuredVideo: EntityVideo | undefined | null;
  imageProps: ImgHTMLAttributes<HTMLImageElement>;
  openVideoPreviewModal: () => void;
  serviceType: ProjectType;
  userOnOwnProfile: boolean;
}

export const ServiceCardImage = ({
  featuredVideo,
  imageProps,
  openVideoPreviewModal,
  serviceType,
  userOnOwnProfile,
}: ServiceCardImageProps) => {
  const canOpenVideoPlayingModal = Boolean(
    featuredVideo &&
      !featuredVideo.deleted &&
      (userOnOwnProfile || featuredVideo.hls_path),
  );

  const handleVideoPreviewModal = () => {
    if (!canOpenVideoPlayingModal) return;
    openVideoPreviewModal();
  };

  return imageProps.src ? (
    <Box
      onClick={handleVideoPreviewModal}
      sx={{
        cursor: canOpenVideoPlayingModal ? "pointer" : "default",
        position: "relative",
      }}
    >
      <ServiceCardImg {...imageProps} />
      {canOpenVideoPlayingModal && <PlayButtonOverlay />}
    </Box>
  ) : (
    <DefaultServicePhotoContainer
      onClick={handleVideoPreviewModal}
      sx={{ cursor: canOpenVideoPlayingModal ? "pointer" : "default" }}
    >
      <img
        src={defaultIconServiceMapping[serviceType]}
        style={{ height: "2rem", width: "100%" }}
      />
      {canOpenVideoPlayingModal && <PlayButtonOverlay />}
    </DefaultServicePhotoContainer>
  );
};
