import { isProjectInReviewStepOrBeyond } from "../../../../utils/projectUtils";
import {
  APIMasteringProject,
  APIMixingProject,
  Project,
  ProjectById,
} from "../../project";

const isValidProjectForPlaylist = (
  project: Project | ProjectById,
): project is Project => {
  if ("step" in project) {
    return isProjectInReviewStepOrBeyond(
      project.service_type,
      (project as Project).step,
    );
  }
  if ("mastering_project" in project) {
    const masteringProject = (project as ProjectById)
      ?.mastering_project as APIMasteringProject;
    return isProjectInReviewStepOrBeyond(
      project.service_type,
      masteringProject?.step ?? 0,
    );
  }
  if ("mixing_project" in project) {
    const mixingProject = (project as ProjectById)
      ?.mixing_project as APIMixingProject;
    return isProjectInReviewStepOrBeyond(
      project.service_type,
      mixingProject?.step || 0,
    );
  }
  return false;
};

export default isValidProjectForPlaylist;
