import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import {
  MutateProfileProps,
  postUpdateProfile,
} from "../../api/accounts/user/updateProfile";
import { displayEngineEarsError } from "../../api/helpers";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import { activeUserIdAtom } from "../../atoms/user/activeUserAtom";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { setUser, storeLocalUTMParams } from "../../store/actions/accountInfo";
import { EngineEarsError, ErrorsAction } from "../../store/actions/errorStore";
import { useAppDispatch } from "../../store/hooks";
import User from "../../store/models/user";
import { callAllHandlersWithData } from "../../utils/utils";

export type MutateSocialMediaProps = Pick<
  MutateProfileProps,
  | "soundcloud_username"
  | "instagram_username"
  | "twitter_username"
  | "facebook_username"
  | "twitch_username"
  | "tiktok_username"
  | "youtube_username"
  | "spotify_username"
  | "apple_username"
  | "amazon_username"
  | "tidal_username"
>;
export type MutateLocationProps = Pick<
  MutateProfileProps,
  "country" | "region" | "city"
>;

export const useUpdateUserProfile = (
  onSuccess?: (user: User) => void,
  isUtm = false,
) => {
  const queryClient = useQueryClient();
  const token = useAtomValue(tokenAtom);
  const activeUserId = useAtomValue(activeUserIdAtom);
  const dispatch = useAppDispatch();
  const defaultOnSuccess = async (updatedUser: User) => {
    queryClient.setQueryData(
      [QUERY_KEYS.GET_USER_PROFILE, updatedUser?.username],
      updatedUser,
    );
    if (isUtm) {
      dispatch(setUser(updatedUser));
      return;
    }
    if (activeUserId === updatedUser.id) {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.LOAD_USER, token],
      });
    }
    return updatedUser;
  };
  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_USER_PROFILE, token],
    mutationFn: async (body: MutateProfileProps) => {
      return await postUpdateProfile(body).then((user) => {
        if (user.utm_source) {
          dispatch(
            storeLocalUTMParams({
              utm_source: user.utm_source,
              utm_medium: user.utm_medium,
              utm_campaign: user.utm_campaign,
              utm_content: user.utm_content,
              utm_term: user.utm_term,
            }),
          );
        }
        return user;
      });
    },
    onSuccess: callAllHandlersWithData(defaultOnSuccess, onSuccess),
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error, true);
      return { errors: error } as ErrorsAction;
    },
  });
};
