import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { useLayoutEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { artistOnboardingSurveyFormModalAtom } from "../../../atoms/modals/artistOnboardingSurveyFormModalAtom";
import { AuthFlows } from "../../../constants/authSteps";
import { isAdmin } from "../../../hooks/useIsAdmin";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { DISCIPLINE_TYPE, getUserDisciplines } from "../../../hooks/user";
import { useAppSelector } from "../../../store/hooks";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import {
  getAddSubscriptionRoute,
  getAdminDashboardRoute,
  getDashboardRoute,
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  AddSubscriptionUpsellContainer,
  CloseButton,
} from "./AuthModal.styles";
import { authModalOpenAtom } from "../../../atoms/location/authModalOpenAtom";
import { currentFlowAtom } from "../../../atoms/location/currentFlowAtom";
import { isActiveUserArtistAtom } from "../../../atoms/user/activeUserAtom";

export const AccountCreateComplete = () => {
  const theme = useTheme();
  const flow = useAtomValue(currentFlowAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const { user, userStudios } = useAppSelector((state) => state.accountInfo);
  const username = getDisplayableNameForUser(user);
  const isCurrentUserArtist = useAtomValue(isActiveUserArtistAtom);
  const { isDesktop } = useMediaQueryBreakpoint();
  const [hasShownHubspot, setHasShownHubspot] = useState(false);
  const showArtistForm = useSetAtom(artistOnboardingSurveyFormModalAtom);
  const userDisciplines = getUserDisciplines(user);
  const showSubscriptionUpsell = userDisciplines?.includes(
    DISCIPLINE_TYPE.ENGINEER,
  );

  const redirectPath = useMemo(() => {
    if (!user) return { to: getDashboardRoute() };

    if (flow === AuthFlows.STUDIO_OWNER) {
      return { to: getStudioScreenRoute(userStudios[0]?.username) };
    } else if (flow === AuthFlows.AANDR) {
      const route = isAdmin(user)
        ? getAdminDashboardRoute()
        : getDashboardRoute();
      return { to: route };
    } else {
      return {
        to: getProfileScreenRoute(user.username),
        state: {
          showTutorial: true,
          showEngineerPromoListModal: isCurrentUserArtist,
        },
      };
    }
  }, [flow, user, userStudios, isCurrentUserArtist]);

  useLayoutEffect(() => {
    if (!hasShownHubspot && user?.artist && !user.artist.deleted) {
      setHasShownHubspot(true);
      showArtistForm(true);
    }
  }, [hasShownHubspot, setHasShownHubspot, user, showArtistForm]);

  if (!user)
    return (
      <Box>
        <Text variant={TextStyleVariant.H5}>
          Your profile has been created!
        </Text>
      </Box>
    );

  return (
    <>
      {isDesktop && (
        <CloseButton>
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            color={theme.palette.text.primary}
            onClick={() => {
              setAuthModalOpen(false);
            }}
          />
        </CloseButton>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "32px",
          padding: "40px",

          ".MuiTypography-root": {
            textAlign: "center",
          },
        }}
      >
        <Text variant={TextStyleVariant.H5}>
          Congratulations {username}, your profile has been created!
        </Text>
        {showSubscriptionUpsell && (
          <AddSubscriptionUpsellContainer>
            <Text
              variant={TextStyleVariant.P1}
              weight={TEXT_WEIGHT.BOLD}
              style={{ color: theme.palette.standardColor.DeepBlue[800] }}
            >
              Upgrade to{" "}
              <Link
                to={getAddSubscriptionRoute()}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setAuthModalOpen(false);
                }}
              >
                <Text
                  variant={TextStyleVariant.P1}
                  weight={TEXT_WEIGHT.BOLD}
                  style={{
                    display: "inline",
                    color: theme.palette.standardColor.DeepBlue[800],
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  Platinum
                </Text>
              </Link>
              <Text
                variant={TextStyleVariant.P1}
                weight={TEXT_WEIGHT.BOLD}
                style={{
                  color: theme.palette.standardColor.DeepBlue[800],
                  display: "inline",
                }}
              >
                {" "}
                and save on service fees!
              </Text>
              <Link
                to={getAddSubscriptionRoute()}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setAuthModalOpen(false);
                }}
              >
                {" "}
                <Text
                  variant={TextStyleVariant.P2}
                  style={{
                    display: "inline",
                    color: theme.palette.standardColor.DeepBlue[800],
                    textDecoration: "underline",
                  }}
                >
                  learn more
                </Text>
              </Link>
            </Text>
          </AddSubscriptionUpsellContainer>
        )}
        <Link
          style={{ textDecoration: "none" }}
          onClick={() => {
            emitAnalyticsTrackingEvent("auth_account_create_complete", {});
            setAuthModalOpen(false);
          }}
          {...redirectPath}
        >
          <Button>Go to profile</Button>
        </Link>
      </Box>
    </>
  );
};
