import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetUserProfile } from "../../../../hooks/profileScreenHooks/useGetUserProfile";
import { useIsVisitingOwnProfile } from "../../../../hooks/profileScreenHooks/useIsVisitingOwnProfile";
import { useEngineerCanHostServices } from "../../../../hooks/useEngineerCanHostServices";
import { useGetEngineerServices } from "../../../../hooks/useGetEngineerServices";
import {
  usePaginatedReviews,
  useUserReviews,
} from "../../../../hooks/useUserReviews";
import { SCREENS } from "../../../../routes/screens";
import {
  downloadReviewsArgs,
  downloadReviewStats,
} from "../../../../store/actions/stats";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { ButtonVariant } from "../../../core-ui/components/Button/Button";
import { SoundWaveLoader } from "../../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  GenerateBookingServiceGroups,
  ServiceTypePopConfirm,
} from "../../../screens/ProfileScreen/components/ServiceTypePopConfirm/ServiceTypePopConfirm";
import { ProfileScreenParamKeys } from "../../../screens/ProfileScreen/utils";
import { NoReviewsButton } from "./NoReviewsButton";
import { ReviewsListV2 } from "./ReviewsListV2";
import { useEngineerHasPublicRecordingService } from "../../../../hooks/profileScreenHooks/engineerGenerateBookingHooks";
import { FixedMinWidthButton } from "../../../core-ui/components/Button/FixedMinWidthButton";
import { useHandleCreateTransaction } from "../../../../hooks/useHandleCreateTransaction";
import { TRACKING_EVENTS_NAME } from "../../../../constants/trackingEvents";
import { useAtomValue, useSetAtom } from "jotai";
import { displayGenerateBookingInfoAtom } from "../../../../atoms/modals/generatedBookingInfoModal";
import { editMode } from "../../../../atoms/profileScreenEdit";

export const ReviewsListV2Wrapper = () => {
  const order_by = "-created";
  const dispatch = useAppDispatch();
  const { username } = useParams<{ username: string }>();
  const { data: userDataForProfileScreen, isPending: isLoadingUserData } =
    useGetUserProfile(username);
  const { user: userMe, localUTMParams } = useAppSelector(
    (state) => state.accountInfo,
  );
  const navigate = useNavigate();
  const userOnOwnProfile = useMemo(() => {
    return userMe?.username === username;
  }, [userMe?.username, username]);
  const [, setSearchParams] = useSearchParams();
  const setSelectedTab = useCallback(
    (value: string) => {
      setSearchParams((prev) => {
        return { ...prev, [ProfileScreenParamKeys.SelectedTab]: value };
      });
    },
    [setSearchParams],
  );
  const setIsOpenGenerateBookingInfo = useSetAtom(
    displayGenerateBookingInfoAtom,
  );
  const isEditMode = useAtomValue(editMode);

  const reviews = usePaginatedReviews(
    userDataForProfileScreen?.artist,
    userDataForProfileScreen?.engineer,
  );

  const reviewsState = useUserReviews(
    userDataForProfileScreen?.artist,
    userDataForProfileScreen?.engineer,
  );

  const isVisitingOwnProfile = useIsVisitingOwnProfile();

  const { data: engineerServicesData, isFetching: isFetchingServices } =
    useGetEngineerServices(userDataForProfileScreen?.engineer?.id ?? 0);

  const engineerHasServices = Boolean(engineerServicesData?.length);

  const engineerHasPublicRecordingService =
    useEngineerHasPublicRecordingService(
      engineerServicesData,
      userDataForProfileScreen?.id,
    );

  const isEngineer = useMemo(() => {
    return Boolean(
      userDataForProfileScreen?.engineer &&
        !userDataForProfileScreen?.engineer?.deleted,
    );
  }, [userDataForProfileScreen?.engineer]);

  const engineerCanHostServices = useEngineerCanHostServices(
    userDataForProfileScreen?.engineer,
  );
  const { isCreatingTransaction, createTransactionAndNavigate } =
    useHandleCreateTransaction(
      localUTMParams,
      userDataForProfileScreen,
      userDataForProfileScreen?.engineer,
    );

  const {
    loading: loading,
    paginating: paginating,
    current_page: currentPage,
    total_pages: totalPages,
    ratings_count: ratingsCount,
    average_rating: averageRating,
  } = reviewsState || {
    paginating: false,
    loading: false,
    current_page: 0,
    total_pages: 0,
    average_rating: 0,
  };

  useEffect(() => {
    if (!userDataForProfileScreen) return;
    const reviewObject: downloadReviewsArgs = {
      page: 1,
      order_by,
    };
    if (isEngineer) {
      reviewObject.engineer_id = userDataForProfileScreen.engineer?.id;
    } else if (
      userDataForProfileScreen.artist &&
      !userDataForProfileScreen.artist.deleted
    ) {
      reviewObject.artist_id = userDataForProfileScreen.artist.id;
    }
    void dispatch(downloadReviewStats(reviewObject));
  }, [
    dispatch,
    userDataForProfileScreen?.artist?.id,
    userDataForProfileScreen?.engineer?.id,
  ]);

  const loadMore = useCallback(() => {
    if (currentPage >= totalPages) return;
    if (!userDataForProfileScreen) return;
    const reviewObject: downloadReviewsArgs = {
      page: currentPage + 1,
      order_by,
    };
    if (isEngineer) {
      reviewObject.engineer_id = userDataForProfileScreen?.engineer?.id;
    } else if (
      userDataForProfileScreen.artist &&
      !userDataForProfileScreen.artist.deleted
    ) {
      reviewObject.artist_id = userDataForProfileScreen.artist.id;
    }
    if (!reviewObject.artist_id && !reviewObject.engineer_id) {
      return;
    }
    emitAnalyticsTrackingEvent("clicked_loading_more_user_reviews", {
      user_profile: userDataForProfileScreen?.username,
    });

    void dispatch(downloadReviewStats(reviewObject));
  }, [currentPage, totalPages, userDataForProfileScreen, dispatch]);

  const buttonText = () => {
    if (!isEngineer) {
      return "Explore profiles";
    }
    if (engineerHasServices && reviews.length === 0) {
      return "Generate your first booking";
    }
    if (engineerCanHostServices && !engineerHasServices) {
      return "Add a service first to begin booking";
    }
    return "";
  };

  const handleNoReviewsButtonClick = (
    serviceGenerated?: GenerateBookingServiceGroups,
  ) => {
    if (!isEngineer) {
      emitAnalyticsTrackingEvent("clicked_explore_profiles_from_review", {});

      navigate(SCREENS.SEARCH);
      return;
    }

    if (
      (engineerHasPublicRecordingService &&
        engineerServicesData?.length == 1) ||
      serviceGenerated === GenerateBookingServiceGroups.RECORDING
    ) {
      emitAnalyticsTrackingEvent(
        TRACKING_EVENTS_NAME.USER_CLICK_GENERATE_BOOKING,
        {
          engineer_id: userDataForProfileScreen?.engineer?.id,
          engineer_user_id: userDataForProfileScreen?.id,
        },
      );
      void createTransactionAndNavigate(true);
      return;
    }
    if (engineerHasServices) {
      setIsOpenGenerateBookingInfo(true);
      return;
    }

    addServicesClick();
  };

  const addServicesClick = () => {
    if (!isEngineer || !userOnOwnProfile) {
      return;
    }
    setSelectedTab("services");
  };

  const ReviewButton = () => {
    if (!userOnOwnProfile) {
      return null;
    }
    if (
      isEngineer &&
      engineerServicesData &&
      engineerHasPublicRecordingService &&
      engineerServicesData.length > 1
    ) {
      return (
        <ServiceTypePopConfirm
          variant={ButtonVariant.OUTLINED}
          loading={isCreatingTransaction}
          onClick={handleNoReviewsButtonClick}
        />
      );
    }

    return (
      <FixedMinWidthButton
        variant={ButtonVariant.OUTLINED}
        loading={isCreatingTransaction}
        onClick={() => handleNoReviewsButtonClick()}
      >
        {buttonText()}
      </FixedMinWidthButton>
    );
  };

  if (isLoadingUserData || loading || isFetchingServices) {
    return <SoundWaveLoader height={100} width={100} />;
  }

  if (reviews.length === 0 && isVisitingOwnProfile && isEditMode) {
    return <NoReviewsButton>{ReviewButton()}</NoReviewsButton>;
  }

  return (
    <ReviewsListV2
      averageRating={averageRating}
      ratingsCount={ratingsCount}
      reviews={reviews}
      showMore={loadMore}
      disabled={currentPage >= totalPages}
      loading={loading || paginating}
      totalPages={totalPages}
    />
  );
};
