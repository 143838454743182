import { useSetAtom } from "jotai";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { recommendedEngineersListAtom } from "../../atoms/recommendedEngineersListAtom";
import { updateStudioRoomSearchPage } from "../../store/actions/studioRoomSearch";
import { updateUserSearchPage } from "../../store/actions/userSearch";
import { useAppDispatch } from "../../store/hooks";
import { parseUrlQueryParams } from "../../utils/searchFiltersUtils";
import {
  ParamKeysAffectedByRecommendedEngineers,
  SearchFiltersQueryParams,
  SearchScreenParamKeys,
} from "./utils";

export interface updateSearchFiltersProps {
  updatedSearchQueryObject: SearchFiltersQueryParams;
  newPathname?: string;
  resetPagination?: boolean;
}

export interface updateSearchFiltersProps {
  updatedSearchQueryObject: SearchFiltersQueryParams;
  newPathname?: string;
  resetPagination?: boolean;
}

export const useUpdateSearchFilters = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const setRecommendedEngineersList = useSetAtom(recommendedEngineersListAtom);

  return ({
    updatedSearchQueryObject,
    newPathname,
    resetPagination = false,
  }: updateSearchFiltersProps) => {
    const parsedQueryParams = parseUrlQueryParams();
    const updatedQueryParamsObject = {
      ...parsedQueryParams,
      ...updatedSearchQueryObject,
    };

    if (resetPagination) {
      dispatch(updateUserSearchPage(1));
      dispatch(updateStudioRoomSearchPage(1));

      const paramsAffectingRecommendedEngineers = Object.entries(
        updatedQueryParamsObject,
      ).filter(([key, value]) => {
        return (
          key !== SearchScreenParamKeys.RecommendedEngineers &&
          ParamKeysAffectedByRecommendedEngineers.includes(
            key as SearchScreenParamKeys,
          ) &&
          Boolean(value)
        );
      });

      // If all params affecting recommended engineers are cleared, remove the recommended engineers param, i.e make it true
      // Otherwise, set the recommended engineers param to false
      if (
        !Object.keys(updatedSearchQueryObject).includes(
          SearchScreenParamKeys.RecommendedEngineers,
        )
      ) {
        if (paramsAffectingRecommendedEngineers.length === 0) {
          delete updatedQueryParamsObject[
            SearchScreenParamKeys.RecommendedEngineers
          ];
        } else {
          updatedQueryParamsObject[SearchScreenParamKeys.RecommendedEngineers] =
            false;
          setRecommendedEngineersList([]);
        }
      }
    }

    const updatedQueryParamsString = queryString.stringify(
      updatedQueryParamsObject,
      { skipNull: true, skipEmptyString: true },
    );
    navigate({
      pathname: newPathname,
      search: updatedQueryParamsString,
    });

    if (resetPagination) {
      dispatch(updateUserSearchPage(1));
      dispatch(updateStudioRoomSearchPage(1));
    }
  };
};
