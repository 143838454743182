import { Components, Theme } from "@mui/material";

/**
 * This theme is used in the MUI baseTheme to style the buttons globally.
 */
export const MuiButtonTheme: Components<Theme>["MuiButton"] = {
  styleOverrides: {
    root: ({ theme, children }) => {
      let isIconOnly = true;
      if (Array.isArray(children)) {
        children.forEach((child) => {
          if (typeof child === "string") {
            isIconOnly = false;
          }
        });
      }
      return {
        boxShadow: "none",
        MozBoxSizing: "border-box",
        WebkitBoxSizing: "border-box",
        boxSizing: "border-box",
        fontFamily: "inherit",
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1,
        letterSpacing: 0,
        minHeight: "44px",
        minWidth: "fit-content",
        padding: theme.spacing(1.5, 2), // 12px 16px
        textTransform: "none",

        "&:hover": {
          boxShadow: "none",
        },

        "& .MuiButton-icon > svg": {
          fontSize: theme.typography.htmlFontSize,
          marginRight: theme.spacing(0.5), // 4px
        },

        "&.MuiButton-outlined": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.customColor.buttonOutlinedColorHover,
          },
        },

        // override the endIcon margins if the button has no text
        "& .MuiButton-endIcon": {
          margin: isIconOnly ? 0 : "0px -4px 0px 8px",
          svg: {
            marginRight: isIconOnly ? 0 : "4px",
          },
        },

        /**
         * By default, there is padding between the icon and text unless a <p> tag is explicitly used.
         * This style ensures that if a <p> tag is used, there is space between the icon and the text.
         */
        p: {
          margin: theme.spacing(0, 1),
        },
      };
    },
  },
};
