import { useQuery } from "@tanstack/react-query";
import fetchAutocomplete from "../../requests/autocomplete/fetchAutocomplete";
import fetchMapAutocomplete from "../../requests/autocomplete/fetchMapAutocomplete";

interface Params {
  term: string;
  enabled?: boolean;
  mapView?: boolean;
  mapDataOnly?: boolean;
}

const useAutocompleteSuggestions = ({
  term,
  enabled = false,
  mapView = false,
  mapDataOnly = false,
}: Params) => {
  return useQuery({
    queryKey: [
      "autocomplete",
      term,
      `${mapView}:mapview`,
      `${mapDataOnly}:mapDataOnly`,
    ],
    queryFn: async () => {
      return mapView
        ? fetchMapAutocomplete({ searchQuery: term, mapDataOnly })
        : fetchAutocomplete(term);
    },
    enabled: Boolean(term) && enabled,
  });
};
export default useAutocompleteSuggestions;
