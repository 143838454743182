import { useSetAtom } from "jotai";
import { setPowerControlKeyData } from "./atoms";
import { POWER_USER_CONTROLS_LABEL, PowerUserControlHookData } from "./types";
import { useKeyPress } from "ahooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { datadogLogs } from "@datadog/browser-logs";
import { useEffect } from "react";

/**
 * Sets up a power user control.
 *
 * A power user control is a shortcut key combination that triggers a specific
 * action when pressed.
 *
 * The `usePowerUserControl` hook takes two parameters:
 * - `data`: an object with two properties:
 *   - `keys`: an array of strings representing the keys that trigger the shortcut
 *   - `label`: a string that describes the shortcut
 * - `onPress`: a callback function that is called when the shortcut is pressed
 *   The callback function is called with the `KeyboardEvent` that triggered the
 *   shortcut.
 *
 * The hook will automatically log an analytics event when the shortcut is used,
 * and will call the `onPress` callback function when the shortcut is pressed.
 *
 * @returns The `data` parameter, so that it can be easily returned from a
 *          parent hook.
 */
export const usePowerUserControl = (
  key: POWER_USER_CONTROLS_LABEL,
  data: PowerUserControlHookData,
  onPress: (e: KeyboardEvent) => void,
) => {
  const setKeyData = useSetAtom(setPowerControlKeyData);
  useKeyPress(
    data.keys.join("."),
    (e) => {
      emitAnalyticsTrackingEvent("keyboard_shortcut_used", { ...data });
      datadogLogs.logger.debug("keyboard_shortcut_used", { ...data });
      onPress(e);
    },
    { exactMatch: true },
  );
  useEffect(() => {
    setKeyData({ [key]: data });
  }, []);
  return data;
};
