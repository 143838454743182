import { Box, Skeleton, styled } from "@mui/material";

export const StudiosSectionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),

  ".verified-list-container": {
    justifyContent: "flex-start",
    gap: "24px",
    minHeight: "unset",
    marginBottom: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },

  ".trending-profile-badge-container": {
    marginBottom: 0,
    marginTop: "4px",
  },

  ".verified-list-container > .trending-profile-link-container >.trending-profile-card-container":
    {
      marginBottom: 0,
      minHeight: "unset",
      maxHeight: "unset",

      p: {
        whiteSpace: "break-spaces",
      },
    },

  [theme.breakpoints.down(876)]: {
    ".verified-list-container": {
      justifyContent: "center",
    },
  },
}));

export const StudioSkeletonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  height: "180px",
  width: "285px",
  backgroundColor: theme.palette.customColor.backgroundColor,
}));

export const StudioImageSkeleton = styled(Skeleton)(({ theme }) => ({
  height: "180px",
  width: "100%",
  backgroundColor: theme.palette.customColor.monochromeBackgroundColor,
}));

export const StudioTextSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.customColor.monochromeBackgroundColor,
}));

export const StudioBadgeSkeleton = styled(Skeleton)(({ theme }) => ({
  height: "20px",
  width: "20px",
  backgroundColor: theme.palette.customColor.monochromeBackgroundColor,
  marginRight: "auto",
}));
