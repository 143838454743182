import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIMasteringProject, APIMixingProject } from "../../models/project";
import { makeBackendGetCallWithJsonResponse } from "../../utils/fetch";
import { LOAD_PROJECT } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { loadProjectParams } from "./types";

const loadProject = createAsyncThunk(
  LOAD_PROJECT,
  async ({ project_id }: loadProjectParams, thunkAPI) => {
    const params = `?project_id=${project_id}`;
    const response = await makeBackendGetCallWithJsonResponse<
      APIMixingProject | APIMasteringProject
    >(LOAD_PROJECT, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default loadProject;
