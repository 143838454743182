import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";

export const StyledFooterPlayerContainer = styled("div", {
  shouldForwardProp: (prop) =>
    ![
      "$expandFooter",
      "$showPlaylist",
      "$isFooterAndHeaderShowing",
      "$showFooterPlayer",
    ].includes(prop as string),
})<{
  $expandFooter?: boolean;
  $showPlaylist?: boolean;
  $isFooterAndHeaderShowing?: boolean;
  $showFooterPlayer?: boolean;
}>`
  position: relative;
  border-top: 1px solid var(--background-no-dark-theme);
  display: ${({ $showFooterPlayer }) => ($showFooterPlayer ? "flex" : "none")};
  background-color: ${({ theme }) => theme.palette.common.black};
  align-items: center;
  padding: 13px 32px;
  box-sizing: border-box;
  width: 100%;
  z-index: var(--footer-player-z-index);
  height: 100%;
  transition: 0.3s ease;
  flex-direction: ${({ $expandFooter }) => ($expandFooter ? "column" : "row")};
  
  @media (max-width: 768px) {
    padding: 13px 20px;
    box-sizing: border-box;
    width: 100%;
    justify-content: ${({ $expandFooter }) => ($expandFooter ? "flex-start" : "space-between")};
    max-height: ${({ $showPlaylist, $isFooterAndHeaderShowing }) => {
      if ($showPlaylist) {
        return $isFooterAndHeaderShowing
          ? "calc(100% - var(--header-height-tablet) - var(--bottom-nav-height) - 26px)"
          : "calc(100% - 26px)";
      }
      return "unset";
    }};
  }

  @media (max-width: 481px) {
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    max-height: ${({ $showPlaylist, $isFooterAndHeaderShowing }) => {
      if ($showPlaylist) {
        return $isFooterAndHeaderShowing
          ? "calc(100% - var(--header-height-tablet) - var(--bottom-nav-height) - 32px)"
          : "calc(100% - 32px)";
      }
      return "unset";
    }};
`;

export const StyledFooterPlayerPlaybackControlContainer = styled("div", {
  shouldForwardProp: (prop) =>
    !["$expandFooter", "$isFooterAndHeaderShowing"].includes(prop as string),
})<{
  $expandFooter?: boolean;
  $isFooterAndHeaderShowing?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const StyledFooterPlayerInfoFontawesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.standardColor.Gray[400]};
`;

export const StyledFooterRowContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "$expandFooter",
})<{ $expandFooter?: boolean }>`
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledFooterWaveformContainer = styled(StyledFooterRowContainer)`
  max-width: ${({ $expandFooter }) => ($expandFooter ? "100%" : "376px")};
  width: 100%;
  margin-left: auto;
  display: flex;
  margin-top: ${({ $expandFooter }) => ($expandFooter ? "16px" : "0")};
  margin-right: 50px;
  @media (max-width: 1024px) {
    max-width: ${({ $expandFooter }) => ($expandFooter ? "100%" : "300px")};
    flex: ${({ $expandFooter }) => ($expandFooter ? "unset" : "1")};
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    display: ${({ $expandFooter }) => ($expandFooter ? "flex" : "none")};
  }
`;

export const StyledFooterPlayerButtonsContainer = styled("div", {
  shouldForwardProp: (prop) =>
    !["$expandFooter", "$hidden"].includes(prop as string),
})<{
  $expandFooter?: boolean;
  $hidden?: boolean;
}>`
  display: flex;
  flex-direction: row-reverse;
  width: ${({ $expandFooter }) => ($expandFooter ? "100%" : "auto")};
  pointer-events: ${({ $hidden }) => ($hidden ? "none" : "auto")};
  visibility: ${({ $hidden }) => ($hidden ? "hidden" : "visible")};
`;

export const StyledFooterPlayerActionsContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "$expandFooter",
})<{ $expandFooter?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: ${({ $expandFooter }) => ($expandFooter ? "relative" : "absolute")};
  left: ${({ $expandFooter }) => ($expandFooter ? "unset" : "50%")};
  transform: ${({ $expandFooter }) =>
    $expandFooter ? "unset" : "translateX(-50%)"};
`;
