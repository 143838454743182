import { atom } from "jotai/index";
import {
  POWER_USER_CONTROLS_CATEGORY,
  PowerUserControlHookData,
  PowerUserControlKeysData,
} from "./types";

export const powerControlKeysDataAtom = atom({} as PowerUserControlKeysData);
export const powerControlKeysListAtom = atom([] as PowerUserControlHookData[]);
export const setPowerControlKeyData = atom(
  null,
  (
    _get,
    set,
    data: { [key: string]: PowerUserControlHookData | undefined },
  ) => {
    set(powerControlKeysDataAtom, (oldState) => {
      const newState = { ...oldState };
      for (const [key, value] of Object.entries(data)) {
        if (!value) {
          delete newState[key];
        } else {
          newState[key] = {
            // Cast the category to a value if not set to be safe.
            category: POWER_USER_CONTROLS_CATEGORY.GENERAL,
            ...value,
          };
        }
      }
      return newState;
    });
  },
);

export const powerControlKeysDataByCategoryAtom = atom((get) => {
  const powerControlKeysData = get(powerControlKeysDataAtom);
  const returnValues: Partial<
    Record<
      POWER_USER_CONTROLS_CATEGORY,
      {
        key: string;
        value: PowerUserControlHookData;
      }[]
    >
  > = {};

  for (const [key, value] of Object.entries(powerControlKeysData)) {
    if (!value.category) {
      value.category = POWER_USER_CONTROLS_CATEGORY.GENERAL;
    }
    if (!returnValues[value.category]) {
      returnValues[value.category] = [];
    }
    returnValues[value.category]!.push({
      key,
      value,
    });
  }
  return returnValues;
});
