import { styled } from "@mui/material";

export const StyledFooterWaveformContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  flex: 1;
  position: relative;
`;

export const StyledFooterWaveformP = styled("p")`
  color: ${({ theme }) => theme.palette.standardColor.Gray[300]};
  font-size: 0.75rem;
  min-width: 25px;
`;

export const StyledWaveform = styled("div")`
  width: 100%;
  height: 50px;
`;

export const StyledWaveformLoadingLosslessAudioContainer = styled("div")`
  position: absolute;
  z-index: var(--footer-player-z-index);
  display: flex;
  font-size: 12px;
  align-items: flex-end;
  right: 0;
  bottom: 0;
  & > .lossless-audio-loading-circle {
    margin-left: 8px;
  }
`;
