import { atomWithStorage } from "jotai/utils";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";
import { atom } from "jotai";

const _supplyChainTokenAtom = atomWithStorage(
  LOCAL_STORAGE_KEYS.SUPPLY_CHAIN_TOKEN,
  "",
);

export const supplyChainTokenAtom = atom(
  (get) => {
    return get(_supplyChainTokenAtom);
  },
  (_, set, token: string | ((oldToken: string) => string)) => {
    let result = "";
    // This is a helper function to capture the result from the set state action
    set(_supplyChainTokenAtom, (oldState) => {
      if (typeof token === "function") {
        result = token(oldState);
      } else {
        result = token;
      }
      return result;
    });
  },
);

supplyChainTokenAtom.debugLabel = "SupplyChain Auth Token";
