import { PlaylistTrack } from "../../../store/models/playListTrack/types";
import { PlayAudioButton } from "../PlayAudioButton/PlayAudioButton";
import {
  usePlayPauseOnFooter,
  useSetPlayList,
} from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useAppSelector } from "../../../store/hooks";
import { FooterFileTrackType } from "../../../store/actions/abPlayerStore";

interface PortfolioPlayPauseButtonProps {
  tracks: PlaylistTrack[];
  userId: number;
}

export const PortfolioPlayPauseButton = ({
  tracks,
  userId,
}: PortfolioPlayPauseButtonProps) => {
  const { handleClick } = usePlayPauseOnFooter();
  const setUserPortfolioPlaylist = useSetPlayList();
  const { isFooterPlaying, playlistId } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const isPlaying = isFooterPlaying && userId === playlistId;

  if (tracks.length === 0) {
    return null;
  }
  return (
    <PlayAudioButton
      size={25}
      padding={4}
      paymentRequiredForPlayback={false}
      progress={0}
      isPlaying={isPlaying}
      handleClick={() => {
        if (userId === playlistId) {
          handleClick();
        } else {
          setUserPortfolioPlaylist({
            tracks,
            index: 0,
            playOnLoad: true,
            playlistId: userId,
            footerFileTrackType: FooterFileTrackType.PORTFOLIO_PLAYLIST_TRACK,
          });
        }
      }}
    />
  );
};
