import { Box, css, styled } from "@mui/material";

export const ServicesTabContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "30px",
  gridTemplateColumns: "repeat(auto-fill, minmax(245px, 1fr))",
  gridAutoRows: "1fr",

  // This is measured manually to sure the cards look good on all screens
  [theme.breakpoints.down(1215)]: {
    gap: "10px",
  },

  [theme.breakpoints.down(1145)]: {
    gap: "30px",
  },

  [theme.breakpoints.down(631)]: {
    gridTemplateColumns: "285px",
    justifyContent: "center",
  },

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    justifyContent: "center",
  },

  ".engineering-service-card": {
    height: "unset",
  },
}));

export const StyledServicesFormModalBody = ({
  $isMobile,
}: {
  $isMobile: boolean;
}) => css`
  justify-content: flex-start;
  padding: 0;
  overflow-y: hidden;
  max-height: ${$isMobile ? "unset" : "60vh"};
  min-height: 350px;
  width: 100%;
  gap: 0;

  .package-details-card {
    overflow-x: unset;
    max-height: unset;
    min-width: unset;
    justify-content: flex-start;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .add-package-service-container {
    width: 100%;
    height: unset;
  }
`;

export const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "16px",
});

export const CenteredModalBody = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "16px",
  textAlign: "center",
  width: "80%",
});
