import { createAsyncThunk } from "@reduxjs/toolkit";
import { Transaction } from "../../models/transaction";
import { makeBackendPostCallWithJsonResponse } from "../../utils/fetch";
import { RENAME_PROJECT_OR_SCHEDULED_PROJECT } from "../../utils/routes";
import { receiveErrors } from "../errorStore";

export interface renameProjectOrScheduledProjectParams {
  project_id?: number;
  scheduled_project_id?: number;
  transaction_code?: string;
  title: string;
}

const renameProjectOrScheduledProject = createAsyncThunk(
  RENAME_PROJECT_OR_SCHEDULED_PROJECT,
  async (args: renameProjectOrScheduledProjectParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<
      Transaction | object
    >(RENAME_PROJECT_OR_SCHEDULED_PROJECT, args);

    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default renameProjectOrScheduledProject;
