import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_ARTISTS_COMPLETED_PROJECTS_COUNT } from "../../store/utils/routes";
import { EngineEarsError } from "../../store/actions/errorStore";
import { promiseEngineEarsHandler } from "../../api/helpers";

interface GetCompletedArtistsProjectsCountResponse {
  completed_projects_count: number;
}

interface UseGetArtistsCompletedProjectsCountParams {
  enabled: boolean;
}

export const useGetArtistsCompletedProjectsCount = ({
  enabled,
}: UseGetArtistsCompletedProjectsCountParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ARTISTS_COMPLETED_PROJECTS_COUNT],
    queryFn: async () => {
      return makeBackendGetCallWithJsonResponse<GetCompletedArtistsProjectsCountResponse>(
        GET_ARTISTS_COMPLETED_PROJECTS_COUNT,
        "",
      ).then(promiseEngineEarsHandler);
    },
    enabled: enabled,
  });
};
