import { FormControlLabel, Switch } from "@mui/material";
import {
  SelectAMPMOptions,
  SelectHourInDayOptions,
} from "../../../store/models/alts";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { Dispatch, SetStateAction } from "react";

interface AllDayToggleProps {
  allDaySelected: boolean;
  index: number;
  handleTimeChange: (
    day: number,
    times: {
      start: OptionType<number>;
      end: OptionType<number>;
      startAMPM: OptionType<number>;
      endAMPM: OptionType<number>;
    },
  ) => void;
  handleDateChange?: Dispatch<SetStateAction<number[]>>;
}

export const AllDayToggle = ({
  allDaySelected,
  index,
  handleTimeChange,
  handleDateChange,
}: AllDayToggleProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={allDaySelected}
          onChange={(e) => {
            if (e.target.checked) {
              handleTimeChange(index, {
                start: SelectHourInDayOptions[0],
                end: SelectHourInDayOptions[0],
                startAMPM: SelectAMPMOptions[0],
                endAMPM: SelectAMPMOptions[0],
              });
              handleDateChange?.((preselected) => [
                ...new Set([...preselected, index]),
              ]);
            }
          }}
        />
      }
      label={<Text variant={TextStyleVariant.P3}>All day</Text>}
    />
  );
};
