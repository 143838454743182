import { faAddressCard } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetUserProfile } from "../../../../../hooks/profileScreenHooks/useGetUserProfile";
import { useIsVisitingOwnProfile } from "../../../../../hooks/profileScreenHooks/useIsVisitingOwnProfile";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../../../elements/SoundWaveLoader/SoundWaveLoader";
import { ProfileScreenParamKeys } from "../../utils";
import {
  AboutMeContainer,
  NoDataContainer,
  StyledEmptyFaIcon,
} from "./AboutMe.styles";
import { useCallback } from "react";

export const AboutMe = () => {
  const { username } = useParams<{ username: string }>();
  const { data: userData, isLoading: isLoadingUserData } =
    useGetUserProfile(username);
  const isVisitingOwnProfile = useIsVisitingOwnProfile();
  const [, setSearchParams] = useSearchParams();
  const setSelectedTab = useCallback(
    (tab: string) => {
      setSearchParams((prev) => {
        return { ...prev, [ProfileScreenParamKeys.SelectedTab]: tab };
      });
    },
    [setSearchParams],
  );

  const renderAboutMe = () => {
    if (isLoadingUserData) {
      return <SoundWaveLoader height={100} width={100} />;
    }
    if (!userData?.profile?.long_bio && isVisitingOwnProfile) {
      return (
        <NoDataContainer>
          <StyledEmptyFaIcon icon={faAddressCard} />
          <Text>No info yet</Text>
        </NoDataContainer>
      );
    }

    return (
      <Box>
        <Text
          variant={TextStyleVariant.P2}
          style={{
            maxHeight: "calc(1.125rem * 3)",
            whiteSpace: "pre-wrap",
            overflow: "hidden",
          }}
        >
          {userData?.profile?.long_bio ?? ""}
        </Text>
        <Button
          onClick={() => setSelectedTab("overview")}
          style={{ minHeight: "unset", textDecoration: "none" }}
          variant={ButtonVariant.TEXT}
        >
          <Text bold>Read more</Text>
        </Button>
      </Box>
    );
  };

  return (
    <AboutMeContainer>
      <Text variant={TextStyleVariant.S2}>About me</Text>
      {renderAboutMe()}
    </AboutMeContainer>
  );
};
