import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, MenuItem, Stack, useTheme } from "@mui/material";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import Link from "../../elements/Link/Link";
import { DropdownSectionInterface } from "./utils";

interface MobileDropdownNavMenuProps extends DropdownSectionInterface {
  onClick: () => void;
  isOpen: boolean;
}

export const MobileDropdownNavMenu = ({
  label,
  icon,
  links,
  onClick,
  isOpen,
}: MobileDropdownNavMenuProps) => {
  const theme = useTheme();
  const handleToggle = () => {
    emitAnalyticsTrackingEvent(
      TRACKING_EVENTS_NAME.USER_CLICKED_DROP_DOWN_MENU_ITEM,
      {
        label,
      },
    );
    onClick();
  };

  return (
    <Stack width="100%" direction="column" gap={1}>
      <Button
        style={{
          padding: 10,
          margin: 0,
        }}
        onClick={handleToggle}
        variant={ButtonVariant.GHOST}
      >
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          {icon && <img src={icon} alt={label} />}
          <Text variant={TextStyleVariant.P3} bold sx={{ width: "100%" }}>
            {label}
          </Text>
          <FontAwesomeIcon
            size="2xs"
            icon={isOpen ? faChevronUp : faChevronDown}
          />
        </Stack>
      </Button>
      <Collapse in={isOpen} sx={{ paddingLeft: theme.spacing(4) }}>
        {links.map((link) => (
          <MenuItem key={link.label} onClick={onClick}>
            <Link
              analytics={{
                label: TRACKING_EVENTS_NAME.USER_CLICK_NAV_MENU_ITEM,
                data: {
                  label: link.label,
                  href: link.href,
                },
              }}
              sx={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
                ...(link.disabled && {
                  pointerEvents: "none",
                  opacity: 0.5,
                  cursor: "not-allowed",
                  color: theme.palette.text.disabled,
                }),
              }}
              to={
                link.href.startsWith("http")
                  ? link.href
                  : `${window.location.origin}${link.href}`
              }
            >
              <Text
                variant={TextStyleVariant.P3}
                style={{
                  width: "100%",
                }}
              >
                {link.label}
              </Text>
            </Link>
          </MenuItem>
        ))}
      </Collapse>
    </Stack>
  );
};
