import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";

const realTimeInteractedUserIdsAtom = atomWithStorage<number[]>(
  LOCAL_STORAGE_KEYS.REAL_TIME_INTERACTION_USER_IDS,
  [],
);

export const useRealTmeInteractedUserIds = () => {
  const [realTimeInteractedUserIds, setRealTimeInteractedUserIds] = useAtom(
    realTimeInteractedUserIdsAtom,
  );

  const updateRealTimeInteractedUserIds = (userId: number) => {
    setRealTimeInteractedUserIds((prev) => {
      const updatedList = [...new Set([userId, ...(prev || [])])];
      return updatedList.slice(0, 6);
    });
  };

  return { realTimeInteractedUserIds, updateRealTimeInteractedUserIds };
};
