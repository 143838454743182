import { Team } from "../models/admins";
import { Studio, StudioRoom } from "../models/studio";
import { UMGSubLabelMap } from "../models/trophy";
import User, { UserLite } from "../models/user";

export const determineIfUser = (
  user?: User | UserLite | string,
): user is User => {
  return (user as User).first_name !== undefined;
};

export const getDisplayableNameForUser = (user?: User | UserLite | null) => {
  if (!user) return "";
  if (determineIfUser(user)) {
    return user.profile?.display_name
      ? user.profile.display_name
      : `@${user.username}`;
  }
  return `@${user.username}`;
};

export const getDisplayableNameForStudio = (studio?: Studio) => {
  if (!studio) return "";
  return studio.studio_profile?.display_name
    ? studio.studio_profile.display_name
    : studio.username;
};

export const getDisplayableNameForStudioRoom = (
  studioRoom?: Pick<StudioRoom, "room_name">,
) => {
  if (!studioRoom) return "";
  return studioRoom.room_name;
};

export const getIsUserAandR = (user?: User) => {
  if (!user) return false;
  return Boolean(user.aandr) && !user.aandr?.deleted;
};

export const getDisplayableTeamName = (team?: Team): string => {
  if (!team) return "";
  if (team.studio) {
    return getDisplayableNameForStudio(team.studio);
  }
  if (!team.label?.umg_sub_label) {
    return "";
  }
  return UMGSubLabelMap.get(team?.label?.umg_sub_label)?.text ?? "";
};
