import classNames from "classnames";
import { CSSProperties, InputHTMLAttributes, useEffect, useState } from "react";
import "./PercentageTextfield.css";

export interface PercentageTextfieldProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "value" | "min" | "max" | "step"
  > {
  label?: string;
  value?: number;
  min?: number;
  max?: number;
  onChangePercentage: (value: number) => void;
  step?: number;
  containerStyle?: CSSProperties;
  offsetForPercentSpace?: boolean;
}

export const PercentageTextfield = ({
  label = "",
  className,
  value,
  min = 0,
  max = 100,
  onChangePercentage,
  step = 1,
  containerStyle = {},
  offsetForPercentSpace = false,
  ...props
}: PercentageTextfieldProps) => {
  const [internalValue, setInternalValue] = useState<string>(
    value != null ? (value * 100).toString() : "",
  );
  const [previousValidValue, setPreviousValidValue] = useState<number | null>(
    value != null ? value : null,
  );

  useEffect(() => {
    setInternalValue(value != null ? (value * 100).toString() : "");
    setPreviousValidValue(value != null ? value : null);
  }, [value]);

  return (
    <div
      style={{
        width: "100%",
        marginRight: offsetForPercentSpace ? "15px" : undefined,
        ...containerStyle,
      }}
    >
      <input
        className={classNames("textfield", className)}
        style={{
          width: 80,
        }}
        inputMode={"numeric"}
        name="text"
        type="number"
        value={internalValue}
        {...props}
        placeholder={label}
        onChange={(e) => {
          setInternalValue(e.target.value);
        }}
        onBlur={() => {
          const numericValue = parseFloat(internalValue || "");
          if (isNaN(numericValue) || internalValue === "") {
            // Revert to previous valid value if the input is empty or not a number
            if (previousValidValue != null) {
              setInternalValue((previousValidValue * 100).toString());
            } else {
              setInternalValue("");
            }
          } else if (numericValue < min) {
            onChangePercentage(min / 100);
            setPreviousValidValue(min / 100);
            setInternalValue(min.toString());
          } else if (numericValue > max) {
            onChangePercentage(max / 100);
            setPreviousValidValue(max / 100);
            setInternalValue(max.toString());
          } else {
            const newValidValue =
              (Math.round(numericValue / step) * step) / 100;
            onChangePercentage(newValidValue);
            setPreviousValidValue(newValidValue);
            setInternalValue((newValidValue * 100).toString());
          }
        }}
      />
      <span className="b1" style={{ marginLeft: "-25px" }}>
        %
      </span>
    </div>
  );
};
