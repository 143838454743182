import { useEffect } from "react";
import { useServiceTypesQuery } from "../../../../hooks/searchHooks/useServiceTypesQuery";
import { isLookingForRecordingEngineerOnly } from "../../../../hooks/searchHooks/utils";
import { SCREENS } from "../../../../routes/screens";
import { setUserLatLngForStudioRoomSearch } from "../../../../store/actions/studioRoomSearch";
import { setUserLatLngForUserSearch } from "../../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

const useUserLocation = () => {
  const dispatch = useAppDispatch();
  const { latitude, longitude } = useAppSelector((state) => state.userSearch);

  const { serviceTypes: selectedServices } = useServiceTypesQuery();

  useEffect(() => {
    if (latitude && longitude) {
      return;
    }
    // Ask the user for location if they are looking for a recording engineer or a studio room
    if (
      isLookingForRecordingEngineerOnly(selectedServices) ||
      [SCREENS.PAGINATED_STUDIOS, SCREENS.SEARCH].includes(
        location.pathname as SCREENS,
      )
    ) {
      const onSuccess = (position: GeolocationPosition) => {
        const { latitude, longitude } = position.coords;
        dispatch(setUserLatLngForUserSearch({ latitude, longitude }));
        dispatch(setUserLatLngForStudioRoomSearch({ latitude, longitude }));
      };
      window.navigator.geolocation.getCurrentPosition(onSuccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServices, dispatch, latitude, longitude, location.pathname]);
};

export default useUserLocation;
