import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { SCREENS } from "../../../routes/screens";
import {
  BaseModal,
  BaseModalProps,
} from "../../core-ui/components/BaseModal/BaseModal";
import { SignInForm } from "../SignInForm/SignInForm";
import "./SignInModal.css";

export interface SignInModalProps
  extends Pick<BaseModalProps, "showCloseButton"> {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  label: string;
}

export const SignInModal = ({
  open = true,
  setOpen = () => {},
  label,
  showCloseButton = false,
}: SignInModalProps) => {
  return (
    <BaseModal
      showCloseButton={showCloseButton}
      open={open}
      setOpen={setOpen}
      header={label}
      style={{ maxWidth: "360px" }}
    >
      <div className="sign-in-modal">
        <div className="redirect-button">
          <Link to={SCREENS.SIGNED_OUT_HOME_SCREEN} className="b2-semi-bold">
            GO HOME
          </Link>
        </div>
        <SignInForm register={false} />
      </div>
    </BaseModal>
  );
};
