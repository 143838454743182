import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { GET_ENTITY_PHOTO_UPLOAD_URL } from "../store/utils/routes";
import { makeBackendPostCallWithJsonResponse } from "../store/utils/fetch";
import { MutateFunctionProps } from "./profileScreenHooks/useUploadProfileCoverPhoto";
import { SupportedEntityTypes } from "./useEntityPhotos";
import { EngineEarsError } from "../store/actions/errorStore";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../api/helpers";

export interface EntitySignedUrlResponseProps extends MutateFunctionProps {
  entity_type: SupportedEntityTypes;
  entity_id: number;
  entity_photo_id?: number;
}

export const useGetEntityPhotoUploadUrl = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.UPLOAD_ENTITY_PHOTO],
    mutationFn: async (props: EntitySignedUrlResponseProps) => {
      return makeBackendPostCallWithJsonResponse<{
        signed_url: string;
      }>(GET_ENTITY_PHOTO_UPLOAD_URL, {
        content_type: props.content_type,
        file_size: props.file_size,
        entity_type: props.entity_type,
        entity_id: props.entity_id,
        entity_photo_id: props.entity_photo_id,
      }).then((res) => {
        if (!res.success) {
          throw res.resultJson;
        }
        return res.resultJson;
      });
    },
    onError: (error: EngineEarsError) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
    },
  });
};
