import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, Theme } from "@mui/material";
import { Button } from "../Button/Button";

interface ChipContainerProps {
  $onDelete?: boolean;
  $loading?: boolean;
}

interface ChipCommonStylesProps extends ChipContainerProps {
  theme: Theme;
}

export const ChipCommonStyles = ({
  $loading,
  theme,
}: ChipCommonStylesProps) => css`
  padding: 5px 8px;
  border-radius: ${theme.border.radius.md};
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  font-size: 0.875rem;
  border: 1px solid transparent;
  cursor: ${$loading ? "not-allowed" : "unset"};
`;

export const ChipContainer = styled("div")<ChipContainerProps>`
  ${ChipCommonStyles}
  height: 16px;
  background: ${({ theme }) => theme.palette.customColor.midgroundColor};
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const ChipCloseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  background: inherit;
`;

export const ChipButton = styled("button")`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.customColor.midgroundColor};
  padding: 0.2em 0.3em;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background: ${({ theme }) => theme.palette.customColor.buttonColorHover};
  }
  &:focus {
    outline: none;
  }
`;

export const ChipContainerOutlinedStyles = ({
  theme,
  $loading,
  $onDelete,
}: ChipCommonStylesProps) => css`
  ${ChipCommonStyles({ theme, $loading, $onDelete })};
  background-color: ${theme.palette.customColor.backgroundColor};
  border-color: ${theme.palette.divider};
  font-weight: normal;
  color: ${$loading
    ? theme.palette.customColor.textTertiaryColor
    : theme.palette.text.primary};

  &:hover {
    background-color: ${theme.palette.customColor.darkerMidgroundColor};
  }

  ${ChipButton} {
    background: inherit;
  }

  ${ChipCloseIcon} {
    font-size: 0.75rem;
    color: ${$loading
      ? theme.palette.customColor.textTertiaryColor
      : theme.palette.text.primary};
  }
`;

export const ChipContainerOutlined = styled("div")<ChipContainerProps>`
  ${ChipContainerOutlinedStyles};
`;

export const ChipAsButton = styled(Button)<ChipContainerProps>`
  ${ChipContainerOutlinedStyles};
  min-height: unset;
  line-height: unset;
  cursor: pointer;
`;
