import { useMutation } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { LoginUMGParams, postLoginUMG } from "../../api/auth/postLoginUMG";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { TRACKING_EVENTS_NAME } from "../../constants/trackingEvents";
import { setUser } from "../../store/actions/accountInfo";
import { EngineEarsError } from "../../store/actions/errorStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

const useLoginUMGMutation = () => {
  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );
  const setToken = useSetAtom(tokenAtom);
  const dispatch = useAppDispatch();
  const clearUserData = () => {
    setToken("");
    dispatch(setUser());
  };
  return useMutation({
    mutationKey: [QUERY_KEYS.LOGIN_UMG],
    mutationFn: async (params: LoginUMGParams) => {
      const response = await postLoginUMG(params);
      if (assertEngineEarsErrorType(response)) {
        throw response;
      }
      return response;
    },
    // If we handle the error as part of the mutation function, it can show the toast numerous times.
    // Our custom errors are technically valid responses
    onSuccess: (result) => {
      setToken(result.token);
      dispatch(setUser(result.user));
      if (result.created) {
        emitAnalyticsTrackingEvent(
          TRACKING_EVENTS_NAME.REGISTER_VIA_WHITELIST,
          {
            email: result.user.email,
            phoneNumber: result.user.phone_number?.phone_number,
            userId: result.user.id,
            username: result.user.username,
            ...localUTMParams,
          },
        );
      }
      return result;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      clearUserData();
      return error;
    },
  });
};

export default useLoginUMGMutation;
