import { styled } from "@mui/material";

export const TableRow = styled("tr", {
  shouldForwardProp: (prop) =>
    prop !== "$disabled" && prop !== "$isTableLoading",
})<{
  $disabled?: boolean;
  $isTableLoading?: boolean;
}>`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  height: 80px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ theme, $disabled, $isTableLoading }) =>
      $disabled || $isTableLoading
        ? "transparent"
        : theme.palette.customColor.midgroundColor};
  }

  td {
    color: ${({ theme, $disabled, $isTableLoading }) =>
      $disabled || $isTableLoading
        ? theme.palette.text.secondary
        : theme.palette.text.primary};
    overflow: visible;
  }
`;

export const TableHeadCell = styled("th", {
  shouldForwardProp: (prop) => prop !== "$widthSize" && prop !== "$disabled",
})<{
  $widthSize: string;
  $disabled?: boolean;
}>`
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  width: ${({ $widthSize }) => $widthSize};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "auto")};

  svg {
    margin-left: 6px;

    &.fa-sort-down {
      path {
        transform: translateY(-15%);
      }
    }

    &.fa-sort-up {
      path {
        transform: translateY(25%);
      }
    }
  }
`;

export const PlayButton = styled("button")`
  background-color: ${({ theme }) => theme.palette.common.black};
  border-radius: 100%;
  height: 32px;
  width: 32px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: white;
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.palette.divider};
    cursor: not-allowed;
  }
`;

export const RowDraggerButton = styled("button")`
  background-color: transparent;
  cursor: grab;
  padding: 12px;
  border: none;
  color: ${({ theme }) => theme.palette.text.primary};

  &:active {
    cursor: grabbing;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`;

export const ActionButtonCell = styled("div")`
  display: grid;
  grid-template-columns: 1fr 16px;
  column-gap: 8px;
  align-items: center;
  text-align: center;

  button {
    text-wrap: wrap;
    width: 100%;
  }
`;

export const CenteredCell = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableHeaderRow = styled("tr")`
  height: 64px;
`;
