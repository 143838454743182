import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import { useSetAtom } from "jotai";
import { useAppDispatch } from "../../store/hooks";
import { setUser } from "../../store/actions/accountInfo";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { postSignUp, SignUpParams } from "../../api/auth/postSignUp";
import { EngineEarsError } from "../../store/actions/errorStore";

const useSignUpMutation = () => {
  const setToken = useSetAtom(tokenAtom);
  const dispatch = useAppDispatch();
  const clearUserData = () => {
    setToken("");
    dispatch(setUser());
  };
  return useMutation({
    mutationKey: [QUERY_KEYS.SIGN_UP],
    mutationFn: async (params: SignUpParams) => {
      if (window.analytics) {
        params.anonymousId = window.analytics.user?.()?.anonymousId();
      }
      emitAnalyticsTrackingEvent("signup_attempt", {
        username: params.username,
        email: params.email,
        passwordLength: params.password.length,
        anonymousId: params.anonymousId,
      });
      const response = await postSignUp(params);
      if (assertEngineEarsErrorType(response)) {
        throw response;
      }
      return response;
    },
    // If we handle the error as part of the mutation function, it can show the toast numerous times.
    // Our custom errors are technically valid responses
    onSuccess: (result) => {
      setToken(result.token);
      dispatch(setUser(result.user));
      return result;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      clearUserData();
      return error;
    },
  });
};

export default useSignUpMutation;
