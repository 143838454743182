import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { ARTIST_SURVEY_DATA_API } from "../../store/utils/routes";

export interface CreateArtistSurveyDataArgs {
  surveyName: string;
  answers: Record<string, unknown>;
}

export const createArtistSurveyData = async (
  args: CreateArtistSurveyDataArgs,
) => {
  const response = await makeBackendPostCallWithJsonResponse<null>(
    ARTIST_SURVEY_DATA_API,
    { answers: args.answers, survey_name: args.surveyName },
  );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};

export default createArtistSurveyData;
