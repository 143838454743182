import artistLandingHeaderIcon from "../../assets/artist-landing-header-icon.svg";
import headerLogo from "../../assets/header-logo.svg";
import { Text } from "../../core-ui/components/Text/Text";
import Link from "../../elements/Link/Link";
import { useLocation } from "react-router-dom";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";
import { SCREENS } from "../../../routes/screens";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Box } from "@mui/material";

const NotFoundScreen = () => {
  const { pathname, hash, search } = useLocation();
  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
      className="404-page"
    >
      <img src={artistLandingHeaderIcon} alt="Waveform element" />
      <img
        alt="uploader logo"
        src={headerLogo}
        width={300}
        className="uploader-logo"
      />
      <Text variant={TextStyleVariant.H5}>404 Page Not Found</Text>
      <Text>
        You seem lost friend!{" "}
        <Link
          to={SCREENS.SIGNED_OUT_HOME_SCREEN}
          analytics={{
            TRACKING_EVENT: TRACKING_EVENTS_NAME.LEAVE_404_PAGE,
            data: { pathname, hash, search },
          }}
        >
          Go Home
        </Link>{" "}
        to find your way!
      </Text>
    </Box>
  );
};

export default NotFoundScreen;
