import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { Theme, useMediaQuery } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useMemo, useRef } from "react";
import { editMode } from "../atoms/profileScreenEdit";
import { QUERY_KEYS } from "../constants/queryKeys";
import User from "../store/models/user";
import {
  ProfileScreenParamKeys,
  ProfileScreenTabs,
  profileTabsMap,
} from "../stories/screens/ProfileScreen/utils";
import { useUpdateUserProfile } from "./userHooks/useUpdateUserProfile";
import { useSearchParams } from "react-router-dom";

interface useProfileScreenTabProps {
  userData?: User;
  username: string;
}

export const useProfileScreenTab = ({
  userData,
  username,
}: useProfileScreenTabProps) => {
  const { mutateAsync: mutateProfile, isPending: isReorderingTab } =
    useUpdateUserProfile();

  const queryClient = useQueryClient();
  const prevUserDataRef = useRef<User>();
  const isEditMode = useAtomValue(editMode);
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const enableTabReordering = isEditMode && isDesktop;
  const [searchParams, setSearchParams] = useSearchParams();
  const profileScreenSelectedTab = useMemo(
    () => searchParams.get(ProfileScreenParamKeys.SelectedTab),
    [searchParams],
  );

  // The available tabs as well as the tab order are returned from the BE
  const tabsList = useMemo(() => {
    if (!userData?.profile?.tab_order_preferences) return [];

    return userData.profile.tab_order_preferences
      .map(({ key, locked, locked_reason }) => {
        const currentTab = profileTabsMap[key as ProfileScreenTabs];
        currentTab.locked = locked;
        currentTab.lockedReason = locked_reason;
        return currentTab;
      })
      .filter(Boolean);
  }, [userData?.profile?.tab_order_preferences]);

  const selectedTabIndex = useMemo(() => {
    if (tabsList.length === 0) return undefined;

    if (profileScreenSelectedTab != null) {
      const foundIdx = tabsList.findIndex(
        (tab) =>
          tab.queryParamsValue === profileScreenSelectedTab && !tab.locked,
      );

      // We set the selectedTab query value to the first tab if the parsed selected tab is not found in user's available tabs
      if (foundIdx === -1) {
        setSearchParams((prev) => {
          return {
            ...prev,
            [ProfileScreenParamKeys.SelectedTab]: tabsList[0].queryParamsValue,
          };
        });
        return 0;
      }

      return foundIdx;
    }

    // If nothing was passed as the `selectedTab` query value, then we just return the first tab
    return 0;
  }, [profileScreenSelectedTab, setSearchParams, tabsList]);

  const handleSetSelectedTabIndex = (
    _: React.SyntheticEvent,
    tabIndex: number,
  ) => {
    if (tabIndex === selectedTabIndex || tabsList.length === 0) {
      return;
    }
    setSearchParams((prev) => {
      return {
        ...prev,
        [ProfileScreenParamKeys.SelectedTab]:
          tabsList[tabIndex].queryParamsValue,
      };
    });
  };

  const sortableTabIds = useMemo(() => {
    if (tabsList.length > 0) {
      return tabsList.map((tab) => `tab-${tab.value}`);
    }

    return [];
  }, [tabsList]);

  const handleDragEnd = async (event: DragEndEvent) => {
    if (!tabsList || !sortableTabIds || tabsList.length === 0) return;
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = sortableTabIds.indexOf(active.id as string);
      const newIndex = sortableTabIds.indexOf(over.id as string);
      const reorderedData = arrayMove(tabsList, oldIndex, newIndex);
      const newTabOrderPreferences = reorderedData
        .map((tab) => tab.value)
        .join(",");

      // Before making the API call, store current state in a ref
      // So that we can restore the state later if the network request fails
      prevUserDataRef.current = userData;

      // Optimistically update the client's tab order
      queryClient.setQueryData(
        [QUERY_KEYS.GET_USER_PROFILE, username],
        (oldData: User | undefined) => {
          if (!oldData) return undefined;

          const newData = { ...oldData };
          if (oldData.profile) {
            newData.profile = {
              ...oldData.profile,
              tab_order_preferences: reorderedData.map(
                ({ value, locked, lockedReason }) => ({
                  key: value,
                  locked,
                  lockedReason,
                }),
              ),
            };
          }

          return newData;
        },
      );

      // Retain the selected tab even after the re-order
      if (!profileScreenSelectedTab) {
        if (oldIndex === selectedTabIndex) {
          setSearchParams((prev) => {
            return {
              ...prev,
              [ProfileScreenParamKeys.SelectedTab]:
                tabsList[oldIndex].queryParamsValue,
            };
          });
        } else if (newIndex === selectedTabIndex) {
          setSearchParams((prev) => {
            return {
              ...prev,
              [ProfileScreenParamKeys.SelectedTab]:
                tabsList[newIndex].queryParamsValue,
            };
          });
        }
      }

      try {
        // Make API call to update the tab preferences
        await mutateProfile({ tab_order_preferences: newTabOrderPreferences });
      } catch (error) {
        // We restore the tab order if the API call fails
        queryClient.setQueryData(
          [QUERY_KEYS.GET_USER_PROFILE, userData?.username],
          (oldData: User | undefined) => {
            if (!oldData) return undefined;
            return prevUserDataRef.current;
          },
        );
      }
    }
  };

  return {
    tabsList,
    handleSetSelectedTabIndex,
    selectedTabIndex,
    enableTabReordering,
    handleDragEnd,
    sortableTabIds,
    isReorderingTab,
  };
};
