// getter/setter for the search term
import { atom } from "jotai";
import { searchParamsAtom, setSearchParamsHelper } from "./locationAtom";

interface ChannelData {
  channelId?: string;
  channelType?: string;
}

export const channelAtom = atom(
  (get) => {
    const value = get(searchParamsAtom).channel || "";
    const parts = value.split(":", 2);
    if (parts.length == 2) {
      return {
        channelType: parts[0],
        channelId: parts[1],
      };
    }
    try {
      return JSON.parse(value) as ChannelData;
    } catch {
      return {} as ChannelData;
    }
  },
  (get, set, value: ChannelData) => {
    setSearchParamsHelper(get, set, {
      key: "channel",
      value: value ? `${value.channelType}:${value.channelId}` : "",
      clear: true,
    });
  },
);

channelAtom.debugLabel = "Channel Atom";
