import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { useLocation } from "react-router-dom";
import pathnameAtom from "../../../../atoms/location/pathnameAtom";

// TODO: Remove jotai-location and use react-router-dom instead
const useSyncProfileState = () => {
  const location = useLocation();
  const setPathname = useSetAtom(pathnameAtom);

  // We need to sync the location pathname from React Router with an atom
  // otherwise we cannot accurately access the active pathname in our atoms
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname, setPathname]);
};

export default useSyncProfileState;
