import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  convertTimeToHour,
  FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE,
  fillInAvailabilityV2,
  INVALID_AM_PM,
  LAST_HOUR_OF_THE_DAY,
  NextWeekDayMapping,
  NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  WEEKDAYS,
  WeekdayTime,
  WorkingHours,
} from "../models/workingHours";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { WORKING_HOURS_API } from "../utils/routes";
import { receiveErrors } from "./errorStore";
import { toast } from "react-toastify";

interface UserWorkingHours {
  [user_id: number]: WorkingHours[] | undefined;
}

interface StudioRoomWorkingHours {
  [studio_room_id: number]: WorkingHours[] | undefined;
}

interface WorkingHoursState {
  userWorkingHours: UserWorkingHours;
  studioRoomWorkingHours: StudioRoomWorkingHours;
}

const initialState: WorkingHoursState = {
  userWorkingHours: {},
  studioRoomWorkingHours: {},
};

export interface workingHoursArgs {
  studio_room_id?: number;
  user_id?: number;
}

export const getWorkingHours = createAsyncThunk(
  WORKING_HOURS_API + "/get",
  async (args: workingHoursArgs, thunkAPI) => {
    let params = "";
    if (args.studio_room_id) {
      params = `?studio_room_id=${args.studio_room_id}`;
    }
    if (args.user_id) {
      params = `?user_id=${args.user_id}`;
    }
    const result = await makeBackendGetCallWithJsonResponse<WorkingHours[]>(
      WORKING_HOURS_API,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export type workingHoursParam = {
  [day in WEEKDAYS]: string | undefined;
};

export interface createWorkingHoursParams {
  working_hours: workingHoursParam;
  studio_room_id?: number;
}

export const createWorkingHours = createAsyncThunk(
  WORKING_HOURS_API + "/post",
  async (args: createWorkingHoursParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<WorkingHours[]>(
      WORKING_HOURS_API,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const workingHoursState = createSlice({
  name: "workingHours",
  initialState,
  reducers: {
    clearWorkingHours: (state) => {
      state.userWorkingHours = {};
      state.studioRoomWorkingHours = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWorkingHours.fulfilled, (state, action) => {
      const payload = action.payload;
      const meta = action.meta;
      if (meta.arg.studio_room_id) {
        state.studioRoomWorkingHours[meta.arg.studio_room_id] = payload;
      } else if (meta.arg.user_id) {
        state.userWorkingHours[meta.arg.user_id] = payload;
      } else if (payload.length > 0 && payload[0].user) {
        const user = payload[0].user;
        state.userWorkingHours[user.id] = payload;
      }
    });
  },
});
export const { clearWorkingHours } = workingHoursState.actions;
export default workingHoursState.reducer;

export const generatingWorkingHours = (
  weekdayTimes: Record<number, WeekdayTime>,
  weeklyWorkDates: WEEKDAYS[],
) => {
  const workingHours: Record<WEEKDAYS, number[]> = {
    [WEEKDAYS.MONDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY).fill(
      FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString(),
    ),
    [WEEKDAYS.TUESDAY]: Array(
      NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
    ).fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString()),
    [WEEKDAYS.WEDNESDAY]: Array(
      NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
    ).fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString()),
    [WEEKDAYS.THURSDAY]: Array(
      NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
    ).fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString()),
    [WEEKDAYS.FRIDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY).fill(
      FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString(),
    ),
    [WEEKDAYS.SATURDAY]: Array(
      NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
    ).fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString()),
    [WEEKDAYS.SUNDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY).fill(
      FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString(),
    ),
  };

  for (const weekday of weeklyWorkDates) {
    const { start, end, startAMPM, endAMPM } = weekdayTimes[weekday];
    const startHour = convertTimeToHour(start.value, startAMPM.value);
    const endHour = convertTimeToHour(end.value, endAMPM.value);
    if (startHour === INVALID_AM_PM || endHour === INVALID_AM_PM) {
      toast.error('Please select a valid start and end time."');
      return;
    }

    if (startHour < endHour) {
      workingHours[weekday] = fillInAvailabilityV2(
        startHour,
        endHour,
        workingHours[weekday] || [],
      );
      // If startHour > endHour, it means the working hour is at least until midnight, or the day after
    } else {
      // We populate the working hour until midnight
      workingHours[weekday] = fillInAvailabilityV2(
        startHour,
        LAST_HOUR_OF_THE_DAY,
        workingHours[weekday] || [],
      );

      // If it's not midnight, then it's the next day
      if (endHour !== 0) {
        workingHours[NextWeekDayMapping[weekday]] = fillInAvailabilityV2(
          0,
          endHour,
          workingHours[NextWeekDayMapping[weekday]] || [],
        );
      }
    }
  }

  return Object.fromEntries(
    Object.entries(workingHours).map(([weekday, availabilityArray]) => [
      weekday,
      availabilityArray.join(""),
    ]),
  ) as workingHoursParam;
};
