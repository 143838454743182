import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import queryString from "query-string";
import type { QueryParamsType } from "../";
import { MockMixingProject } from "../models/mockDataForMixMaster/mockDataForMixMaster";
import { Project, ProjectType, transformRawData } from "../models/project";
import { BasicRecordingSession } from "../models/recordingSession";
import {
  CompleteTodayProjectsResponse,
  MockScheduleOverview,
  ScheduledProject,
  ScheduledProjectOverview,
  ScheduledProjectShareLink,
  ScheduledProjectShareLinkOut,
  transformRawScheduledProjectData,
} from "../models/scheduledproject";
import { TransactionStatus } from "../models/transaction";
import {
  FETCH_STATES,
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  COMPLETE_TODAY,
  CREATE_SCHEDULED_PROJECT_SHARE_LINK,
  GET_IN_PROGRESS_PROJECT_TRANSACTION_DATA,
  GET_PROJECT_OVERVIEW_LINK,
  GET_SCHEDULED_PROJECT,
  GET_SCHEDULED_PROJECT_FOR_PROJECT,
  GET_SCHEDULED_PROJECT_OVERVIEW,
  PROJECT_REQUESTS,
  UPDATE_PROJECTS_ORDER_INDEX,
} from "../utils/routes";
import { manageScheduledProject, ProjectManagementActions } from "./booking";
import { receiveErrors } from "./errorStore";
import markFinalAssetsApproved from "./projects/markFinalAssetsApproved";
import artistMasteringTransitions from "./projects/artistMasteringTransitions";
import engMixTransition from "./projects/engMixTransition";
import engMasteringTransition from "./projects/engMasteringTransition";
import renameProjectOrScheduledProject from "./projects/renameProjectOrScheduledProject";
import artistMixingTransitions from "./projects/artistMixingTransitions";
import { fetchTransactionStatus } from "./transactions";
import { fetchScheduledProject } from "../../api/recording/scheduledProjects/fetchScheduledProject";

interface ScheduledProjectState {
  isLoadingScheduleOverview: boolean;
  scheduleOverview: ScheduledProject[];
  isLoadingCompleteToday: boolean;
  completeTodayScheduledProjects: ScheduledProject[];
  completeTodayMixingProjects: Project[];
  completeTodayMasteringProjects: Project[];
  completeTodayMixingAdminTasks: Project[];
  completeTodayMasteringAdminTasks: Project[];
  isLoadingProjectRequests: boolean;
  projectRequests: ScheduledProject[];
  isLoadingActivePaginatedScheduledProjects: boolean;
  activePaginatedScheduledProjects: ScheduledProject[];
  isLoadingCompletedPaginatedScheduledProjects: boolean;
  completedPaginatedScheduledProjects: ScheduledProject[];
  scheduledProject: ScheduledProject | null;
  isLoadingScheduledProject: boolean;
  isLoadingProjectOverviewScheduledProject: boolean;
  scheduledProjectsFilterBy: number;
  localScheduledProjectsSortBy: string;
  scheduledProjectsSearchQuery: string;
  completeTodayRecordingSessions: BasicRecordingSession[];
  recordingSessions: BasicRecordingSession[];
  projectsOrderIndexUpdateLoading: boolean;
  simplifiedScheduledProjectDetails: {
    [key: string]: {
      status: FETCH_STATES;
      data: ScheduledProjectOverview | null;
    };
  };
  paymentPlanPaid: boolean;
}

const initialState: ScheduledProjectState = {
  isLoadingScheduleOverview: false,
  scheduleOverview: [],
  isLoadingCompleteToday: false,
  completeTodayScheduledProjects: [],
  completeTodayMixingProjects: [],
  completeTodayMasteringProjects: [],
  completeTodayMixingAdminTasks: [],
  completeTodayMasteringAdminTasks: [],
  isLoadingProjectRequests: false,
  projectRequests: [],
  isLoadingActivePaginatedScheduledProjects: false,
  activePaginatedScheduledProjects: [],
  isLoadingCompletedPaginatedScheduledProjects: false,
  completedPaginatedScheduledProjects: [],
  scheduledProject: null,
  isLoadingScheduledProject: false,
  isLoadingProjectOverviewScheduledProject: false,
  scheduledProjectsFilterBy: ProjectType.NO_TYPE,
  //  used on complete today and project overview page.
  localScheduledProjectsSortBy: "-created",
  scheduledProjectsSearchQuery: "",
  completeTodayRecordingSessions: [],
  recordingSessions: [],
  projectsOrderIndexUpdateLoading: false,
  simplifiedScheduledProjectDetails: {},
  paymentPlanPaid: false,
};

export const fetchScheduledProjectOverview = createAsyncThunk(
  GET_SCHEDULED_PROJECT_OVERVIEW,
  async (args: { scheduled_project_id: number }, thunkAPI) => {
    const response =
      await makeBackendGetCallWithJsonResponse<ScheduledProjectOverview>(
        GET_SCHEDULED_PROJECT_OVERVIEW,
        `?scheduled_project_id=${args.scheduled_project_id}`,
      );

    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getCompleteTodayProjects = createAsyncThunk(
  COMPLETE_TODAY,
  async (args: { studio_id?: number }, thunkAPI) => {
    const params = args.studio_id ? `?studio_id=${args.studio_id}` : "";
    const response =
      await makeBackendGetCallWithJsonResponse<CompleteTodayProjectsResponse>(
        COMPLETE_TODAY,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getProjectRequests = createAsyncThunk(
  PROJECT_REQUESTS,
  async (
    args: { filterBy?: number; pendingSearchQuery?: string },
    thunkAPI,
  ) => {
    if (args.filterBy === undefined) {
      args.filterBy = ProjectType.NO_TYPE;
    }
    if (args.pendingSearchQuery === undefined) {
      args.pendingSearchQuery = "";
    }
    const params = `?filter_by=${args.filterBy}&search_query=${args.pendingSearchQuery}`;
    const response = await makeBackendGetCallWithJsonResponse<
      ScheduledProject[]
    >(PROJECT_REQUESTS, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface getScheduledProjectForProjectParams {
  project_id: string;
}

export const getScheduledProjectForProject = createAsyncThunk(
  GET_SCHEDULED_PROJECT_FOR_PROJECT,
  async (args: getScheduledProjectForProjectParams, thunkAPI) => {
    const { project_id } = args;
    const params = `?project_id=${project_id}`;
    const response = await makeBackendGetCallWithJsonResponse<ScheduledProject>(
      GET_SCHEDULED_PROJECT_FOR_PROJECT,
      params,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface getScheduledProjectParams {
  scheduledProjectId: number;
}

export const getScheduledProject = createAsyncThunk(
  GET_SCHEDULED_PROJECT,
  async (args: getScheduledProjectParams, thunkAPI) => {
    return fetchScheduledProject(args).catch((errors) => {
      const errorPayload = { errors };
      thunkAPI.dispatch(receiveErrors(errorPayload));
      return thunkAPI.rejectWithValue(errorPayload);
    });
  },
);

interface updateProjectsOrderIndexParams {
  scheduled_project_id: number;
  projects_to_update: number[];
}

export const updateProjectsOrderIndex = createAsyncThunk(
  UPDATE_PROJECTS_ORDER_INDEX,
  async (args: updateProjectsOrderIndexParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<number[]>(
      UPDATE_PROJECTS_ORDER_INDEX,
      args,
    );

    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface getInProgressProjectTransactionDataParams {
  scheduled_project_id?: number;
  project_id?: number;
  share_link: string;
}

interface InProgressProjectTransactionData {
  transactionId: number;
  code: string;
}

export const getInProgressProjectTransactionData = createAsyncThunk(
  GET_IN_PROGRESS_PROJECT_TRANSACTION_DATA,
  async (
    {
      scheduled_project_id,
      project_id,
      share_link,
    }: getInProgressProjectTransactionDataParams,
    thunkAPI,
  ) => {
    const paramsObject: QueryParamsType = {};
    paramsObject.share_link = share_link;
    if (scheduled_project_id) {
      paramsObject.scheduled_project_id = scheduled_project_id;
    }
    if (project_id) {
      paramsObject.project_id = project_id;
    }
    const params = `?${queryString.stringify(paramsObject)}`;

    const response =
      await makeBackendGetCallWithJsonResponse<InProgressProjectTransactionData>(
        GET_IN_PROGRESS_PROJECT_TRANSACTION_DATA,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getProjectOverviewLink = createAsyncThunk(
  GET_PROJECT_OVERVIEW_LINK,
  async (args: { code: string; password?: string }, thunkAPI) => {
    let params = "?code=".concat(args.code);

    if (args.password) {
      params = params.concat("&password=" + args.password);
    }

    const response =
      await makeBackendGetCallWithJsonResponse<ScheduledProjectShareLinkOut>(
        GET_PROJECT_OVERVIEW_LINK,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface createScheduledProjectShareLinkParams {
  scheduled_project_id: string;
  password?: string;
  emails?: string[];
  single_use?: boolean;
}

export const createScheduledProjectShareLink = createAsyncThunk(
  CREATE_SCHEDULED_PROJECT_SHARE_LINK,
  async (args: createScheduledProjectShareLinkParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<
      ScheduledProjectShareLink[]
    >(CREATE_SCHEDULED_PROJECT_SHARE_LINK, args);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const scheduledProjectsSlice = createSlice({
  name: "scheduledprojects",
  initialState,
  reducers: {
    removeRescheduledProject: (state, action: PayloadAction<number>) => {
      state.projectRequests = state.projectRequests.filter(
        (project) => project.id !== action.payload,
      );
    },
    updateScheduledProjectsTracks: (
      state,
      action: PayloadAction<Project[]>,
    ) => {
      if (state.scheduledProject) {
        state.scheduledProject.projects = action.payload;
      }
    },
    clearScheduledProject: (state) => {
      state.scheduledProject = null;
    },
    clearSchedulesProjects: (state: ScheduledProjectState) => {
      state.completeTodayRecordingSessions = [];
      state.isLoadingScheduleOverview = false;
      state.scheduleOverview = [];
      state.isLoadingCompleteToday = false;
      state.completeTodayScheduledProjects = [];
      state.completeTodayMixingProjects = [];
      state.completeTodayMasteringProjects = [];
      state.completeTodayMixingAdminTasks = [];
      state.completeTodayMasteringAdminTasks = [];
      state.projectRequests = [];
      state.isLoadingActivePaginatedScheduledProjects = false;
      state.activePaginatedScheduledProjects = [];
      state.isLoadingCompletedPaginatedScheduledProjects = false;
      state.completedPaginatedScheduledProjects = [];
    },
    addMockScheduledOverview: (state) => {
      state.scheduleOverview = MockScheduleOverview;
    },
    addMockCompleteTodayState: (state) => {
      state.completeTodayScheduledProjects = MockScheduleOverview;
      state.completeTodayMasteringProjects = [MockMixingProject];
      state.completeTodayMixingProjects = [MockMixingProject];
      state.completeTodayMasteringAdminTasks = [MockMixingProject];
      state.completeTodayMixingAdminTasks = [MockMixingProject];
    },
    addMockProjectRequests: (state) => {
      state.projectRequests = MockScheduleOverview;
    },
    setScheduledProjectsSortBy: (state, action: PayloadAction<string>) => {
      state.localScheduledProjectsSortBy = action.payload;
    },
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.scheduledProject!.projects = action.payload;
    },
    setScheduledProject: (state, action: PayloadAction<ScheduledProject>) => {
      state.scheduledProject = action.payload;
    },
    setScheduledProjectsFilterBy: (state, action: PayloadAction<number>) => {
      state.scheduledProjectsFilterBy = action.payload;
    },
    setScheduledProjectsSearchQuery: (state, action: PayloadAction<string>) => {
      state.scheduledProjectsSearchQuery = action.payload;
    },
    clearScheduledProjectOverview: (state, action: PayloadAction<number>) => {
      state.simplifiedScheduledProjectDetails = {
        ...state.simplifiedScheduledProjectDetails,
        [action.payload.toString()]: {
          status: FETCH_STATES.IDLE,
          data: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduledProjectOverview.pending, (state, action) => {
      state.simplifiedScheduledProjectDetails = {
        ...state.simplifiedScheduledProjectDetails,
        [action.meta.arg.scheduled_project_id.toString()]: {
          status: FETCH_STATES.LOADING,
          data: null,
        },
      };
    });
    builder.addCase(fetchScheduledProjectOverview.rejected, (state, action) => {
      state.simplifiedScheduledProjectDetails = {
        ...state.simplifiedScheduledProjectDetails,
        [action.meta.arg.scheduled_project_id.toString()]: {
          status: FETCH_STATES.FAILED,
          data: null,
        },
      };
    });
    builder.addCase(
      fetchScheduledProjectOverview.fulfilled,
      (state, action) => {
        state.simplifiedScheduledProjectDetails = {
          ...state.simplifiedScheduledProjectDetails,
          [action.meta.arg.scheduled_project_id.toString()]: {
            status: FETCH_STATES.LOADED,
            data: action.payload,
          },
        };
      },
    );
    builder.addCase(getProjectOverviewLink.pending, (state) => {
      state.isLoadingProjectOverviewScheduledProject = true;
    });
    builder.addCase(getProjectOverviewLink.fulfilled, (state, action) => {
      const { payload } = action;
      state.isLoadingProjectOverviewScheduledProject = false;
      state.scheduledProject = transformRawScheduledProjectData(
        payload.scheduled_project,
      );
    });
    builder.addCase(updateProjectsOrderIndex.pending, (state) => {
      state.projectsOrderIndexUpdateLoading = true;
    });
    builder.addCase(updateProjectsOrderIndex.fulfilled, (state, action) => {
      if (!state.scheduledProject) {
        state.projectsOrderIndexUpdateLoading = false;
        return;
      }
      const { payload } = action;
      const reorderedProjects = payload.map((projectId, index) => {
        const project = state.scheduledProject!.projects.find(
          (project) => project.id == projectId,
        );
        return {
          ...project!,
          order_index: index,
        } as Project;
      });
      state.scheduledProject.projects = reorderedProjects;
      state.projectsOrderIndexUpdateLoading = false;
    });
    builder.addCase(updateProjectsOrderIndex.rejected, (state) => {
      state.projectsOrderIndexUpdateLoading = false;
    });
    builder.addCase(getProjectOverviewLink.rejected, (state) => {
      state.isLoadingProjectOverviewScheduledProject = false;
    });
    builder.addCase(getScheduledProject.pending, (state) => {
      state.isLoadingScheduledProject = true;
    });
    builder.addCase(getScheduledProject.fulfilled, (state, action) => {
      const { payload } = action;
      state.isLoadingScheduledProject = false;
      if (payload) {
        state.scheduledProject = transformRawScheduledProjectData(payload);
      }
    });
    builder.addCase(getCompleteTodayProjects.rejected, (state) => {
      state.isLoadingCompleteToday = false;
    });
    builder.addCase(getCompleteTodayProjects.pending, (state) => {
      state.isLoadingCompleteToday = true;
    });
    builder.addCase(getCompleteTodayProjects.fulfilled, (state, action) => {
      const { payload } = action;
      state.isLoadingCompleteToday = false;
      state.completeTodayScheduledProjects = payload.scheduled_projects.map(
        transformRawScheduledProjectData,
      );
      state.completeTodayMixingProjects =
        payload.mixing_tasks.map(transformRawData);
      state.completeTodayMasteringProjects =
        payload.mastering_tasks.map(transformRawData);
      state.completeTodayMixingAdminTasks =
        payload.admin_mixing_tasks.map(transformRawData);
      state.completeTodayMasteringAdminTasks =
        payload.admin_mastering_tasks.map(transformRawData);
    });
    builder.addCase(getProjectRequests.rejected, (state) => {
      state.isLoadingProjectRequests = false;
    });
    builder.addCase(getProjectRequests.pending, (state) => {
      state.isLoadingProjectRequests = true;
    });
    builder.addCase(getProjectRequests.fulfilled, (state, action) => {
      const { payload } = action;
      state.isLoadingProjectRequests = false;
      state.projectRequests = payload.map(transformRawScheduledProjectData);
    });
    builder.addCase(
      renameProjectOrScheduledProject.fulfilled,
      (state, action) => {
        const scheduledProjectId = action.meta.arg?.scheduled_project_id;
        const projectId = action.meta.arg?.project_id;
        const scheduledProject = state.scheduledProject;
        const title = action.meta.arg.title;
        if (scheduledProject && scheduledProject.id === scheduledProjectId) {
          scheduledProject.title = title;
        }
        if (projectId && scheduledProject) {
          scheduledProject.projects = scheduledProject.projects.map(
            (currentProject) => {
              if (currentProject.id === projectId) {
                currentProject.title = title;
                return currentProject;
              }
              return currentProject;
            },
          );
        }
        state.scheduledProject = scheduledProject;
      },
    );
    builder.addCase(manageScheduledProject.fulfilled, (state, action) => {
      const {
        payload,
        meta: {
          arg: { action: manageProjectAction },
        },
      } = action;
      if (manageProjectAction === ProjectManagementActions.RejectProject) {
        state.projectRequests = state.projectRequests.filter(
          (project) => project.id !== payload.id,
        );
      }
    });
    builder.addCase(fetchTransactionStatus.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === TransactionStatus.PAID) {
        if (state.scheduledProject) {
          state.scheduledProject = {
            ...state.scheduledProject,
            outstanding_balance: 0,
          };
        }
        state.paymentPlanPaid = true;
      }
    });
    builder.addCase(markFinalAssetsApproved.fulfilled, (state, action) => {
      const { payload } = action;
      if (!state.scheduledProject) return;

      const index = state.scheduledProject.projects.findIndex(
        (project) => project.id === payload.id,
      );
      if (index >= 0) {
        state.scheduledProject.projects[index].assets_approved =
          payload.assets_approved;
      }
    });
    builder.addMatcher(
      isAnyOf(
        engMasteringTransition.fulfilled,
        artistMasteringTransitions.fulfilled,
      ),
      (state, action) => {
        if (!state.scheduledProject) {
          return;
        }
        const args = action.meta.arg;
        const projectId = isNaN(+args.project_id)
          ? undefined
          : Number(args.project_id);
        if (!projectId) return;
        state.scheduledProject.projects = state.scheduledProject.projects.map(
          (currentProject) => {
            if (currentProject.id === projectId) {
              return {
                ...currentProject,
                step: action.payload.step,
                mastering_project: {
                  ...currentProject.mastering_project,
                  ...action.payload,
                },
              };
            }
            return currentProject;
          },
        );
      },
    );
    builder.addMatcher(
      isAnyOf(engMixTransition.fulfilled, artistMixingTransitions.fulfilled),
      (state, action) => {
        if (!state.scheduledProject) {
          return;
        }
        const args = action.meta.arg;
        const projectId = isNaN(+args.project_id)
          ? undefined
          : Number(args.project_id);
        if (!projectId) return;
        state.scheduledProject.projects = state.scheduledProject.projects.map(
          (currentProject) => {
            if (currentProject.id === projectId) {
              return {
                ...currentProject,
                step: action.payload.step,
                mixing_project: {
                  ...currentProject.mixing_project,
                  ...action.payload,
                },
              };
            }
            return currentProject;
          },
        );
      },
    );
  },
});

export const {
  addMockScheduledOverview,
  addMockCompleteTodayState,
  addMockProjectRequests,
  clearSchedulesProjects,
  clearScheduledProject,
  setScheduledProjectsSortBy,
  setProjects,
  setScheduledProjectsFilterBy,
  setScheduledProjectsSearchQuery,
  setScheduledProject,
  removeRescheduledProject,
  updateScheduledProjectsTracks,
  clearScheduledProjectOverview,
} = scheduledProjectsSlice.actions;
export default scheduledProjectsSlice.reducer;
