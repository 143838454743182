import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import "./ExpandableTextArea.css";
import { useDebounceFn } from "ahooks";

export interface ExpandableTextAreaProps {
  header?: string;
  body: string;
  maxHeight?: number;
  minHeight?: number;
  hideIcon?: boolean;
  isGreyVariant?: boolean;
}

export const ExpandableTextArea = ({
  header,
  body,
  maxHeight,
  minHeight,
  hideIcon = false,
  isGreyVariant,
}: ExpandableTextAreaProps) => {
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const textRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const handleClick = () => {
    setClamped(!clamped);
  };

  // If the window resizes, re-compute whether we need to show the
  // "Show More" button or not. Inspiration taken from:
  // https://plainenglish.io/blog/creating-a-read-more-collapsible-component-in-reactjs-6d55fbc4ff0f
  const hasClamping = (textElement: HTMLElement) => {
    const { clientHeight, scrollHeight } = textElement;
    return clientHeight < scrollHeight;
  };

  const checkButtonAvailability = () => {
    if (textRef.current) {
      // Save current state to reapply later if necessary.
      const hadClampClass =
        textRef.current.classList.contains("body-text-clamped");
      // Make sure that CSS clamping is applied if aplicable.
      if (!hadClampClass) {
        textRef.current.classList.add("body-text-clamped");
      }
      // Check for clamping and show or hide button accordingly.
      setShowButton(hasClamping(textRef.current));
      // Sync clamping with local state.
      if (!hadClampClass) {
        textRef.current.classList.remove("body-text-clamped");
      }
    }
  };

  const { run: debouncedCheck } = useDebounceFn(checkButtonAvailability, {
    wait: 50,
  });

  useEffect(() => {
    debouncedCheck();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, []);

  const textAreaClassNames = classNames({
    "body-text": !isGreyVariant,
    "body-text-grey": isGreyVariant,
    "body-text-clamped": clamped,
    "body-text-fade": clamped && showButton,
  });

  return (
    <div className="expandable-text-area">
      {header && <p className="collapsable-header">{header.toUpperCase()}</p>}
      <div
        className={textAreaClassNames}
        ref={textRef}
        style={{
          maxHeight: clamped ? maxHeight ?? "none" : "unset",
          minHeight: clamped ? minHeight ?? "unset" : "unset",
        }}
      >
        {body}
      </div>
      {showButton && (
        <div className="extend-text-container" onClick={handleClick}>
          <span className={classNames("extend-text", "h8-semi-bold")}>
            {clamped ? "Read More" : "Hide"}
          </span>
          {!hideIcon && (
            <FontAwesomeIcon
              color={theme.palette.text.primary}
              icon={clamped ? faChevronDown : faChevronUp}
            />
          )}
        </div>
      )}
    </div>
  );
};
