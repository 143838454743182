import queryString from "query-string";
import { NullableQueryParamsType } from "../store";
import {
  ArtistSuggestion,
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
  LocationSuggestion,
} from "../store/models/autocomplete";

export const QUERY_KEY_EXCLUDED_ENTRIES = ["longitude", "latitude"];

/**
 * Adds rate parameters to search params if applicable
 */
export const applyRateFiltersToSearchParams = (
  searchParamsObject: NullableQueryParamsType,
  maxRate: number,
  minRate: number,
  simpleBudgetSelected: string[],
) => {
  // Max Rate and Min Rate are prioritized
  if ((maxRate || minRate) && simpleBudgetSelected.length === 0) {
    searchParamsObject.max_rate = maxRate;
    searchParamsObject.min_rate = minRate;
    // Otherwise, we should set the simple_budget_selected if applicable
  } else {
    searchParamsObject.simple_budget_selected = simpleBudgetSelected;
  }
};

/**
 * Adds location parameters to search params if applicable
 */
export const applyMaxDistanceToSearchParams = (
  searchParamsObject: NullableQueryParamsType,
  autocompleteSuggestions: AutocompleteSuggestion[],
  maxDistance: number | null | undefined,
  cachedLatitude: number | null | undefined,
  cachedLongitude: number | null | undefined,
  includeLocationString = false,
) => {
  if (!maxDistance || !cachedLatitude || !cachedLongitude) return;

  const checkIfLocation = autocompleteSuggestions.find(
    (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
  ) as LocationSuggestion | undefined;

  // The latitude and longitude comes from the Autocomplete result if applicable
  if (checkIfLocation) {
    const { latitude, longitude, location_string } = checkIfLocation;
    searchParamsObject.latitude = parseFloat(latitude);
    searchParamsObject.longitude = parseFloat(longitude);

    if (includeLocationString) {
      searchParamsObject.location_string = location_string;
    }
    // Otherwise, we could use the cached latitude and longitude
  } else if (cachedLatitude && cachedLongitude) {
    searchParamsObject.latitude = cachedLatitude;
    searchParamsObject.longitude = cachedLongitude;
  }

  searchParamsObject.max_distance = maxDistance;
};

/**
 * Adds artist credit to the search parameters if an artist credit suggestion is present.
 */
export const applyArtistCreditToSearchParams = (
  searchParamsObject: NullableQueryParamsType,
  autocompleteSuggestions: AutocompleteSuggestion[],
) => {
  const checkIfArtistCredit = autocompleteSuggestions.find(
    (suggestion) => suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
  );
  if (checkIfArtistCredit) {
    const artistCredit = checkIfArtistCredit as ArtistSuggestion;
    searchParamsObject.artist_credit = artistCredit.label;
  }
};

/**
 * Creates a new object without specified keys from the source object.
 * @param sourceObject - The original object to filter.
 * @param keysToOmit - An array of keys to remove from the source object.
 * @returns A new object with the specified keys omitted.
 */
export const omitKeys = (sourceObject: object, keysToOmit: string[]) => {
  return Object.fromEntries(
    Object.entries(sourceObject).filter(([key]) => !keysToOmit.includes(key)),
  );
};

/**
 * Parses a comma-separated string of numbers into an array of integers.
 * Filters out any non-numeric values.
 * @param queryStringValue - The comma-separated string of numbers
 * @returns An array of parsed integers
 */
export const parseQueryStringAsNumberArray = (
  queryStringValue: string | undefined,
  parseMethod = parseQueryStringAsInt,
) => {
  return (queryStringValue
    ?.split(",")
    .map((el) => parseMethod(el))
    .filter((el) => !Number.isNaN(el)) || []) as number[];
};

/**
 * Parses a string into a floating-point number.
 * @param queryStringValue - The string to parse
 * @param defaultValue - The value to return if parsing fails
 * @returns The parsed floating-point number or the default value
 */
export const parseQueryStringAsFloat = (
  queryStringValue: string | undefined,
  defaultValue?: number,
) => {
  if (queryStringValue) {
    const queryStringValueAsFloat = parseFloat(queryStringValue);
    if (!Number.isNaN(queryStringValueAsFloat)) {
      return queryStringValueAsFloat;
    }
  }

  return defaultValue;
};

/**
 * Parses a string into an integer.
 * @param queryStringValue - The string to parse
 * @param defaultValue - The value to return if parsing fails
 * @returns The parsed integer or the default value
 */
export const parseQueryStringAsInt = (
  queryStringValue: string | undefined,
  defaultValue?: number,
) => {
  if (queryStringValue) {
    const queryStringValueAsInt = parseInt(queryStringValue);
    if (!Number.isNaN(queryStringValueAsInt)) {
      return queryStringValueAsInt;
    }
  }

  return defaultValue;
};

/**
 * Parses a string into a boolean value.
 * @param queryStringValue - The string to parse
 * @param defaultValue - The value to return if the string is not 'true'
 * @returns true if the string is 'true', otherwise the default value
 */
export const parseQueryStringAsBoolean = (
  queryStringValue: string | undefined,
  defaultValue = false,
) => {
  if (queryStringValue && queryStringValue === "true") {
    return true;
  }

  return defaultValue;
};

/**
 * Parses the current URL's query parameters into a key-value object.
 * @returns An object with query parameter keys and their string values
 */
export const parseUrlQueryParams = () => {
  return queryString.parse(location.search) as Record<
    string,
    string | undefined
  >;
};
