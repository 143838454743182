import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIMasteringProject } from "../../models/project";
import { makeBackendPostCallWithJsonResponse } from "../../utils/fetch";
import { ARTIST_MASTER_PROJECT } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { masteringTransitionParams } from "./types";

interface artistMasteringTransition extends masteringTransitionParams {
  artist_master_notes?: string;
  artist_master_revision_notes?: string;
  code?: string | null;
  artist_file_link?: string;
  final_asset_file_notes?: string;
  instagram_username?: string;
  social_media_sharing_approval?: boolean;
}

const artistMasteringTransitions = createAsyncThunk(
  ARTIST_MASTER_PROJECT,
  async (args: artistMasteringTransition, thunkAPI) => {
    if (!args.code) {
      delete args.code;
    }
    const response =
      await makeBackendPostCallWithJsonResponse<APIMasteringProject>(
        ARTIST_MASTER_PROJECT,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default artistMasteringTransitions;
