import { useMutation } from "@tanstack/react-query";
import {
  ResendEmailVerificationArgs,
  sendEmailVerification,
} from "./sendEmailVerification";

const useSendEmailVerificationCodeMutation = () => {
  return useMutation({
    mutationKey: ["send_email_verification_code"],
    mutationFn: (
      args: ResendEmailVerificationArgs = {
        send_verification_code: true,
      },
    ) => {
      return sendEmailVerification(args);
    },
  });
};

export default useSendEmailVerificationCodeMutation;
