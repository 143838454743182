import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { RESEND_EMAIL_VERIFICATION } from "../../store/utils/routes";

export interface ResendEmailVerificationArgs {
  send_verification_code?: boolean;
  email_or_username?: string;
}

export const sendEmailVerification = async (
  args: ResendEmailVerificationArgs,
) => {
  const result = await makeBackendPostCallWithJsonResponse(
    RESEND_EMAIL_VERIFICATION,
    args,
  );
  if (result.success) {
    return result.resultJson;
  }
  return Promise.reject(result.resultJson);
};
