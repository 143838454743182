import { styled } from "@mui/material";

export const BottomNavContainer = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "$showNav" && prop !== "$navItemsHeight",
})<{
  $showNav?: boolean;
  $navItemsHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  transition: 0.3s ease;
  z-index: var(--bottom-nav-z-index) !important;
  bottom: ${({ $navItemsHeight, $showNav }) =>
    $showNav ? 0 : ($navItemsHeight || 0) * -1}px;
`;
