import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIMasteringProject, APIMixingProject } from "../../models/project";
import { makeBackendGetCallWithJsonResponse } from "../../utils/fetch";
import { LOAD_IN_PROGRESS_PROJECT } from "../../utils/routes";
import { receiveErrors } from "../errorStore";

export interface loadInProgressProjectParams {
  project_id: string;
  code: string;
}

const loadInProgressProject = createAsyncThunk(
  LOAD_IN_PROGRESS_PROJECT,
  async ({ project_id, code }: loadInProgressProjectParams, thunkAPI) => {
    const params = `?project_id=${project_id}&code=${code}`;
    const response = await makeBackendGetCallWithJsonResponse<
      APIMixingProject | APIMasteringProject
    >(LOAD_IN_PROGRESS_PROJECT, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default loadInProgressProject;
