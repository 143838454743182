// getter/setter for the search term
import { atom } from "jotai";
import { searchParamsAtom, setSearchParamsHelper } from "./locationAtom";

export const statusAtom = atom(
  (get) => {
    const value = parseInt(get(searchParamsAtom).status || "");
    if (Number.isNaN(value)) {
      return 0;
    }
    return value;
  },
  (get, set, value: number) => {
    setSearchParamsHelper(get, set, {
      key: "status",
      value: String(value),
      clear: true,
    });
  },
);

statusAtom.debugLabel = "Status Atom";
