import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIMixingProject } from "../../models/project";
import { makeBackendPostCallWithJsonResponse } from "../../utils/fetch";
import { ENGINEER_MIXING_PROJECT } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { ProjectParams } from "./types";

export interface engineerMixTransitionParams extends ProjectParams {
  engineer_mix_notes?: string;
  engineer_file_notes?: string;
  engineer_reference_notes?: string;
  enable_artist_review_mp3_file_download?: boolean;
  enable_artist_review_wav_file_download?: boolean;
}

const engMixTransition = createAsyncThunk(
  ENGINEER_MIXING_PROJECT,
  async (args: engineerMixTransitionParams, thunkAPI) => {
    const response =
      await makeBackendPostCallWithJsonResponse<APIMixingProject>(
        ENGINEER_MIXING_PROJECT,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default engMixTransition;
