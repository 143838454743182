import {
  AccountSettingsTextField,
  AccountSettingsTextFieldProps,
} from "./AccountSettingsTextField";
import { ValidationLabelProps } from "./ValidationLabel";

export const checkHasSpace = (value: string) => {
  return /\s/.test(value);
};

const HAS_SPACE_VALIDATION_KEY = "hasSpace";
export const HAS_SPACE_VALIDATION_LABEL: ValidationLabelProps = {
  label: "Username cannot contain spaces",
};

export const checkHasSpecialCharacter = (value: string) => {
  return /[^a-zA-Z0-9_-]/.test(value);
};

const HAS_SPECIAL_CHARACTER_VALIDATION_KEY = "hasSpecialCharacter";
export const HAS_SPECIAL_CHARACTER_VALIDATION_LABEL: ValidationLabelProps = {
  label: "No special characters (dashes and underscores are allowed)",
};

const VALIDATION_TESTS: {
  key: string;
  label: ValidationLabelProps;
  func: (value: string) => boolean;
}[] = [
  {
    key: HAS_SPACE_VALIDATION_KEY,
    label: HAS_SPACE_VALIDATION_LABEL,
    func: checkHasSpace,
  },
  {
    key: HAS_SPECIAL_CHARACTER_VALIDATION_KEY,
    label: HAS_SPECIAL_CHARACTER_VALIDATION_LABEL,
    func: checkHasSpecialCharacter,
  },
];

export const validateUsername = (value: string) => {
  let hadError = false;
  const results: Record<string, ValidationLabelProps> = {
    [HAS_SPACE_VALIDATION_KEY]: HAS_SPACE_VALIDATION_LABEL,
  };
  VALIDATION_TESTS.forEach((test) => {
    results[test.key] = { invalid: false, ...test.label };
    if (test.func(value)) {
      hadError = true;
      results[test.key].invalid = true;
    }
  });
  if (!hadError) return {} as Record<string, ValidationLabelProps>;
  return results;
};

interface UsernameFieldProps extends AccountSettingsTextFieldProps {
  isStudio?: boolean;
}

export const UsernameField = ({
  isStudio,
  TextFieldProps,
  ...props
}: UsernameFieldProps) => {
  return (
    <AccountSettingsTextField
      label={"Username"}
      name={"username"}
      TextFieldProps={{
        placeholder: "Username",
        ...TextFieldProps,
      }}
      helperText={isStudio ? "" : "Note: used to login"}
      {...props}
    />
  );
};
