import { styled } from "@mui/material";

export const StyledPlaylistElementContainer = styled("div")`
  display: flex;
  padding: 8px;
  width: 100%;
  height: 54px;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const StyledPlaylistElementImg = styled("img")`
  border-radius: var(--border-radius-md);
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledPlaylistElementTitle = styled("p")`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
`;

export const StyledPlaylistElementText = styled("p")`
  color: ${({ theme }) => theme.palette.standardColor.Gray[400]};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 14px;
`;

export const StyledPlaylistElementIndex = styled(StyledPlaylistElementTitle)<{
  $isPlaying?: boolean;
}>`
  text-align: center;
  font-weight: 500;
  color: ${({ theme, $isPlaying }) =>
    $isPlaying
      ? theme.palette.standardColor.Green[600]
      : theme.palette.common.white};
`;

export const StyledPlaylistElementTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`;
