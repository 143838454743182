import { createAsyncThunk } from "@reduxjs/toolkit";
import { downloadFilesFromUrlsWithDelay } from "../../utils/downloadFilesFromUrls";
import { makeBackendGetCallWithJsonResponse } from "../../utils/fetch";
import { DOWNLOAD_ALL_FILES } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { DownloadFilesResponse } from "./types";

export interface downloadAllFilesParams {
  scheduledProjectId: string;
  shareLinkCode?: string;
}

const downloadAllFilesForScheduledProject = createAsyncThunk(
  DOWNLOAD_ALL_FILES,
  async (
    { scheduledProjectId, shareLinkCode }: downloadAllFilesParams,
    thunkAPI,
  ) => {
    let params = `?scheduled_project_id=${scheduledProjectId}`;
    if (shareLinkCode) {
      params += `&code=${shareLinkCode}`;
    }
    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        DOWNLOAD_ALL_FILES,
        params,
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default downloadAllFilesForScheduledProject;
