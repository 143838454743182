export enum POWER_USER_CONTROLS {
  META = "meta",
  DARK_MODE = "d",
  HELP = "forwardslash",
}

export enum POWER_USER_CONTROLS_LABEL {
  DARK_MODE = "Dark Mode",
  HELP = "Help",
}

export enum POWER_USER_CONTROLS_CATEGORY {
  GENERAL = "General",
}

// Basic list of platforms we support for keyboard controls
export enum PLATFORM {
  WINDOWS = "windows",
  MAC = "mac",
  IOS = "ios",
}

export interface PowerUserControlHookData {
  keys: POWER_USER_CONTROLS[];
  label: string;
  description?: string;
  category?: POWER_USER_CONTROLS_CATEGORY;
}

export type PowerUserControlKeysData = Record<string, PowerUserControlHookData>;

export const COMMANDS: Record<
  POWER_USER_CONTROLS_LABEL,
  { keys: POWER_USER_CONTROLS[]; label: string }
> = {
  [POWER_USER_CONTROLS_LABEL.DARK_MODE]: {
    keys: [POWER_USER_CONTROLS.META, POWER_USER_CONTROLS.DARK_MODE],
    label: "Toggle Dark Mode",
  },
  [POWER_USER_CONTROLS_LABEL.HELP]: {
    keys: [POWER_USER_CONTROLS.META, POWER_USER_CONTROLS.HELP],
    label: "Show/Hide Shortcuts",
  },
};
