import { Studio, StudioRoom } from "../store/models/studio";
import User from "../store/models/user";

export const isValidRecordingServiceStudio = (
  studio: Studio | undefined,
  loggedInUser: User | undefined,
  // if this is false, this function will not check whether the studio has any rooms with a recording service.
  checkIfRecordingServiceExists = true,
  studioRooms: StudioRoom[] = [],
) => {
  if (!studio || !loggedInUser) {
    return true;
  }
  if (
    checkIfRecordingServiceExists &&
    studioRooms?.some((room) => room.recording_service)
  ) {
    return true;
  }
  const managers = studio.studio_team.managers ?? [];
  if (managers.some((manager) => manager?.id === loggedInUser.id)) {
    return true;
  }
  const engineerUsers = studio.studio_team.members ?? [];
  if (
    engineerUsers.some((engineerUser) => engineerUser?.id === loggedInUser.id)
  ) {
    return Boolean(studio.affiliated_engineer_booking_links_enabled);
  }

  return true;
};
