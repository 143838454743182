import { porfolioCoverArtPathPrefix } from "../../../utils";
import { getDisplayableNameForUser } from "../../../utils/entityUtils";
import { PortfolioFeatureData } from "../../portfolio";
import { PlaylistTrack, PlaylistTrackType } from "../types";

const convertPortfolioFeatureDataToPlayListTrack = (
  portfolioFeatureData?: PortfolioFeatureData,
): PlaylistTrack => {
  if (!portfolioFeatureData) {
    return {
      id: 0,
      type: PlaylistTrackType.PortfolioFeatureData,
      title: "",
      artistDisplayName: "",
      artistUsername: "",
      engineerUsername: "",
      engineerDisplayName: "",
    };
  }
  const { muso_credit, featured_track_artwork } = portfolioFeatureData;
  const source = featured_track_artwork?.path
    ? porfolioCoverArtPathPrefix + featured_track_artwork?.path
    : undefined;
  const externalSource = muso_credit?.album_art;
  const artworkSource = source || externalSource;
  return {
    id: portfolioFeatureData.id || 0,
    type: PlaylistTrackType.PortfolioFeatureData,
    title: portfolioFeatureData.title,
    artistDisplayName: portfolioFeatureData.artist,
    artistUsername: "",
    engineerUsername: portfolioFeatureData.user?.username || "",
    engineerDisplayName: getDisplayableNameForUser(portfolioFeatureData?.user),
    artworkSource: artworkSource,
  };
};

export default convertPortfolioFeatureDataToPlayListTrack;
