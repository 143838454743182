import { Theme, useTheme } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { PALETTE_MODE } from "../../../../atoms/user/darkModeAtom";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import {
  CardContentContainer,
  PrimaryFilterCardContainer,
  StyledCheckbox,
} from "./PrimaryFilterCard.styles";

export interface PrimaryFilterCardProps {
  variant: PrimaryFilterCardVariant;
  active: boolean;
  inactive: boolean;
  name: PrimaryFilterInputName;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title: string | ReactNode;
  subTitle: string;
}

export const PrimaryFilterCard = ({
  variant,
  active,
  inactive,
  name,
  onSelect,
  title,
  subTitle,
}: PrimaryFilterCardProps) => {
  const theme = useTheme();

  const activeStateBorderColor = useMemo(() => {
    return getActiveStateBorderColor(theme, variant);
  }, [theme, variant]);

  const activeStateBackgroundColor = useMemo(() => {
    return getActiveStateBackgroundColor(theme)[theme.palette.mode][variant];
  }, [theme, variant]);

  const defaultBackgroundColor = useMemo(() => {
    if (active && !inactive) {
      return activeStateBackgroundColor;
    }

    return "transparent";
  }, [active, activeStateBackgroundColor, inactive]);

  const defaultBorderColor = useMemo(() => {
    if (active && !inactive) {
      return activeStateBorderColor;
    }

    if (inactive && theme.palette.mode === PALETTE_MODE.DARK) {
      return theme.palette.text.disabled;
    }

    return theme.palette.standardColor.Gray[100];
  }, [
    active,
    activeStateBorderColor,
    inactive,
    theme.palette.mode,
    theme.palette.standardColor.Gray,
    theme.palette.text.disabled,
  ]);

  return (
    <PrimaryFilterCardContainer
      control={
        <StyledCheckbox
          disableRipple
          name={name}
          onChange={onSelect}
          checked={active}
        />
      }
      $borderColor={defaultBorderColor}
      $backgroundColor={defaultBackgroundColor}
      $inactive={inactive}
      $activeStateBorderColor={activeStateBorderColor}
      label={
        <CardContentContainer>
          {typeof title === "string" ? (
            <Text bold fontSize="20px">
              {title}
            </Text>
          ) : (
            title
          )}
          <Text variant={TextStyleVariant.P3}>{subTitle}</Text>
        </CardContentContainer>
      }
    />
  );
};

export enum PrimaryFilterCardVariant {
  STUDIO = "studio",
  ENGINEER = "engineer",
  PRO = "pro",
}

export enum PrimaryFilterInputName {
  STUDIOS = "studios",
  RECORDING = "recording",
  MIXING = "mixing",
  MASTERING = "mastering",
  ATMOS = "atmos",
  PRO = "pro",
}

const getActiveStateBackgroundColor = (theme: Theme) => {
  return {
    [PALETTE_MODE.LIGHT]: {
      [PrimaryFilterCardVariant.STUDIO]: theme.palette.standardColor.Green[50],
      [PrimaryFilterCardVariant.ENGINEER]:
        theme.palette.standardColor.DeepBlue[50],
      [PrimaryFilterCardVariant.PRO]:
        theme.palette.standardColor.BoomyOrange[50],
    },
    [PALETTE_MODE.DARK]: {
      [PrimaryFilterCardVariant.STUDIO]: theme.palette.standardColor.Green[950],
      [PrimaryFilterCardVariant.ENGINEER]:
        theme.palette.standardColor.DeepBlue[950],
      [PrimaryFilterCardVariant.PRO]:
        theme.palette.standardColor.BoomyOrange[950],
    },
  };
};

const getActiveStateBorderColor = (
  theme: Theme,
  variant: PrimaryFilterCardVariant,
) => {
  switch (variant) {
    case PrimaryFilterCardVariant.STUDIO:
      return theme.palette.standardColor.Green[600];
    case PrimaryFilterCardVariant.ENGINEER:
      return theme.palette.standardColor.DeepBlue[400];
    case PrimaryFilterCardVariant.PRO:
      return theme.palette.standardColor.BoomyOrange[500];
    default:
      return theme.palette.standardColor.Gray[100];
  }
};
