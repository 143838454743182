import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { StatusCodes } from "http-status-codes";
import { checkIfPasswordlessAccount } from "../../api/auth/checkIfPasswordlessAccount";

export const useCheckIfPasswordlessAccountQuery = (
  email: string,
  isValidEmail: boolean,
) => {
  return useQuery({
    enabled: isValidEmail,
    queryKey: [QUERY_KEYS.CHECK_IF_PASSWORDLESS_ACCOUNT, email],
    queryFn: async () => {
      emitAnalyticsTrackingEvent("check_if_passwordless_account", {
        email,
      });
      try {
        const response = await checkIfPasswordlessAccount(email);
        return response.statusCode === StatusCodes.NO_CONTENT;
      } catch {
        return false;
      }
    },
  });
};
