import { Theme, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { GenresList } from "../../core-ui/components/GenresList/GenresList";
import { Collaborators } from "../../screens/ProfileScreen/components/Collaborators/Collaborators";
import { MusoCollaborators } from "../../screens/ProfileScreen/components/MusoCollaborators/MusoCollaborators";
import { StudiosSection } from "../../screens/ProfileScreen/components/StudioSection/StudioSection";
import { LongBio } from "../LongBio/LongBio";
import { OverviewContainer, OverviewMobileContainer } from "./Overview.styles";
import { EquipmentSection } from "../../screens/ProfileScreen/components/Equipment/EquipmentSection";
import { useMemo } from "react";

export const Overview = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);

  const isEngineer = useMemo(() => {
    return Boolean(userData?.engineer && !userData?.engineer.deleted);
  }, [userData]);

  const isStudioManager = useMemo(() => {
    return Boolean(
      userData?.studio_manager && !userData?.studio_manager.deleted,
    );
  }, [userData]);

  if (!userData) {
    return null;
  }

  return (
    <OverviewContainer>
      <LongBio />
      {isMobile && (
        <OverviewMobileContainer>
          <GenresList userId={userData.id} />
          {isEngineer && <MusoCollaborators userData={userData} />}
          <Collaborators onSidebar />
        </OverviewMobileContainer>
      )}
      {(isEngineer || isStudioManager) && <StudiosSection />}
      {(isEngineer || isStudioManager) && <EquipmentSection />}
    </OverviewContainer>
  );
};
