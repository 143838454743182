import { lazy } from "react";

export const MyLibraryPage = lazy(
  () => import("../stories/screens/MyLibrary/MyLibrary"),
);

export const ForAllPage = lazy(
  () => import("../stories/screens/HomeScreen/HomeScreen"),
);

export const ReleaseCheckoutScreenPage = lazy(
  () =>
    import("../stories/screens/ReleasesCheckoutScreen/ReleasesCheckoutScreen"),
);

export const ReleaseDetailScreenPage = lazy(
  () => import("../stories/screens/ReleaseDetailScreen/ReleaseDetailScreen"),
);

export const BookingsScreenPage = lazy(
  () => import("../stories/screens/BookingsScreen/BookingsScreen"),
);

export const AdminPerformanceChartScreenPage = lazy(
  () =>
    import(
      "../stories/screens/AdminPerformanceChartScreen/AdminPerformanceChartScreen"
    ),
);

export const PaginatedVerifiedEntityScreenPage = lazy(
  () =>
    import(
      "../stories/screens/PaginatedVerifiedEntityScreens/PaginatedVerifiedEntityScreen"
    ),
);

export const PaginatedVerifiedStudioRoomsScreenPage = lazy(
  () =>
    import(
      "../stories/screens/PaginatedVerifiedEntityScreens/PaginatedVerifiedStudioRoomsScreen"
    ),
);

export const FavoritesScreenPage = lazy(
  () => import("../stories/screens/FavoritesScreen/FavoritesScreen"),
);

export const RecordingSessionScreenPage = lazy(
  () =>
    import("../stories/screens/RecordingSessionScreen/RecordingSessionScreen"),
);

export const AandRSetupScreenPage = lazy(
  () => import("../stories/screens/AandRSetupScreen/AandRSetupScreen"),
);

export const DolbyCourseRegistrationPage = lazy(
  () =>
    import(
      "../stories/screens/DolbyCourseRegistrationPage/DolbyCourseRegistrationPage"
    ),
);

export const ProToolRegistrationPage = lazy(
  () =>
    import(
      "../stories/screens/ProToolsRegistrationPage/ProToolsRegistrationPage"
    ),
);

export const NammRegistrationPage = lazy(
  () => import("../stories/screens/NammRegistrationPage/NammRegistrationPage"),
);

export const UserSatisfactionRatingPage = lazy(
  () =>
    import("../stories/screens/UserSatisfactionRating/UserSatisfactionRating"),
);

export const ScheduledProjectOverviewScreenPage = lazy(
  () =>
    import(
      "../stories/screens/LoggedOutProjectOverview/ScheduledProjectOverviewScreen"
    ),
);

export const MobileCheckoutScreenPage = lazy(
  () => import("../stories/screens/MobileCheckoutScreen/MobileCheckoutScreen"),
);

export const StudioRoomScreenPage = lazy(
  () => import("../stories/screens/StudioScreen/StudioRoomScreen"),
);

export const StudioScreenPage = lazy(
  () => import("../stories/screens/StudioScreen/StudioScreen"),
);

export const LoggedOutReviewScreenPage = lazy(
  () =>
    import("../stories/screens/LoggedOutReviewScreen/LoggedOutReviewScreen"),
);

export const LoggedOutUploadScreenPage = lazy(
  () =>
    import("../stories/screens/LoggedOutUploadScreen/LoggedOutUploadScreen"),
);

export const LoggedOutRecordingSessionDetailsScreenPage = lazy(
  () =>
    import(
      "../stories/screens/LoggedOutRecordingSessionDetailsScreen/LoggedOutRecordingSessionDetailsScreen"
    ),
);

export const ResetPasswordScreenPage = lazy(
  () => import("../stories/screens/ResetPasswordScreen/ResetPasswordScreen"),
);

export const ModularDashboardPage = lazy(
  () => import("../stories/screens/Dashboard/ModularDashboard"),
);

export const CustomerPortalHomeScreenPage = lazy(
  () =>
    import("../stories/screens/CustomerPortalHomeScreen/CustomerPortalIndex"),
);

export const KlarnaPaymentRedirectScreenPage = lazy(
  () =>
    import(
      "../stories/screens/StripePaymentRedirectScreen/KlarnaPaymentRedirectScreen"
    ),
);

export const CashAppPaymentRedirectScreenPage = lazy(
  () =>
    import(
      "../stories/screens/StripePaymentRedirectScreen/CashAppPaymentRedirectScreen"
    ),
);

export const AffirmPaymentRedirectScreenPage = lazy(
  () =>
    import(
      "../stories/screens/StripePaymentRedirectScreen/AffirmPaymentRedirectScreen"
    ),
);

export const MusoAuthRedirectScreenPage = lazy(
  () =>
    import("../stories/screens/MusoAuthRedirectScreen/MusoAuthRedirectScreen"),
);

export const MusoSelectionScreenPage = lazy(
  () => import("../stories/screens/MusoSelectionScreen/MusoSelectionScreen"),
);

export const VerifyEmailScreenPage = lazy(
  () => import("../stories/screens/VerifyEmailScreen/VerifyEmailScreen"),
);

export const StripeSetupRedirectPage = lazy(
  () => import("../stories/screens/StripeSetupRedirect/StripeSetupRedirect"),
);

export const MobileNotificationsScreenPage = lazy(
  () =>
    import(
      "../stories/screens/MobileNotificationsScreen/MobileNotificationsScreen"
    ),
);

export const ProjectsPage = lazy(() => import("../stories/screens/Projects"));

export const ProjectScreenPage = lazy(
  () => import("../stories/screens/ProjectScreen/ProjectScreen"),
);

export const MessagesPage = lazy(
  () => import("../stories/screens/MessagesV2Screen/index"),
);

export const CommunityPage = lazy(
  () => import("../stories/screens/CommunityScreen/CommunityScreen"),
);

export const MobileProjectReviewScreenPage = lazy(
  () =>
    import(
      "../stories/screens/MobileProjectReviewScreen/MobileProjectReviewScreen"
    ),
);

export const FindMyEngineerScreenPage = lazy(
  () => import("../stories/screens/FindMyEngineerScreen/FindMyEngineerScreen"),
);

export const ExploreScreenPage = lazy(
  () => import("../stories/screens/ExploreScreen/ExploreScreen"),
);

export const AddSubscriptionScreenPage = lazy(
  () =>
    import("../stories/screens/AddSubscriptionScreen/AddSubscriptionScreen"),
);

export const LoadPredefinedProjectScreenPage = lazy(
  () =>
    import(
      "../stories/screens/LoadPredefinedProjectScreen/LoadPredefinedProject"
    ),
);

export const TransactionOverviewScreenPage = lazy(
  () =>
    import(
      "../stories/screens/TransactionOverviewScreen/TransactionOverviewScreen"
    ),
);

export const OnboardingRSVPScreenPage = lazy(
  () => import("../stories/screens/OnboardingRSVPScreen/OnboardingRSVPScreen"),
);

export const TeamInviteScreenPage = lazy(
  () => import("../stories/screens/TeamInviteScreen/TeamInviteScreen"),
);

export const AdminDashboardScreenPage = lazy(
  () => import("../stories/screens/AdminDashboardScreen/AdminDashboardScreen"),
);

export const BlogScreenPage = lazy(
  () => import("../stories/screens/BlogScreen/BlogScreen"),
);

export const TransactionBookingScreenPage = lazy(
  () =>
    import(
      "../stories/screens/TransactionBookingScreen/TransactionBookingScreen"
    ),
);

export const LivestreamScreenPage = lazy(
  () => import("../stories/screens/LivestreamScreen/LivestreamScreen"),
);

export const FeaturedTrackReviewPage = lazy(
  () => import("../stories/screens/FeaturedTrackReview/FeaturedTrackReview"),
);

export const SelectFeaturedTrackScreenPage = lazy(
  () =>
    import(
      "../stories/screens/SelectFeaturedTrackScreen/SelectFeaturedTrackScreen"
    ),
);

export const MobileAuthPage = lazy(
  () => import("../stories/components/Auth/AuthModal"),
);

export const SubscriptionSuccessScreenPage = lazy(
  () =>
    import("../stories/screens/SubscriptionSuccess/SubscriptionSuccessScreen"),
);

export const DistributionScreenPage = lazy(
  () => import("../stories/screens/Distribution/DistributionScreen"),
);

export const ProfileScreenPage = lazy(
  () => import("../stories/screens/ProfileScreen/ProfileScreenV2"),
);

export const BeatMatrixScreenPage = lazy(
  () => import("../stories/screens/BeatMatrixScreen/BeatMatrixScreen"),
);

export const BeatMatrixSubmissionScreenPage = lazy(
  () =>
    import("../stories/screens/BeatMatrixSubmissions/BeatMatrixSubmissions"),
);

export const ExternalPage = ({ path }: { path: string }) => {
  window.location.href = path;
  return null;
};
