import { useNavigate } from "react-router-dom";
import {
  useGetMusoAuth,
  useMusoCardText,
} from "../../../hooks/useGetMusoCredits";
import { SCREENS } from "../../../routes/screens";
import { useAppSelector } from "../../../store/hooks";
import {
  getMusoAuthorizationURL,
  MUSO_ASSOCIATION_ACCEPTED_STATUSES,
  MusoSelectionTab,
} from "../../../store/models/muso";
import { SUBSCRIPTION_STATUS } from "../../../store/models/subscription";
import { isEngineerOnboardedSelector } from "../../../store/selectors/userInfoSelectors";
import { getMusoSelectionScreenRoute } from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
// @ts-ignore
import MusoLogo from "../../assets/muso-card.svg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import "./MusoCard.css";

export const MusoAuthButtons = () => {
  const navigate = useNavigate();
  const engineerIsOnboarded = useAppSelector(isEngineerOnboardedSelector);
  const subscription = useAppSelector((state) => state.subscriptionStore);

  const {
    activeMusoUserId,
    loading: isLoadingMusoAssociation,
    musoAssociation,
  } = useGetMusoAuth(true);

  const onClickHandler = () => {
    if (
      subscription.stripe_subscription_status !== SUBSCRIPTION_STATUS.ACTIVE
    ) {
      emitAnalyticsTrackingEvent("click_muso_subscription_required_button", {});
      navigate(SCREENS.ADD_SUBSCRIPTION);
      return;
    }

    const redirectPath = getMusoSelectionScreenRoute(
      musoAssociation &&
        MUSO_ASSOCIATION_ACCEPTED_STATUSES.includes(musoAssociation.status)
        ? MusoSelectionTab.CREDITS
        : MusoSelectionTab.ROSTER,
    );

    if (!activeMusoUserId) {
      emitAnalyticsTrackingEvent("click_muso_link_profile_button", {
        musoAssociationId: musoAssociation?.id,
      });
      window.location.assign(getMusoAuthorizationURL(redirectPath));
      return;
    }

    emitAnalyticsTrackingEvent("click_muso_credits_button", {
      musoAssociationId: musoAssociation?.id,
      redirectPath: redirectPath,
    });
    navigate(redirectPath);
  };

  const label = useMusoCardText(
    engineerIsOnboarded,
    subscription,
    musoAssociation,
    activeMusoUserId,
  );

  return (
    <div className="muso-card-button-container">
      <img src={MusoLogo} alt="Muso" className="muso-card-icon" />
      <Button
        style={{ color: "black" }}
        loading={isLoadingMusoAssociation}
        disabled={!engineerIsOnboarded}
        variant={ButtonVariant.UNSTYLED}
        onClick={onClickHandler}
      >
        {label}
      </Button>
    </div>
  );
};
