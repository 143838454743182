import { StyledText } from "./Text.styles";
import {
  MUI_TEXT_VARIANTS,
  TEXT_COMPONENT,
  TextColor,
  TextStyleVariant,
} from "./TextUtils";
import { TypographyProps } from "@mui/material";

export const TEXT_COLOR = TextColor;

export enum TEXT_SIZE {
  XXS = "var(--text-size-xxs)",
  XS = "var(--text-size-xs)",
  SMALL = "var(--text-size-sm)",
  MEDIUM = "var(--text-size-md)",
  LARGE = "var(--text-size-lg)",
}

export enum TEXT_WEIGHT {
  MEDIUM = "",
  SEMI_BOLD = "var(--text-weight-semibold)",
  BOLD = "var(--text-weight-bold)",
}

export enum TEXT_LINE_HEIGHT {
  SHORT = "var(--text-line-height-short)",
  NORMAL = "",
  LONG = "var(--text-line-height-long)",
  NO_EXTRA_HEIGHT = "1",
}

export interface TextProps extends Omit<TypographyProps, "variant"> {
  bold?: boolean;
  color?: TextColor | string;
  lineHeight?: TEXT_LINE_HEIGHT; // deprecated (use variant instead)
  size?: TEXT_SIZE; // deprecated (use variant instead)
  variant?: TextStyleVariant;
  weight?: TEXT_WEIGHT; // deprecated (use bold instead)
}

/**
 * A basic text component that can be used to render text with different styles.
 *
 * Use the TextStyleVariant enum to set the style of the text in the `variant` prop.
 * Optionally define the HTML element in the `component` prop (defaults to `p`).
 *
 * Bold text can be set using the `bold` prop.
 * Color can be set using the `color` prop.
 *
 * sx or style can be used for custom styling.
 * All other props should be phased out.
 */
export const Text = ({
  bold = false,
  children,
  component,
  color,
  className,
  lineHeight,
  size,
  style,
  variant = TextStyleVariant.P1,
  weight,
  ...props
}: TextProps) => {
  return (
    <StyledText
      // for some reason, styled Typography cannot accept component prop
      as={component || TEXT_COMPONENT[variant]}
      $bold={bold}
      className={className}
      $color={color}
      variant={MUI_TEXT_VARIANTS[variant]}
      style={{
        ...(lineHeight && { lineHeight }),
        ...(size && { fontSize: size }),
        ...(weight && { fontWeight: weight }),
        ...style,
      }}
      {...props}
    >
      {children}
    </StyledText>
  );
};
