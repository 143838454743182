import { useAtomValue } from "jotai";
import { useCallback, useRef } from "react";
import { useBeforeUnload, useLocation } from "react-router-dom";
import { navElementRefAtom } from "../../../atoms/navAtoms";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const elementRef = useAtomValue(navElementRefAtom);
  const previousPathname = useRef<string>();
  useBeforeUnload(
    useCallback(() => {
      const scrollToTop = () => elementRef?.current?.scrollTo(0, 0);
      if (previousPathname.current && previousPathname.current !== pathname) {
        scrollToTop();
      }
      previousPathname.current = pathname;
    }, [elementRef, previousPathname, pathname]),
  );

  return null;
};

export default ScrollToTop;
