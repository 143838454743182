import {
  AccountSettingsTextField,
  AccountSettingsTextFieldProps,
} from "./AccountSettingsTextField";

export interface DisplayNameFieldProps extends AccountSettingsTextFieldProps {
  isStudio?: boolean;
}

export const DisplayNameField = ({
  isStudio = false,
  TextFieldProps,
  ...props
}: DisplayNameFieldProps) => {
  return (
    <AccountSettingsTextField
      label={isStudio ? "Studio Facility Name" : "Display Name"}
      name={"display_name"}
      TextFieldProps={{
        placeholder: isStudio ? "Studio Name" : "Display Name",
        ...TextFieldProps,
      }}
      helperText={`Note: shown publicly on your ${isStudio ? "studio" : "profile"} page`}
      {...props}
    />
  );
};
