import {
  CheckBoxGroupTitle,
  CheckboxLabel,
} from "../../../core-ui/components/Select/Select.styles";
import { styled } from "@mui/material";

export const Container = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: 600;
`;

export const Header = styled("div")`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background-color: var(--background-color);
  z-index: var(--modal-index);
`;

export const HeaderContents = styled("div")`
  padding: 18px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
`;

export const Body = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  flex: 1;

  ${CheckBoxGroupTitle} {
    padding: 19px 24px 11px 24px;
  }

  ${CheckboxLabel} {
    padding: 19px 40px;
  }
`;

export const Footer = styled("div")`
  padding: 32px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  background-color: var(--background-color);
  z-index: var(--modal-index);

  @media (max-width: 480px) {
    padding: 16px 20px;
  }
`;

export const CloseButton = styled("button")`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  border: none;
  border-radius: var(--border-radius-sm);
  background-color: transparent;
  padding: 0.1rem 0.25rem;

  &:hover {
    background-color: var(--button-secondary-color--hover);
  }
`;
