import { Skeleton } from "@mui/material";
import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetUserCollaborators } from "../../../../../hooks/profileScreenHooks/useGetUserCollaborators";
import { useGetUserProfile } from "../../../../../hooks/profileScreenHooks/useGetUserProfile";
import { getImageURLFromPath } from "../../../../../hooks/useImageURLFromPath";
import { getDisplayableNameForUser } from "../../../../../store/utils/entityUtils";
import { getProfileScreenRoute } from "../../../../../store/utils/routeGetters";
import { ISingleItem } from "../../../../core-ui/components/ChipsList/ChipsList";
import { ChipsListDisplay } from "../../../../core-ui/components/ChipsList/ChipsListDisplay";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import {
  CollaboratorBox,
  CollaboratorItemsBox,
  IndividualCollaboratorItemBox,
} from "./Collaborators.styles";

interface CollaboratorItemProps {
  name: string;
  path: string;
  imagePath?: string;
}

const CollaboratorItem = ({ name, path, imagePath }: CollaboratorItemProps) => {
  return (
    <Link to={path} style={{ textDecoration: "unset" }}>
      <IndividualCollaboratorItemBox>
        <img
          width={100}
          height={100}
          alt={name}
          src={imagePath}
          style={{ borderRadius: "50%" }}
        />
        <Text variant={TextStyleVariant.P2}>{name}</Text>
      </IndividualCollaboratorItemBox>
    </Link>
  );
};

interface CollaboratorsProps {
  onSidebar: boolean;
}

export const Collaborators = ({ onSidebar }: CollaboratorsProps) => {
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const navigate = useNavigate();
  const { data: userCollaborators, isFetching: isFetchingUserCollaborators } =
    useGetUserCollaborators({
      userId: userData?.id,
    });

  const collaboratorItems: ISingleItem[] = useMemo(() => {
    if (!userCollaborators || userCollaborators.length === 0) return [];
    return userCollaborators.map((user) => ({
      value: user.username,
      label: getDisplayableNameForUser(user),
    }));
  }, [userCollaborators]);

  if (!userData || !collaboratorItems || collaboratorItems.length === 0) {
    return null;
  }

  const handleCollaboratorClick = (username: string | number) => {
    navigate(getProfileScreenRoute(`${username}`));
  };

  if (onSidebar) {
    return (
      <ChipsListDisplay
        title="COLLABORATORS"
        dataSource={collaboratorItems}
        loading={isFetchingUserCollaborators}
        isEditMode={false}
        handleElementClick={handleCollaboratorClick}
      />
    );
  }

  if (!userCollaborators || userCollaborators.length === 0) {
    return null;
  }

  if (isFetchingUserCollaborators) {
    return (
      <CollaboratorItemsBox>
        {Array(2)
          .fill(0)
          .map((_, index) => (
            <IndividualCollaboratorItemBox key={index}>
              <Skeleton variant="circular" width={100} height={100} />
              <Skeleton variant="text" width={40} />
            </IndividualCollaboratorItemBox>
          ))}
      </CollaboratorItemsBox>
    );
  }

  return (
    <CollaboratorBox>
      <Text variant={TextStyleVariant.S2}> Top Collaborators</Text>
      <CollaboratorItemsBox>
        {userCollaborators?.map((user) => (
          <CollaboratorItem
            key={user.id}
            path={getProfileScreenRoute(user.username)}
            name={getDisplayableNameForUser(user)}
            imagePath={getImageURLFromPath(false, user.photo?.path)}
          />
        ))}
      </CollaboratorItemsBox>
    </CollaboratorBox>
  );
};
