import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface studioRoomSearchState {
  page: number;
  latitude: number;
  longitude: number;
}

export const initialState: studioRoomSearchState = {
  page: 1,
  latitude: 0,
  longitude: 0,
};

const studioRoomSearchSlice = createSlice({
  name: "studioRoomSearch",
  initialState,
  reducers: {
    updateStudioRoomSearchPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setUserLatLngForStudioRoomSearch: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>,
    ) => {
      state.page = 1;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
  },
});

export default studioRoomSearchSlice.reducer;
export const { setUserLatLngForStudioRoomSearch, updateStudioRoomSearchPage } =
  studioRoomSearchSlice.actions;
