import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  NoReviewsButtonContainer,
  ReviewsStarContainer,
  ReviewsTextContainer,
} from "./NoReviewsButton.styles";
import { Text } from "../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../core-ui/components/Text/TextUtils";
import { ReactNode } from "react";

interface NoReviewsButtonProps {
  children: ReactNode;
}
export const NoReviewsButton = ({ children }: NoReviewsButtonProps) => {
  return (
    <NoReviewsButtonContainer>
      <ReviewsTextContainer>
        <ReviewsStarContainer>
          {[...Array(5)].map((_, index) => (
            <FontAwesomeIcon key={index} icon={faStar} />
          ))}
        </ReviewsStarContainer>
        <Text variant={TextStyleVariant.H6} color={TextColor.BLACK}>
          Let&apos;s get some reviews in here.
        </Text>
      </ReviewsTextContainer>
      {children}
    </NoReviewsButtonContainer>
  );
};
