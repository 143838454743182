import { AccountSettingsPhoneNumberVerificationField } from "./AccountSettingsPhoneNumberVerificationField";
import { useCallback, useState } from "react";
import { AccountSettingsPhoneVerifiedField } from "./AccountSettingsPhoneVerifiedField";
import { AccountSettingsPhoneNumberInputField } from "./AccountSettingsPhoneNumberInputField";
import useActiveUserQuery from "../../../../hooks/userHooks/useActiveUserQuery";
import { SoundWaveLoader } from "../../../elements/SoundWaveLoader/SoundWaveLoader";

export const AccountSettingsPhoneNumberField = () => {
  const { data: activeUser, isLoading } = useActiveUserQuery();
  const originalNumber = activeUser?.phone_number;
  const [isUpdating, setIsUpdating] = useState(false);
  const isVerified = originalNumber?.verified;
  const startUpdating = useCallback(() => {
    setIsUpdating(true);
  }, [setIsUpdating]);
  const stopUpdating = useCallback(() => {
    setIsUpdating(false);
  }, [setIsUpdating]);
  if (isLoading) {
    return <SoundWaveLoader width={100} height={100} />;
  }
  if (originalNumber?.phone_number && !isVerified && !isUpdating) {
    return (
      <AccountSettingsPhoneNumberVerificationField
        phoneNumber={originalNumber?.phone_number}
        onCancel={startUpdating}
      />
    );
  }
  if (originalNumber?.phone_number && isVerified && !isUpdating) {
    return (
      <AccountSettingsPhoneVerifiedField
        phoneNumber={originalNumber.phone_number}
        onUpdate={startUpdating}
      />
    );
  }
  return (
    <AccountSettingsPhoneNumberInputField
      originalNumber={originalNumber?.phone_number}
      onSave={stopUpdating}
      onCancel={stopUpdating}
    />
  );
};
