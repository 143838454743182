import { useMutation } from "@tanstack/react-query";
import queryString from "query-string";
import EntityVideo from "../../store/models/entityVideo";
import {
  makeBackendGetCallWithJsonResponse,
  SignedUrlFormProps,
} from "../../store/utils/fetch";
import { GET_FEATURED_VIDEO_UPLOAD_SIGNED_URL } from "../../store/utils/routes";

interface MutateFunctionProps {
  content_type: string;
  file_name: string;
  file_size: number;
}

interface SignedUrlResponseProps {
  signed_url: SignedUrlFormProps;
  entity_video: EntityVideo;
}

export const useGetServiceFeaturedVideoSignedURL = () => {
  return useMutation({
    mutationFn: async ({
      content_type,
      file_name,
      file_size,
    }: MutateFunctionProps) => {
      return makeBackendGetCallWithJsonResponse<SignedUrlResponseProps>(
        GET_FEATURED_VIDEO_UPLOAD_SIGNED_URL,
        `?${queryString.stringify({
          content_type,
          file_name,
          file_size,
        })}`,
      ).then((res) => {
        if (!res.success) {
          throw res.resultJson;
        }

        return res.resultJson;
      });
    },
  });
};
