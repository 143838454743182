import React from "react";
import ChatInjector from "./ChatInjector";
import { useAtomValue } from "jotai";
import {
  activeUserChatDataAtom,
  activeUserChatTokenAtom,
} from "../../../atoms/user/activeUserAtom";

export interface ChatInjectorParams {
  children?: React.ReactNode;
}
const ChatSuspense = ({ children = null }: ChatInjectorParams) => {
  const token = useAtomValue(activeUserChatTokenAtom);
  const userData = useAtomValue(activeUserChatDataAtom);
  if (!token) {
    return null;
  }
  return (
    <ChatInjector token={token} userData={userData}>
      {children}
    </ChatInjector>
  );
};

export default ChatSuspense;
