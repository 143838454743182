import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import useSendEmailVerificationCodeMutation from "../../../hooks/authHooks/useSendEmailVerificationCodeMutation";
import {
  resendEmailVerification,
  verifyAccountEmail,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";
import VerificationCodeInput from "./VerificationCodeInput";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { useAccountStatus } from "../../../hooks/accountHooks";
import { AuthFlows } from "../../../constants/authSteps";
import { useAtom } from "jotai";
import { currentFlowAtom } from "../../../atoms/location/currentFlowAtom";

const VerifyEmail = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();
  const [emailVerified, setEmailVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resentVerification, setResentVerification] = useState(false);
  const { emailVerified: polledUserEmailVerified } = useAccountStatus(true);
  const [currentFlow, setCurrentFlow] = useAtom(currentFlowAtom);

  const mutation = useSendEmailVerificationCodeMutation();

  useEffect(() => {
    mutation.mutate({
      send_verification_code: true,
    });
  }, []);

  useEffect(() => {
    if (polledUserEmailVerified) {
      if (currentFlow === AuthFlows.AANDR_NON_UMG) {
        setCurrentFlow(AuthFlows.AANDR);
      } else {
        nextStep();
      }
    }
  }, [polledUserEmailVerified]);

  useEffect(() => {
    if (resentVerification) {
      const timer = setTimeout(() => {
        setResentVerification(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [resentVerification]);

  const handleVerified = () => {
    setVerificationError(false);
    setEmailVerified(true);
  };

  const handleVerificationError = () => {
    setEmailVerified(false);
    setVerificationError(true);
  };

  const handleResendVerifyEmail = () => {
    setResendLoading(true);
    dispatch(resendEmailVerification({}))
      .then(() => setResentVerification(true))
      .then(() => {
        setResendLoading(false);
      });
  };

  const onComplete = (verificationCode: string) => {
    if (!user?.id) return;
    setIsLoading(true);
    dispatch(
      verifyAccountEmail({
        verification: verificationCode,
        user_id: user.id.toString(),
        send_verification_code: true,
      }),
    )
      .unwrap()
      .then(() => {
        emitAnalyticsTrackingEvent("auth_verify_email_used_code", {});
        handleVerified();
        if (currentFlow === AuthFlows.AANDR_NON_UMG) {
          setCurrentFlow(AuthFlows.AANDR);
        } else {
          nextStep();
        }
      })
      .catch(() => {
        handleVerificationError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    if (emailVerified) {
      emitAnalyticsTrackingEvent("auth_verify_email_click_submit", {});
      if (currentFlow === AuthFlows.AANDR_NON_UMG) {
        setCurrentFlow(AuthFlows.AANDR);
      } else {
        nextStep();
      }
    }
  };

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Confirm your email</Text>
        <Text variant={TextStyleVariant.P1}>
          Enter the verification code we emailed to:
          <br />
          {user?.email}
        </Text>
        <VerificationCodeInput codeLength={6} onComplete={onComplete} />
        {verificationError && (
          <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faXmark} />
            Invalid verification code
          </Text>
        )}
        {emailVerified && (
          <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
            Verified!
          </Text>
        )}
        {resendLoading ? (
          <SoundWaveLoader width={100} height={100} />
        ) : resentVerification ? (
          <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
            Verification code resent
          </Text>
        ) : (
          <Text variant={TextStyleVariant.P1} style={{ whiteSpace: "nowrap" }}>
            <Button
              variant={ButtonVariant.TEXT}
              style={{ display: "inline", fontWeight: "600" }}
              onClick={() => {
                emitAnalyticsTrackingEvent(
                  "auth_verify_email_click_resend_email_verification",
                  {},
                );
                handleResendVerifyEmail();
              }}
            >
              Resend a code
            </Button>{" "}
            or click the link in the email.
          </Text>
        )}
        <Button
          loading={isLoading}
          fullWidth
          onClick={handleSubmit}
          disabled={!emailVerified || isLoading}
        >
          Submit
        </Button>
        {currentFlow !== AuthFlows.AANDR_NON_UMG && (
          <Button
            variant={ButtonVariant.UNSTYLED}
            style={{
              textDecoration: "underline",
              alignSelf: "flex-end",
              color: theme.palette.text.primary,
            }}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_verify_email_click_remind_me_later",
                {},
              );
              nextStep();
            }}
          >
            Remind me later
          </Button>
        )}
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};

export default VerifyEmail;
