import { getDisplayableNameForUser } from "../../../utils/entityUtils";
import { Project, ProjectById } from "../../project";
import { PlaylistTrack, PlaylistTrackType } from "../types";
import convertProjectByIdToPlayListTrack from "./convertProjectByIdToPlayListTrack";

const convertProjectToPlayListTrack = (
  project: Project | ProjectById,
): PlaylistTrack => {
  if ("artist" in project) {
    const projectWithArtist = project as Project;
    return {
      id: project.id,
      type: PlaylistTrackType.Project,
      title: project.title,
      artistDisplayName: getDisplayableNameForUser(projectWithArtist.artist),
      artistUsername: projectWithArtist.artist?.username || "",
      engineerUsername: projectWithArtist.engineer?.username || "",
      engineerDisplayName: getDisplayableNameForUser(project.engineer),
    };
  } else {
    const projectWithId = project as ProjectById;
    return convertProjectByIdToPlayListTrack(projectWithId);
  }
};
export default convertProjectToPlayListTrack;
