import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Fade, Menu, Stack } from "@mui/material";
import { useState } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { DropdownNavSection } from "./DropdownNavSection";
import { MobileDropdownNavMenu } from "./MobileDropdownNavMenu";
import { dropdownMenuData } from "./utils";

interface DropdownNavMenuProps {
  isMobile?: boolean;
}

export const DropdownNavMenu = ({ isMobile }: DropdownNavMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [selected, setSelected] = useState<string | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isMobile) {
    return (
      <Stack
        marginBottom={"auto"}
        direction="column"
        height={"100%"}
        justifyContent={"flex-start"}
        alignItems="flex-start"
        overflow="scroll"
        gap={1}
      >
        {Object.entries(dropdownMenuData).map(([key, section]) => (
          <MobileDropdownNavMenu
            isOpen={selected === key}
            key={key}
            {...section}
            onClick={() => {
              if (selected === key) {
                setSelected(null);
              } else {
                setSelected(key);
              }
            }}
          />
        ))}
      </Stack>
    );
  }

  return (
    <Stack direction="column" gap={1}>
      <Button variant={ButtonVariant.UNSTYLED} onClick={handleClick}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text bold>Product</Text>
          <FontAwesomeIcon
            icon={open ? faChevronUp : faChevronDown}
            size="2xs"
          />
        </Stack>
      </Button>
      <Menu
        MenuListProps={{
          sx: {
            flexDirection: "column",
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            marginTop: "35px",
            marginRight: "8px",
            marginLeft: "8px",
          },
        }}
      >
        <Stack
          divider={<Divider orientation="vertical" variant="middle" flexItem />}
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {Object.entries(dropdownMenuData)
            .filter(([key]) => key !== "LearnMore")
            .map(([key, section]) => (
              <DropdownNavSection
                key={key}
                {...section}
                onClick={handleClose}
              />
            ))}
        </Stack>
      </Menu>
    </Stack>
  );
};
