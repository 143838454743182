import { AccountDetailsFieldContainer } from "../AccountSettingsModal.styles";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { PhoneInput } from "../../../core-ui/components/PhoneNumber/PhoneInput";
import { MuiTelInputCountry } from "mui-tel-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Box, useTheme } from "@mui/material";
import { useGeolocationGoogleAppEngine } from "../../../../hooks/useGeolocationCountryCode";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";

interface AccountSettingsPhoneVerifiedFieldProps {
  phoneNumber: string;
  onUpdate: () => void;
}

export const AccountSettingsPhoneVerifiedField = ({
  phoneNumber,
  onUpdate = () => {},
}: AccountSettingsPhoneVerifiedFieldProps) => {
  const { countryCode } = useGeolocationGoogleAppEngine();
  const theme = useTheme();
  return (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Phone number
      </Text>
      <PhoneInput
        disabled={true}
        isValidPhoneNumber={true}
        defaultCountry={(countryCode as MuiTelInputCountry) || "US"}
        value={phoneNumber}
        size={"small"}
        style={{ width: "100%" }}
      />
      <Box display={"flex"} alignItems={"center"} gap={2}>
        <Text
          variant={TextStyleVariant.P1}
          style={{ color: theme.palette.standardColor.Green[600] }}
        >
          <FontAwesomeIcon
            style={{ marginRight: "4px" }}
            icon={faCheck}
            width={12}
            height={12}
          />
          verified
        </Text>
        <Button
          variant={ButtonVariant.GHOST}
          onClick={() => {
            onUpdate();
          }}
        >
          update
        </Button>
      </Box>
    </AccountDetailsFieldContainer>
  );
};
