import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { PAGE_HASH } from "../../atoms/location/locationAtom";

interface UseHashBooleanParams {
  value: PAGE_HASH;
}

/**
 * useHashBoolean
 *
 * This hook can be used to control whether a part of
 * the component is open or closed based on a hash value.
 *
 * @param value the value of the hash
 * @returns an object with the following properties:
 * - `isOpen`: a boolean indicating whether the hash matches the `value`
 * - `closeHash`: a function that closes the hash by setting it to "#"
 * - `openHash`: a function that opens the hash by setting it to "<value>"
 */
const useHashBoolean = ({ value }: UseHashBooleanParams) => {
  const { hash, search } = useLocation();
  const navigate = useNavigate();

  const closeHash = useCallback(
    (newSearch?: string) => {
      navigate({ hash: "#", search: newSearch ?? search }, { replace: true });
    },
    [navigate, search],
  );

  const openHash = useCallback(
    (newSearch?: string) => {
      navigate({ hash: value, search: newSearch ?? search }, { replace: true });
    },
    [navigate, search, value],
  );

  const isOpen = useMemo(
    () => hash.length > 1 && hash === value,
    [hash, value],
  );

  const toggle = useCallback(() => {
    if (isOpen) {
      closeHash();
    } else {
      openHash();
    }
  }, [closeHash, isOpen, openHash]);

  const setHash = useCallback(
    (open: boolean, newSearch?: string) => {
      if (open) {
        openHash(newSearch);
      } else {
        closeHash(newSearch);
      }
    },
    [closeHash, openHash],
  );

  return { isOpen, openHash, closeHash, setHash, toggle };
};

export default useHashBoolean;
