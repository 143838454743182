import { isMobile, isMobileSafari } from "react-device-detect";
import { AvailabilityForDate } from "../actions/availability";
import User from "../models/user";
import { addSeconds, format } from "date-fns";

export const host = import.meta.env.DEV
  ? "http://localhost:3000"
  : `https://${window.location.hostname}`;

export const checkIsMobile = () => isMobileSafari || isMobile;

export const userVerifiedOrOnboardedBeforeLaunchDate = (user: User) => {
  const launchDate = new Date(2022, 2, 1); // Month is zero-base indexed.
  if (!user?.engineer?.started_onboarding) {
    return false;
  }
  const userOnboardedDate = new Date(user?.engineer?.started_onboarding);
  if (userOnboardedDate < launchDate) {
    return true;
  }
  if (!user?.engineer?.verified) {
    return false;
  }
  const userVerifiedDate = new Date(user?.engineer?.verified);
  return userVerifiedDate < launchDate;
};

export const formatTime = (start: number, end: number) => {
  return (start == end ? [start] : [start, end])
    .map((time) =>
      [
        Math.floor((time % 3600) / 60), // minutes
        ("00" + Math.floor(time % 60)).slice(-2), // seconds
      ].join(":"),
    )
    .join("-");
};

export const convertTimeFormatToSeconds = (value: string) => {
  const [hours, minutes, seconds] = value.split(":").map(Number);

  if (!isNaN(hours) && isNaN(minutes) && isNaN(seconds)) {
    return hours;
  }

  if (!isNaN(hours) && !isNaN(minutes) && isNaN(seconds)) {
    return hours * 60 + minutes;
  }

  if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
    return hours * 60 * 60 + minutes * 60 + seconds;
  }

  return 0;
};

export const convertSecondsToTimeFormat = (secs: number) => {
  const days = Math.floor(secs / (3600 * 24));
  const remainderSeconds = secs % (3600 * 24);
  const date = addSeconds(new Date(0), remainderSeconds);

  // Check if the duration is less than an hour
  if (secs < 3600) {
    return format(date, "m:ss");
  } else {
    const time = format(date, "HH:mm:ss");
    return days > 0 ? `${days}:${time}` : time;
  }
};

export const convertAvailabilityForDateToMap = (
  availabilityForData?: AvailabilityForDate,
) => {
  const availabilityMap = new Map<string, string>();
  if (!availabilityForData) {
    return availabilityMap;
  }
  Object.keys(availabilityForData).forEach((date) => {
    availabilityMap.set(date, availabilityForData[date]);
  });
  return availabilityMap;
};

export const downloadFile = (url: string, filename: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
