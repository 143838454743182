import { Box, css, styled } from "@mui/material";

export const StyledEditServicesFormModalBody = css`
  justify-content: flex-start;
  padding: 0;
  overflow-y: hidden;
  max-height: 70vh;
  min-height: 350px;
  width: 100%;
  gap: 24px;

  .package-details-card {
    overflow-x: unset;
    max-height: unset;
    min-width: unset;
    justify-content: flex-start;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .add-package-service-container {
    width: 100%;
    height: unset;
  }
`;

export const ServicesEditModalContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
  alignItems: "stretch",
  width: "100%",
  padding: "24px",
  boxSizing: "border-box",
});

export const ServiceRow = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px",
  borderRadius: theme.border.radius.md,
}));

export const ServiceRowButtons = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  columnGap: "16px",
});

export const AddServiceSelectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  alignItems: "stretch",
  width: "100%",
  padding: "24px",
  boxSizing: "border-box",
});
