import { useTheme } from "@mui/material";
import { getDocUrl } from "../../../constants/googleStorage";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Text, TEXT_COLOR } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import Link from "../../elements/Link/Link";

const FooterContent = () => {
  const theme = useTheme();
  return (
    <Text
      variant={TextStyleVariant.P2}
      color={TEXT_COLOR.SECONDARY}
      sx={{
        textAlign: "center",
      }}
    >
      {"By joining, you agree to the EngineEars "}
      <Link
        style={{
          color: theme.palette.text.secondary,
          textDecoration: "underline",
        }}
        to={getDocUrl("EngineEarsTermsOfUse.pdf")}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          emitAnalyticsTrackingEvent(
            "auth_footer_click_on_terms_of_service",
            {},
          )
        }
      >
        Terms of Service
      </Link>
      {" and "}
      <Link
        style={{
          color: theme.palette.text.secondary,
          textDecoration: "underline",
        }}
        to={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          emitAnalyticsTrackingEvent("auth_footer_click_on_privacy_policy", {})
        }
      >
        Privacy Policy
      </Link>
      .
    </Text>
  );
};

export default FooterContent;
