// getter/setter for the search term
import { atom } from "jotai";
import {
  ExpectedSearchParams,
  searchParamsAtom,
  setSearchParamsHelper,
} from "./locationAtom";

export const codeAtom = atom(
  (get) => {
    return get(searchParamsAtom).code || "";
  },
  (get, set, value: string) => {
    const key: keyof ExpectedSearchParams = "code";
    setSearchParamsHelper(get, set, { key, value, clear: true });
  },
);

codeAtom.debugLabel = "Code Atom";
