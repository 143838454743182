import { PortfolioProjectTrackResponse } from "../../portfolio";
import { PlaylistTrack, PlaylistTrackType } from "../types";
import { porfolioCoverArtPathPrefix } from "../../../utils";

export const convertPortfolioProjectToPlayListTrack = (
  portfolioProject?: PortfolioProjectTrackResponse[],
): PlaylistTrack[] => {
  if (!portfolioProject) return [];
  return portfolioProject.map((data) => {
    return {
      id: data.id,
      type: PlaylistTrackType.PortfolioProjectTrackResponse,
      title: data.title,
      artistDisplayName: data.artist_display_name || "",
      artistUsername: data.artist_username || "",
      engineerUsername: data.engineer_username || "",
      engineerDisplayName: data.engineer_display_name || "",
      artworkSource: data.artwork
        ? porfolioCoverArtPathPrefix + data.artwork
        : undefined,
      fileVersionId: data.latest_main_fileversion_id || undefined,
    };
  });
};
