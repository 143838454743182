import { Box, styled } from "@mui/material";
import { ElementType } from "react";

export const ClearSelectionButton = styled(Box)<{ component?: ElementType }>(
  ({ theme }) => ({
    position: "absolute",
    right: theme.spacing(2),
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    visibility: "hidden",
    opacity: 0,
    outline: "none",
    border: "none",
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.secondary.contrastText,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "16px",

    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
    },
  }),
);

export const OptionsDropdownContainer = styled(Box)({
  position: "relative",

  "&:hover": {
    ".clear-selection-btn": {
      visibility: "visible",
      opacity: 1,
    },
  },
});
