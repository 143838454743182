import { MusoCredit } from "../../muso";
import { PlaylistTrack, PlaylistTrackType } from "../types";

const convertMusoCreditToPlayListTrack = (
  credit?: MusoCredit,
  engineerDisplayName?: string,
): PlaylistTrack => {
  if (!credit) {
    return {
      id: 0,
      type: PlaylistTrackType.Muso,
      title: "",
      artistDisplayName: "",
      artistUsername: "",
      engineerUsername: "",
      engineerDisplayName: "",
      spotify_preview_url: "",
    };
  }
  const { id, track_title, album_art, artist } = credit;
  const artworkSource = album_art;
  return {
    id: id || 0,
    type: PlaylistTrackType.Muso,
    title: track_title,
    artistDisplayName: artist,
    artistUsername: "",
    engineerUsername: engineerDisplayName ?? "",
    engineerDisplayName: engineerDisplayName ?? "",
    artworkSource: artworkSource,
    spotify_preview_url: credit.spotify_preview_url ?? "",
  };
};

export default convertMusoCreditToPlayListTrack;
