import AccountTypeBase from "./base";

export default interface Artist extends AccountTypeBase {
  verified?: string | null;
}

export const MockArtist: Artist = {
  id: 35,
  user_id: 60,
  created: "2021-11-16T22:28:38.351243",
  deleted: null,
  is_primary_type: null,
};
