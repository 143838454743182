import { styled } from "@mui/material/styles";

export const StyledSwitchButtonAB = styled("button")(({ theme }) => ({
  backgroundColor: theme.palette.customColor.midgroundColor,
  border: "unset",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: 26,
  minWidth: 60,
  padding: 8,
  borderRadius: 8,
  "&.project-view": {
    minWidth: 26,
  },
  "&.active": {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledSwitchButtonABP = styled("p")(({ theme }) => ({
  textWrap: "wrap",
  wordBreak: "break-word",
  wordWrap: "break-word",
  fontSize: theme.typography.body3.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  "&.active": {
    color: theme.palette.common.white,
  },
}));
