import { faChevronDown } from "@fortawesome/sharp-solid-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  CSSObject,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { ReactNode, useMemo, useState } from "react";
import { BaseAccordionExpandIcon } from "./BaseAccordion.styles";

export enum BaseAccordionVariants {
  DEFAULT = "default",
}

export interface BaseAccordionProps extends AccordionProps {
  renderHeader: (isExpanded: boolean) => ReactNode;
  disableIconRotate?: boolean;
  hideIcon?: ReactNode;
  AccordionSummaryProps?: AccordionSummaryProps;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  overrideSxProps?: (theme: Theme) => CSSObject;
}

export const BaseAccordion = ({
  renderHeader,
  children,
  hideIcon,
  disabled,
  defaultExpanded = false,
  hidden = false,
  disableIconRotate = false,
  AccordionSummaryProps,
  onChange,
  overrideSxProps,
  ...restProps
}: BaseAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const theme = useTheme();

  const accordionSummaryStyle: SxProps = disableIconRotate
    ? {
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "unset",
        },
      }
    : {};

  const Icon = useMemo(() => {
    if (!AccordionSummaryProps?.expandIcon) {
      return <BaseAccordionExpandIcon icon={faChevronDown} />;
    }
    return hideIcon && isExpanded ? hideIcon : AccordionSummaryProps.expandIcon;
  }, [isExpanded, hideIcon, AccordionSummaryProps?.expandIcon]);

  return (
    <Accordion
      onChange={(event, expanded) => {
        setIsExpanded(expanded);
        if (onChange) onChange(event, expanded);
      }}
      sx={{
        "&.MuiAccordion-root": {
          boxShadow: "none",
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: "transparent",
          backgroundImage: "none",
          ...(overrideSxProps?.(theme) || {}),
        },
        "&.Mui-disabled": {
          backgroundColor: "transparent",
        },
      }}
      disableGutters
      hidden={hidden}
      square
      disabled={disabled}
      {...restProps}
    >
      <AccordionSummary
        {...AccordionSummaryProps}
        sx={accordionSummaryStyle}
        expandIcon={Icon}
      >
        {renderHeader(isExpanded)}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
