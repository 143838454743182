import {
  a11yProps,
  AccountSettingsModalTabs,
  accountSettingsTabs,
} from "./utils";
import {
  EETab,
  EETabs,
  TabsBottomGreyBar,
} from "../../screens/ProfileScreen/ProfileScreen.styles";
import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";
import { useAppSelector } from "../../../store/hooks";
import { accountSettingsModalOpenAtom } from "./atoms";
import { useAtom, useAtomValue } from "jotai";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { DISCIPLINE_TYPE, getUserDisciplines } from "../../../hooks/user";
import { activeProfileAtom } from "../../../atoms/user/activeProfileAtom";
import { ResponsiveDialog } from "../../core-ui/components/ResponsiveDialog/ResponsiveDialog";
import { SERVICE_FORM_MODAL_FOOTER_ID } from "../Navigation/BottomNav/useBottomTabBarOverlayView";
import { ACCOUNT_SETTINGS_MODAL_ID } from "./constants";

interface AccountSettingsModalProps {
  onClose: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
    </div>
  );
};

export const AccountSettingsModal = ({
  onClose,
}: AccountSettingsModalProps) => {
  const theme = useTheme();
  const { user } = useAppSelector((state) => state.accountInfo);
  const userDiscpilineTypes = getUserDisciplines(user);
  const activeProfile = useAtomValue(activeProfileAtom);
  const displayName = activeProfile?.display_name;
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    AccountSettingsModalTabs.AccountDetails,
  );
  const [isAccountSettingsModalOpen, setIsAccountSettingsModalOpen] = useAtom(
    accountSettingsModalOpenAtom,
  );

  const handleSetSelectedTabIndex = (
    _: React.SyntheticEvent,
    tabIndex: number,
  ) => {
    setSelectedTabIndex(tabIndex);
  };

  if (!user) {
    return null;
  }

  return (
    <ResponsiveDialog
      open={isAccountSettingsModalOpen}
      onClose={() => setIsAccountSettingsModalOpen(false)}
      titleProps={{
        children: `Account Settings for ${displayName}`,
      }}
      id={ACCOUNT_SETTINGS_MODAL_ID}
      maxWidth={"md"}
      fullWidth
      hideCloseAction={true}
      actionsProps={{
        id: SERVICE_FORM_MODAL_FOOTER_ID,
        children: <></>,
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      }}
      contentProps={{
        dividers: false,
        sx: {
          padding: 0,
        },
        children: (
          <>
            <Box
              px={2}
              boxSizing={"border-box"}
              sx={(theme) => ({
                zIndex: theme.zIndex.appBar,
                position: "sticky",
                top: 0,
                backgroundColor: theme.palette.background.default,
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
              overflow={"hidden"}
            >
              <TabsBottomGreyBar />
              <EETabs
                value={selectedTabIndex}
                onChange={handleSetSelectedTabIndex}
                aria-label="Account Settings Navigation Tab"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {accountSettingsTabs.map((tab, index) => {
                  if (
                    tab.value === AccountSettingsModalTabs.PaymentAndBilling &&
                    !userDiscpilineTypes.includes(DISCIPLINE_TYPE.ENGINEER) &&
                    !userDiscpilineTypes.includes(
                      DISCIPLINE_TYPE.STUDIO_MANAGER,
                    )
                  ) {
                    return null;
                  }
                  const tabLabel = (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        variant={TextStyleVariant.P1}
                        style={{
                          color:
                            selectedTabIndex === tab.value
                              ? theme.palette.text.primary
                              : theme.palette.standardColor.Gray[400],
                        }}
                        bold
                      >
                        {tab.label}
                      </Text>
                    </Box>
                  );

                  return (
                    <EETab
                      key={tab.value}
                      label={tabLabel}
                      {...a11yProps(index)}
                    />
                  );
                })}
              </EETabs>
            </Box>
            <Box
              sx={{
                overflowY: "auto",
                width: "100%",
                boxSizing: "border-box",
              }}
              boxSizing={"border-box"}
              px={2}
              pb={2}
            >
              {accountSettingsTabs.map((tab) => (
                <TabPanel
                  key={tab.value}
                  value={selectedTabIndex}
                  index={tab.value}
                >
                  {tab.content(onClose)}
                </TabPanel>
              ))}
            </Box>
          </>
        ),
      }}
    />
  );
};
