import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import User from "../../../../store/models/user";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { NavProfileButton } from "../NavProfileButton/NavProfileButton";
import { NavProfileDropDown } from "../NavProfileDropDown/NavProfileDropDown";
import "./NavProfileMenu.css";
import { useAtomValue } from "jotai";
import { showTopNavAtom } from "../../../../atoms/navAtoms";

export interface NavProfileMenuProps {
  user: User;
}

export const NavProfileMenu = ({ user }: NavProfileMenuProps) => {
  const { isMobile } = useMediaQueryBreakpoint();
  const [showDropDown, setShowDropDown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const onClickOutside = () => {
    if (showDropDown) {
      setShowDropDown(false);
    }
  };

  useClickOutside(containerRef, onClickOutside);
  const showHeader = useAtomValue(showTopNavAtom);

  const handleToggleDropDown = () => {
    emitAnalyticsTrackingEvent("show_profile_dropdown", {});
    if (!isMobile) {
      setShowDropDown(!showDropDown);
    }
  };

  const handleCloseDropDown = () => setShowDropDown(false);

  useEffect(() => {
    if (showDropDown && !showHeader) {
      setShowDropDown(false);
    }
  }, [showDropDown, showHeader]);

  return (
    <div
      className={`nav-profile-menu ${showDropDown ? "drop-down-enabled" : ""}`}
      ref={containerRef}
    >
      <NavProfileButton
        expanded={showDropDown}
        isMobile={isMobile}
        onClick={handleToggleDropDown}
      />
      <NavProfileDropDown
        user={user}
        showDropDown={showDropDown}
        onClose={handleCloseDropDown}
      />
    </div>
  );
};
