import { styled, Tab } from "@mui/material";

export const DEFAULT_TAB_WIDTH = 170;

export const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: theme.spacing(2),

  "&, p": {
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
    whiteSpace: "nowrap",
  },

  "&.Mui-selected > p": {
    color: theme.palette.text.primary,
  },
}));
