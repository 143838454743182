import { useCallback, useEffect, useRef } from "react";
import { MutateProfileProps } from "../../../../api/accounts/user/updateProfile";
import { TRACKING_EVENTS_NAME } from "../../../../constants/trackingEvents";
import { useUpdateUserProfile } from "../../../../hooks/userHooks/useUpdateUserProfile";
import { setUser } from "../../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { getCurrentPositionPromise } from "../../../../utils/utils";

export const useUpdateUserOnLoad = () => {
  const hasLoggedInUserBeenUpdated = useRef(false);
  const dispatch = useAppDispatch();
  const { isAuthenticated, user } = useAppSelector(
    (state) => state.accountInfo,
  );
  const { mutate: updateUser } = useUpdateUserProfile((updatedUser) => {
    void dispatch(setUser(updatedUser));
  });

  const updateUserOnLoad = useCallback(async () => {
    const payload: MutateProfileProps = {
      update_user_location: true,
      timezone_shift_minutes: new Date().getTimezoneOffset(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (navigator.permissions) {
      try {
        const geoLocationPermission = await navigator.permissions.query({
          name: "geolocation",
        });

        if (geoLocationPermission.state === "granted") {
          const position = await getCurrentPositionPromise();

          payload.latitude = position.coords.latitude;
          payload.longitude = position.coords.longitude;
        }
      } catch {
        // Do nothing
      }
    }

    if (isAuthenticated && !user?.anonymous_id) {
      payload.migrate_anonymous_id = true;
      const anonymousId = window.analytics?.user?.()?.anonymousId();
      emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.MIGRATE_ANONYMOUS_ID, {
        anonymousId,
      });

      if (anonymousId) {
        payload.anonymous_id = anonymousId;
      }
    }

    updateUser(payload);

    hasLoggedInUserBeenUpdated.current = true;
  }, [dispatch, isAuthenticated, updateUser, user?.anonymous_id]);

  useEffect(() => {
    if (!isAuthenticated || hasLoggedInUserBeenUpdated.current) return;

    void updateUserOnLoad();
  }, [updateUserOnLoad, isAuthenticated]);

  // If the user logs out, reset the flag
  useEffect(() => {
    if (!isAuthenticated) {
      hasLoggedInUserBeenUpdated.current = false;
    }
  }, [isAuthenticated]);
};
