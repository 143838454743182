import queryString from "query-string";
import { AutocompleteSuggestion } from "../../store/models/autocomplete";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { MAP_AUTOCOMPLETE } from "../../store/utils/routes";

const fetchMapAutocomplete = async ({
  searchQuery,
  mapDataOnly = false,
}: {
  searchQuery: string;
  mapDataOnly?: boolean;
}) => {
  const params = `?${queryString.stringify({ search: searchQuery, map_data_only: mapDataOnly })}`;
  const response = await makeBackendGetCallWithJsonResponse<
    AutocompleteSuggestion[]
  >(MAP_AUTOCOMPLETE, params);

  if (response.success) {
    return response.resultJson;
  }
  throw new Error(String(JSON.stringify(response.resultJson)));
};

export default fetchMapAutocomplete;
