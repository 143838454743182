// These class names are tracked in various external tools for knowledge about the DOM.
// Ex: wait for an element with a specific class name to load in a test suite such as playwright
export enum TrackableClassNames {
  ENGINEEARS_DIALOG = "engineears-dialog",
  ENGINEEARS_DIALOG_ACTIONS = "engineears-dialog-actions",
  ENGINEEARS_DIALOG_CONTENT = "engineears-dialog-content",
  ENGINEEARS_DIALOG_CLOSE_BUTTON = "engineears-dialog-close-button",
  ENGINEEARS_DIALOG_FULLSCREEN = "engineears-dialog-fullscreen",
  ENGINEEARS_DIALOG_TITLE = "engineears-dialog-title",
  ENGINEEARS_FORM = "engineears-form",
  ENGINEEARS_TEXTFIELD = "engineears-textfield",
  ENGINEEARS_SELECT = "engineears-select",
  ENGINEEARS_DATE_PICKER = "engineears-date-picker",
}
