import {
  Text,
  TEXT_WEIGHT,
  TextProps,
} from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { AccountDetailsFieldContainer } from "../AccountSettingsModal.styles";
import { ReactNode, useMemo } from "react";
import { BoxProps } from "@mui/material";
import FormOutlinedTextField, {
  FormOutlinedTextFieldProps,
} from "../../../elements/Form/FormOutlinedTextField";
import { ValidationLabel, ValidationLabelProps } from "./ValidationLabel";
import { UsernameChecksContainer } from "../../SignInForm/SignInForm.styles";

export interface AccountSettingsTextFieldProps
  extends Omit<BoxProps, "children" | "onChange"> {
  errorMessage?: ReactNode;
  disabled?: boolean;
  helperText?: ReactNode;
  name?: string;
  label?: ReactNode;
  TextProps?: Omit<TextProps, "children">;
  TextFieldProps?: Omit<FormOutlinedTextFieldProps, "name" | "helperText">;
  validationLabels?: Record<string, ValidationLabelProps>;
}
export const AccountSettingsTextField = ({
  errorMessage,
  disabled,
  helperText,
  label,
  name,
  TextProps = {},
  TextFieldProps = {},
  validationLabels = {},
  ...props
}: AccountSettingsTextFieldProps) => {
  const validationLabelElements = useMemo(() => {
    if (!validationLabels) return [];
    return Object.keys(validationLabels).map((key) => {
      const label = validationLabels[key];
      return <ValidationLabel key={key} {...label} />;
    });
  }, [validationLabels]);
  return (
    <AccountDetailsFieldContainer {...props}>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        {...TextProps}
      >
        {label}
      </Text>
      <FormOutlinedTextField
        disabled={disabled}
        fullWidth={true}
        size={"small"}
        name={name}
        error={Boolean(errorMessage)}
        helperText={errorMessage ? errorMessage : helperText}
        {...TextFieldProps}
      />
      {Boolean(validationLabelElements.length) && (
        <UsernameChecksContainer>
          {validationLabelElements}
        </UsernameChecksContainer>
      )}
    </AccountDetailsFieldContainer>
  );
};
