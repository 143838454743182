import { useState } from "react";
import "./EntitySearchResultList.css";
import defaultUser from "../../../stories/assets/defaultuser.jpg";
import {
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
} from "../../../store/models/autocomplete";
import { getImageURLFromPath } from "../../../hooks/useImageURLFromPath";
import locationIcon from "../../assets/Icon_Location.svg";
import folderIcon from "../../assets/folder-icon.svg";
import soundwaveIcon from "../../assets/Soundwave_Small_1.svg";
import musoIcon from "../../assets/muso-icon-black-background.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophoneStand,
  faPuzzlePieceSimple,
  faSpeakers,
  faTools,
} from "@fortawesome/pro-solid-svg-icons";
import {
  EquipmentTypeEnum,
  EquipmentTypeReadableNameMap,
} from "../../../store/models/equipment";

export interface EntitySearchResultListItemProps {
  autocompleteItem: AutocompleteSuggestion;
  onListItemClick: (item: AutocompleteSuggestion) => void;
}

const equipmentTypeIconMap: Partial<
  Record<EquipmentTypeEnum, React.ReactNode>
> = {
  [EquipmentTypeEnum.HARDWARE]: <FontAwesomeIcon size="lg" icon={faTools} />,
  [EquipmentTypeEnum.SOFTWARE]: (
    <FontAwesomeIcon size="lg" icon={faPuzzlePieceSimple} />
  ),
  [EquipmentTypeEnum.MONITORS]: <FontAwesomeIcon size="lg" icon={faSpeakers} />,
  [EquipmentTypeEnum.MICROPHONES]: (
    <FontAwesomeIcon size="lg" icon={faMicrophoneStand} />
  ),
};

export const EntitySearchResultListItem = ({
  autocompleteItem,
  onListItemClick,
}: EntitySearchResultListItemProps) => {
  const [useFallback, setUseFallback] = useState(false);

  const getImageUrl = () => {
    switch (autocompleteItem.type) {
      case AutoCompleteTypeEnum.USER:
        return getImageURLFromPath(false, autocompleteItem?.path ?? "");
      case AutoCompleteTypeEnum.STUDIO:
        return getImageURLFromPath(true, autocompleteItem?.path ?? "");
      default:
        return defaultUser;
    }
  };

  switch (autocompleteItem.type) {
    case AutoCompleteTypeEnum.USER:
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            onListItemClick(autocompleteItem);
          }}
          className="entity-search-result-list-item"
        >
          <img
            src={useFallback ? defaultUser : getImageUrl()}
            width={44}
            height={44}
            onError={() => {
              setUseFallback(true);
            }}
            alt="user profile"
          />
          <div className="entity-search-result-list-item-description">
            <span className="b2-semi-bold">
              {autocompleteItem.display_name}
            </span>
            <p className="b3 mx-2">
              {autocompleteItem.display_name !== autocompleteItem.username
                ? ` Engineer • @${autocompleteItem.username}`
                : " Engineer"}
            </p>
          </div>
        </div>
      );
    case AutoCompleteTypeEnum.STUDIO:
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            onListItemClick(autocompleteItem);
          }}
          className="entity-search-result-list-item"
        >
          <img
            src={useFallback ? defaultUser : getImageUrl()}
            width={44}
            height={44}
            onError={() => {
              setUseFallback(true);
            }}
            alt="studio profile"
          />
          <div className="entity-search-result-list-item-description">
            <span className="b2-semi-bold">
              {autocompleteItem.display_name}
            </span>
            <p className="b3 mx-2">
              {autocompleteItem.display_name !== autocompleteItem.username
                ? ` Studio • @${autocompleteItem.username}`
                : " Studio"}
            </p>
          </div>
        </div>
      );
    case AutoCompleteTypeEnum.LOCATION:
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            onListItemClick(autocompleteItem);
          }}
          className="entity-search-result-list-item"
        >
          <div
            className="entity-search-result-icon"
            style={{ backgroundColor: "var(--sky-light-blue)" }}
          >
            <img src={locationIcon} alt="location" />
          </div>
          <div className="entity-search-result-list-item-description">
            <span className="b2-semi-bold">{autocompleteItem.label}</span>
          </div>
        </div>
      );
    case AutoCompleteTypeEnum.GENRE:
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            onListItemClick(autocompleteItem);
          }}
          className="entity-search-result-list-item"
        >
          <div
            className="entity-search-result-icon"
            style={{ backgroundColor: "var(--timbre-tan)" }}
          >
            <img src={folderIcon} alt="genre" />
          </div>
          <div className="entity-search-result-list-item-description">
            <span className="b2-semi-bold">{autocompleteItem.label}</span>
            <p className="b3 mx-2">genre</p>
          </div>
        </div>
      );
    case AutoCompleteTypeEnum.SERVICE_TYPE:
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            onListItemClick(autocompleteItem);
          }}
          className="entity-search-result-list-item"
        >
          <div
            className="entity-search-result-icon"
            style={{ backgroundColor: "var(--echo-orange)" }}
          >
            <img src={soundwaveIcon} alt="service type" />
          </div>
          <div className="entity-search-result-list-item-description">
            <span className="b2-semi-bold">{autocompleteItem.label}</span>
            <p className="b3 mx-2">service type</p>
          </div>
        </div>
      );
    case AutoCompleteTypeEnum.ARTIST_CREDIT:
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            onListItemClick(autocompleteItem);
          }}
          className="entity-search-result-list-item"
        >
          <div className="entity-search-result-icon">
            <img src={musoIcon} alt="artist credit" />
          </div>
          <div className="entity-search-result-list-item-description">
            <p className="b3">has worked with</p>
            <span className="b2-semi-bold mx-2">{autocompleteItem.label}</span>
          </div>
        </div>
      );
    case AutoCompleteTypeEnum.EQUIPMENT:
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            onListItemClick(autocompleteItem);
          }}
          className="entity-search-result-list-item"
        >
          <div className="entity-search-result-icon">
            {
              equipmentTypeIconMap[
                autocompleteItem.equipment_type as EquipmentTypeEnum
              ]
            }
          </div>
          <div className="entity-search-result-list-item-description">
            <p className="b3">
              {
                EquipmentTypeReadableNameMap[
                  autocompleteItem.equipment_type as EquipmentTypeEnum
                ]
              }
            </p>
            <span className="b2-semi-bold mx-2">{autocompleteItem.label}</span>
          </div>
        </div>
      );
    default:
      return null;
  }
};
