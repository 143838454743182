import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select, SelectProps, Theme } from "@mui/material";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import {
  ClearSelectionButton,
  OptionsDropdownContainer,
} from "./MUIOptionsDropdown.styles";

type OptionsDropdownProps<T> = SelectProps<T> & {
  placeholder?: string;
  options: OptionType<T>[];
  overrideSx?: (theme: Theme) => object;
  onClearValue?: () => void;
};

export const OptionsDropdown = <T extends string | number>({
  placeholder = "Choose an option",
  options,
  overrideSx,
  onClearValue,
  ...restProps
}: OptionsDropdownProps<T>) => {
  const shouldDisplayClearValueButton = Boolean(
    restProps.value && onClearValue,
  );

  return (
    <OptionsDropdownContainer>
      <Select
        displayEmpty
        IconComponent={(iconProps) => (
          <FontAwesomeIcon icon={faChevronDown} {...iconProps} />
        )}
        MenuProps={{
          PaperProps: {
            sx: (theme) => {
              return {
                border: `1px solid ${theme.palette.text.primary}`,
                marginTop: "8px",
                padding: "8px",
                boxSizing: "border-box",
                ".MuiMenuItem-root.Mui-selected, .MuiButtonBase-root-MuiMenuItem-root":
                  {
                    backgroundColor: theme.palette.customColor.midgroundColor,

                    "&:hover": {
                      backgroundColor: theme.palette.customColor.midgroundColor,
                    },
                  },

                ".MuiList-root.MuiMenu-list": {
                  paddingTop: 0,
                  paddingBottom: 0,
                },

                ".MuiMenuItem-root": {
                  padding: "16px",
                  color: theme.palette.text.secondary,
                },
              };
            },
          },
        }}
        sx={(theme) => ({
          width: "100%",

          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: theme.palette.text.primary,
              borderWidth: "1px",
            },

          "&.MuiOutlinedInput-root .MuiOutlinedInput-input": {
            padding: "14px 16px",
            minHeight: "unset",
            lineHeight: 1,
            paddingRight: "40px",
          },

          "&:hover": {
            "& .MuiSelect-icon": {
              display: shouldDisplayClearValueButton ? "none" : "block",
            },
          },

          "& .MuiSelect-icon": {
            right: "16px",
            color: theme.palette.text.primary,
          },

          ...(overrideSx ? overrideSx(theme) : {}),
        })}
        {...restProps}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {shouldDisplayClearValueButton && (
        <ClearSelectionButton
          className="clear-selection-btn"
          onClick={onClearValue}
          component="button"
        >
          <FontAwesomeIcon icon={faXmark} fontSize="8px" />
        </ClearSelectionButton>
      )}
    </OptionsDropdownContainer>
  );
};
