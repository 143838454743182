import { SCREENS } from "../../../routes/screens";
import Dropdown, { MenuItem } from "./Dropdown";

export const learnMoreLinks: MenuItem[] = [
  {
    label: "Help Center",
    link: {
      href: "https://support.engineears.com/en/knowledge-base",
    },
    analytics: {
      label: "help_center_click",
      data: { source: "learn_dropdown" },
    },
  },
  {
    label: "Community",
    link: {
      href: SCREENS.COMMUNITY,
    },
    analytics: {
      label: "community_click",
      data: { source: "learn_dropdown" },
    },
  },
  {
    label: "EngineEars Blog",
    link: {
      href: SCREENS.BLOG_SCREEN,
    },
    analytics: {
      label: "blog_click",
      data: { source: "learn_dropdown" },
    },
  },
  {
    label: "Dolby Atmos Course",
    link: {
      href: SCREENS.DOLBY_ATMOS,
    },
    analytics: {
      label: "dolby_atmos_click",
      data: { source: "learn_dropdown" },
    },
  },
  {
    label: "Pro Tools Course",
    link: {
      href: SCREENS.PRO_TOOLS,
    },
    analytics: {
      label: "pro_tools_click",
      data: { source: "learn_dropdown" },
    },
  },
];

const LearnDropdown = () => {
  return <Dropdown buttonText="Learn" menuItems={learnMoreLinks} />;
};

export default LearnDropdown;
