import queryString from "query-string";
import { PromoCode } from "../../store/models/promoCode";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { PROMO_CODE_API } from "../../store/utils/routes";

export interface OptInResponse {
  campaigns_opted_in: boolean;
  competitions_opted_in: boolean;
  opted_into_unverified_engineer_promotion: boolean;
}

export interface GetUserPromoCodesResponse extends OptInResponse {
  data: PromoCode[];
  page: number;
  num_pages: number;
}

export const getPromoCodes = async (
  studioId: number | null,
): Promise<GetUserPromoCodesResponse> => {
  const params = queryString.stringify(
    {
      studio_id: studioId,
    },
    {
      skipNull: true,
      skipEmptyString: true,
    },
  );
  const response =
    await makeBackendGetCallWithJsonResponse<GetUserPromoCodesResponse>(
      PROMO_CODE_API,
      params ? "?".concat(params) : "",
    );
  if (response.success) {
    return response.resultJson;
  }
  throw response.resultJson;
};
