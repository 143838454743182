import { useLocalStorageState } from "ahooks";
import { useState } from "react";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/localstorageConstants";
import {
  AnchorPointProps,
  availableMaxDistanceOptionValue,
  MAX_LOCATION_FILTER_HISTORY_COUNT,
  MaxDistanceOptionValue,
} from "./LocationFilterPopover.utils";

export interface useLocationFilterProps {
  defaultMaxDistance?: number;
  defaultAnchorPoint?: AnchorPointProps;
}

export const useLocationFilter = ({
  defaultMaxDistance,
  defaultAnchorPoint,
}: useLocationFilterProps) => {
  const [maxDistance, setMaxDistance] = useState<
    MaxDistanceOptionValue | undefined
  >(() => {
    if (
      defaultMaxDistance &&
      availableMaxDistanceOptionValue.includes(defaultMaxDistance)
    ) {
      return defaultMaxDistance;
    }

    return MaxDistanceOptionValue.TEN;
  });
  const [anchorPoint, setAnchorPoint] = useState(defaultAnchorPoint);
  const { cachedLocationHistory, updateCachedAnchorPoint } =
    useCachedAnchorPoint();

  return {
    maxDistance,
    setMaxDistance,
    anchorPoint,
    setAnchorPoint,
    cachedLocationHistory,
    updateCachedAnchorPoint,
  };
};

export const useCachedAnchorPoint = () => {
  const [cachedLocationHistory, setCachedLocationHistory] =
    useLocalStorageState<AnchorPointProps[]>(
      LOCAL_STORAGE_KEYS.LOCATION_FILTER_HISTORY,
      { defaultValue: [] },
    );

  const updateCachedAnchorPoint = (updatedAnchorPoint?: AnchorPointProps) => {
    if (!updatedAnchorPoint) {
      return;
    }

    // Remove duplication if there is one
    const filteredCachedLocationHistory =
      cachedLocationHistory?.filter(
        ({ formatted_address }) =>
          formatted_address !== updatedAnchorPoint.formatted_address,
      ) || [];

    // Update cached location history
    setCachedLocationHistory(
      [updatedAnchorPoint, ...filteredCachedLocationHistory].slice(
        0,
        MAX_LOCATION_FILTER_HISTORY_COUNT,
      ),
    );
  };

  return { cachedLocationHistory, updateCachedAnchorPoint };
};
