import { Box, BoxProps, TabProps, Tabs, TabsProps } from "@mui/material";
import { ReactNode } from "react";
import { Text, TextProps } from "../Text/Text";
import { StyledTab } from "./TabsV2.styles";

export interface TabsV2Props {
  // using a record forces unique keys to be created when rendering the tabs
  tabs: Record<string, ReactNode>;
  onChange: TabsProps["onChange"];
  selectedTab: TabsProps["value"];
  sx?: BoxProps["sx"];
  TabsProps?: TabsProps;
  TabProps?: TabProps;
  TextProps?: Omit<TextProps, "children">;
}

/**
 * This version of Tabs is based on the Tabs used on the Booking Screen.
 * It works similarly to the old Tabs component, however it utilizes MUI
 * components.
 *
 * @TODO - Update other usages of Tabs to use this component
 */
export const TabsV2 = ({
  tabs,
  selectedTab,
  onChange,
  TabsProps,
  TabProps,
  TextProps,
  sx,
}: TabsV2Props) => {
  const tabTitles = Object.entries(tabs).map(([key, label]) => ({
    key,
    label,
  }));
  return (
    <Box sx={{ display: "flex", position: "relative", ...sx }}>
      {/* Bottom gray bar */}
      <Box
        sx={(theme) => ({
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "4px",
          background: theme.palette.customColor.darkerMidgroundColor,
        })}
      />
      <Tabs
        value={selectedTab}
        onChange={onChange}
        variant="scrollable"
        sx={(theme) => ({
          [".MuiTabs-indicator"]: {
            height: theme.spacing(0.5),
            backgroundColor: theme.palette.primary.main,
          },
        })}
        {...TabsProps}
      >
        {tabTitles.map((tab, index) => (
          <StyledTab
            key={`${tab.key}-${index}`}
            disableRipple
            value={index}
            label={
              typeof tab.label === "string" ? (
                <Text {...TextProps}>{tab.label}</Text>
              ) : (
                tab.label
              )
            }
            {...TabProps}
          />
        ))}
      </Tabs>
    </Box>
  );
};
