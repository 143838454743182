import { useMemo } from "react";
import { ProjectType } from "../../store/models/project";
import {
  parseQueryStringAsNumberArray,
  parseUrlQueryParams,
} from "../../utils/searchFiltersUtils";
import { useUpdateSearchFilters } from "./useUpdateSearchFilters";
import { SearchFiltersQueryParams, SearchScreenParamKeys } from "./utils";

export const useServiceTypesQuery = () => {
  const parsedQueryParams = parseUrlQueryParams();
  const updateSearchFilters = useUpdateSearchFilters();
  const serviceTypesValue =
    parsedQueryParams[SearchScreenParamKeys.ServiceTypes];

  const serviceTypes = useMemo(
    () =>
      parseQueryStringAsNumberArray(serviceTypesValue).filter((el) =>
        Object.values(ProjectType).includes(el),
      ) as ProjectType[],
    [serviceTypesValue],
  );

  const setServiceType = (serviceType: ProjectType, newPathname?: string) => {
    let newServiceTypes: ProjectType[] = [];
    const updatedSearchQueryObject = {} as SearchFiltersQueryParams;

    if (serviceTypes.includes(serviceType)) {
      newServiceTypes = serviceTypes.filter(
        (service) => service !== serviceType,
      );
    } else {
      newServiceTypes = [...serviceTypes, serviceType];
    }

    if (newServiceTypes.length === 5 || serviceType === ProjectType.NO_TYPE) {
      updatedSearchQueryObject[SearchScreenParamKeys.ServiceTypes] = undefined;
    } else {
      updatedSearchQueryObject[SearchScreenParamKeys.ServiceTypes] =
        newServiceTypes.join(",");
    }

    updateSearchFilters({
      updatedSearchQueryObject,
      newPathname,
      resetPagination: true,
    });
  };

  const setServiceTypes = (newServiceTypes: ProjectType[]) => {
    updateSearchFilters({
      updatedSearchQueryObject: {
        [SearchScreenParamKeys.ServiceTypes]: newServiceTypes.join(","),
      },
    });
  };

  return { setServiceType, serviceTypes, setServiceTypes };
};
