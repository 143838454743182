import User from "../../../store/models/user";
import { makeBackendPostCallWithJsonResponse } from "../../../store/utils/fetch";
import { UPDATE_PROFILE } from "../../../store/utils/routes";

export interface MutateProfileProps {
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  birth_date?: string;
  bio?: string;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_term?: string | null;
  timezone_shift_minutes?: number;
  timezone?: string;
  phone_number?: string;
  soundcloud_username?: string;
  instagram_username?: string;
  twitter_username?: string;
  facebook_username?: string;
  twitch_username?: string;
  tiktok_username?: string;
  youtube_username?: string;
  spotify_username?: string;
  apple_username?: string;
  amazon_username?: string;
  tidal_username?: string;
  long_bio?: string;
  country?: string;
  city?: string;
  region?: string;
  banner_color?: number;
  disable_sms_notifications?: boolean;
  tab_order_preferences?: string;
  latitude?: number;
  longitude?: number;
  update_user_location?: boolean;
  anonymous_id?: string;
  migrate_anonymous_id?: boolean;
}

export const postUpdateProfile = async (body: MutateProfileProps) => {
  if (body.username) {
    body = {
      ...body,
      username: body.username?.toLowerCase(),
    };
  }
  if (body.email) {
    body = {
      ...body,
      email: body.email?.toLowerCase(),
    };
  }
  if (body.phone_number) {
    body = {
      ...body,
      phone_number: body.phone_number?.replace(/\s/g, "").trim(),
    };
  }
  if (!body.timezone) {
    delete body.timezone;
  }
  const response = await makeBackendPostCallWithJsonResponse<User>(
    UPDATE_PROFILE,
    body,
  );
  if (response.success) {
    return response.resultJson;
  }
  throw response.resultJson;
};
