import { useMemo } from "react";
import { ProjectType } from "../../../../store/models/project";
import { getServiceFromServiceType } from "../../../../store/utils/serviceUtils";
import { EditableTextArea } from "../../../elements/EditableTextArea/EditableTextArea";
import { StyledForm } from "./ServicesTab.styles";

interface ServiceDescriptionTabProps {
  description: string;
  setDescription: (description: string) => void;
  selectedServiceType: ProjectType;
}

export const ServiceDescriptionTab = ({
  setDescription,
  description,
  selectedServiceType,
}: ServiceDescriptionTabProps) => {
  const serviceName = useMemo(() => {
    let suffix = " service";
    if (selectedServiceType === ProjectType.RECORDING) {
      suffix = " (hourly)";
    }
    return getServiceFromServiceType(selectedServiceType, true) + suffix;
  }, [selectedServiceType]);

  const servicePlaceholderText = useMemo(() => {
    let serviceText = "mix";
    if (selectedServiceType === ProjectType.RECORDING) {
      serviceText = "record";
    } else if (selectedServiceType === ProjectType.MASTERING) {
      serviceText = "master";
    }
    return serviceText;
  }, [selectedServiceType]);

  return (
    <StyledForm>
      <label htmlFor="service-description">
        Describe to potential clients what makes your {serviceName} stand out?
      </label>
      <EditableTextArea
        initialValue={description ?? ""}
        editMode={true}
        placeholder={`When I ${servicePlaceholderText}, I uniquely offer…`}
        numberOfLines={8}
        textAreaProps={{
          id: "service-description",
        }}
        onChange={setDescription}
        characterCount={1000}
      />
    </StyledForm>
  );
};
