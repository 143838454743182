import { useAtom } from "jotai";
import { lazy, Suspense } from "react";
import { isSearchFiltersPanelOpenAtom } from "../../../../atoms/searchFiltersPanelAtom";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { CenteredSoundWaveLoader } from "../../CenteredSoundWaveLoader/CenteredSoundWaveLoader";
import SidePanelCloseButton from "../../ProjectFilters/SidePanelFilters/SidePanelCloseButton";
import { SidePanel } from "../../SidePanel/SidePanel";

const SearchFiltersPaneBodylWrapper = lazy(
  () => import("./SearchFiltersPanelBodyWrapper"),
);

export const SearchFiltersPanel = () => {
  const [isOpen, toggle] = useAtom(isSearchFiltersPanelOpenAtom);
  const { isMobile } = useMediaQueryBreakpoint();

  const handleClosePanel = () => {
    toggle(false);
  };

  return (
    <SidePanel
      isOpen={isOpen}
      onClose={handleClosePanel}
      fillScreen={isMobile}
      hideCloseButton
    >
      <SidePanelCloseButton onClick={handleClosePanel} />
      <Suspense fallback={<CenteredSoundWaveLoader />}>
        {isOpen && <SearchFiltersPaneBodylWrapper onClose={handleClosePanel} />}
      </Suspense>
    </SidePanel>
  );
};
