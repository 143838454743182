import { Dialog, styled } from "@mui/material";

export const EngineEarsDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.palette.customColor.backgroundColor,
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.customColor.foregroundColor,
  },
  "& .MuiDialogActions-root": {
    backgroundColor: theme.palette.customColor.foregroundColor,
  },
}));
