import { Box, Skeleton } from "@mui/material";
import { useAtomValue } from "jotai";
import { editMode } from "../../../../../atoms/profileScreenEdit";
import { FrequentlyAskedQuestion } from "../../../../../store/models/frequentlyAskedQuestion";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import { FrequentlyAskedItemsWrapper } from "./FrequentlyAskedItemsWrapper";
import { FrequentlyAskedSectionBox } from "./FrequentlyAskedSection.styles";

interface FrequentlyAskedSectionWrapperProps {
  faqData: FrequentlyAskedQuestion[];
  isInitialLoadingFaqs: boolean;
  editModeText?: string;
}

export const FrequentlyAskedSectionWrapper = ({
  faqData,
  isInitialLoadingFaqs,
  editModeText,
}: FrequentlyAskedSectionWrapperProps) => {
  const isEditMode = useAtomValue(editMode);

  if (isInitialLoadingFaqs) {
    return (
      <FrequentlyAskedSectionBox>
        <Skeleton width="100px" height="1.75rem" animation="wave" />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Skeleton width="100%" height="40px" animation="wave" />
          <Skeleton width="100%" height="40px" animation="wave" />
        </Box>
      </FrequentlyAskedSectionBox>
    );
  }

  if (faqData.length === 0 && !isEditMode) {
    return null;
  }

  return (
    <FrequentlyAskedSectionBox $isEditMode={isEditMode}>
      <Text variant={TextStyleVariant.S2}>FAQ</Text>
      {isEditMode && (
        <Text>
          {editModeText
            ? editModeText
            : "Adding Frequently Asked Questions will help your customers book you more often."}
        </Text>
      )}
      <FrequentlyAskedItemsWrapper items={faqData} editMode={isEditMode} />
    </FrequentlyAskedSectionBox>
  );
};
