import { faMusic } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { useFetchArtistReleasesQuery } from "../../../api/releases/hooks/useFetchArtistReleasesQuery";
import { editMode } from "../../../atoms/profileScreenEdit";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { ReleaseType } from "../../../store/models/release";
import { Text } from "../../core-ui/components/Text/Text";
import {
  EmptyContainer,
  EmptyContainerFaIcon,
} from "../../screens/ProfileScreen/ProfileScreen.styles";
import { ArtistReleases } from "../ArtistReleases/ArtistReleases";
import { useGetFrequentlyAskedQuestions } from "../../../hooks/profileScreenHooks/useGetFrequentlyAskedQuestions";
import { FrequentlyAskedSectionWrapper } from "../../screens/ProfileScreen/components/FrequentlyAskedSection/FrequentlyAskedSectionWrapper";
import { useGetArtistsCompletedProjectsCount } from "../../../hooks/projectHooks/useGetArtistsCompletedProjectsCount";
import { useIsVisitingOwnProfile } from "../../../hooks/profileScreenHooks/useIsVisitingOwnProfile";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { SCREENS } from "../../../routes/screens";
import { getMyBookingsRoute } from "../../../store/utils/routeGetters";
import { BookingQueryParamKeys } from "../../screens/BookingsScreen/hooks";
import { ScheduledProjectStage } from "../../../store/models/scheduledproject";
import { TrackStage } from "../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";

interface ReleaseOverviewProps {
  onReleaseClick: (releaseId?: number) => void;
  children?: ReactNode;
}

export const ReleaseOverview = ({
  children,
  onReleaseClick,
}: ReleaseOverviewProps) => {
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const isEditMode = useAtomValue(editMode);
  const isVisitingOwnProfile = useIsVisitingOwnProfile();
  const { data, isPending } = useFetchArtistReleasesQuery(
    userData?.id || 0,
    ReleaseType.NO_RELEASE_TYPE,
    1,
  );
  const { data: faqData, isLoading: isInitialLoadingFaqs } =
    useGetFrequentlyAskedQuestions(
      userData?.artist?.verified ? userData?.id : undefined,
    );
  const { data: artistsProjectCountData } = useGetArtistsCompletedProjectsCount(
    {
      enabled: isVisitingOwnProfile,
    },
  );
  const userHasCompletedProjects =
    (artistsProjectCountData?.completed_projects_count ?? 0) > 0;
  const emptyReleaseCTAHref = userHasCompletedProjects
    ? getMyBookingsRoute("projects", {
        [BookingQueryParamKeys.Stages]: ScheduledProjectStage.COMPLETED,
        [BookingQueryParamKeys.TrackStages]: TrackStage.COMPLETED,
      })
    : SCREENS.SEARCH;

  if (!isPending && (!data || data.count === 0)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "54px",
          textAlign: "center",
        }}
      >
        <EmptyContainer>
          <EmptyContainerFaIcon icon={faMusic} />
          {artistsProjectCountData && isEditMode && (
            <>
              <Text>
                {userHasCompletedProjects
                  ? "Select a completed project on EngineEars to feature on your profile here"
                  : "Start a project to build a following"}
              </Text>
              <Button
                variant={ButtonVariant.OUTLINED}
                href={emptyReleaseCTAHref}
                onClick={() => {
                  emitAnalyticsTrackingEvent(
                    TRACKING_EVENTS_NAME.USER_CLICKED_EMPTY_RELEASES_CTA,
                    {
                      did_user_have_zero_completed_projects: Boolean(
                        artistsProjectCountData.completed_projects_count === 0,
                      ),
                    },
                  );
                }}
              >
                {userHasCompletedProjects
                  ? "Select a Project to Publish"
                  : "Start a project"}
              </Button>
            </>
          )}
          {!isEditMode && <Text>No releases for this artist available</Text>}
        </EmptyContainer>
        {userData?.artist?.verified && (
          <FrequentlyAskedSectionWrapper
            faqData={faqData}
            isInitialLoadingFaqs={isInitialLoadingFaqs}
            editModeText={
              "Adding Frequently Asked Questions will help your listeners find what they need."
            }
          />
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "54px",
        textAlign: "center",
      }}
    >
      {children}
      <ArtistReleases onReleaseClick={onReleaseClick} />
      {userData?.artist?.verified && (
        <FrequentlyAskedSectionWrapper
          faqData={faqData}
          isInitialLoadingFaqs={isInitialLoadingFaqs}
          editModeText={
            "Adding Frequently Asked Questions will help your listeners find what they need."
          }
        />
      )}
    </Box>
  );
};
